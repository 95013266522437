import React from 'react'
import { Helmet } from "react-helmet"
import { connect } from 'react-redux'
import Chart from "react-apexcharts"
// import MainDashboardPagination from '../../Pagination/MainDashboardPagination'
import DayProductPagination from '../../../modules/ProductPagination/DayProductPagination'
import "./loader.css"
import SalesKPis from '../KPIs'
import ProductsTable from '../ProductsTable'
const DayDashboard = (props) => {
    const FormData = props.kpiData
    const currencyFormatter = props.currencyFormatter
    const numberFormatter = props.numberFormatter
    const CostProfitData = props.costProfitKPIData
    const SalesTrendingData = props.trendingData
    const xaxis = SalesTrendingData?.map(data => data.trend);
    const revenueSeries = SalesTrendingData?.map(data => data.revenue);
    const unitsSeries = SalesTrendingData?.map(data => data.units);

    const DcData = props.dcData
    const CPTData = props.cptData
    const ProductData = props.productData
    const PageSize = props.pageSize
    const TotalProducts = props.TotalProducts
    const CurrentDate = props.currentDate
    const LoadDayProductSalesList = props.LoadDayProductSalesList
    const MonthProfile = props.MonthProfile
    const YearProfile = props.YearProfile
    const Sortby = props.Sortby
    const SortDirection = props.SortDirection
    const onSortChange = props.onSortChange
    const onPageSizeChange = props.onPageSizeChange
    const onSortDirectionChange = props.onSortDirectionChange
    const apexChartSeries = (seriesName, seriesData, seriesName1, seriesData1) =>
    (
        [
            {
                name: seriesName,
                data: seriesData,
                type: 'line'
            },
            {
                name: seriesName1,
                data: seriesData1,
                type: 'column'
            }
        ]
    )
    const apexChartDualOptions = (chartId, xaxis, seriesA, seriesB) =>
    (
        {
            chart: {
                id: chartId,
                width: "100%"
            },
            dataLabels: {
                enabled: true,
            },
            stroke: {
                width: [0, 4]
            },
            markers: {
                size: 1
            },
            xaxis: {
                categories: xaxis
            },
            yaxis: [
                {
                    title: {
                        text: seriesA
                    },
                },
                {
                    opposite: true,
                    title: {
                        text: seriesB
                    }
                }
            ],
            colors: ['#1B2850', '#FF9F43',],
            stroke: {
                curve: 'smooth'
            },
            dataLabels: {
                enabled: false,
                style: {
                    fontSize: '12px',
                    fontWeight: 'bold',
                    color: 'black'
                },
                background: {
                    enabled: true,
                    foreColor: 'black',
                    borderRadius: 2,
                    padding: 4,
                    opacity: 0.9,
                    borderWidth: 1,
                    borderColor: '#fff',
                },
            },
            plotOptions: {
                pie: {
                    legend: {
                        position: 'bottom'
                    }
                }
            },
            grid: {
                show: false
            }

        }
    )
    return (
        <>
            <Helmet>
                <link rel="stylesheet" href="../assets/css/style.css" />
                <script src="../assets/js/script.js" />
                <script src="../assets/plugins/apexchart/chart-data.js" />
            </Helmet>
            <div>
                <SalesKPis FormData={ FormData } currencyFormatter={ currencyFormatter } numberFormatter={ numberFormatter } />
                {/* <!-- Button trigger modal --> */ }
                <br />
                <div className="row">
                    <div className="col-lg-7 col-sm-12 col-12 d-flex">
                        <div className="card flex-fill">
                            <div className="card-header pb-0 d-flex justify-content-between align-items-center">
                                <h5 className="card-title mb-0">Sales</h5>

                            </div>
                            <div className="card-body">
                                {/* <div id="sales_charts"></div> */ }
                                <Chart
                                    options={ apexChartDualOptions('sales-trending-data', xaxis, 'Sales', 'Quantity') }
                                    series={ apexChartSeries('Revenue', revenueSeries, 'Quantity', unitsSeries) }
                                    type="line"
                                    width="100%"
                                    height="100%"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-sm-12 col-12 d-flex">
                        <div className="card flex-fill">
                            {
                                DcData?.map(
                                    (dc, index) => {
                                        return (

                                            <div key={ index }>
                                                <div className="card-header pb-0 mb-0 d-flex justify-content-between align-items-center">
                                                    <h4 className="card-title mb-0">DC / { dc?.dc }</h4>
                                                </div>
                                                <div className="card-body pb-0 mb-0">
                                                    <div className='row'>
                                                        <div className="col-lg-6 col-sm-6 col-6 d-flex">
                                                            <div className="dash-count das2">
                                                                <div className="dash-counts">
                                                                    { dc?.kpis?.revenue || dc?.kpis?.revenue >= 0 ? (
                                                                        <h4>{ currencyFormatter.format(dc?.kpis?.revenue) }</h4>
                                                                    ) :
                                                                        <h5>
                                                                            <span className="skeleton-loader"></span>
                                                                        </h5>
                                                                    }
                                                                    <h5>Total Revenue</h5>
                                                                </div>
                                                                <div className="dash-imgs">
                                                                    <i data-feather="user"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-sm-6 col-6 d-flex">
                                                            <div className="dash-count">
                                                                <div className="dash-counts">
                                                                    { dc?.kpis?.revenue || dc?.kpis?.revenue >= 0 ? (
                                                                        <h4>{ numberFormatter.format(dc?.kpis?.units) }</h4>
                                                                    ) :
                                                                        <h5>
                                                                            <span className="skeleton-loader"></span>
                                                                        </h5>
                                                                    }
                                                                    <h5>Total Units</h5>
                                                                </div>
                                                                <div className="dash-imgs">
                                                                    <i data-feather="user"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className="card mb-0">
                    <div className="card-body">
                        <div className="card-header pb-0 d-flex justify-content-between align-items-center">
                            <h4 className="card-title">Products</h4>
                            <div className="graph-sets">

                                <div className="dropdown mx-1">
                                    <button className="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Page Size
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><a className={ PageSize === "10" ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onPageSizeChange(e, "10") } } href="#">10</a></li>
                                        <li><a className={ PageSize === "20" ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onPageSizeChange(e, "20") } } href="#">20</a></li>
                                        <li><a className={ PageSize === "50" ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onPageSizeChange(e, "50") } } href="#">50</a></li>
                                        <li><a className={ PageSize === "100" ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onPageSizeChange(e, "100") } } href="#">100</a></li>
                                    </ul>
                                </div>
                                <div className="dropdown">
                                    <button className="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Sort by
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><a className={ Sortby === "units" ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onSortChange(e, "units") } } href="#">Units</a></li>
                                        <li><a className={ Sortby === "sales" ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onSortChange(e, "sales") } } href="#">Sales</a></li>
                                        {/* <li><a className={Sortby === "cost" ? "dropdown-item active" : "dropdown-item"} onClick={(e)=>{onSortChange(e, "cost")}} href="#">Cost</a></li>
                                        <li><a className={Sortby === "profit" ? "dropdown-item active" : "dropdown-item"} onClick={(e)=>{onSortChange(e, "profit")}} href="#">Profit</a></li> */}
                                    </ul>
                                </div>
                                <div className='py-1'>
                                    <button
                                        onClick={ (e) => { onSortDirectionChange(e, "up") } }
                                        className={
                                            SortDirection === "up" ?
                                                'btn btn-primary mx-1 my-1'
                                                :
                                                'btn btn-outline-primary mx-1'
                                        }
                                    >
                                        <i class="fa-solid fa-arrow-up-wide-short"></i>
                                    </button>
                                </div>
                                <div className='py-1'>
                                    <button
                                        onClick={ (e) => { onSortDirectionChange(e, "down") } }
                                        className={
                                            SortDirection === "down" ?
                                                'btn btn-primary mx-1'
                                                :
                                                'btn btn-outline-primary mx-1 my-1'
                                        }
                                    >
                                        <i class="fa-solid fa-arrow-down-wide-short"></i>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="table-responsive dataview">
                            <ProductsTable ProductData={ ProductData } PageSize={ PageSize } currencyFormatter={ currencyFormatter } numberFormatter={ numberFormatter } />

                            <br />
                            <div className='pagination justify-content-center'>

                                {
                                    TotalProducts > 0 ?
                                        <DayProductPagination
                                            currentPage={ props.currentPage }
                                            setPage={ props.setCurrentPage }
                                            totalPages={ props.TotalPages }
                                            pageSize={ PageSize }
                                            sBy={ Sortby }
                                            month={ props.month }
                                            year={ props.monthYear }
                                            sDirection={ SortDirection }
                                            onPageChange={ LoadDayProductSalesList }
                                        />
                                        // <MainDashboardPagination totalRecords={TotalProducts} pageLimit={Number(PageSize)} sortBy={Sortby} sortDirection={SortDirection} paginationDate={MonthYear+"-"+Month} onPageChanged={LoadMonthProductSalesList}  />
                                        : <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.Auth.isAuthenticated,
    user: state.Auth.user,
})

export default connect(mapStateToProps)(DayDashboard)