import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import Helmet from 'react-helmet'
import Swal from 'sweetalert2'
const Calculator = (props) => {
    const CompanyProfile = props.CompanyProfile
    const location = useLocation();
    const { state } = location
    const dashboard = state['dashboard']
    const [ProductSKU, setProductSKU] = useState(state['sku'])
    const [productDetails, setproductDetails] = useState(state['tsin_id'])
    const [productCostPerUnit, setproductCostPerUnit] = useState({
        dashboard: state['dashboard'],
        tsin_id: state['tsin_id'],
        sku: state['sku'],
        supplier: "",
        price_per_unit : 0,
        total_units_ordered : 0,
        cost_per_unit : 0,
        cost_per_unit_usd : 0,
        storage_cost_per_unit : 0,
        shipment_cost_per_unit : 0
    })
    const [errorData, setErrorData] = useState({
        status: "",
        message: "",
        errors: []  
    })
    const onChange = e => {
        setproductCostPerUnit({...productCostPerUnit, [e.target.name]: e.target.value})    
    }

    const [LoadingVisibility, setLoadingVisibility] = useState("none")
    const [dataVisibility, setDataVisibility] = useState("block")
    
    const formatter = new Intl.NumberFormat('en-ZA', {
        style: 'currency',
        currency: 'ZAR',
    
        // These options are needed to round to whole numbers if that's what you want.
        minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    })
    const loadProductDetails = async() => {
        setLoadingVisibility("block")
        setDataVisibility("none")
        const config = {
            headers: {
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
                'Authorization' : `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify(
            {
                "dashboard" : state['dashboard'],
                "tsin_id" : state['tsin_id'],
                "sku" : state['sku']
            }
        )
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/cost/getProductCost/`, Body,config)
            if (response.data['data'] !== 0) {
                setproductCostPerUnit(response.data['data'])
            } 
        } catch (error) {
            // console.log('first', error)
        }
        setLoadingVisibility("none")
        setDataVisibility("block")
    }
    
    const [SuccessMessage, setSuccessMessage] = useState("")
    const [SuccessMessageVisibility, setSuccessMessageVisibility] = useState("none")
    const [SubmissionErrorVisibilty, setSubmissionErrorVisibilty] = useState("none")
    const insertProductPrice = async() => {
        setLoadingVisibility("block")
        setDataVisibility("none")
        const config = {
            headers: {
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
                'Authorization' : `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify(
            productCostPerUnit
        )
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/insertProductCost/`, Body,config)
            Swal.fire({
                position: "bottom-end",
                type: "success",
                title: "Success",
                html: 'Cost has been updated.',
                showConfirmButton: !1,
                timer: 1500,
                confirmButtonClass: "btn btn-primary",
                buttonsStyling: !1,
            })
        } catch (error) {
            console.log(error.response)
            // console.log('first', error)
            setErrorData({
                status: error.response.status,
                message: error.response.data.detail,
                errors: error.response.errors,
            });
        
            setSubmissionErrorVisibilty("block");
            setTimeout(() => {
                setSubmissionErrorVisibilty("none")
            }, 5000)
        }
        setLoadingVisibility("none")
        setDataVisibility("block")
    }
      
    const onSubmit = e => {
        e.preventDefault()
        insertProductPrice()
    
        // window.location.reload();
    }
      
    const functionsCalledOnLoad = () => {
        loadProductDetails()
    }
    useEffect(() => {
        
        functionsCalledOnLoad()
        setProductSKU(state['tsin_id'])
    }, [])

    return (
        <>
            
            <Helmet>				
				<title>{CompanyProfile['companyName']} Products</title>
					<meta
						name="description"
						content={`${CompanyProfile['companyName']} ${ProductSKU}'s Cost Calculator`}
					/>
				<link rel="stylesheet" href="../assets/css/style.css"/>
                <script src="../assets/js/script.js" />
            </Helmet>
            <div style={{display: LoadingVisibility}}>
                <div id="global-loader" >
                    <div className="whirly-loader"> </div>
                </div>
            </div>
            <div className="content">
                <div style={{display: dataVisibility}}>
                <div className="row mb-4">
                    <div className="col-md">
                        <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Product /</span> Cost Calculator</h4>
                        <div className="card mb-4">
                            <div className="alert alert-danger text-center" style={{display: SubmissionErrorVisibilty}} role="alert">
                                <p><b>Error {errorData.status}: {errorData.message} </b></p>
                            </div>
                            <div className="card-body">
                                <form onSubmit={e => onSubmit(e)}>
                                <div className='row'>
                                    <label className="col-form-label h4" style={{textAlign: "center"}} htmlFor="basic-default-name">Currency ZAR</label>
                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label" htmlFor="basic-default-name">Supplier</label>
                                        <div className="col-sm-10">
                                            <div className="input-group">
                                            <input type="text" min={1} className="form-control" value={productCostPerUnit['supplier']} name="supplier" onChange={(e)=>{onChange(e)}} id="basic-default-name" placeholder='Supplier Name'/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label" htmlFor="basic-default-name">Total Unit Ordered</label>
                                        <div className="col-sm-10">
                                            <div className="input-group">
                                            <span className="input-group-text">R</span>
                                            <input type="number" className="form-control" value={productCostPerUnit['total_units_ordered']} name="total_units_ordered" onChange={(e)=>{onChange(e)}} id="basic-default-name" placeholder='Cost Per Unit'/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label" htmlFor="basic-default-company">Cost per Unit (USD)</label>
                                        <div className="col-sm-10">
                                            <div className="input-group">
                                            <span className="input-group-text">R</span>
                                            <input type="number" className="form-control" value={productCostPerUnit['cost_per_unit_usd']} name="cost_per_unit_usd" onChange={(e)=>{onChange(e)}} id="basic-default-name" placeholder='Cost Per Unit'/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label" htmlFor="basic-default-company">Cost per Unit</label>
                                        <div className="col-sm-10">
                                            <div className="input-group">
                                            <span className="input-group-text">R</span>
                                            <input type="number" className="form-control" value={productCostPerUnit['cost_per_unit']} name="cost_per_unit" onChange={(e)=>{onChange(e)}} id="basic-default-name" placeholder='Cost Per Unit'/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label" htmlFor="basic-default-company">Storage Cost per Unit</label>
                                        <div className="col-sm-10">
                                            <div className="input-group">
                                            <span className="input-group-text">R</span>
                                            <input type="number" className="form-control" value={productCostPerUnit['storage_cost_per_unit']} name="storage_cost_per_unit" onChange={(e)=>{onChange(e)}} id="basic-default-name" placeholder='Cost Per Unit'/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label" htmlFor="basic-default-company">Shipment Cost per Unit (to Takealot)</label>
                                        <div className="col-sm-10">
                                            <div className="input-group">
                                            <span className="input-group-text">R</span>
                                            <input type="number" className="form-control" value={productCostPerUnit['shipment_cost_per_unit']} name="shipment_cost_per_unit" onChange={(e)=>{onChange(e)}} id="basic-default-name" placeholder='Cost Per Unit'/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label" htmlFor="basic-default-company">Total Cost / Unit</label>
                                        <div className="col-sm-10">
                                            <div className="input-group">
                                            <label>{formatter.format(Number(productCostPerUnit['cost_per_unit'])+ Number(productCostPerUnit['shipment_cost_per_unit']) + Number(productCostPerUnit['storage_cost_per_unit']))}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="" style={{textAlign:"center"}}>
                                        <button className="col-6 btn btn-sm btn-outline-primary form-select ">Update Cost</button>
                                    </div>
                                </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Calculator