import React from 'react'

const BuyboxModal = (props) => {
    const storeId = props.storeId
    const formatter = props.formatter
    const numberFormatter = props.numberFormatter
    const SalesDetailsVisibility = props.SalesDetailsVisibility
    const LoadingSalesDetailsVisibility = props.LoadingSalesDetailsVisibility
    const ProductInfo = props.ProductInfo
    const BuyBox = props.BuyBox
    const onBuyBoxChange = props.onBuyBoxChange
    const OtherOffers = props.OtherOffers
    const HourDetails = props.HourDetails
    const setBuyBox = props.setBuyBox
    return (
        <>
            
            <div className="modal fade" id={"productBuyBoxDetailsModal-"+storeId} tabindex="-1" aria-hidden="true">
                <div className="modal-dialog  modal-xl" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <div style={{textAlign: "center"}}>
                        <h4 className="modal-title" id={"productBuyBoxDetailsTitle-"+storeId} style={{textAlign: "center"}}><span className="text-muted fw-light">Product /</span> BuyBox</h4>
                        </div>
                        <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body" style={{wordBreak:"break-all"}}>
                        <div style={{display: LoadingSalesDetailsVisibility}}>
                            <div id="global-loader" style={{width: '100%'}}>
                                <div className="whirly-loader"> </div>
                            </div>
                        </div>
                        <div style={{display: SalesDetailsVisibility}} className="row">
                            <div className='container'>
                            <div className="row">
                                <div className="col-md-4 py-3 px-3">
                                <img className="card-img card-img-left" width="100%" src={ProductInfo['image_url']} alt="Card image" />
                                </div>
                                <div className="col-md-8 py-3 px-3">
                                <div className="card-body">
                                    <h5 className="card-title">{ProductInfo['title']}</h5>
                                    <br/>
                                    <p>Note: *Product is Eligible for BuyBox only if is buyable, have other offers and have sales in past 7 days.</p>
                                    <div className='row'>
                                        <div className='col-xl col-lg col-md-12 col-xs-12'>
                                        <h4 className="col-form-label h4" style={{textAlign: "center"}} >Hour Stats (in past 7 days)</h4>
                                        <div className="row mb-3">
                                            <label className="col-sm-6 col-form-label" htmlFor="basic-default-company">Eligible for BuyBox?*</label>
                                            {
                                            ProductInfo['isAddToCartAvailable']  && OtherOffers.length != 0 ? 
                                            <p className="col-6">
                                                Yes
                                            </p> :
                                            <p className="col-6">
                                                Not Eligible {OtherOffers}
                                            </p>
                                            }
                                        </div>
                                        {
                                            ProductInfo['isAddToCartAvailable'] && OtherOffers.length != 0?
                                            <>
                                            <div className='col-xl col-lg col-md-12 col-xs-12'>
                                                <form onSubmit={e => props.onBuyBoxSubmit(e)}>
                                                <div className='row'>
                                                {/* <label className="col-form-label h4" style={{textAlign: "center"}} >Currency ZAR</label> */}
                                                <div className="row mb-3">
                                                    <label className="col-sm-6 col-form-label" htmlFor="basic-default-company">Minimum Price</label>
                                                    <div className="col-sm-6">
                                                    <div className="input-group">
                                                    <span className="input-group-text">R</span>
                                                        <input type="number" required className="form-control" id="basic-default-name" value={BuyBox['minPrice']} onChange={(e)=>{onBuyBoxChange(e)}} name="minPrice" placeholder='Minimum Cost Per Unit'/>
                                                    </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label className="col-sm-6 col-form-label" htmlFor="basic-default-company">Normal Selling Price</label>
                                                    <div className="col-sm-6">
                                                    <div className="input-group">
                                                    <span className="input-group-text">R</span>
                                                        <input type="number" required className="form-control" id="basic-default-name" value={BuyBox['avgPrice']} onChange={(e)=>{onBuyBoxChange(e)}} name="avgPrice" placeholder='Average Cost Per Unit'/>
                                                    </div>
                                                    </div>
                                                </div>
                                                {/* <div className="row mb-3">
                                                    <label className="col-sm-6 col-form-label" htmlFor="basic-default-company">Maximum Price</label>
                                                    <div className="col-sm-6">
                                                    <div className="input-group">
                                                    <span className="input-group-text">R</span>
                                                        <input type="number" required className="form-control" id="basic-default-name" value={BuyBox['maxPrice']} onChange={(e)=>{onBuyBoxChange(e)}} name="maxPrice" placeholder='Maximum Per Unit'/>
                                                    </div>
                                                    </div>
                                                </div> */}
                                                <div className="row mb-3">
                                                    <label className="col-sm-6 col-form-label" htmlFor="basic-default-company">Buybox Disabled Hour</label>
                                                    <div className="col-sm-6">
                                                    <select value={BuyBox['bestHour']} name="bestHour" onChange={(e)=>{onBuyBoxChange(e)}} className="form-select">
                                                    <option value="">Select</option>
                                                        <option value="1">01:00 AM</option>
                                                        <option value="2">02:00 AM</option>
                                                        <option value="3">03:00 AM</option>
                                                        <option value="4">04:00 AM</option>
                                                        <option value="5">05:00 AM</option>
                                                        <option value="6">06:00 AM</option>
                                                        <option value="7">07:00 AM</option>
                                                        <option value="8">08:00 AM</option>
                                                        <option value="9">09:00 AM</option>
                                                        <option value="10">10:00 AM</option>
                                                        <option value="11">11:00 AM</option>
                                                        <option value="12">12:00 PM</option>
                                                        <option value="13">01:00 PM</option>
                                                        <option value="14">02:00 PM</option>
                                                        <option value="15">03:00 PM</option>
                                                        <option value="16">04:00 PM</option>
                                                        <option value="17">05:00 PM</option>
                                                        <option value="18">06:00 PM</option>
                                                        <option value="19">07:00 PM</option>
                                                        <option value="20">08:00 PM</option>
                                                        <option value="21">09:00 PM</option>
                                                        <option value="22">10:00 PM</option>
                                                        <option value="23">11:00 PM</option>
                                                    </select>                                      
                                                    <div id="defaultFormControlHelp" className="form-text">
                                                        Suggested Hour {HourDetails['bestHour']} (Sales: {formatter.format(HourDetails['bestHourSales'])} in last 30 days)
                                                    </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label className="col-sm-6 col-form-label" htmlFor="basic-default-company">Auto Pricing Running Cycle**</label>
                                                    <div className="col-sm-6">
                                                    <input className="form-check-input" type="checkbox" id="inlineCheckbox1" name='autoPricing' checked={BuyBox['autoPricing'] === 1 ? true : false} onChange={(e)=>{BuyBox["autoPricing"] == 1 ? setBuyBox({...BuyBox, [e.target.name]: 0}) : setBuyBox({...BuyBox, [e.target.name]: 1})}}/>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label className="col-sm-6 col-form-label" htmlFor="basic-default-company">Status</label>
                                                    <div className="col-sm-6">
                                                    <select value={BuyBox['status']} name="status" onChange={(e)=>{onBuyBoxChange(e)}} className="form-select">
                                                        <option value="0">Inactive</option>
                                                        <option value="1">Active</option>
                                                    </select>                                      
                                                    <div id="defaultFormControlHelp" className="form-text">
                                                        Suggested Hour {HourDetails['bestHour']}
                                                    </div>
                                                    </div>
                                                </div>
                                                {
                                                    ProductInfo['isAddToCartAvailable'] && OtherOffers.length != 0? 
                                                    <>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                        <div className="" style={{textAlign:"center"}}>
                                                            <button className="col-6 btn btn-sm btn-primary form-select " data-bs-dismiss="modal">Update</button>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    </>
                                                    : <></>
                                                }
                                                </div>
                                                </form>
                                            </div>
                                            </>
                                            : <></>
                                        }
                                        </div>
                                    </div>
                                </div>

                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BuyboxModal