import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Moment from 'moment'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import Pagination from '../SalesPagination'
import { Helmet } from 'react-helmet'
import SearchComponent from '../../Search'
import SalesList from '../SalesList'
import SalesModal from '../SalesModal'

const CompanySales = (props) => {
    const user = props.user
    const CompanyProfile = props.CompanyProfile
    const [TotalOrderProducts, setTotalOrderProducts] = useState(1)
    const [LoadingVisibility, setLoadingVisibility] = useState("none")
    const [dataVisibility, setDataVisibility] = useState("block")
    const [LoadingSalesDetailsVisibility, setLoadingSalesDetailsVisibility] = useState("none")
    const [SalesDetailsVisibility, setSalesDetailsVisibility] = useState("block")

    const [SalesData, setSalesData] = useState([])
    const [SalesQuery, setSalesQuery] = useState("")
    const [NextLink, setNextLink] = useState("")
    const [PreviousLink, setPreviousLink] = useState("")
    const [orderNo, setOrderNo] = useState("")
    const [TotalProducts, setTotalProducts] = useState(0)
    const [PageLimit, setPageLimit] = useState(1)
    const [OrderDetails, setOrderDetails] = useState([])
    const [FeeDetails, setFeeDetails] = useState([])
    const [CustomerAddressVisible, setCustomerAddressVisible] = useState(false)
    const [CustomerDetails, setCustomerDetails] = useState({
        customerName: "",
        customerBusinessName: "",
        customerVAT: "",
        customerReg: "",
        customerAddressStreet: "",
        customerAddressCity: "",
        customerAddressPostalCode: "",
        customerAddressCountry: "",
    })
    const onChange = (e) => setCustomerDetails({ ...CustomerDetails, [e.target.name]: e.target.value })
    const LoadSaleDetailsData = async (orderNo, order_item_id, store) => {
        setLoadingSalesDetailsVisibility("block")
        setSalesDetailsVisibility("none")
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify({
            "order_id": orderNo,
            "order_item_id": order_item_id,
            "store": store
        })

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/products/sales/details/${orderNo}/`, Body, config)
            setOrderDetails(response.data['data'][0])
            setTotalOrderProducts(response.data['totalProducts'])
            // console.log(response.data['data'][0]['customer'])
            setCustomerDetails({ ...CustomerDetails, "customerName": response.data['data'][0]['customer'] })
            setFeeDetails(response.data['fee'])
        } catch (error) {
            // console.log('first', error)
            //   setResponseError(error.response.statusText)
        }

        setLoadingSalesDetailsVisibility("none")
        setSalesDetailsVisibility("block")
    }
    // console.log(CustomerDetails)

    const LoadQueryData = async (page_number, searchingQuery) => {
        setSalesData({
            loading: true
        })
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify({
            "query": searchingQuery,
            "page_number": page_number

        })
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/products/sales/`, Body, config)
            // await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/finance/currentMonthSales/`,config)
            // console.log(response.data['stats']['JHB'])
            setSalesData(response.data['results'])
            setTotalProducts(response.data['total_records'])
            setPageLimit(response.data['pagelimit'])
            // setSalesTrendingData(response.data['stats']['sales_trending_data'])
            //   console.log('Users', JSON.stringify(response.data.Data))
        } catch (error) {
            // console.log('first', error)
            //   setResponseError(error.response.statusText)
        }
    }
    const onLoadQueryData = async (page_number) => {

        setLoadingVisibility("block")
        setDataVisibility("none")
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify({
            "query": SalesQuery,

            "page_number": page_number
        })

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/products/sales/`, Body, config)
            // await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/finance/currentMonthSales/`,config)
            // console.log(response.data['stats']['JHB'])
            setSalesData(response.data['results'])
            setTotalProducts(response.data['total_records'])
            setPageLimit(response.data['pagelimit'])
            // setSalesTrendingData(response.data['stats']['sales_trending_data'])
            //   console.log('Users', JSON.stringify(response.data.Data))
        } catch (error) {
            // console.log('first', error)
            //   setResponseError(error.response.statusText)
        }

        setLoadingVisibility("none")
        setDataVisibility("block")
    }

    const LoadProductData = async (page_number) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify({
            "query": SalesQuery,

            "page_number": page_number
        })

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/products/sales/`, Body, config)
            // await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/finance/currentMonthSales/`,config)
            // console.log(response.data['stats']['JHB'])
            setSalesData(response.data['results'])
            setTotalProducts(response.data['total_records'])
            setPageLimit(response.data['pagelimit'])
            // setSalesTrendingData(response.data['stats']['sales_trending_data'])
            //   console.log('Users', JSON.stringify(response.data.Data))
        } catch (error) {
            // console.log('first', error)
            //   setResponseError(error.response.statusText)
        }
    }

    const LoadNextSalesData = async () => {
        setLoadingVisibility("block")
        setDataVisibility("none")
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify({
            "account": user.id
        })

        try {
            const response = await axios.post(NextLink, Body, config)
            // await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/finance/currentMonthSales/`,config)
            // console.log(response.data)
            setSalesData(response.data['results'])
            setPreviousLink(response.data['previous'])
            setNextLink(response.data['next'])
            // setSalesTrendingData(response.data['stats']['sales_trending_data'])
            //   console.log('Users', JSON.stringify(response.data.Data))
        } catch (error) {
            // console.log('first', error)
            //   setResponseError(error.response.statusText)
        }

        setLoadingVisibility("none")
        setDataVisibility("block")
    }

    const LoadPreviousSalesData = async () => {
        setLoadingVisibility("block")
        setDataVisibility("none")
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify({
            "account": user.id,
        })

        try {
            const response = await axios.post(PreviousLink, Body, config)
            // await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/finance/currentMonthSales/`,config)
            // console.log(response.data['stats']['JHB'])
            setSalesData(response.data['results'])
            setPreviousLink(response.data['previous'])
            setNextLink(response.data['next'])
            // setSalesTrendingData(response.data['stats']['sales_trending_data'])
            //   console.log('Users', JSON.stringify(response.data.Data))
        } catch (error) {
            // console.log('first', error)
            //   setResponseError(error.response.statusText)
        }

        setLoadingVisibility("none")
        setDataVisibility("block")
    }
    const numberFormatter = new Intl.NumberFormat('en-ZA', {
        // These options are needed to round to whole numbers if that's what you want.
        minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    })
    const formatter = new Intl.NumberFormat('en-ZA', {
        style: 'currency',
        currency: 'ZAR',

        // These options are needed to round to whole numbers if that's what you want.
        minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    })
    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "short", day: "numeric", hour: '2-digit', minute: '2-digit', hour12: true }
        return new Date(dateString).toLocaleDateString(undefined, options)
    }
    const onNextClick = (e) => {
        e.preventDefault()
        LoadNextSalesData()
    }
    const onPreviousClick = (e) => {
        e.preventDefault()
        LoadPreviousSalesData()
    }
    // const submitForm = (e) => {
    //   setTotalProducts(0)
    //   e.preventDefault()
    //   if (SalesQuery === ""){
    //     LoadSalesData()
    //   } else {
    //     LoadQueryData(1)
    //   }
    // } 

    const onSalesQueryChange = (e) => {
        e.preventDefault()
        setSalesQuery(e.target.value)
        // if (e.target.value) {
        // } else {
        //     LoadQueryData(1, "")
        // }
    }
    const searchForm = (e) => {
        e.preventDefault()
        LoadQueryData(1, SalesQuery)
    }
    useEffect(() => {
        onLoadQueryData(1)
    }, [])
    return (
        <>
            <Helmet>
                <title>{ CompanyProfile['companyName'] } Sales</title>
                <meta
                    name="description"
                    content={ `${CompanyProfile['companyName']} Sales Dashboard` }
                />
                <link rel="stylesheet" href="../assets/css/style.css" />
                <script src="../assets/js/script.js" />
            </Helmet>
            <div className="content">
                <div>
                    <SearchComponent
                        placeholder={ "Order Number / Customer Name" }
                        SearchQuery={ SalesQuery }
                        onSearchInputChange={ onSalesQueryChange }
                        searchForm={ searchForm }
                    />
                    <hr />
                    <SalesList
                        storeId={ 0 }
                        SalesData={ SalesData }
                        LoadSaleDetailsData={ LoadSaleDetailsData }
                        onLoadQueryData={ LoadProductData }
                        formatter={ formatter }
                        numberFormatter={ numberFormatter }
                        TotalProducts={ TotalProducts }
                        onChange={ onChange }
                        OrderDetails={ OrderDetails }
                        PageLimit={ PageLimit }
                        SalesQuery={ SalesQuery }
                    />
                    <SalesModal
                        storeId={ 0 }
                        SalesData={ SalesData }
                        LoadSaleDetailsData={ LoadSaleDetailsData }
                        formatter={ formatter }
                        numberFormatter={ numberFormatter }
                        TotalProducts={ TotalProducts }
                        CustomerDetails={ CustomerDetails }
                        onChange={ onChange }
                        CustomerAddressVisible={ CustomerAddressVisible }
                        FeeDetails={ FeeDetails }
                        TotalOrderProducts={ TotalOrderProducts }
                        setCustomerAddressVisible={ setCustomerAddressVisible }
                        LoadingSalesDetailsVisibility={ LoadingSalesDetailsVisibility }
                        OrderDetails={ OrderDetails }
                    />
                </div>
            </div>

        </>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.Auth.isAuthenticated,
    user: state.Auth.user,
})

export default connect(mapStateToProps, {})(CompanySales)