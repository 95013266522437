import axios from 'axios'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { NavLink, Navigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Moment from 'moment'
import Swal from 'sweetalert2'
const StaffAccount = (props) => {
    const user = props.user
    const [UserList, setUserList] = useState([])
    // APIs
    const loadUsers = async () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/users/staff/`, config)
            setUserList(response?.data?.users)
        } catch (error) {
            // console.log('first', error)
            //   setResponseError(error.response.statusText)
        }
    }
    // Search
    const [StaffUser, setStaffUser] = useState({
        "user": 0,
        "dashboard": 0,
    })

    const onStaffUserChange = (e) => {
        setStaffUser({
            ...StaffUser,
            [e.target.name]: e.target.value
        })
    }


    const [Stores, setStores] = useState([])
    const [TotalStores, setTotalStores] = useState(-1)
    const LoadStores = async () => {

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/users/staff/stores/`, config)
            // console.log(response.data['total_stores'])
            setTotalStores(response.data['total_stores'])
            setStores(response.data['data'])
        } catch (error) {
            // console.log('first', error)
            setTotalStores({
                legal_name: "",
                key: null
            })
        }

    }

    const [NonStaffUsers, setNonStaffUsers] = useState([])

    const loadNonStaffUsers = async (searchQuery) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/users/non/staff/users/`, config)
            setNonStaffUsers(response?.data?.data)
        } catch (error) {
            // console.log('first', error)
            //   setResponseError(error.response.statusText)
        }
    }

    const addUser = async (e) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify(StaffUser)
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/users/staff/add/`, Body, config)
            loadUsers()
            LoadStores()
            Swal.fire({
                icon: 'success',
                title: 'Staff User Added Successfully',
                showConfirmButton: false,
                timer: 1500
            })
            setStaffUser({
                "user": 0,
                "dashboard": 0,
            })
        } catch (error) {
            // console.log('first', error)
            //   setResponseError(error.response.statusText)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.response.data.message,
            })
        }
    }

    const removeUser = async (userId) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        try {
            const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/v1/users/staff/delete/${userId}/`, config)
            loadUsers()

            LoadStores()
            Swal.fire({
                icon: 'success',
                title: 'Staff User Removed Successfully',
                showConfirmButton: false,
                timer: 1500
            })
        } catch (error) {
            // console.log('first', error)
            //   setResponseError(error.response.statusText)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.response.data.message,
            })
        }
    }

    useEffect(() => {
        loadUsers()
        LoadStores()
    }, [])

    if (!user?.is_superuser) {
        return <Navigate to="/" />
    }
    return (
        <div className='content'>
            <Helmet>
                <title>
                    Staff User Accounts - Buybox
                </title>
            </Helmet>
            <div className="page-header">
                <div className="page-title">
                    <h4>Users Management</h4>
                    <h6>Manage Users</h6>
                    <p>You can add upto { TotalStores } staff accounts</p>
                </div>
            </div>
            <hr />
            <div className="card mb-0">
                <div className="card-body">
                    <div className="card-header pb-0 d-flex justify-content-between align-items-center">
                        <h4 className="card-title">User List</h4>
                        <div className="graph-sets">

                            <div className='px-1 w-100'>
                                <a onClick={ () => { loadNonStaffUsers(""); LoadStores() } } data-bs-toggle="modal" data-bs-target="#add-staff-user" className='btn btn-primary'>Add Staff Account</a>
                            </div>
                        </div>
                    </div>

                    <div className="table-responsive text-wrap">
                        <table className="table mb-0">
                            <thead>

                                <tr>
                                    <th>
                                        Name
                                    </th>
                                    <th >
                                        Email
                                    </th>
                                    <th >
                                        Store
                                    </th>
                                    <th>
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    UserList.length > 0 ?
                                        UserList.map(
                                            (key) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td>
                                                                { key?.first_name + " " + key?.last_name }
                                                            </td>
                                                            <td>
                                                                { key?.email }
                                                            </td>
                                                            <td>
                                                                { key?.dashboard }
                                                            </td>
                                                            <td>

                                                                <a
                                                                    onClick={ () => { removeUser(key?.id) } }
                                                                    className="btn btn-danger" >
                                                                    Remove
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                            })
                                        :
                                        <tr>
                                            <td colSpan="9" className="text-center">No data found</td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                        <br />
                        <div className='pagination justify-content-center'>

                        </div>
                        {/* Modal */ }
                        <div class="modal fade" id="add-staff-user" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h1 class="modal-title fs-5" id="exampleModalLabel">Add Staff User</h1>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <p>You can add upto { TotalStores } staff accounts</p>
                                        <div className="row">
                                            <div className="col">
                                                <div className='form-floating'>
                                                    <select class="form-select" aria-label="Default select example" value={ StaffUser?.staff } onChange={ (e) => { onStaffUserChange(e) } } name="staff">
                                                        <option selected>Select User</option>
                                                        {
                                                            NonStaffUsers.length > 0 ?
                                                                NonStaffUsers.map(
                                                                    (key) => {
                                                                        return (
                                                                            <>
                                                                                <option value={ key?.email }>{ key?.first_name + " " + key?.last_name }</option>
                                                                            </>
                                                                        )
                                                                    })
                                                                :
                                                                <option value={ 0 }>No Users Found</option>
                                                        }
                                                    </select>
                                                    <label for="floatingSelect">Non Staff User</label>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className='form-floating'>
                                                    <select class="form-select" aria-label="Default select example" value={ StaffUser?.dashboard } onChange={ (e) => { onStaffUserChange(e) } } name="dashboard">
                                                        <option selected>Select Store</option>
                                                        {
                                                            Stores.length > 0 ?
                                                                Stores.map(
                                                                    (key) => {
                                                                        return (
                                                                            <>
                                                                                <option value={ key?.id }>{ key?.legal_name } ({ key?.store_name })</option>
                                                                            </>
                                                                        )
                                                                    })
                                                                :
                                                                <option value={ 0 }>No Stores Found</option>
                                                        }
                                                    </select>
                                                    <label for="floatingSelect">Store</label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        <button type="button" onClick={ (e) => { addUser(e) } } data-bs-dismiss="modal" class="btn btn-primary">Add</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.Auth.isAuthenticated,
    user: state.Auth.user
})

export default connect(mapStateToProps)(StaffAccount)