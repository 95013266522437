export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const USER_LOADED_SUCCESS = 'USER_LOADED_SUCCESS'
export const USER_LOADED_FAILED = 'USER_LOADED_FAILED'
export const AUTHENTICATED_SUCCESS = 'AUTHENTICATED_SUCCESS'
export const AUTHENTICATED_FAILED = 'AUTHENTICATED_FAILED'
export const LOGOUT = 'LOGOUT'
export const SERVER_DOWN = 'SERVER_DOWN'
export const SERVER_UP = 'SERVER_UP'
export const PASSWORD_RESET_FAILED = 'PASSWORD_RESET_FAILED'
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS'
export const PASSWORD_RESET_CONFIRM_FAILED = 'PASSWORD_RESET_CONFIRM_FAILED'
export const PASSWORD_RESET_CONFIRM_SUCCESS = 'PASSWORD_RESET_CONFIRM_SUCCESS'
export const MICROSOFT_AUTHENTICATED_SUCCESS = 'MICROSOFT_AUTHENTICATED_SUCCESS'
export const MICROSOFT_AUTHENTICATED_FAILED = 'MICROSOFT_AUTHENTICATED_FAILED'
export const GOOGLE_AUTHENTICATED_SUCCESS = 'GOOGLE_AUTHENTICATED_SUCCESS'
export const GOOGLE_AUTHENTICATED_FAILED = 'GOOGLE_AUTHENTICATED_FAILED'
export const FACEBOOK_AUTHENTICATED_SUCCESS = 'GOOGLE_AUTHENTICATED_SUCCESS'
export const FACEBOOK_AUTHENTICATED_FAILED = 'GOOGLE_AUTHENTICATED_FAILED'