import React, { useEffect, useState } from 'react'
import { Helmet } from "react-helmet"
import { connect } from 'react-redux'
import FilterComponent from './Filters'
import axios from 'axios'
import Moment from 'moment'
import DayFinance from './DayFinance'
import CustomDateFinance from './CustomDateFinance'
import MonthFinance from './MonthFinance'
import AnnualFinance from './AnnualFinance'
import '../Styles/styles.css'
const StoreFinance = (props) => {
	const user = props.user
	const [CurrentPage, setCurrentPage] = useState(1)
	const [DayCurrentPage, setDayCurrentPage] = useState(1)
	const [MonthlyCurrentPage, setMonthlyCurrentPage] = useState(1)
	const [CustomCurrentPage, setCustomCurrentPage] = useState(1)
	const [TotalPages, setTotalPages] = useState(2)
	const CompanyProfile = props.CompanyProfile
	const storeId = props.storeId
	const [LoadingVisibility, setLoadingVisibility] = useState("none")
	const [dataVisibility, setDataVisibility] = useState("block")
	const [FilterType, setFilterType] = useState(localStorage.getItem(`filterType-dashboard-${storeId}`) ? localStorage.getItem(`filterType-dashboard-${storeId}`) : 3)
	const [CustomFilterType, setCustomFilterType] = useState(localStorage.getItem(`custom-filterType-dashboard-${storeId}`) ? localStorage.getItem(`custom-filterType-dashboard-${storeId}`) : 1)
	const Date_Var = new Date()
	const yesterday = Moment(new Date(Date.now() - 86400000)).format('YYYY-MM-DD')
	const currentYear = Date_Var.getFullYear()
	const [Month, setMonth] = useState(localStorage.getItem(`month-dashboard-${storeId}`) ? localStorage.getItem(`month-dashboard-${storeId}`) : ("0" + (Date_Var.getMonth() + 1)).slice(-2))
	const [Year, setYear] = useState(localStorage.getItem(`year-dashboard-${storeId}`) ? localStorage.getItem(`year-dashboard-${storeId}`) : currentYear)
	const [MonthYear, setMonthYear] = useState(localStorage.getItem(`monthyear-dashboard-${storeId}`) ? localStorage.getItem(`monthyear-dashboard-${storeId}`) : currentYear)
	const [CurrentDate, setCurrentDate] = useState(localStorage.getItem(`date-dashboard-${storeId}`) ? localStorage.getItem(`date-dashboard-${storeId}`) : Date_Var.getFullYear() + "-" + ("0" + (Date_Var.getMonth() + 1)).slice(-2) + "-" + ("0" + Date_Var.getDate()).slice(-2))
	const [FromDate, setFromDate] = useState(localStorage.getItem(`fromdate-dashboard-${storeId}`) ? localStorage.getItem(`fromdate-dashboard-${storeId}`) : Date_Var.getFullYear() + "-" + ("0" + (Date_Var.getMonth() + 1)).slice(-2) + "-" + ("0" + Date_Var.getDate()).slice(-2))
	const [ToDate, setToDate] = useState(localStorage.getItem(`todate-dashboard-${storeId}`) ? localStorage.getItem(`todate-dashboard-${storeId}`) : Date_Var.getFullYear() + "-" + ("0" + (Date_Var.getMonth() + 1)).slice(-2) + "-" + ("0" + Date_Var.getDate()).slice(-2))
	const year = 2021
	const years = Array.from(new Array(currentYear - year + 1), (val, index) => index + year)
	// Data
	const [SalesTrendingData, setSalesTrendingData] = useState([])
	const [ExpectedData, setExpectedData] = useState({})
	const [FormData, setFormData] = useState({})
	const [CostProfitData, setCostProfitData] = useState({})
	const [SalesVsQuantityTrendingData, setSalesVsQuantityTrendingData] = useState([])
	const [PageSize, setPageSize] = useState(localStorage.getItem('page-size') !== null ? JSON.parse(localStorage.getItem('page-size')) || 10 : 10)
	const [Sortby, setSortby] = useState(localStorage.getItem('sort-by') !== null ? localStorage.getItem('sort-by') || 'units' : 'units')
	const [SortDirection, setSortDirection] = useState(localStorage.getItem('sort-direction') !== null ? localStorage.getItem('sort-direction') || 'down' : 'down')
	const [TrendingPageSize, setTrendingPageSize] = useState(6)
	const [PageLimit, setPageLimit] = useState(1)
	const [YearProfile, setYearProfile] = useState({ "revenue_change": "+" })
	const [MonthProfile, setMonthProfile] = useState({ "revenue": 0, "revenue_change": "+0" })
	const [ProductsData, setProductsData] = useState([])
	const [TotalProducts, setTotalProducts] = useState(0)
	const [TotalTrendingData, setTotalTrendingData] = useState(0)
	const [ProductsBody, setProductsBody] = useState([])

	const config = {
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			'Authorization': `JWT ${localStorage.getItem('access')}`
		}
	}
	const [TakealotStore, setTakealotStore] = useState({
		"store_name": ""
	})
	const [JHBData, setJHBData] = useState({
		"revenue_change": "+",
		"net_revenue_change": "+",
		"quantity_change": "+",
		"fee_change": "+",
		"fee": 0,
		"revenue": 0,
		"quantity": 0,
		"net_revenue": 0,
		"normal_orders_percentage": 0,
		"cancelled_revenue": 0,
		"cnr_quantity": 0,
		"fee_percentage": 0,
	})
	const [CPTData, setCPTData] = useState({
		"revenue_change": "+",
		"net_revenue_change": "+",
		"quantity_change": "+",
		"fee_change": "+",
		"fee": 0,
		"revenue": 0,
		"quantity": 0,
		"net_revenue": 0,
		"normal_orders_percentage": 0,
		"cancelled_revenue": 0,
		"cnr_quantity": 0,
		"fee_percentage": 0,
	})
	// APIs
	const onPageSizeChange = (e, value) => {
		e.preventDefault()
		setTotalProducts(0)
		localStorage.setItem(`page-size`, value)
		setPageSize(value)
		if (FilterType == 1) {
			LoadAnnualProductList(1, value, Year, Sortby, SortDirection)
		} else if (FilterType == 2) {
			LoadMonthProductList(1, value, MonthYear + "-" + Month, Sortby, SortDirection)
		} else if (FilterType == 3) {
			LoadDayProductList(1, value, CurrentDate, Sortby, SortDirection)
		} else if (FilterType == 4) {
			LoadCustomProductList(1, value, Sortby, SortDirection)
		}
		else if (FilterType == 5) {
			LoadDayProductList(1, value, yesterday, Sortby, SortDirection)
			// LoadMonthlyProfile(Moment(yesterday).format('YYYY-MM'))
		}
	}
	const onSortChange = (e, value) => {
		e.preventDefault()
		setTotalProducts(0)
		setTotalProducts([])
		localStorage.setItem(`sort-by`, value)
		setSortby(value)
		if (FilterType == 1) {
			LoadAnnualProductList(1, PageSize, Year, value, SortDirection)
		} else if (FilterType == 2) {
			LoadMonthProductList(1, PageSize, MonthYear + "-" + Month, value, SortDirection)
		} else if (FilterType == 3) {
			LoadDayProductList(1, PageSize, CurrentDate, value, SortDirection)
		} else if (FilterType == 4) {
			LoadCustomProductList(1, PageSize, value, SortDirection)
		}
		else if (FilterType == 5) {
			LoadDayProductList(1, PageSize, yesterday, value, SortDirection)
			// LoadMonthlyProfile(Moment(yesterday).format('YYYY-MM'))
		}
	}
	const onSortDirectionChange = (e, value) => {
		e.preventDefault()
		setTotalProducts(0)
		localStorage.setItem(`sort-direction`, value)
		setSortDirection(value)
		if (FilterType == 1) {
			LoadAnnualProductList(1, PageSize, Year, Sortby, value)
		} else if (FilterType == 2) {
			LoadMonthProductList(1, PageSize, MonthYear + "-" + Month, Sortby, value)
		} else if (FilterType == 3) {
			LoadDayProductList(1, PageSize, CurrentDate, Sortby, value)
		} else if (FilterType == 4) {
			LoadCustomProductList(1, PageSize, Sortby, value)
		}
		else if (FilterType == 5) {
			LoadDayProductList(1, PageSize, yesterday, Sortby, value)
			// LoadMonthlyProfile(Moment(yesterday).format('YYYY-MM'))
		}
	}

	const onTrendingPageSizeChange = (e) => {
		e.preventDefault()
		setTotalProducts(0)
		setPageSize(e.target.value)
		if (FilterType == 1) {
			// LoadAnnualProductSalesList(1, e.target.value, Year)
		} else if (FilterType == 2) {
			// LoadMonthProductSalesList(1, e.target.value, MonthYear+"-"+Month)
		} else if (FilterType == 3) {
			LoadDayTrendingList(1, e.target.value, CurrentDate)
		} else if (FilterType == 4) {
			// LoadCustomProductSalesList(1, e.target.value)
		}
		else if (FilterType == 5) {
			LoadDayProductList(1, e.target.value, yesterday)
			// LoadMonthlyProfile(Moment(yesterday).format('YYYY-MM'))
		}
	}

	const LoadDayProductList = async (page_number, pageSize, inputDate, sortBy, sortDirection) => {
		// const config = {
		//     headers: {
		//         'Content-Type' : 'application/json',
		//         'Accept' : 'application/json',
		//         'Authorization' : `JWT ${localStorage.getItem('access')}`
		//     }
		// }
		const Body = JSON.stringify({
			"page_number": page_number,
			"page_size": pageSize,
			"date": inputDate,
			"dashboard": storeId,
			"sortBy": sortBy,
			"sortDirection": sortDirection,
		})
		setProductsData([])
		setCurrentPage(page_number)
		try {
			const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v4/finance/day/productTrendingData/updated/`, {
				method: 'POST',
				body: Body,
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
					'Authorization': `JWT ${localStorage.getItem('access')}`
				}
			});

			if (!response.ok || !response.body) {
				throw response.statusText;
			}

			// Here we start prepping for the streaming response
			const reader = response.body.getReader();
			const decoder = new TextDecoder();
			const loopRunner = true;

			let incompleteChunk = "";
			while (loopRunner) {
				// Here we start reading the stream, until its done.
				try {
					const { value, done } = await reader.read();
					if (done) {
						break;
					}
					let decodedChunk = decoder.decode(value, { stream: true });

					if (!decodedChunk.endsWith('\n\n')) {
						incompleteChunk += decodedChunk;
						continue;
					}

					if (incompleteChunk) {
						if (!decodedChunk.startsWith('data:')) {
							decodedChunk = incompleteChunk + decodedChunk;
						}
						incompleteChunk = '';
					}

					const lines = decodedChunk.split('\n\n');
					for (const line of lines) {
						if (line.startsWith('data:')) {

							const responseData = line.slice(5); // Remove 'data:' prefix
							if (responseData.trim() === '[DONE]') {
								continue
								// const data = JSON.parse(jsonData);
							}
							const JSONResponse = JSON.parse(responseData)
							if (JSONResponse?.results != {}) {
								setProductsData((a) => [...a, JSONResponse?.results])
								setTotalProducts(JSONResponse?.total_records)
								setPageLimit(JSONResponse?.pagelimit)
								setTotalPages(JSONResponse?.total_pages)
							}
						}
					}




					// Process the chunk...

				} catch (error) {
					console.error('Error reading stream:', error);
				}

			}
			// fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v4/finance/day/productTrendingData/updated/`, {
			// 	method: 'POST',
			// 	body: Body,
			// 	headers: {
			// 		'Content-Type': 'application/json',
			// 		'Accept': 'application/json',
			// 		'Authorization': `JWT ${localStorage.getItem('access')}`
			// 	},
			// 	responseData: 'stream'
			// }).then(response => {
			// 	const reader = response.body.getReader();
			// 	const decoder = new TextDecoder();
			// 	return reader.read().then(function processText({ done, value }) {
			// 		if (done) {
			// 			return;
			// 		}


			// 		const result = decoder.decode(value);
			// 		const lines = result.split('\n\n');
			// 		for (const line of lines) {
			// 			if (line.startsWith('data:')) {

			// 				const responseData = line.slice(5); // Remove 'data:' prefix
			// 				if (responseData.trim() === '[DONE]') { 
			// 					// setProductsData(ProductsData['0'])
			// 					break
			// 					// const data = JSON.parse(jsonData);
			// 				}
			// 				const JSONResponse = JSON.parse(responseData)
			// 				const oldProductsData = [...ProductsData]
			// 				oldProductsData.push(JSONResponse?.results)
			// 				setProductsData((a)=>[...a,JSONResponse?.results])
			// 				setTotalProducts(JSONResponse?.total_records)
			// 				setPageLimit(JSONResponse?.pagelimit)
			// 				setTotalPages(JSONResponse?.total_pages)
			// 			}
			// 		}
			// 		// Update your CostData here

			// 		return reader.read().then(processText);
			// 	});
			// });

			// setNextLink(response.data['next'])
		} catch (error) {
			// console.log('first', error)
		}
	}

	const LoadMonthProductList = async (page_number, pageSize, inputDate) => {
		// const config = {
		//     headers: {
		//         'Content-Type' : 'application/json',
		//         'Accept' : 'application/json',
		//         'Authorization' : `JWT ${localStorage.getItem('access')}`
		//     }
		// }
		const Body = JSON.stringify({
			"page_number": page_number,
			"page_size": pageSize,
			"date": inputDate,
			"dashboard": storeId
		})
		setProductsData([])
		setCurrentPage(page_number)
		try {
			const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v4/finance/month/productTrendingData/updated/`, {
				method: 'POST',
				body: Body,
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
					'Authorization': `JWT ${localStorage.getItem('access')}`
				}
			});

			if (!response.ok || !response.body) {
				throw response.statusText;
			}

			// Here we start prepping for the streaming response
			const reader = response.body.getReader();
			const decoder = new TextDecoder();
			const loopRunner = true;

			let incompleteChunk = "";
			while (loopRunner) {
				// Here we start reading the stream, until its done.
				try {
					const { value, done } = await reader.read();
					if (done) {
						break;
					}
					let decodedChunk = decoder.decode(value, { stream: true });

					if (!decodedChunk.endsWith('\n\n')) {
						incompleteChunk += decodedChunk;
						continue;
					}

					if (incompleteChunk) {
						if (!decodedChunk.startsWith('data:')) {
							decodedChunk = incompleteChunk + decodedChunk;
						}
						incompleteChunk = '';
					}

					const lines = decodedChunk.split('\n\n');
					for (const line of lines) {
						if (line.startsWith('data:')) {

							const responseData = line.slice(5); // Remove 'data:' prefix
							if (responseData.trim() === '[DONE]') {
								continue
								// const data = JSON.parse(jsonData);
							}
							const JSONResponse = JSON.parse(responseData)
							if (JSONResponse?.results != {}) {
								setProductsData((a) => [...a, JSONResponse?.results])
								setTotalProducts(JSONResponse?.total_records)
								setPageLimit(JSONResponse?.pagelimit)
								setTotalPages(JSONResponse?.total_pages)
							}
						}
					}




					// Process the chunk...

				} catch (error) {
					console.error('Error reading stream:', error);
				}

			}
			// fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v4/finance/month/productTrendingData/updated/`, {
			// 	method: 'POST',
			// 	body: Body,
			// 	headers: {
			// 		'Content-Type': 'application/json',
			// 		'Accept': 'application/json',
			// 		'Authorization': `JWT ${localStorage.getItem('access')}`
			// 	},
			// 	responseData: 'stream'
			// }).then(response => {
			// 	const reader = response.body.getReader();
			// 	const decoder = new TextDecoder();
			// 	return reader.read().then(function processText({ done, value }) {
			// 		if (done) {
			// 			return;
			// 		}


			// 		const result = decoder.decode(value);
			// 		const lines = result.split('\n\n');
			// 		for (const line of lines) {
			// 			if (line.startsWith('data:')) {

			// 				const responseData = line.slice(5); // Remove 'data:' prefix
			// 				if (responseData.trim() === '[DONE]') { 
			// 					// setProductsData(ProductsData['0'])
			// 					break
			// 					// const data = JSON.parse(jsonData);
			// 				}
			// 				const JSONResponse = JSON.parse(responseData)
			// 				const oldProductsData = [...ProductsData]
			// 				oldProductsData.push(JSONResponse?.results)
			// 				setProductsData((a)=>[...a,JSONResponse?.results])
			// 				setTotalProducts(JSONResponse?.total_records)
			// 				setPageLimit(JSONResponse?.pagelimit)
			// 				setTotalPages(JSONResponse?.total_pages)
			// 			}
			// 		}
			// 		// Update your CostData here

			// 		return reader.read().then(processText);
			// 	});
			// });

			// setNextLink(response.data['next'])
		} catch (error) {
			// console.log('first', error)
		}
	}

	const LoadAnnualProductList = async (page_number, pageSize, year) => {
		const Body = JSON.stringify({
			"page_number": page_number,
			"page_size": pageSize,
			"year": year,
			"dashboard": storeId
		})
		setProductsData([])
		setCurrentPage(page_number)
		try {
			const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v4/finance/productTrendingData/updated/`, {
				method: 'POST',
				body: Body,
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
					'Authorization': `JWT ${localStorage.getItem('access')}`
				}
			});

			if (!response.ok || !response.body) {
				throw response.statusText;
			}

			// Here we start prepping for the streaming response
			const reader = response.body.getReader();
			const decoder = new TextDecoder();
			const loopRunner = true;

			let incompleteChunk = "";
			while (loopRunner) {
				// Here we start reading the stream, until its done.
				try {
					const { value, done } = await reader.read();
					if (done) {
						break;
					}
					let decodedChunk = decoder.decode(value, { stream: true });

					if (!decodedChunk.endsWith('\n\n')) {
						incompleteChunk += decodedChunk;
						continue;
					}

					if (incompleteChunk) {
						if (!decodedChunk.startsWith('data:')) {
							decodedChunk = incompleteChunk + decodedChunk;
						}
						incompleteChunk = '';
					}

					const lines = decodedChunk.split('\n\n');
					for (const line of lines) {
						if (line.startsWith('data:')) {

							const responseData = line.slice(5); // Remove 'data:' prefix
							if (responseData.trim() === '[DONE]') {
								continue
								// const data = JSON.parse(jsonData);
							}
							const JSONResponse = JSON.parse(responseData)
							if (JSONResponse?.results != {}) {
								setProductsData((a) => [...a, JSONResponse?.results])
								setTotalProducts(JSONResponse?.total_records)
								setPageLimit(JSONResponse?.pagelimit)
								setTotalPages(JSONResponse?.total_pages)
							}
						}
					}




					// Process the chunk...

				} catch (error) {
					console.error('Error reading stream:', error);
				}

			}
			// fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v4/finance/productTrendingData/updated/`, {
			// 	method: 'POST',
			// 	body: Body,
			// 	headers: {
			// 		'Content-Type': 'application/json',
			// 		'Accept': 'application/json',
			// 		'Authorization': `JWT ${localStorage.getItem('access')}`
			// 	},
			// 	responseData: 'stream'
			// }).then(response => {
			// 	const reader = response.body.getReader();
			// 	const decoder = new TextDecoder();
			// 	return reader.read().then(function processText({ done, value }) {
			// 		if (done) {
			// 			return;
			// 		}


			// 		const result = decoder.decode(value);
			// 		const lines = result.split('\n\n');
			// 		for (const line of lines) {
			// 			if (line.startsWith('data:')) {

			// 				const responseData = line.slice(5); // Remove 'data:' prefix
			// 				if (responseData.trim() === '[DONE]') { 
			// 					// setProductsData(ProductsData['0'])
			// 					break
			// 					// const data = JSON.parse(jsonData);
			// 				}
			// 				const JSONResponse = JSON.parse(responseData)
			// 				const oldProductsData = [...ProductsData]
			// 				oldProductsData.push(JSONResponse?.results)
			// 				setProductsData((a)=>[...a,JSONResponse?.results])
			// 				setTotalProducts(JSONResponse?.total_records)
			// 				setPageLimit(JSONResponse?.pagelimit)
			// 				setTotalPages(JSONResponse?.total_pages)
			// 			}
			// 		}
			// 		// Update your CostData here

			// 		return reader.read().then(processText);
			// 	});
			// });

			// setNextLink(response.data['next'])
		} catch (error) {
			// console.log('first', error)
		}
	}

	const LoadCustomProductList = async (page_number, pageSize) => {
		const Body = JSON.stringify({
			"fromDate": FromDate,
			"toDate": ToDate,
			"page_number": page_number,
			"page_size": pageSize,
			"filterType": CustomFilterType,
			"dashboard": storeId
		})
		setProductsData([])
		setCurrentPage(page_number)
		try {
			const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v4/finance/custom/productTrendingData/updated/`, {
				method: 'POST',
				body: Body,
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
					'Authorization': `JWT ${localStorage.getItem('access')}`
				}
			});

			if (!response.ok || !response.body) {
				throw response.statusText;
			}

			// Here we start prepping for the streaming response
			const reader = response.body.getReader();
			const decoder = new TextDecoder();
			const loopRunner = true;

			let incompleteChunk = "";
			while (loopRunner) {
				// Here we start reading the stream, until its done.
				try {
					const { value, done } = await reader.read();
					if (done) {
						break;
					}
					let decodedChunk = decoder.decode(value, { stream: true });

					if (!decodedChunk.endsWith('\n\n')) {
						incompleteChunk += decodedChunk;
						continue;
					}

					if (incompleteChunk) {
						if (!decodedChunk.startsWith('data:')) {
							decodedChunk = incompleteChunk + decodedChunk;
						}
						incompleteChunk = '';
					}

					const lines = decodedChunk.split('\n\n');
					for (const line of lines) {
						if (line.startsWith('data:')) {

							const responseData = line.slice(5); // Remove 'data:' prefix
							if (responseData.trim() === '[DONE]') {
								continue
								// const data = JSON.parse(jsonData);
							}
							const JSONResponse = JSON.parse(responseData)
							if (JSONResponse?.results != {}) {
								setProductsData((a) => [...a, JSONResponse?.results])
								setTotalProducts(JSONResponse?.total_records)
								setPageLimit(JSONResponse?.pagelimit)
								setTotalPages(JSONResponse?.total_pages)
							}
						}
					}




					// Process the chunk...

				} catch (error) {
					console.error('Error reading stream:', error);
				}

			}
			// fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v4/finance/custom/productTrendingData/updated/`, {
			// 	method: 'POST',
			// 	body: Body,
			// 	headers: {
			// 		'Content-Type': 'application/json',
			// 		'Accept': 'application/json',
			// 		'Authorization': `JWT ${localStorage.getItem('access')}`
			// 	},
			// 	responseData: 'stream'
			// }).then(response => {
			// 	const reader = response.body.getReader();
			// 	const decoder = new TextDecoder();
			// 	return reader.read().then(function processText({ done, value }) {
			// 		if (done) {
			// 			return;
			// 		}


			// 		const result = decoder.decode(value);
			// 		const lines = result.split('\n\n');
			// 		for (const line of lines) {
			// 			if (line.startsWith('data:')) {

			// 				const responseData = line.slice(5); // Remove 'data:' prefix
			// 				if (responseData.trim() === '[DONE]') { 
			// 					// setProductsData(ProductsData['0'])
			// 					break
			// 					// const data = JSON.parse(jsonData);
			// 				}
			// 				const JSONResponse = JSON.parse(responseData)
			// 				const oldProductsData = [...ProductsData]
			// 				oldProductsData.push(JSONResponse?.results)
			// 				setProductsData((a)=>[...a,JSONResponse?.results])
			// 				setTotalProducts(JSONResponse?.total_records)
			// 				setPageLimit(JSONResponse?.pagelimit)
			// 				setTotalPages(JSONResponse?.total_pages)
			// 			}
			// 		}
			// 		// Update your CostData here

			// 		return reader.read().then(processText);
			// 	});
			// });

			// setNextLink(response.data['next'])
		} catch (error) {
			// console.log('first', error)
		}
	}

	const LoadDayTrendingList = async (page_number, pageSize, inputDate) => {

		const Body = JSON.stringify({
			"page_number": page_number,
			"trending_page_size": pageSize,
			"date": inputDate,
			"dashboard": storeId
		})
		try {
			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v4/finance/day/trendingData/`, Body, config)
			setSalesTrendingData((response.data['results']))
			setTotalTrendingData(response.data['total_records'])
			setPageLimit(response.data['pagelimit'])
		} catch (error) {
			// console.log('first', error)
		}
	}

	const LoadKPIs = async (TYPE, RequestBody) => {

		try {
			const response = await axios.post(
				`${process.env.REACT_APP_BACKEND_URL}/api/v4/finance/${TYPE}/kpis/updated/`,
				RequestBody,
				config
			)
			setFormData(response?.data)
			console.log(response?.data)
		} catch (error) {

		}
	}

	const LoadTrendingData = async (TYPE, RequestBody) => {

		try {
			const response = await axios.post(
				`${process.env.REACT_APP_BACKEND_URL}/api/v4/finance/${TYPE}/trendingData/updated/`,
				RequestBody,
				config
			)
			setSalesTrendingData((response?.data))
		} catch (error) {

		}
		// const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v4/finance/${TYPE}/trendingData/updated/`, {
		// 	method: 'POST',
		// 	body: RequestBody,
		// 	headers: {
		// 		'Content-Type': 'application/json',
		// 		'Accept': 'application/json',
		// 		'Authorization': `JWT ${localStorage.getItem('access')}`
		// 	}
		// });

		// if (!response.ok || !response.body) {
		// 	throw response.statusText;
		// }

		// // Here we start prepping for the streaming response
		// const reader = response.body.getReader();
		// const decoder = new TextDecoder();
		// const loopRunner = true;

		// let incompleteChunk = "";
		// while (loopRunner) {
		// 	// Here we start reading the stream, until its done.
		// 	try {
		// 		const { value, done } = await reader.read();
		// 		if (done) {
		// 			break;
		// 		}
		// 		let decodedChunk = decoder.decode(value, { stream: true });

		// 		if (!decodedChunk.endsWith('\n\n')) {
		// 			incompleteChunk += decodedChunk;
		// 			continue;
		// 		}

		// 		if (incompleteChunk) {
		// 			if (!decodedChunk.startsWith('data:')) {
		// 				decodedChunk = incompleteChunk + decodedChunk;
		// 			}
		// 			incompleteChunk = '';
		// 		}
		// 		const lines = decodedChunk.split('\n\n');
		// 		for (const line of lines) {
		// 			if (line.startsWith('data:')) {

		// 				const responseData = line.slice(5); // Remove 'data:' prefix
		// 				if (responseData.trim() === '[DONE]') {
		// 					continue
		// 					// const data = JSON.parse(jsonData);
		// 				}
		// 				const JSONResponse = JSON.parse(responseData)
		// 				setSalesTrendingData((JSONResponse?.sales_trending_data))
		// 			}
		// 		}




		// 		// Process the chunk...

		// 	} catch (error) {
		// 		console.error('Error reading stream:', error);
		// 	}

		// }
	}


	const LoadFinanceData = async (TYPE, RequestBody) => {
		setTotalProducts(0)

		await Promise.all([
			LoadKPIs(TYPE, RequestBody),
			LoadTrendingData(TYPE, RequestBody),
		])

		// fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v4/finance/${TYPE}/trendingData/updated/`, {
		// 	method: 'POST',
		// 	body: RequestBody,
		// 	headers: {
		// 		'Content-Type': 'application/json',
		// 		'Accept': 'application/json',
		// 		'Authorization': `JWT ${localStorage.getItem('access')}`
		// 	}
		// }).then(response => {
		// 	const reader = response.body.getReader();
		// 	const decoder = new TextDecoder();
		// 	return reader.read().then(function processText({ done, value }) {
		// 		if (done) {
		// 			return;
		// 		}

		// 		const result = decoder.decode(value);
		// 		const lines = result.split('\n\n');
		// 		for (const line of lines) {
		// 			if (line.startsWith('data:')) {

		// 				const responseData = line.slice(5); // Remove 'data:' prefix
		// 				if (responseData.trim() === '[DONE]') { 
		// 					continue
		// 					// const data = JSON.parse(jsonData);
		// 				}
		// 				const JSONResponse = JSON.parse(responseData)
		// 				// setCostProfitData(JSONResponse)
		// 				setSalesTrendingData((JSONResponse?.sales_trending_data))
		// 			}
		// 		}
		// 		// Update your CostData here

		// 		return reader.read().then(processText);
		// 	});
		// });
		// const config = {
		// 	method: 'post',
		// 	maxBodyLength: Infinity,
		// 	url: `${process.env.REACT_APP_BACKEND_URL}/api/v4/finance/${TYPE}/trendingData/updated/`,
		// 	headers: { 
		// 		'Authorization': `JWT ${localStorage.getItem('access')}`, 
		// 		'Content-Type': 'application/json'
		// 	},
		// 	data : RequestBody
		// };

		// axios.request(config).then(
		// 	(response) => {
		// 		const result = (response.data);
		// 		const lines = result.split('\n\n');
		// 		// console.log(lines);
		// 		for (const line of lines) {
		// 			if (line.startsWith('data:')) {

		// 				const responseData = line.slice(5); // Remove 'data:' prefix
		// 				if (responseData.trim() === '[DONE]') { 
		// 					continue
		// 					// const data = JSON.parse(jsonData);
		// 				}
		// 				const JSONResponse = JSON.parse(responseData)
		// 				setSalesTrendingData((JSONResponse?.sales_trending_data))
		// 			}
		// 		}
		// 	}
		// ).catch((error) => {
		// 	console.log(error);
		// });
		// setLoadingVisibility("none")
		// setDataVisibility("block")
		// try {
		//     const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v2/finance/${TYPE}/kpis/`, RequestBody, config)
		//     // console.log(response.data['stats'])
		//     setFormData(response.data['stats'])

		// } catch (error) {
		//   console.log(error.response)
		// }

		// fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v4/finance/${TYPE}/kpis/updated/`, {
		// 	method: 'POST',
		// 	body: RequestBody,
		// 	headers: {
		// 		'Content-Type': 'application/json',
		// 		'Accept': 'application/json',
		// 		'Authorization': `JWT ${localStorage.getItem('access')}`
		// 	}
		// }).then(response => {
		// 	const reader = response.body.getReader();
		// 	const decoder = new TextDecoder();
		// 	return reader.read().then(function processText({ done, value }) {
		// 		if (done) {
		// 			return;
		// 		}

		// 		const result = decoder.decode(value);
		// 		const lines = result.split('\n\n');
		// 		for (const line of lines) {
		// 			if (line.startsWith('data:')) {

		// 				const responseData = line.slice(5); // Remove 'data:' prefix
		// 				if (responseData.trim() === '[DONE]') { 
		// 					continue
		// 					// const data = JSON.parse(jsonData);
		// 				}
		// 				const JSONResponse = JSON.parse(responseData)
		// 				setFormData((JSONResponse?.KPIStats))
		// 			}
		// 		}
		// 		// Update your CostData here

		// 		return reader.read().then(processText);
		// 	});
		// });
		// const config1 = {
		// 	method: 'post',
		// 	maxBodyLength: Infinity,
		// 	url: `${process.env.REACT_APP_BACKEND_URL}/api/v4/finance/${TYPE}/kpis/updated/`,
		// 	headers: { 
		// 		'Authorization': `JWT ${localStorage.getItem('access')}`, 
		// 		'Content-Type': 'application/json'
		// 	},
		// 	data : RequestBody
		// };

		// axios.request(config1).then(
		// 	(response) => {
		// 		const result = (response.data);
		// 		const lines = result.split('\n\n');
		// 		// console.log(lines);
		// 		for (const line of lines) {
		// 			if (line.startsWith('data:')) {

		// 				const responseData = line.slice(5); // Remove 'data:' prefix
		// 				if (responseData.trim() === '[DONE]') { 
		// 					continue
		// 					// const data = JSON.parse(jsonData);
		// 				}
		// 				const JSONResponse = JSON.parse(responseData)
		// 				setFormData((JSONResponse?.KPIStats))
		// 			}
		// 		}
		// 	}
		// ).catch((error) => {
		// 	console.log(error);
		// });
		// try {
		//     const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v2/finance/${TYPE}/expectedKPIS/`, RequestBody, config)
		//     setExpectedData(response.data['expected_kpis'])
		// } catch (error) {
		//   // console.log('first', error)
		// //   setResponseError(error.response.statusText)
		// }


		// fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v4/finance/${TYPE}/expectedKPIS/updated/`, {
		// 	method: 'POST',
		// 	body: RequestBody,
		// 	headers: {
		// 		'Content-Type': 'application/json',
		// 		'Accept': 'application/json',
		// 		'Authorization': `JWT ${localStorage.getItem('access')}`
		// 	}
		// }).then(response => {
		// 	const reader = response.body.getReader();
		// 	const decoder = new TextDecoder();
		// 	return reader.read().then(function processText({ done, value }) {
		// 		if (done) {
		// 			return;
		// 		}

		// 		const result = decoder.decode(value);
		// 		const lines = result.split('\n\n');
		// 		for (const line of lines) {
		// 			if (line.startsWith('data:')) {

		// 				const responseData = line.slice(5); // Remove 'data:' prefix
		// 				if (responseData.trim() === '[DONE]') { 
		// 					continue
		// 					// const data = JSON.parse(jsonData);
		// 				}
		// 				const JSONResponse = JSON.parse(responseData)
		// 				// setCostProfitData(JSONResponse)
		// 				setExpectedData((JSONResponse?.expected_kpis))
		// 			}
		// 		}
		// 		// Update your CostData here

		// 		return reader.read().then(processText);
		// 	});
		// });
		// const config2 = {
		// 	method: 'post',
		// 	maxBodyLength: Infinity,
		// 	url: `${process.env.REACT_APP_BACKEND_URL}/api/v4/finance/${TYPE}/expectedKPIS/updated/`,
		// 	headers: { 
		// 		'Authorization': `JWT ${localStorage.getItem('access')}`, 
		// 		'Content-Type': 'application/json'
		// 	},
		// 	data : RequestBody
		// };

		// axios.request(config2).then(
		// 	(response) => {
		// 		const result = (response.data);
		// 		const lines = result.split('\n\n');
		// 		// console.log(lines);
		// 		for (const line of lines) {
		// 			if (line.startsWith('data:')) {

		// 				const responseData = line.slice(5); // Remove 'data:' prefix
		// 				if (responseData.trim() === '[DONE]') { 
		// 					continue
		// 					// const data = JSON.parse(jsonData);
		// 				}
		// 				const JSONResponse = JSON.parse(responseData)
		// 				setExpectedData((JSONResponse?.expected_kpis))
		// 			}
		// 		}
		// 	}
		// ).catch((error) => {
		// 	console.log(error);
		// });
	}

	// console.log(SalesTrendingData)

	const LoadProductsTrendingData = async (TYPE, RequestBody) => {
		// const config = {
		//     headers: {
		//         'Content-Type' : 'application/json',
		//         'Accept' : 'application/json',
		//         'Authorization' : `JWT ${localStorage.getItem('access')}`
		//     }
		// }
		// try {
		//     	const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v4/finance/${TYPE}/productTrendingData/`, RequestBody, config)
		//     	setProductsData(response.data['results'])
		// 		setTotalProducts(response.data['total_records'])
		// 		setPageLimit(response.data['pagelimit'])
		// } catch (error) {
		// }
		setProductsData([])
		try {
			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v4/finance/${TYPE}/productTrendingData/updated/`, RequestBody, config)
			setTotalProducts(response?.data?.total_records)
			setPageLimit(response?.data?.pagelimit)
			setTotalPages(response?.data?.total_pages)
			setProductsData(response?.data?.results)
		} catch (error) {

		}
		// try {

		// 	const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v4/finance/${TYPE}/productTrendingData/updated/`, {
		// 		method: 'POST',
		// 		body: RequestBody,
		// 		headers: {
		// 			'Content-Type': 'application/json',
		// 			'Accept': 'application/json',
		// 			'Authorization': `JWT ${localStorage.getItem('access')}`
		// 		}
		// 	});

		// 	if (!response.ok || !response.body) {
		// 		throw response.statusText;
		// 	}

		// 	// Here we start prepping for the streaming response
		// 	const reader = response.body.getReader();
		// 	const decoder = new TextDecoder();
		// 	const loopRunner = true;

		// 	let incompleteChunk = "";
		// 	while (loopRunner) {
		// 		// Here we start reading the stream, until its done.
		// 		try {
		// 			const { value, done } = await reader.read();
		// 			if (done) {
		// 				break;
		// 			}
		// 			let decodedChunk = decoder.decode(value, { stream: true });

		// 			if (!decodedChunk.endsWith('\n\n')) {
		// 				incompleteChunk += decodedChunk;
		// 				continue;
		// 			}

		// 			if (incompleteChunk) {
		// 				if (!decodedChunk.startsWith('data:')) {
		// 					decodedChunk = incompleteChunk + decodedChunk;
		// 				}
		// 				incompleteChunk = '';
		// 			}

		// 			const lines = decodedChunk.split('\n\n');
		// 			for (const line of lines) {
		// 				if (line.startsWith('data:')) {

		// 					const responseData = line.slice(5); // Remove 'data:' prefix
		// 					if (responseData.trim() === '[DONE]') {
		// 						continue
		// 						// const data = JSON.parse(jsonData);
		// 					}
		// 					const JSONResponse = JSON.parse(responseData)
		// 					if (JSONResponse?.results != {}) {
		// 						setProductsData((a) => [...a, JSONResponse?.results])
		// 						setTotalProducts(JSONResponse?.total_records)
		// 						setPageLimit(JSONResponse?.pagelimit)
		// 						setTotalPages(JSONResponse?.total_pages)
		// 					}
		// 				}
		// 			}




		// 			// Process the chunk...

		// 		} catch (error) {
		// 			console.error('Error reading stream:', error);
		// 		}

		// 	}
		// 	// fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v4/finance/${TYPE}/productTrendingData/updated/`, {
		// 	// 	method: 'POST',
		// 	// 	body: RequestBody,
		// 	// 	headers: {
		// 	// 		'Content-Type': 'application/json',
		// 	// 		'Accept': 'application/json',
		// 	// 		'Authorization': `JWT ${localStorage.getItem('access')}`
		// 	// 	},
		// 	// 	responseData: 'stream'
		// 	// }).then(response => {
		// 	// 	const reader = response.body.getReader();
		// 	// 	const decoder = new TextDecoder();
		// 	// 	return reader.read().then(function processText({ done, value }) {
		// 	// 		if (done) {
		// 	// 			return;
		// 	// 		}


		// 	// 		const result = decoder.decode(value);
		// 	// 		const lines = result.split('\n\n');
		// 	// 		for (const line of lines) {
		// 	// 			if (line.startsWith('data:')) {

		// 	// 				const responseData = line.slice(5); // Remove 'data:' prefix
		// 	// 				if (responseData.trim() === '[DONE]') { 
		// 	// 					// setProductsData(ProductsData['0'])
		// 	// 					break
		// 	// 					// const data = JSON.parse(jsonData);
		// 	// 				}
		// 	// 				const JSONResponse = JSON.parse(responseData)

		// 	// 				// const oldProductsData = [...ProductsData]
		// 	// 				// oldProductsData.push(JSONResponse?.results)
		// 	// 				setProductsData((a)=>[...a, JSONResponse?.results])
		// 	// 				setTotalProducts(JSONResponse?.total_records)
		// 	// 				setPageLimit(JSONResponse?.pagelimit)
		// 	// 				setTotalPages(JSONResponse?.total_pages)
		// 	// 			}
		// 	// 		}
		// 	// 		// Update your CostData here

		// 	// 		return reader.read().then(processText);
		// 	// 	});
		// 	// });

		// 	// setNextLink(response.data['next'])
		// } catch (error) {
		// 	// console.log('first', error)
		// }

	}

	const getExpectedKPIs = async () => {
		setExpectedData({})
		try {
			const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v4/expected-kpis/?store=${storeId}`, config)
			setExpectedData(response.data?.data)
		} catch (error) {

		}
	}

	const LoadDayStats = async (date, sortBy, sortDirection) => {
		setFormData({})
		setProductsData([])

		setSalesTrendingData([])
		const Body = JSON.stringify(
			{
				"date": date,
				"account": user.id,
				"dashboard": storeId,
				"page_size": PageSize,
				"trending_page_size": TrendingPageSize,
				"page_number": 1,
				"sortBy": sortBy,
				"sortDirection": sortDirection,
			}
		)
		await Promise.all([
			LoadFinanceData('day', Body),
			LoadProductsTrendingData('day', Body, sortBy, sortDirection)
		])
	}

	const LoadMonthlyStats = async (date) => {
		setFormData({})
		setProductsData([])

		setSalesTrendingData([])
		const Body = JSON.stringify(
			{
				"date": date,
				"account": user.id,
				"dashboard": storeId,
				"page_size": PageSize,
				"trending_page_size": TrendingPageSize,
				"sortBy": Sortby,
				"sortDirection": SortDirection,
				"page_number": 1,
			}
		)
		await Promise.all([
			LoadFinanceData('month', Body),
			LoadProductsTrendingData('month', Body)
		])
	}

	const formatter = new Intl.NumberFormat('en-ZA', {
		style: 'currency',
		currency: 'ZAR',
		minimumFractionDigits: 0
	})

	const numberFormatter = new Intl.NumberFormat('en-ZA', {
		// These options are needed to round to whole numbers if that's what you want.
		minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
		//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
	})

	// const onPageSizeChange = (e) => {
	// 	e.preventDefault()
	// 	setTotalProducts(0)
	// 	setPageSize(e.target.value)
	// 	if (FilterType == 1){
	// 	//   LoadAnnualProductSalesList(1, e.target.value, Year)
	// 	} else if (FilterType == 2){
	// 	//   LoadMonthProductSalesList(1, e.target.value, MonthYear+"-"+Month)
	// 	} else if (FilterType == 3){
	// 	  	loadDayProductList(1, e.target.value, CurrentDate)
	// 	} else if (FilterType == 4){
	// 	  	LoadCustomProductSalesList(1, e.target.value)
	// 	}
	// 	else if (FilterType == 5){
	// 	  	loadDayProductList(1, e.target.value, yesterday)
	// 	  // LoadMonthlyProfile(Moment(yesterday).format('YYYY-MM'))
	// 	}
	// }

	const LoadCustomStats = async (fromDate, toDate, sortBy, sortDirection, customFilterType) => {
		setFormData({})
		setProductsData([])

		setSalesTrendingData([])
		const Body = JSON.stringify(
			{
				"fromDate": fromDate,
				"toDate": toDate,
				"account": user.id,
				"dashboard": storeId,
				"page_size": PageSize,
				"sortBy": sortBy,
				"sortDirection": sortDirection,
				"filterType": customFilterType,
				"page_number": 1
			}
		)

		await Promise.all([
			LoadFinanceData('custom', Body),
			LoadProductsTrendingData('custom', Body, sortBy, sortDirection)
		])
	}

	const LoadAnnualStats = async (year) => {
		setFormData({})
		setProductsData([])

		setSalesTrendingData([])
		const Body = JSON.stringify(
			{
				"year": year,
				"account": user.id,
				"dashboard": storeId,
				"page_size": PageSize,
				"sortBy": Sortby,
				"sortDirection": SortDirection,
				"page_number": 1
			}
		)

		await Promise.all([
			LoadFinanceData('annual', Body),
			LoadProductsTrendingData('annual', Body)
		])
	}

	const refreshData = async () => {

		setLoadingVisibility("block")
		setDataVisibility("none")

		const Body = JSON.stringify({
			"account": user.id,
		})
		try {
			await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/refreshWeeklyData/`, Body, config)

			if (FilterType == 1) {
				LoadAnnualStats(Year, Sortby, SortDirection)
			} else if (FilterType == 2) {
				LoadMonthlyStats(MonthYear + "-" + Month, Sortby, SortDirection)
			} else if (FilterType == 3) {
				LoadDayStats(CurrentDate, Sortby, SortDirection)
			} else if (FilterType == 4) {
				LoadCustomStats(FromDate, ToDate, Sortby, SortDirection, CustomFilterType)
			}
			if (FilterType == 5) {
				LoadDayStats(yesterday, Sortby, SortDirection)
				// LoadMonthlyProfile(Moment(yesterday).format('YYYY-MM'))
			}
			//   console.log('Users', JSON.stringify(response.data.Data))
		} catch (error) {
		}

	}

	const LoadMonthlyProfile = async (date) => {

		const Body = JSON.stringify(
			{
				"date": "" + date,
				"account": user.id
			}
		)
		try {
			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/monthlyProfile/`, Body, config)

			setMonthProfile(response.data['stats'])

		} catch (error) {
		}

	}
	const LoadYearlyProfile = async (year) => {

		const Body = JSON.stringify(
			{
				"year": "" + year,
				"account": user.id,

			}
		)
		try {
			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/yearlyProfile/`, Body, config)
			setYearProfile(response.data['stats'])
		} catch (error) {
			// console.log('first', error)
			//   setResponseError(error.response.statusText)
		}

	}

	const ExportBreakdownCsv = (e) => {
		e.preventDefault()
		if (FilterType == 5) {
			ExportDayStats(yesterday)
		}
		if (FilterType == 1) {
			ExportYearStats(Year)
		} else if (FilterType == 2) {

			ExportMonthlyStats(MonthYear + "-" + Month)
		} else if (FilterType == 3) {
			ExportDayStats(CurrentDate)
		} else if (FilterType == 4) {
			ExportCustomStats(FromDate, ToDate)
		}
	}

	const ExportYearStats = async (year) => {
		setLoadingVisibility("block")
		setDataVisibility("none")

		const Body = JSON.stringify(
			{
				"year": year,
				"account": user.id,
				"dashboard": storeId
			}
		)
		try {
			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/csv/finance/yearlySales/`, Body, config)
			const url = `${process.env.REACT_APP_BACKEND_URL}/${response.data['file']}`
			window.open(url, '_blank').focus()
		} catch (error) {
			// console.log('first', error)
		}
		setLoadingVisibility("none")
		setDataVisibility("block")
	}

	const ExportMonthlyStats = async (date) => {
		setLoadingVisibility("block")
		setDataVisibility("none")

		const Body = JSON.stringify(
			{
				"date": date,
				"account": user.id,
				"dashboard": storeId
			}
		)
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_BACKEND_URL}/api/v1/csv/finance/monthlySales/`,
				Body,
				config
			)
			const url = `${process.env.REACT_APP_BACKEND_URL}/${response.data['file']}`
			window.open(url, '_blank').focus();
		} catch (error) {
		}
		setLoadingVisibility("none")
		setDataVisibility("block")
	}

	const ExportDayStats = async (date) => {
		setLoadingVisibility("block")
		setDataVisibility("none")

		const Body = JSON.stringify(
			{
				"date": date,
				"account": user.id,
				"dashboard": storeId
			}
		)
		try {
			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/csv/finance/daySales/`, Body, config)
			// console.log(response.data['stats']['JHB'])

			const url = `${process.env.REACT_APP_BACKEND_URL}/${response.data['file']}`
			window.open(url, '_blank').focus();
			// setSalesTrendingData(response.data['stats']['sales_trending_data'])
			//   console.log('Users', JSON.stringify(response.data.Data))
		} catch (error) {
			// console.log('first', error)
			//   setResponseError(error.response.statusText)
		}
		setLoadingVisibility("none")
		setDataVisibility("block")
	}


	const ExportCustomStats = async (fromDate, toDate) => {
		setLoadingVisibility("block")
		setDataVisibility("none")

		const Body = JSON.stringify(
			{
				"fromDate": fromDate,
				"toDate": toDate,
				"account": user.id,
				"dashboard": storeId
			}
		)
		try {
			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/csv/finance/customSales/`, Body, config)

			const url = `${process.env.REACT_APP_BACKEND_URL}/${response.data['file']}`
			window.open(url, '_blank').focus()
		} catch (error) {
			// console.log('first', error)
		}
		setLoadingVisibility("none")
		setDataVisibility("block")
	}

	const ExportProductBreakdownCsv = (e) => {
		e.preventDefault()
		if (FilterType == 5) {
			ExportProductDayStats(yesterday)
		}
		if (FilterType == 1) {
			ExportProductYearStats(Year)
		} else if (FilterType == 2) {
			// console.log(("0" + (Date_Var.getMonth() + 1)).slice(-2))
			ExportProductMonthlyStats(Year + "-" + Month)
		} else if (FilterType == 3) {
			ExportProductDayStats(CurrentDate)
		} else if (FilterType == 4) {
			ExportProductCustomStats(FromDate, ToDate)
		}
	}


	const ExportProductYearStats = async (year) => {
		setLoadingVisibility("block")
		setDataVisibility("none")

		const Body = JSON.stringify(
			{
				"year": year,
				"account": user.id,
				"dashboard": storeId
			}
		)
		try {
			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/csv/productFinance/yearlySales/`, Body, config)
			const url = `${process.env.REACT_APP_BACKEND_URL}/${response.data['file']}`
			window.open(url, '_blank').focus()
		} catch (error) {
			// console.log('first', error)
		}
		setLoadingVisibility("none")
		setDataVisibility("block")
	}

	const ExportProductMonthlyStats = async (date) => {
		setLoadingVisibility("block")
		setDataVisibility("none")

		const Body = JSON.stringify(
			{
				"date": date,
				"account": user.id,
				"dashboard": storeId
			}
		)
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_BACKEND_URL}/api/v1/csv/productFinance/monthlySales/`,
				Body,
				config
			)
			const url = `${process.env.REACT_APP_BACKEND_URL}/${response.data['file']}`
			window.open(url, '_blank').focus();
			//   console.log('Users', JSON.stringify(response.data.Data))
		} catch (error) {
			// console.log('first', error)
			//   setResponseError(error.response.statusText)
		}

		setLoadingVisibility("none")
		setDataVisibility("block")
	}

	const ExportProductDayStats = async (date) => {
		setLoadingVisibility("block")
		setDataVisibility("none")

		const Body = JSON.stringify(
			{
				"date": date,
				"account": user.id,
				"dashboard": storeId
			}
		)
		try {
			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/csv/productFinance/daySales/`, Body, config)
			// console.log(response.data['stats']['JHB'])

			const url = `${process.env.REACT_APP_BACKEND_URL}/${response.data['file']}`
			window.open(url, '_blank').focus();
			// setSalesTrendingData(response.data['stats']['sales_trending_data'])
			//   console.log('Users', JSON.stringify(response.data.Data))
		} catch (error) {
			// console.log('first', error)
			//   setResponseError(error.response.statusText)
		}
		setLoadingVisibility("none")
		setDataVisibility("block")
	}

	const ExportProductCustomStats = async (fromDate, toDate) => {
		setLoadingVisibility("block")
		setDataVisibility("none")

		const Body = JSON.stringify(
			{
				"fromDate": fromDate,
				"toDate": toDate,
				"account": user.id,
				"dashboard": storeId
			}
		)
		try {
			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/csv/finance/customSales/`, Body, config)

			const url = `${process.env.REACT_APP_BACKEND_URL}/${response.data['file']}`
			window.open(url, '_blank').focus()
		} catch (error) {
			// console.log('first', error)
		}
		setLoadingVisibility("none")
		setDataVisibility("block")
	}
	// 
	const OnMonthChange = async (e) => {
		e.preventDefault()
		setMonth(e.target.value)
		LoadMonthlyStats(MonthYear + "-" + e.target.value)
	}
	const OnMonthYearChange = async (e) => {
		e.preventDefault()
		setMonthYear(e.target.value)
		LoadMonthlyStats(e.target.value + "-" + Month)
	}
	const OnDayChange = async (e) => {
		e.preventDefault()
		setCurrentDate(e.target.value)
		LoadDayStats(e.target.value)
	}
	const onFromDateChange = async (e) => {
		e.preventDefault()
		LoadCustomStats(e.target.value, ToDate)
		setFromDate(e.target.value)
	}
	const onToDateChange = async (e) => {
		e.preventDefault()
		LoadCustomStats(FromDate, e.target.value)
		setToDate(e.target.value)
	}
	const OnYearChange = async (e) => {
		e.preventDefault()
		LoadAnnualStats(e.target.value)
		setYear(e.target.value)
		// await delay(2000)
	}
	const ChangeFilter = (e) => {
		setFilterType(e.target.value)
		if (e.target.value == 1) {
			LoadAnnualStats(Year)
		} else if (e.target.value == 2) {
			LoadMonthlyStats(MonthYear + "-" + Month)
		} else if (e.target.value == 3) {
			LoadDayStats(CurrentDate)
		} else if (e.target.value == 4) {
			LoadCustomStats(FromDate, ToDate)
		}
		if (e.target.value == 5) {
			LoadDayStats(yesterday)
			// LoadMonthlyProfile(Moment(yesterday).format('YYYY-MM'))
		}
	}
	// 

	// localStorage 

	localStorage.setItem(`filterType-dashboard-${storeId}`, FilterType)
	localStorage.setItem(`custom-filterType-dashboard-${storeId}`, CustomFilterType)
	localStorage.setItem(`year-dashboard-${storeId}`, Year)
	localStorage.setItem(`month-dashboard-${storeId}`, Month)
	localStorage.setItem(`monthyear-dashboard-${storeId}`, MonthYear)
	localStorage.setItem(`date-dashboard-${storeId}`, CurrentDate)
	localStorage.setItem(`yesterday-dashboard-${storeId}`, yesterday)
	localStorage.setItem(`fromdate-dashboard-${storeId}`, FromDate)
	localStorage.setItem(`todate-dashboard-${storeId}`, ToDate)
	localStorage.setItem(`page-size`, PageSize)

	//  

	useEffect(() => {
		getExpectedKPIs()
		if (user) {
			if (localStorage.getItem(`filterType-dashboard-${storeId}`) == 1) {
				LoadAnnualStats(localStorage.getItem(`year-dashboard-${storeId}`), localStorage.getItem(`sort-by`), localStorage.getItem(`sort-direction`))
			} else if (localStorage.getItem(`filterType-dashboard-${storeId}`) == 2) {
				LoadMonthlyStats(localStorage.getItem(`monthyear-dashboard-${storeId}`) + "-" + localStorage.getItem(`month-dashboard-${storeId}`), localStorage.getItem(`sort-by`), localStorage.getItem(`sort-direction`))
			} else if (localStorage.getItem(`filterType-dashboard-${storeId}`) == 3) {
				LoadDayStats(localStorage.getItem(`date-dashboard-${storeId}`), localStorage.getItem(`sort-by`), localStorage.getItem(`sort-direction`))
			} else if (localStorage.getItem(`filterType-dashboard-${storeId}`) == 4) {
				LoadCustomStats(localStorage.getItem(`fromdate-dashboard-${storeId}`), localStorage.getItem(`todate-dashboard-${storeId}`), localStorage.getItem(`sort-by`), localStorage.getItem(`sort-direction`), localStorage.getItem(`custom-filterType-dashboard-${storeId}`))
			}
			if (localStorage.getItem(`filterType-dashboard-${storeId}`) == 5) {
				LoadDayStats(yesterday, localStorage.getItem(`sort-by`), localStorage.getItem(`sort-direction`))
				// LoadMonthlyProfile(Moment(yesterday).format('YYYY-MM'))
			}
		}
		const interval = setInterval(() => {
			if (localStorage.getItem(`filterType-dashboard-${storeId}`) == 1) {
				LoadAnnualStats(localStorage.getItem(`year-dashboard-${storeId}`), localStorage.getItem(`sort-by`), localStorage.getItem(`sort-direction`))
			} else if (localStorage.getItem(`filterType-dashboard-${storeId}`) == 2) {
				LoadMonthlyStats(localStorage.getItem(`monthyear-dashboard-${storeId}`) + "-" + localStorage.getItem(`month-dashboard-${storeId}`), localStorage.getItem(`sort-by`), localStorage.getItem(`sort-direction`))
			} else if (localStorage.getItem(`filterType-dashboard-${storeId}`) == 3) {
				LoadDayStats(localStorage.getItem(`date-dashboard-${storeId}`), localStorage.getItem(`sort-by`), localStorage.getItem(`sort-direction`))
			} else if (localStorage.getItem(`filterType-dashboard-${storeId}`) == 4) {
				LoadCustomStats(localStorage.getItem(`fromdate-dashboard-${storeId}`), localStorage.getItem(`todate-dashboard-${storeId}`), localStorage.getItem(`sort-by`), localStorage.getItem(`sort-direction`), localStorage.getItem(`custom-filterType-dashboard-${storeId}`))
			}
			if (localStorage.getItem(`filterType-dashboard-${storeId}`) == 5) {
				LoadDayStats(yesterday, localStorage.getItem(`sort-by`), localStorage.getItem(`sort-direction`))
				// LoadMonthlyProfile(Moment(yesterday).format('YYYY-MM'))
			}

		}, 300000)
		return () => {
			clearInterval(interval);
		}
	}, [])



	return (
		<>
			<Helmet>

				<title>{ CompanyProfile['companyName'] } Finance Dashboard</title>
				<meta
					name="description"
					content={ `${CompanyProfile['companyName']} Sales Dashboard` }
				/>
				<link rel="stylesheet" href="../assets/css/style.css" />
				<script src="../assets/js/script.js" />
			</Helmet>
			<div style={ { display: LoadingVisibility } }>
				<div id="global-loader" >
					<div className="whirly-loader"> </div>
				</div>
			</div>
			<div className="content">
				{/* Filter starts */ }
				<FilterComponent
					storeId={ storeId }
					filterType={ FilterType }
					updateFilter={ setFilterType }
					Month={ Month }
					updateMonth={ setMonth }
					Year={ Year }
					updateYear={ setYear }
					MonthYear={ MonthYear }
					updateMonthYear={ setMonthYear }
					CurrentDate={ CurrentDate }
					updateCurrentDate={ setCurrentDate }
					FromDate={ FromDate }
					updateFromDate={ setFromDate }
					ToDate={ ToDate }
					updateToDate={ setToDate }
					years={ years }
					dayStats={ LoadDayStats }
					monthStats={ LoadMonthlyStats }
					annualStats={ LoadAnnualStats }
					customStats={ LoadCustomStats }
					refreshData={ refreshData }
					PageSize={ PageSize }
					Sortby={ Sortby }
					SortDirection={ SortDirection }
					CustomFilterType={ CustomFilterType }
					setCustomFilterType={ setCustomFilterType }
				/>
				{/* Filter Ends */ }
				<div style={ { display: dataVisibility } }>
					{
						FilterType == 3 ?
							<>
								<div>
									<DayFinance
										numberFormatter={ numberFormatter }
										currencyFormatter={ formatter }
										kpiData={ FormData }
										expectedKPIs={ ExpectedData }
										pageSize={ PageSize }
										trendingPageSize={ TrendingPageSize }
										onPageSizeChange={ onPageSizeChange }
										TotalProducts={ TotalProducts }
										TotalTrendingData={ TotalTrendingData }
										currentDate={ CurrentDate }
										loadDayProductList={ LoadDayProductList }
										SalesTrendingData={ SalesTrendingData }
										ExportBreakdownCsv={ ExportBreakdownCsv }
										ProductsData={ ProductsData }
										ExportProductBreakdownCsv={ ExportProductBreakdownCsv }
										Sortby={ Sortby }
										SortDirection={ SortDirection }
										onSortChange={ onSortChange }
										currentPage={ DayCurrentPage }
										setCurrentPage={ setDayCurrentPage }
										onSortDirectionChange={ onSortDirectionChange }
										TotalPages={ TotalPages }
									/>
								</div>
							</>
							: FilterType == 5 ?
								<>
									<div>
										<DayFinance
											numberFormatter={ numberFormatter }
											currencyFormatter={ formatter }
											kpiData={ FormData }
											expectedKPIs={ ExpectedData }
											pageSize={ PageSize }
											trendingPageSize={ TrendingPageSize }
											onPageSizeChange={ onPageSizeChange }
											TotalProducts={ TotalProducts }
											TotalTrendingData={ TotalTrendingData }
											currentDate={ yesterday }
											loadDayProductList={ LoadDayProductList }
											SalesTrendingData={ SalesTrendingData }
											ExportBreakdownCsv={ ExportBreakdownCsv }
											ProductsData={ ProductsData }
											ExportProductBreakdownCsv={ ExportProductBreakdownCsv }
											Sortby={ Sortby }
											SortDirection={ SortDirection }
											onSortChange={ onSortChange }
											currentPage={ DayCurrentPage }
											setCurrentPage={ setDayCurrentPage }
											onSortDirectionChange={ onSortDirectionChange }
											TotalPages={ TotalPages }
										/>
									</div>
								</>
								: FilterType == 2 ?
									<>
										<div>
											<MonthFinance
												numberFormatter={ numberFormatter }
												currencyFormatter={ formatter }
												kpiData={ FormData }
												expectedKPIs={ ExpectedData }
												pageSize={ PageSize }
												trendingPageSize={ TrendingPageSize }
												onPageSizeChange={ onPageSizeChange }

												TotalProducts={ TotalProducts }
												TotalTrendingData={ TotalTrendingData }
												month={ Month }
												monthYear={ MonthYear }
												loadMonthProductList={ LoadMonthProductList }
												SalesTrendingData={ SalesTrendingData }
												ExportBreakdownCsv={ ExportBreakdownCsv }
												ProductsData={ ProductsData }
												ExportProductBreakdownCsv={ ExportProductBreakdownCsv }
												Sortby={ Sortby }
												SortDirection={ SortDirection }
												onSortChange={ onSortChange }
												onSortDirectionChange={ onSortDirectionChange }

												currentPage={ MonthlyCurrentPage }
												setCurrentPage={ setMonthlyCurrentPage }

												TotalPages={ TotalPages }
											/>
										</div>
									</>
									: FilterType == 1 ?
										<>
											<div>
												<AnnualFinance
													numberFormatter={ numberFormatter }
													currencyFormatter={ formatter }
													kpiData={ FormData }
													expectedKPIs={ ExpectedData }
													pageSize={ PageSize }
													trendingPageSize={ TrendingPageSize }
													onPageSizeChange={ onPageSizeChange }

													TotalProducts={ TotalProducts }
													TotalTrendingData={ TotalTrendingData }
													Year={ Year }
													LoadAnnualProductList={ LoadAnnualProductList }
													SalesTrendingData={ SalesTrendingData }
													ExportBreakdownCsv={ ExportBreakdownCsv }
													ProductsData={ ProductsData }
													ExportProductBreakdownCsv={ ExportProductBreakdownCsv }
													Sortby={ Sortby }
													SortDirection={ SortDirection }
													onSortChange={ onSortChange }
													onSortDirectionChange={ onSortDirectionChange }
													currentPage={ CurrentPage }
													setCurrentPage={ setCurrentPage }
													TotalPages={ TotalPages }
												/>
											</div>
										</>
										: FilterType == 4 ?
											<>
												<div>
													<CustomDateFinance
														numberFormatter={ numberFormatter }
														currencyFormatter={ formatter }
														kpiData={ FormData }
														expectedKPIs={ ExpectedData }
														pageSize={ PageSize }
														trendingPageSize={ TrendingPageSize }
														onPageSizeChange={ onPageSizeChange }

														TotalProducts={ TotalProducts }
														TotalTrendingData={ TotalTrendingData }
														fromDate={ FromDate }
														toDate={ ToDate }
														loadCustomProductList={ LoadCustomProductList }
														SalesTrendingData={ SalesTrendingData }
														ExportBreakdownCsv={ ExportBreakdownCsv }
														ProductsData={ ProductsData }
														ExportProductBreakdownCsv={ ExportProductBreakdownCsv }
														Sortby={ Sortby }
														SortDirection={ SortDirection }
														onSortChange={ onSortChange }
														currentPage={ CustomCurrentPage }
														setCurrentPage={ setCustomCurrentPage }
														onSortDirectionChange={ onSortDirectionChange }
														TotalPages={ TotalPages }
													/>
												</div>
											</>
											: <></>
					}
				</div>
				<hr />
				<div className=''>
					<div className='card'>
						<div className='card-body'>
							<div className="card-header pb-0 d-flex justify-content-between align-items-center">
								<h4 className="card-title">Notes</h4>
							</div>
							<ol className="list-group list-group">
								<li className="list-group-item"><strong>Sales Returns: </strong>are the number of orders returns</li>
								<li className="list-group-item">The data comparison is
									{
										FilterType == 1 ? ` between Years (${Year} and ${parseInt(Year) - 1})` :
											FilterType == 2 & Month === Date_Var.getMonth() + 1 ? ` between same number of days of this and last month` :
												FilterType == 2 & Month != Date_Var.getMonth() + 1 ? ` between selected and last month` :
													FilterType == 3 ? ` between ${Moment(CurrentDate).format('D MMM YY')} and ${Moment(CurrentDate).subtract(1, 'months').format('D MMM YY')}` :
														FilterType == 4 ? ` from ${Moment(FromDate).format('D MMM YY') + " to " + Moment(ToDate).format('D MMM YY')}` :
															FilterType == 5 ? ` yesterday and day before yesterday.` : <></>
									}
								</li>
								<li className="list-group-item">% Total Revenue and Total Net Revenue is comparison between
									{
										FilterType == 1 ? ` between Years (${Year} and ${parseInt(Year) - 1})` :
											FilterType == 2 & Month === Date_Var.getMonth() + 1 ? ` between same number of days of this and last month` :
												FilterType == 2 & Month != Date_Var.getMonth() + 1 ? ` between selected and last month` :
													FilterType == 3 ? ` between ${Moment(CurrentDate).format('D MMM YY')} and ${Moment(CurrentDate).subtract(1, 'months').format('D MMM YY')}` :
														FilterType == 4 ? ` from ${Moment(FromDate).format('D MMM YY') + " to " + Moment(ToDate).format('D MMM YY')}` :
															FilterType == 5 ? ` yesterday and day before yesterday.` : <></>
									}
								</li>
								<li className="list-group-item">% Total Fee = Ratio of Total Fee to Total Revenue</li>
								<li className="list-group-item">% Total Cost = Ratio of Total Cost to Total Revenue</li>
								<li className="list-group-item">% Total Profit = Ratio of Total Profit to Total Revenue</li>
								<li className="list-group-item">Expected KPIs are arbitrary values of remaining data.</li>

							</ol>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

const mapStateToProps = state => ({
	isAuthenticated: state.Auth.isAuthenticated,
	user: state.Auth.user,
})

export default connect(mapStateToProps, {})(StoreFinance)