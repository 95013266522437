import React from 'react'

const SalesKPis = (props) => {
    const FormData = props.FormData
    const currencyFormatter = props.currencyFormatter
    const numberFormatter = props.numberFormatter
    return (

        <div className="row">
            <div className="col-lg-3 col-sm-6 col-6">
                <div className="dash-widget">
                    <div className="dash-widgetimg">
                        <span><img src="assets/img/icons/dash1.svg" alt="img" /></span>
                    </div>
                    <div className="dash-widgetcontent">
                        { FormData?.kpis?.revenue || FormData?.kpis?.revenue >= 0 ? (
                            <h5>
                                <span data-count={ Number(FormData?.kpis?.revenue) }>
                                    { currencyFormatter.format(FormData?.kpis?.revenue) }
                                </span>
                            </h5>
                        ) :
                            <h5>
                                <span className="skeleton-loader"></span>
                            </h5>
                        }
                        <h6>Total Revenue</h6>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-6">
                <div className="dash-widget dash1">
                    <div className="dash-widgetimg">
                        <span><img src="assets/img/icons/dash2.svg" alt="img" /></span>
                    </div>
                    <div className="dash-widgetcontent">
                        { FormData?.kpis?.revenue || FormData?.kpis?.revenue >= 0 ? (
                            <h5>
                                <span data-count={ Number(FormData?.kpis?.net_revenue) }>
                                    { currencyFormatter.format(FormData?.kpis?.net_revenue) }
                                </span>
                                (
                                <span data-count={ Number(numberFormatter.format(FormData?.kpis?.net_revenue_change)) }>
                                    { numberFormatter.format(Math.round(FormData?.kpis?.net_revenue / FormData?.kpis?.revenue * 100)) }%
                                </span>
                                )
                            </h5>
                        ) :
                            <h5>
                                <span className="skeleton-loader"></span>
                            </h5>
                        }
                        <h6>Total Net Revenue</h6>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-6">
                <div className="dash-widget dash2">
                    <div className="dash-widgetimg">
                        <span><img src="assets/img/icons/dash3.svg" alt="img" /></span>
                    </div>
                    <div className="dash-widgetcontent">
                        { FormData?.kpis?.revenue || FormData?.kpis?.revenue >= 0 ? (
                            <h5><span data-count={ Number(FormData?.kpis?.fee) }>{ currencyFormatter.format(FormData?.kpis?.fee) }</span> (<span data-count={ Number(numberFormatter.format(Number(FormData?.kpis?.fee) / Number(FormData?.kpis?.revenue) * 100)) }>{ numberFormatter.format(Math.round(Number(FormData?.kpis?.fee / Number(FormData?.kpis?.revenue) * 100))) }%</span>)</h5>
                        ) :
                            <h5>
                                <span className="skeleton-loader"></span>
                            </h5>
                        }
                        <h6>Total Fee</h6>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-6">
                <div className="dash-widget dash3">
                    <div className="dash-widgetimg">
                        <span><img src="assets/img/icons/dash4.svg" alt="img" /></span>
                    </div>
                    <div className="dash-widgetcontent">
                        { FormData?.kpis?.revenue || FormData?.kpis?.revenue >= 0 ? (
                            <h5><span>{ currencyFormatter.format(Math.round(FormData?.kpis?.net_revenue - FormData?.kpis?.total_cost)) }</span> (<span>{ numberFormatter.format(Math.round(Number(FormData?.kpis?.net_revenue - FormData?.kpis?.total_cost) / Number(FormData?.kpis?.revenue) * 100)) }%</span>)</h5>
                        ) :
                            <h5>
                                <span className="skeleton-loader"></span>
                            </h5>
                        }
                        <h6>Total Profit</h6>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-6 d-flex">
                <div className="dash-count">
                    <div className="dash-counts">
                        { FormData?.kpis?.revenue || FormData?.kpis?.revenue >= 0 ? (
                            <h4>{ numberFormatter.format(FormData?.kpis?.units) }</h4>
                        ) :
                            <h5>
                                <span className="skeleton-loader"></span>
                            </h5>
                        }
                        <h5>Total Units</h5>
                    </div>
                    <div className="dash-imgs">
                        <i data-feather="user"></i>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-6 d-flex">
                <div className="dash-count das1">
                    <div className="dash-counts">
                        { FormData?.kpis?.revenue || FormData?.kpis?.revenue >= 0 ? (
                            <h4>{ numberFormatter.format(FormData?.cnr_kpis?.units || 0) }</h4>
                        ) :
                            <h5>
                                <span className="skeleton-loader"></span>
                            </h5>
                        }
                        <h5>Total CnR Units</h5>
                    </div>
                    <div className="dash-imgs">
                        <i data-feather="user-check"></i>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-6 d-flex">
                <div className="dash-count das2">
                    <div className="dash-counts">
                        { FormData?.kpis?.revenue || FormData?.kpis?.revenue >= 0 ? (
                            <h4>{ currencyFormatter.format(FormData?.cnr_kpis?.revenue || 0) }</h4>
                        ) :
                            <h5>
                                <span className="skeleton-loader"></span>
                            </h5>
                        }
                        <h5>Total CnR Revenue</h5>
                    </div>
                    <div className="dash-imgs">
                        <i data-feather="file-text"></i>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-6 d-flex">
                <div className="dash-count das3">
                    <div className="dash-counts">
                        { FormData?.kpis?.revenue || FormData?.kpis?.revenue >= 0 ? (
                            <h4>{ currencyFormatter.format(FormData?.kpis?.total_cost) }</h4>
                        ) :
                            <h5>
                                <span className="skeleton-loader"></span>
                            </h5>
                        }
                        <h5>Total Cost</h5>
                    </div>
                    <div className="dash-imgs">
                        <i data-feather="file"></i>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SalesKPis