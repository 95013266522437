import React, { useState } from 'react'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { Navigate, Outlet, useSearchParams } from 'react-router-dom'

const NormalLayout = ({ isAuthenticated }) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const next = searchParams.get("next") ? searchParams.get("next") : null
    if (isAuthenticated === true) {
        if (next) {
            return <Navigate to={ `${next}` } />
        } else {
            return <Navigate to="/" />
        }
    }
    return (
        <>
            <Outlet />
        </>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.Auth.isAuthenticated
})

export default connect(mapStateToProps)(NormalLayout)