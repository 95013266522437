import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'

const ViewPlan = () => {const location = useLocation()
    const { state } = location
    const planId = state['planId']
    const [PlanDetails, setPlanDetails] = useState({
        'name' : "",
        'pricing' : "",
        'trailDays' : "",
        'takealotStores' : "",
    })
    const {
        name,
        pricing,
        trailDays,
        takealotStores,
    } = PlanDetails

    const onPlanChange = (e) => {setPlanDetails({...PlanDetails, [e.target.name]: e.target.value})}
    // API
    
    const loadPlanDetails = async(planId) => {
        const config = {
            headers: {
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
                'Authorization' : `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify({
            planId : planId
        })
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/subscription/plan/get/details/`, Body, config)
            setPlanDetails(response?.data?.data)
        } catch (error) {
            Swal.fire({
                position: "bottom-end",
                type: "error",
                title: "Success",
                html: `${error?.response?.data?.message}`,
                showConfirmButton: !1,
                timer: 5000,
                confirmButtonClass: "btn btn-primary",
                buttonsStyling: !1,
            })
        }
    }
    // Load Subscribers
    const [SubscribersList, setSubscribersList] = useState([])
    const [TotalSubscribers, setTotalSubscribers] = useState(0)
    const [TotalActiveSubscribers, setTotalActiveSubscribers] = useState(0)
    const loadSubscribers = async(planId) => {
        const config = {
            headers: {
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
                'Authorization' : `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify({
            planId: planId
        })
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/subscription/plan/get/subscribers/`, Body, config)
            setSubscribersList(response?.data?.data)
            setTotalSubscribers(response?.data?.total_subcribers)
            setTotalActiveSubscribers(response?.data?.total_active_subcribers)
        } catch (error) {
            Swal.fire({
                position: "bottom-end",
                type: "error",
                title: "Success",
                html: `${error?.response?.data?.message}`,
                showConfirmButton: !1,
                timer: 5000,
                confirmButtonClass: "btn btn-primary",
                buttonsStyling: !1,
            })
        }
    }
    useEffect(() => {
        loadPlanDetails(planId)
        loadSubscribers(planId)
    }, [])
    
    return (
        <div className=''>
            <div className='content'>
                <div className="page-header">
                    <div className="page-title">
                        <h4>View Plan</h4>
                        <h6>View existing plan</h6>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="productdetails">
                            <ul className="product-bar">
                                <li>
                                    <h4>Plan</h4>
                                    <h6>{PlanDetails?.name}</h6>
                                </li>
                                <li>
                                    <h4>Price per month</h4>
                                    <h6>{PlanDetails?.pricing}</h6>
                                </li>
                                <li>
                                    <h4>Stores Allowed</h4>
                                    <h6>{PlanDetails?.takealotStores}</h6>
                                </li>
                                <li>
                                    <h4>Total Subscribers</h4>
                                    <h6>{TotalSubscribers}</h6>
                                </li>
                                <li>
                                    <h4>Total Active Subscribers</h4>
                                    <h6>{TotalActiveSubscribers}</h6>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <hr/>
                <div className="page-header">
                    <div className="page-title">
                        <h4>Subscribers</h4>
                        <h6>View Subscribers</h6>
                    </div>                    
                </div>
                <div className="card mb-0">
                    <div className="card-body">
                        <div className="card-header pb-0 d-flex justify-content-between align-items-center">
                            <h4 className="card-title">Subscribers List</h4>
                        </div>
                        <div className="table-responsive text-wrap">
                            <table className="table mb-0">
                                <thead>
                                    
                                    <tr>
                                        <th>
                                            Name 
                                        </th>
                                        <th>
                                            Subscribed
                                        </th>
                                        <th>
                                            Starting Date
                                        </th>
                                        <th>
                                            Valid Until                                    
                                        </th>
                                        <th>
                                            Amount Paid                                   
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        SubscribersList.map(
                                            (key)=>{
                                            return(
                                                <>
                                                <tr>
                                                    <td dangerouslySetInnerHTML={{__html: key?.user}}>
                                                        
                                                    </td>
                                                    <td>
                                                        {key?.subscriptionMonth}
                                                    </td>
                                                    <td>
                                                        {key?.startingDate}
                                                    </td>
                                                    <td>
                                                        {key?.endingDate}
                                                    </td>
                                                    <td>
                                                        {key?.amountPaid}
                                                    </td>
                                                </tr>
                                                </>
                                            )
                                            })
                                        }
                                </tbody>
                            </table>
                            <br/>
                            <div className='pagination justify-content-center'>
                                
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ViewPlan