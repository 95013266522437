import React, { useEffect, useState } from 'react'

const index = (props) => {
    const storeId = props.storeId
    const formatter = props.formatter
    const numberFormatter = props.numberFormatter
    const SalesDetailsVisibility = props.SalesDetailsVisibility
    const LoadingSalesDetailsVisibility = props.LoadingSalesDetailsVisibility
    const ProductInfo = props.ProductInfo
    const WarehouseDetails = props.WarehouseDetails
    const UpdatedProductDetails = props.UpdatedProductDetails
    const ProductCost = props.ProductCost
    const onProductCostSubmit = props.onProductCostSubmit
    const onProductCostChange = props.onProductCostChange
    const apexChartDualOptions = (chartId, xaxis, seriesA, seriesB) =>
    ({
        chart: {
            id: chartId,
            width: "100%"

        },
        xaxis: {
            categories: xaxis
        },
        yaxis: [
            {
                title: {
                    text: seriesA
                },
            },
            {
                opposite: true,
                title: {
                    text: seriesB
                }
            }
        ],
        colors: ['#FFA500', '#808080', '#00FF7F', '#228B22', '#228B22'],
        dataLabels: {
            enabled: false,
            style: {
                fontSize: '12px',
                fontWeight: 'bold',
                color: 'black'
            },
            background: {
                enabled: true,
                foreColor: 'black',
                borderRadius: 2,
                padding: 4,
                opacity: 0.9,
                borderWidth: 1,
                borderColor: '#fff',
            },
        },
        grid: {
            show: false
        },
        stroke: {
            curve: 'smooth',
        }

    })
    const apexChartDualOptions1 = (chartId, xaxis, seriesA, seriesB, seriesC) =>
    ({
        chart: {
            id: chartId,
            width: "100%"

        },
        xaxis: {
            categories: xaxis
        },
        colors: ['#FFA500', '#228B22', '#808080'],
        dataLabels: {
            enabled: false,
            style: {
                fontSize: '12px',
                fontWeight: 'bold',
                color: 'black'
            },
            background: {
                enabled: true,
                foreColor: 'black',
                borderRadius: 2,
                padding: 4,
                opacity: 0.9,
                borderWidth: 1,
                borderColor: '#fff',
            },
        },
        stroke: {
            curve: 'smooth',
        },
        grid: {
            show: false
        }

    })
    const apexChartSeries = (seriesName, seriesData, seriesName1, seriesData1, seriesName2, seriesData2, seriesName3, seriesData3) => ([
        {
            name: seriesName,
            data: seriesData
        },
        {
            name: seriesName1,
            data: seriesData1
        }
    ])
    const apexChartSeries1 = (seriesName, seriesData, seriesName1, seriesData1, seriesName2, seriesData2, seriesName3, seriesData3) => ([
        {
            name: seriesName,
            data: seriesData
        },
        {
            name: seriesName1,
            data: seriesData1
        },
        {
            name: seriesName2,
            data: seriesData2
        }
    ])
    return (
        <>
            <div className="modal fade" id={ "productDetailsModal-" + storeId } tabindex="-1" aria-hidden="true">
                <div className="modal-dialog  modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div style={ { textAlign: "center" } }>
                                <h4 className="modal-title" id="productDetailsModalTitle" style={ { textAlign: "center" } }><span className="text-muted fw-light">Product /</span> Details</h4>
                            </div>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body" style={ { wordBreak: "break-all", minWidth: '100px' } }>
                            <div style={ { display: LoadingSalesDetailsVisibility } }>
                                <div id="global-loader" >
                                    <div className="whirly-loader"> </div>
                                </div>
                            </div>
                            <div style={ { display: SalesDetailsVisibility } } className="row">
                                {/* <div className='justify-content-center'>
                                <ul className="nav nav-pills mb-3" role="tablist">
                                    <li className="nav-item">
                                        <button type="button" className="nav-link active" role="tab" data-bs-toggle="tab" data-bs-target="#navs-pills-product-details" aria-controls="navs-pills-product-details" aria-selected="true">
                                            Product Details
                                        </button>
                                    </li>
                                    <li className="nav-item">
                                        <button type="button" className="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#navs-pills-product-sales" aria-controls="navs-pills-product-sales" aria-selected="true">
                                            Sales
                                        </button>
                                    </li>
                                </ul>
                            </div> */}
                                <div className='container'>
                                    <div className="row">
                                        <div className="col-md-4 py-3 px-3">
                                            <img className="card-img card-img-left" width="100%" src={ ProductInfo['image_url'] } alt="Card image" />
                                        </div>
                                        <div className="col-md-8 py-3 px-3">
                                            <div className="card-body">
                                                <h5 className="card-title">{ ProductInfo['title'] }</h5>
                                                <div className='row'>
                                                    <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12'>
                                                        <div className="row mb-3">
                                                            <div className="col-sm-6">
                                                                <p ><b>Current Price</b></p>
                                                            </div>
                                                            <div className="col-sm-6" >
                                                                {/* <div className="input-group mb-3">
                                                <span className="input-group-text" id="basic-addon1">R</span>
                                                <input type="text" className="form-control" placeholder="Username" value={(productDetails['selling_price'])} aria-label="Username" aria-describedby="basic-addon1"/>
                                            </div>                             */}
                                                                <p>{ formatter.format(ProductInfo['selling_price']) }</p>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <p className="col-sm-6" ><b>Recomm. Retail Price</b></p>
                                                            <p className="col-sm-6" >{ formatter.format(ProductInfo['rrp']) }</p>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <p className="col-sm-6" ><b>Johannesberg Stock</b></p>
                                                            <p className="col-sm-6" >{ ProductInfo['JHB'] }</p>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <p className="col-sm-6" ><b>Cape Town Stock</b></p>
                                                            <p className="col-sm-6" >{ ProductInfo['CPT'] }</p>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <p className="col-sm-6" ><b>Product Label</b></p>
                                                            <p className="col-sm-6" >{ ProductInfo['product_label_number'] }</p>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <p className="col-sm-6" ><b>Offer ID</b></p>
                                                            <p className="col-sm-6" >{ ProductInfo['offer_id'] }</p>
                                                        </div>
                                                    </div>
                                                    <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12'>
                                                        {/* <div className="row mb-3">
                                            <p className="col-sm-6" ><b>GTIN</b></p>
                                            <p className="col-sm-6" >{productDetails['gtin'] ? productDetails['gtin'] : "N.A."}</p>
                                        </div> */}
                                                        <div className="row mb-3">
                                                            <p className="col-sm-6" ><b>SKU</b></p>
                                                            <p className="col-sm-6" >{ ProductInfo['sku'] }</p>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <p className="col-sm-6" ><b>Product Status</b></p>
                                                            <p className="col-sm-6" >
                                                                {
                                                                    ProductInfo['status'] === "Buyable" ?
                                                                        <span className="bg-label-primary me-1">
                                                                            { ProductInfo['status'] }
                                                                        </span> :
                                                                        ProductInfo['status'] === "Not Buyable" ?
                                                                            <span className="bg-label-danger me-1">
                                                                                { ProductInfo['status'] }
                                                                            </span>
                                                                            :
                                                                            <span className="bg-label-secondary me-1">
                                                                                { ProductInfo['status'] }
                                                                            </span>
                                                                }
                                                            </p>
                                                        </div>
                                                        {/* <div className="row mb-3">
                                            <p className="col-sm-6" ><b>Marketplace Barcode</b></p>
                                            <p className="col-sm-6" >{productDetails['barcode']}</p>
                                        </div> */}
                                                        {/* <div className="row mb-3">
                                                            <p className="col-sm-6" ><b>Takealot URL</b></p>
                                                            <a href={ ProductInfo['offer_url'] } target="_blank" className="col-sm-6" >{ ProductInfo['offer_url'] }</a>
                                                        </div> */}
                                                        <div className="row mb-3">
                                                            <p className="col-sm-6" ><b>TSIN ID</b></p>
                                                            <p className="col-sm-6" >{ ProductInfo['tsin_id'] }</p>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <p className="col-sm-6" ><b>PID</b></p>
                                                            <p className="col-sm-6" >{ ProductInfo['offerPID'] }</p>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <p className="col-sm-6" ><b>Warehouse Stock</b></p>
                                                            <p className="col-sm-6" >{ WarehouseDetails['Level'] }</p>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <p className="col-sm-6" ><b>Warehouse Total Stock</b></p>
                                                            <p className="col-sm-6" >{ WarehouseDetails['TotalStockLevel'] }</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button className="btn btn-md btn-primary col-4" data-bs-toggle="modal" data-bs-target={ "#editProductDetailsModal-" + storeId }>Edit Product Details</button>
                                                {/* <p className="card-text"><small className="text-muted">Discount: {productDetails['discount']}</small></p> */ }
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/* <div className="tab-content">
                                <div className="tab-pane fade active show" id="navs-pills-product-details" role="tabpanel">
                                </div>
                                <div className="tab-pane fade" id="navs-pills-product-sales" role="tabpanel">
                                            
                                </div>
                            </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id={ "editProductDetailsModal-" + storeId } tabindex="-1" aria-hidden="true">
                <div className="modal-dialog  modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div style={ { textAlign: "center" } }>
                                <h4 className="modal-title" id={ "editProductDetailsModalTitle-" + storeId } style={ { textAlign: "center" } }><span className="text-muted fw-light">Product /</span> Edit Details</h4>
                            </div>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body" style={ { wordBreak: "break-all" } }>
                            <div style={ { display: LoadingSalesDetailsVisibility } }>
                                <div id="global-loader" >
                                    <div className="whirly-loader"> </div>
                                </div>
                            </div>
                            <div style={ { display: SalesDetailsVisibility } } className="row">
                                <div className='container'>
                                    <div className="row">
                                        <div className="col-md-4 py-3 px-3">
                                            <img className="card-img card-img-left" width="100%" src={ ProductInfo['image_url'] } alt="Card image" />
                                        </div>
                                        <div className="col-md-8 py-3 px-3">
                                            <div className="card-body">
                                                <h5 className="card-title">{ ProductInfo['title'] }</h5>
                                                <br />
                                                <form onSubmit={ (e) => { props.onUpdatedProductDetailsSubmit(e) } }>
                                                    <div className="row mb-3">
                                                        <label className="col-sm-4 col-form-label" htmlfor="basic-icon-default-company">Selling Price</label>
                                                        <div className="col-sm-8">
                                                            <div className="input-group input-group-merge">
                                                                <span id="basic-icon-default-company2" className="input-group-text">R</span>
                                                                <input type="number" className="form-control" placeholder="Selling Price" name="selling_price" onChange={ (e) => { props.onUpdatedProductDetailsChange(e) } } value={ UpdatedProductDetails['selling_price'] } />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <label className="col-sm-4 col-form-label" htmlfor="basic-icon-default-company">Recomm. Retail Price</label>
                                                        <div className="col-sm-8">
                                                            <div className="input-group input-group-merge">
                                                                <span id="basic-icon-default-company2" className="input-group-text">R</span>
                                                                <input type="number" className="form-control" placeholder="Recommended Retail Price" name="rrp" onChange={ (e) => { props.onUpdatedProductDetailsChange(e) } } value={ UpdatedProductDetails['rrp'] } />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-end">
                                                        <div className="col-sm-8">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary"
                                                                data-bs-dismiss="modal"
                                                            >
                                                                Update</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id={ "editProductCostModal-" + storeId } tabindex="-1" aria-hidden="true">
                <div className="modal-dialog  modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div style={ { textAlign: "center" } }>
                                <h4 className="modal-title" id={ "editProductCostModalTitle-" + storeId } style={ { textAlign: "center" } }><span className="text-muted fw-light">Product /</span> Cost Calculator</h4>
                            </div>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body" style={ { wordBreak: "break-all" } }>
                            <div style={ { display: SalesDetailsVisibility } } className="row">
                                <div className='container'>
                                    <div className="row">
                                        <div className="col-md-4 py-3 px-3">
                                            <img className="card-img card-img-left" width="100%" src={ ProductInfo['image_url'] } alt="Card image" />
                                        </div>
                                        <div className="col-md-8 py-3 px-3">
                                            <div className="card-body">
                                                <h5 className="card-title">{ ProductInfo['title'] }</h5>
                                                <br />
                                                <form onSubmit={ e => onProductCostSubmit(e) }>{/* */ }
                                                    <div className='row'>
                                                        <label className="col-form-label h4" style={ { textAlign: "center" } } >Currency ZAR</label>
                                                        <div className="row mb-3">
                                                            <label className="col-sm-6 col-form-label" >Total Unit Ordered</label>
                                                            <div className="col-sm-6">
                                                                <div className="input-group">
                                                                    <span className="input-group-text">R</span>
                                                                    <input type="number" min={ 1 } className="form-control" value={ ProductCost['total_units_ordered'] } name="total_units_ordered" onChange={ (e) => { onProductCostChange(e) } } id="basic-default-name" placeholder='Cost Per Unit' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <label className="col-sm-6 col-form-label" htmlFor="basic-default-company">Supplier</label>
                                                            <div className="col-sm-6">
                                                                <div className="input-group">
                                                                    <span className="input-group-text">R</span>
                                                                    <input type="text" className="form-control" value={ ProductCost['supplier'] } name="supplier" onChange={ (e) => { onProductCostChange(e) } } id="basic-default-name" placeholder='Supplier Name' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <label className="col-sm-6 col-form-label" htmlFor="basic-default-company">Cost per Unit</label>
                                                            <div className="col-sm-6">
                                                                <div className="input-group">
                                                                    <span className="input-group-text">R</span>
                                                                    <input type="number" className="form-control" value={ ProductCost['cost_per_unit'] } name="cost_per_unit" onChange={ (e) => { onProductCostChange(e) } } id="basic-default-name" placeholder='Cost Per Unit' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <label className="col-sm-6 col-form-label" htmlFor="basic-default-company">Storage Cost per Unit</label>
                                                            <div className="col-sm-6">
                                                                <div className="input-group">
                                                                    <span className="input-group-text">R</span>
                                                                    <input type="number" className="form-control" value={ ProductCost['storage_cost_per_unit'] } name="storage_cost_per_unit" onChange={ (e) => { onProductCostChange(e) } } id="basic-default-name" placeholder='Cost Per Unit' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <label className="col-sm-6 col-form-label" htmlFor="basic-default-company">Shipment Cost per Unit (to Takealot)</label>
                                                            <div className="col-sm-6">
                                                                <div className="input-group">
                                                                    <span className="input-group-text">R</span>
                                                                    <input type="number" className="form-control" value={ ProductCost['shipment_cost_per_unit'] } name="shipment_cost_per_unit" onChange={ (e) => { onProductCostChange(e) } } id="basic-default-name" placeholder='Cost Per Unit' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <label className="col-sm-6 col-form-label" htmlFor="basic-default-company">Total Cost / Unit</label>
                                                            <div className="col-sm-6">
                                                                <div className="input-group">
                                                                    <label>{ formatter.format(Number(ProductCost['cost_per_unit']) + Number(ProductCost['shipment_cost_per_unit']) + Number(ProductCost['storage_cost_per_unit'])) }</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="" style={ { textAlign: "center" } }>
                                                            <button className="col-6 btn btn-sm btn-primary form-select " data-bs-dismiss="modal">Update Cost</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default index