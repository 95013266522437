import React from 'react';
import PropTypes from 'prop-types';

const BBPagination = ({ currentPage, totalPages, setCurrentPage }) => {
    const showPages = 3;
    const pages = [];
    for (let i = currentPage - 1; i <= currentPage + 1; i++) {
        if (i > 0 && i <= totalPages) {
            pages.push(i);
        }
    }

    return (
        <nav aria-label="Page navigation example">
            <ul className="pagination">
                { currentPage > 1 && (
                    <li className="page-item">
                        <a className="page-link" onClick={ () => setCurrentPage(currentPage - 1) }>
                            Previous
                        </a>
                    </li>
                ) }

                { pages.map((page) => (
                    <li key={ page } className={ `page-item ${page === currentPage ? 'active' : ''}` }>
                        <a className="page-link" onClick={ () => setCurrentPage(page) }>
                            { page }
                        </a>
                    </li>
                )) }

                { currentPage < totalPages && (
                    <li className="page-item">
                        <a className="page-link" onClick={ () => setCurrentPage(currentPage + 1) }>
                            Next
                        </a>
                    </li>
                ) }
            </ul>
        </nav>
    );
};

BBPagination.propTypes = {
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    setCurrentPage: PropTypes.func.isRequired,
};

export default BBPagination;
