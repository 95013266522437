import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import Swal from 'sweetalert2'

const CostCSV = (props) => {
    const [LoadingVisibility, setLoadingVisibility] = useState("none")
    const [dataVisibility, setDataVisibility] = useState("block")
    const CompanyProfile = props.CompanyProfile
    const [SuccessMessage, setSuccessMessage] = useState("")
    const [SuccessMessageVisibility, setSuccessMessageVisibility] = useState("none")
    const [Error, setError] = useState({
        errorName: "",
        errorMessage: ""
    })
    const [ErrorMessageVisibility, setErrorMessageVisibility] = useState("none")
    const SampleCSVFile = async () => {
        setLoadingVisibility('block')
        setDataVisibility('none')
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify(Store)
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/email/product/export/`, Body, config)
            // const url = `${process.env.REACT_APP_BACKEND_URL}/${response.data['file']}`
            // window.open(url, '_blank').focus()
            Swal.fire({
                position: "bottom-end",
                type: "error",
                title: "Success",
                html: `${response?.data?.message}`,
                showConfirmButton: !1,
                timer: 5000,
                confirmButtonClass: "btn btn-primary",
                buttonsStyling: !1,
            })
        } catch (error) {
            // console.log('first', error)
        }
        setLoadingVisibility('none')
        setDataVisibility('block')
    }

    const uploadCSVFile = async () => {
        setLoadingVisibility('block')
        setDataVisibility('none')
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify(csvFile)
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/cost/bulkUpdateCost/`, Body, config)
            setSuccessMessage("Successfully Updated")
            setSuccessMessageVisibility("block");
            setTimeout(() => {
                setSuccessMessageVisibility("none")
            }, 5000)
            Swal.fire({
                position: "bottom-end",
                type: "success",
                title: "Success",
                html: response?.data?.message,
                showConfirmButton: !1,
                timer: 5000,
                confirmButtonClass: "btn btn-primary",
                buttonsStyling: !1,
            })
        } catch (error) {
            console.log(error?.response)
            Swal.fire({
                position: "bottom-end",
                type: "error",
                title: "Error",
                html: `${error?.response?.data}`,
                showConfirmButton: !1,
                timer: 5000,
                confirmButtonClass: "btn btn-primary",
                buttonsStyling: !1,
            })
            if (typeof (error.response.data?.Errors) == 'object') {
                let key = Object.keys(error.response.data?.Errors)[0]
                let message = error.response.data?.Errors[key]
                setError({
                    errorName: key,
                    errorMessage: message
                })
                setErrorMessageVisibility('block')
                setTimeout(() => {
                    setErrorMessageVisibility("none")
                }, 5000)
            } else {
                let key = "Value Errors"
                let message = "Some values are not matching"
                setError({
                    errorName: key,
                    errorMessage: message
                })
                setErrorMessageVisibility('block')
                setTimeout(() => {
                    setErrorMessageVisibility("none")
                }, 5000)
            }
        }
        setLoadingVisibility('none')
        setDataVisibility('block')
    }
    const [Store, setStore] = useState({ store: 0 })
    const onDowloadSampleFile = (e) => {
        e.preventDefault()
        SampleCSVFile()
    }
    const onUpload = (e) => {
        e.preventDefault()
        uploadCSVFile()
    }
    const [csvFile, setcsvFile] = useState({
        costCsv: null
    })
    const convertToBase64 = (e) => {
        const reader = new FileReader()
        reader.readAsDataURL(e.target.files[0])
        reader.onload = () => {
            //   console.log('called: ', reader)
            setcsvFile({ ...csvFile, [e.target.name]: reader.result })
            //   setCompanyProfile({...CompanyProfile,[e.target.name]: e.target.files[0]})
        }
    }

    const [Stores, setStores] = useState([])
    const [TotalStores, setTotalStores] = useState(-1)
    const LoadStores = async () => {

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/takealot/getApiKey/`, config)
            // console.log(response.data['total_stores'])
            setTotalStores(response.data['total_stores'])
            setStores(response.data['data'])
        } catch (error) {
            // console.log('first', error)
            setTotalStores({
                legal_name: "",
                key: null
            })
        }

    }
    // console.log(TotalStores)

    useEffect(() => {
        LoadStores()

    }, [])
    return (
        <>
            <Helmet>
                <title>Import Cost from CSV - { CompanyProfile?.companyName }</title>
                <meta
                    name="description"
                    content={ `${CompanyProfile?.companyName} Import Cost from CSV` }
                />
                <link rel="stylesheet" href="../assets/css/style.css" />
                <script src="../assets/js/script.js" />
            </Helmet>
            <div style={ { display: LoadingVisibility } }>
                <div id="global-loader" >
                    <div className="whirly-loader"> </div>
                </div>
            </div>
            <div className="content" style={ { display: dataVisibility } }>
                <div className="page-header">
                    <div className="page-title">
                        <h4>Cost Calculator</h4>
                        <h6>Import from CSV</h6>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className='row'>
                            <div className="col">
                                <label htmlFor="store" className="form-label">Store</label>
                                <select id="store" onChange={ (e) => { setStore({ store: e.target.value }) } } value={ Store?.store } className="form-select">
                                    {/* Replace with your actual store data */ }
                                    <option value="0">All Stores</option>
                                    {
                                        Stores.map((store, index) => {
                                            return (
                                                <>
                                                    <option key={ index } value={ store?.id }>{ store?.legal_name }</option>

                                                </>
                                            )
                                        })
                                    }
                                    {/* Add more options as needed */ }
                                </select>
                            </div>
                            <div className="col">
                                <label htmlFor="store" className="form-label" style={ { color: 'white' } }>Btn</label>
                                <br />
                                <button className='btn btn-md btn-primary' onClick={ (e) => { onDowloadSampleFile(e) } }>Please download the sample CSV file from here</button>
                            </div>
                        </div>
                        <br />
                        <br />
                        <p>After downloading the sample csv file, please input the cost in this file and upload it back.</p>
                        <p>In case of only cost, leave Shipment and Storage columns empty.</p>
                        <p>In case of Microsoft Excel, change type of SKU to Number with 0 decimal points.</p>
                        <hr />
                        <div className="alert alert-success text-center" style={ { display: SuccessMessageVisibility } } role="alert">
                            <p><b>{ SuccessMessage } </b></p>
                        </div>
                        <div className="alert alert-danger text-center" style={ { display: ErrorMessageVisibility } } role="alert">
                            <p><b>{ Error?.errorName }: </b>{ Error?.errorMessage }</p>
                        </div>
                        <div className="mb-4">
                            <div className="tab-content p-0">
                                <div className="">
                                    <form className="" onSubmit={ (e) => { onUpload(e) } }>
                                        <div className="col mb-3">
                                            <label htmlFor="updateBulk" className="form-label">Update Bulk Cost</label>
                                            <input type="file" required id="updateBulk" accept='.csv' className="form-control" name="costCsv" multiple={ false } onChange={ (e) => (convertToBase64(e)) } placeholder="Cost .csv file" />
                                        </div>
                                        <button type='submit' className="btn btn-md btn-primary col-12">Update</button>

                                    </form>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}

export default CostCSV