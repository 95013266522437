import React, {useEffect, useState} from 'react'
import { Helmet } from "react-helmet"
import { connect } from 'react-redux'
import TransactionFilterComponent from './Filters'
import axios from 'axios'
import Moment from 'moment'
import KPIs from './KPIs'
import '../../Styles/styles.css'
const Transcations = ({CompanyProfile, user}) => {
	const [LoadingVisibility, setLoadingVisibility] = useState("none")
    const [KPIsData, setKPIsData] = useState({
        "total_records" : 0,
        "total_gross" : 0,
        "total_fee" : 0,
        "total_net" : 0,
    })
	const [dataVisibility, setDataVisibility] = useState("none")
    const [HistoryData, setHistoryData] = useState([])
    const [Type, setType] = useState('monthly')
	const [FilterType, setFilterType] = useState(2)
    const Date_Var = new Date()
    const yesterday = Moment(new Date(Date.now() - 86400000)).format('YYYY-MM-DD')
    const currentYear = Date_Var.getFullYear()
    const [Month, setMonth] = useState(("0" + (Date_Var.getMonth() + 1)).slice(-2))
    const [Year, setYear] = useState(currentYear)
    const [MonthYear, setMonthYear] = useState(currentYear)
    const [CurrentDate, setCurrentDate] = useState(Date_Var.getFullYear()+"-"+ ("0" + (Date_Var.getMonth() + 1)).slice(-2)+"-"+("0" + Date_Var.getDate()).slice(-2))
    const [FromDate, setFromDate] = useState(Date_Var.getFullYear()+"-"+ ("0" + (Date_Var.getMonth() + 1)).slice(-2)+"-"+("0" + Date_Var.getDate()).slice(-2))
    const [ToDate, setToDate] = useState(Date_Var.getFullYear()+"-"+ ("0" + (Date_Var.getMonth() + 1)).slice(-2)+"-"+("0" + Date_Var.getDate()).slice(-2))
    const year = 2021
    const years = Array.from(new Array(currentYear - year + 1),( val, index) => index + year)
	// Data
	const [PageSize, setPageSize] = useState(10)
	const [TrendingPageSize, setTrendingPageSize] = useState(6)
	// APIs
    
	const LoadHistory = async(type, fromDate, toDate, date) => {
        setLoadingVisibility('block')
		const config = {
            headers: {
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
                'Authorization' : `JWT ${localStorage.getItem('access')}`
            }
		}
		const Body = JSON.stringify({
            "type" : type,
            "fromDate" : fromDate,
            "toDate" : toDate,
            "date" : date
		})
		try {
		const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/subscription/payfast/history/`, Body,config)
			setHistoryData(response.data['data'])
            setKPIsData(response?.data?.kpis)
            // console.log(response.data['data'])
		} catch (error) {
		// console.log('first', error)

		}
        setLoadingVisibility('none')
	}
    // Type Change
    const onFilterChange = (e) => {
        e.preventDefault()
        setFilterType(e.target.value)
        if (e.target.value == 2) {
            setType('monthly')
            LoadHistory('monthly', FromDate, ToDate, CurrentDate)
        } 
        if (e.target.value == 3) {
            setType('daily')
            LoadHistory('daily', FromDate, ToDate, CurrentDate)
        } 
        if (e.target.value == 4) {
            setType('custom')
            LoadHistory('custom', FromDate, ToDate, CurrentDate)
        } 
        if (e.target.value == 5) {
            setType('daily')
            LoadHistory('daily', FromDate, ToDate, yesterday)
        } 
        
    }

    useEffect(() => {
        if (user){
            LoadHistory(Type, FromDate, ToDate, MonthYear+'-'+Month)
        }
    }, [user])
    
	

    return (
        <>			
			<Helmet>
				
				<title>{CompanyProfile['companyName']} PayFast Transaction History</title>
					<meta
						name="description"
						content={`${CompanyProfile['companyName']} PayFast Transaction History`}
					/>
				<link rel="stylesheet" href="../assets/css/style.css"/>
                <script src="../assets/js/script.js" />
            </Helmet>
            <div style={{display: LoadingVisibility}}>
                <div id="global-loader" >
                    <div className="whirly-loader"> </div>
                </div>
            </div>
            <div className="content">
				<TransactionFilterComponent 
					filterType={FilterType} 
					updateFilter={setFilterType} 
					onFilterChange={onFilterChange} 
					Month={Month} 
					updateMonth={setMonth} 
					Year={Year} 
					updateYear={setYear} 
					MonthYear={MonthYear} 
					updateMonthYear={setMonthYear} 
					CurrentDate={CurrentDate} 
					updateCurrentDate={setCurrentDate} 
					FromDate={FromDate} 
					updateFromDate={setFromDate} 
					ToDate={ToDate} 
					updateToDate={setToDate} 
					years={years}
					dayStats={LoadHistory}
					monthStats={LoadHistory}
					annualStats={LoadHistory}
					customStats={LoadHistory}
				/>
                <hr/>
                <KPIs
                    KPIsData={KPIsData}
                />
                <div className="card mb-0">
                    <div className="card-body">
                        <div className="card-header pb-0 d-flex justify-content-between align-items-center">
                            <h4 className="card-title">Transaction List</h4>
                            
                        </div>
                        <div className="table-responsive text-wrap">
                            <table className="table">
                                <thead>
                                    
                                    <tr>
                                        <th>Date</th>
                                        <th>Type</th>
                                        <th>Sign</th>
                                        <th>Name</th>
                                        <th>Currency</th>
                                        <th>Funding Type</th>
                                        <th>Gross</th>
                                        <th>Fee</th>
                                        {/* <th>Net	Balance</th> */}
                                        <th>M Payment ID</th>
                                        <th>PF Payment ID</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        HistoryData
                                            .map(
                                            (key, index)=>{
                                            return(
                                                <>
                                                    <tr key={index}>
                                                        <th>{Moment(key?.Date).format('hh:mm:ss A on DD MMMM YYYY')}</th>
                                                        <th>{key?.Type}</th>
                                                        <th>{key?.Sign}</th>
                                                        <th>{key?.Name}</th>
                                                        {/* <th>{key?.Description}</th> */}
                                                        <th>{key?.Currency}</th>
                                                        <th>{key?.['Funding Type']}</th>
                                                        <th>{key?.Gross}</th>
                                                        <th>{key?.Fee *-1}</th>
                                                        {/* <th>{key?.['Net	Balance']}</th> */}
                                                        <th>{key?.['M Payment ID']}</th>
                                                        <th>{key?.['PF Payment ID']}</th>
                                                    </tr>
                                                </>
                                            )
                                            })
                                        }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
				<div style={{display: dataVisibility}}>
                    
                </div>
			</div>
        </>
    )
}

const mapStateToProps = state => ({
	isAuthenticated: state.Auth.isAuthenticated,
	user: state.Auth.user,
})

export default connect(mapStateToProps ,{})(Transcations)