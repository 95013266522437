import React, { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Helmet } from "react-helmet"
import { LogOut } from '../Actions/Auth'
import { connect } from 'react-redux'

const Sidebar = (props) => {
	const user = props.user
	const logout = e => {
		e.preventDefault()
		props.LogOut()
	}
	const location = useLocation()
	const expandMenu = () => {
		document.body.classList.remove("expand-menu")
	}
	const expandMenuOpen = () => {
		document.body.classList.add("expand-menu")
	}

	const sidebarOverlay = () => {
		if (document.getElementById('main-wrapper')) {
			document.getElementById('main-wrapper').classList.remove("slide-nav")
		}
		if (document.querySelector(".sidebar-overlay")) {
			document.querySelector(".sidebar-overlay").classList.remove("opened")
		}
		document.querySelector("html").classList.remove("menu-opened")
		document.getElementById("html").classList.remove("menu-opened")
	}
	// useEffect(() => {
	//     document.querySelector(".main-wrapper").classList.remove("slide-nav")
	//     document.querySelector(".sidebar-overlay").classList.remove("opened")
	//     document.querySelector(".sidebar-overlay").onclick = function () {
	//     this.classList.remove("opened")
	//         document.querySelector(".main-wrapper").classList.remove("slide-nav")
	//     }
	// }, [])
	return (
		<>
			<Helmet>
				<link rel="stylesheet" href="../assets/css/style.css" />
				<script src="../assets/js/script.js" />
			</Helmet>
			<div className="sidebar" id="sidebar">
				<div className="sidebar-inner slimscroll">
					<div
						id="sidebar-menu"
						className="sidebar-menu"
						onMouseLeave={ expandMenu }
						onMouseOver={ expandMenuOpen }
					>
						<ul>
							<li className="submenu-open">
								<h6 className="submenu-hdr">Main</h6>
								<ul>
									<li className={ location.pathname === "/" ? "active" : "" }>
										<NavLink to="/" onClick={ sidebarOverlay() }><i data-feather="grid"></i><span>Dashboard</span></NavLink>
									</li>
									<li className={ location.pathname === "/finance" ? "active" : "" }>
										<NavLink to="/finance" onClick={ sidebarOverlay() }><i data-feather="dollar-sign"></i><span>Finance</span></NavLink>
									</li>
								</ul>
							</li>
							<li className="submenu-open">
								<h6 className="submenu-hdr">Products</h6>
								<ul>
									<li className={ location.pathname === "/products" ? "active" : "" }>
										<NavLink to="/products" onClick={ sidebarOverlay() }><i data-feather="list"></i><span>Products</span></NavLink>
									</li>
									<li className={ location.pathname === "/cost_calculator" || location.pathname === "/product_cost_calculator" || location.pathname === "/costCSV" ? "active" : "" }>
										<NavLink to="/cost_calculator" onClick={ sidebarOverlay() }><i data-feather="hash"></i><span>Cost Calculator</span></NavLink>
									</li>
									<li className={ location.pathname === "/buybox" ? "active" : "" }>
										<NavLink to="/buybox" onClick={ sidebarOverlay() }><i data-feather="coffee"></i><span>Buybox</span></NavLink>
									</li>
									<li className={ location.pathname === "/sales" || location.pathname === "/invoice" || location.pathname === "/combinedInvoice" ? "active" : "" }>
										<NavLink to="/sales" ><i data-feather="shopping-cart"></i><span>Sales</span></NavLink>
									</li>
									<li className={ location.pathname === "/product_inventory" || location.pathname === "/inventory" || location.pathname === "/inventoryCSV" ? "active" : "" }>
										<NavLink to="/inventory" ><i data-feather="box"></i><span>Inventory</span></NavLink>
									</li>
									{/* <li><a href="#"><i data-feather="plus-square"></i><span>Create Product</span></a></li> */ }
								</ul>
							</li>
							{
								user?.is_superuser ?
									<li className="submenu-open">
										<h6 className="submenu-hdr">Admin Settings</h6>
										<ul>
											<li className={ location.pathname === "/users-management" || location.pathname === "/users/edit/user" || location.pathname === "/users/staff" || location.pathname === "/users/staff/add" || location.pathname === "/users/staff/edit" ? "active" : "" }>
												<NavLink to="/users-management" ><i data-feather="user"></i><span>User Management</span></NavLink>
											</li>
											<li className={ location.pathname === "/plans-management" || location.pathname === "/plans/view" || location.pathname === "/plans/edit" ? "active" : "" }>
												<NavLink to="/plans-management" onClick={ sidebarOverlay() }><i data-feather="credit-card"></i><span>Subscriptions</span></NavLink>
											</li>
											<li className={ location.pathname === "/transactions" ? "active" : "" }>
												<NavLink to="/transactions" onClick={ sidebarOverlay() }><i data-feather="list"></i><span>Transaction History</span></NavLink>
											</li>
										</ul>
									</li>
									: <></>
							}
							<li className="submenu-open">
								<h6 className="submenu-hdr">Settings</h6>
								<ul>
									<li className={ location.pathname === "/settings" || location.pathname === "/api-connections" || location.pathname === "/notification-settings" ? "active" : "" }>
										<NavLink to="/settings" ><i data-feather="settings"></i><span>Settings</span></NavLink>
									</li>
									{
										user?.is_staff ?
											null
											:

											<li className={ location.pathname === "/subscription-management" ? "active" : "" }>
												<NavLink to="/subscription-management" onClick={ sidebarOverlay() }><i data-feather="credit-card"></i><span>Subscription</span></NavLink>
											</li>
									}
									{/* <li className={location.pathname === "/payment" ? "active" : ""}>
										<NavLink to="/payment" onClick={sidebarOverlay()}><i data-feather="dollar-sign"></i><span>Payment </span></NavLink>
									</li> */}
									{/* <li><a href="#"><i data-feather="file-text"></i><span>Invoices</span></a></li> */ }
									<li>
										<a onClick={ (e) => { logout(e) } }><i data-feather="log-out"></i><span>Log Out</span></a>
									</li>
								</ul>
							</li>
							{/* <li className="submenu-open">
								<h6 className="submenu-hdr">Inventory Management</h6>
								<ul>
									<li><a href="#"><i data-feather="clipboard"></i><span>Inventory</span></a></li>
								</ul>
							</li> */}

						</ul>
					</div>
				</div>
			</div>
		</>
	)
}

const mapStateToProps = (state) => ({
	isAuthenticated: state.Auth.isAuthenticated,
	user: state.Auth.user
})

export default connect(mapStateToProps, { LogOut })(Sidebar)