import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
const API_Connections = (props) => {
    const user = props.user
    const [Loader, setLoader] = useState('none')
    const [LoadingContent, setLoadingContent] = useState('block')
    const CompanyProfile = props.CompanyProfile
    const [userName, setUserName] = useState("")
    const [MessageTitle, setMessageTitle] = useState("")
    const [Message, setMessage] = useState("")
    const [MessageVisibility, setMessageVisibility] = useState("none")
    const [MessageType, setMessageType] = useState("primary")
    const [APIKeyData, setAPIKeyData] = useState([])
    const [TotalRegisteredStores, setTotalRegisteredStores] = useState(0)
    const [ShopifyAPIKeyData, setShopifyAPIKeyData] = useState([])
    const [TotalRegisteredShopifyStores, setTotalRegisteredShopifyStores] = useState(0)
    const [StoreDetails, setStoreDetails] = useState([])
    const [ShopifyStoreDetails, setShopifyStoreDetails] = useState([])
    const [LastStoreId, setLastStoreId] = useState(0)
    const [TakealotAPIKey, setTakealotAPIKey] = useState({
        store_name: "",
        key: "",
        storeVAT: 15,
        storeAddressStreet: "",
        storeAddressCity: "",
        storeAddressCountry: "",
        storeAddressPostalCode: "",
        storeVATNumber: "",
    })
    const [ShopifyAPIKey, setShopifyAPIKey] = useState({
        name: "",
        api_key: "",
        access_token: "",
        storeVAT: 15,
        storeAddressStreet: "",
        storeAddressCity: "",
        storeAddressCountry: "",
        storeAddressPostalCode: "",
        storeVATNumber: "",
    })
    const [StorePermission, setStorePermission] = useState([{
        name: "Sales App",
        application: 1,
        status: true,
    }, {
        name: "Inventory App",
        application: 2,
        status: true,
    }])
    const [MintsoftAPIKey, setMintsoftAPIKey] = useState({
        id: "1",
        company_id: "1",
        username: "",
        password: ""
    })
    const onTakealMintsoftChange = e => setMintsoftAPIKey({ ...MintsoftAPIKey, [e.target.name]: e.target.value })
    const [userData, setUserData] = useState([])
    const [WarehouseDetails, setWarehouseDetails] = useState({
        Name: "",
        Code: "",
        Details: "",
        AllowTransfersIn: "",
        AllowUnassignedLocations: "",
        AllocateBasedOnLocationTypePriority: "",
        IncludeAllocatedStockInReplenPoint: "",
        VerifyLocationsWhenPicking: "",
        Active: "",
        Type: "",
        PrependWarehouseName: "",
        AddressLine1: "",
        AddressLine2: "",
        City: "",
        County: "",
        PostCode: "",
        CompanyName: "",
        ContactName: "",
        ContactNumber: "",
        ContactEmail: "",
        ClientId: "",
        ID: "",
    })
    const LoadWarehouseDetails = async () => {

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        try {

            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/mintsoft/getWareHouseDetails/`, config)
            // console.log(response.data['company']['companyLogo'])
            setWarehouseDetails(response.data['WarehouseDetails'])
        } catch (error) {
            // console.log('first', error)
            setWarehouseDetails({
                Name: "",
                Code: "",
                Details: "",
                AllowTransfersIn: "",
                AllowUnassignedLocations: "",
                AllocateBasedOnLocationTypePriority: "",
                IncludeAllocatedStockInReplenPoint: "",
                VerifyLocationsWhenPicking: "",
                Active: "",
                Type: "",
                PrependWarehouseName: "",
                AddressLine1: "",
                AddressLine2: "",
                City: "",
                County: "",
                PostCode: "",
                CompanyName: "",
                ContactName: "",
                ContactNumber: "",
                ContactEmail: "",
                ClientId: "",
                ID: "",
            })
        }

    }

    const [NewStoreData, setNewStoreData] = useState({
        legal_name: "",
        store_name: "",
        userId: user.id,
        key: "",
        storeVAT: 15,
        storeAddressStreet: "",
        storeAddressCity: "",
        storeAddressCountry: "",
        storeAddressPostalCode: "",
        storeVATNumber: "",
    })

    const [NewShopifyStoreData, setNewShopifyStoreData] = useState({
        name: "",
        api_key: "",
        access_token: "",
        storeVAT: 15,
        storeAddressStreet: "",
        storeAddressCity: "",
        storeAddressCountry: "",
        storeAddressPostalCode: "",
        storeVATNumber: "",
    })

    const onNewStoreChange = (e) => { setNewStoreData({ ...NewStoreData, [e.target.name]: e.target.value }) }

    const onNewShopifyStoreChange = (e) => { setNewShopifyStoreData({ ...NewShopifyStoreData, [e.target.name]: e.target.value }) }
    const onStoreDetailsChange = (e) => { setStoreDetails({ ...StoreDetails, [e.target.name]: e.target.value }) }
    const onShopifyStoreDetailsChange = (e) => { setShopifyStoreDetails({ ...ShopifyStoreDetails, [e.target.name]: e.target.value }) }

    const getTakealotAPIKey = async () => {

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/takealot/getApiKey/`, config)
            setAPIKeyData(response.data['data'])
            setTotalRegisteredStores(response.data['total_stores'])
            setLastStoreId(response.data['lastId'])
        } catch (error) {
            //   setCompanyProfile({
            //     companyName : "",
            //     companyLogo : ""
            // })
        }
        setTimeout(() => {
            setMessageVisibility("none")
        }, 3000)
    }

    const getShopifyAPIKey = async () => {

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/shopify/getApiKey/`, config)
            setShopifyAPIKeyData(response.data['data'])
            setTotalRegisteredShopifyStores(response.data['total_stores'])
        } catch (error) {
            //   setCompanyProfile({
            //     companyName : "",
            //     companyLogo : ""
            // })
        }
        setTimeout(() => {
            setMessageVisibility("none")
        }, 3000)
    }

    const updateTakealotAPIKey = async () => {

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }

        const Body = JSON.stringify(TakealotAPIKey)
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/takealot/updateApiKey/`, Body, config)
            setMessageTitle("Takealot API Key Updated")
            setMessage("Your Takealot API Key has been updated")
            setMessageVisibility("block")
            setMessageType("primary")
        } catch (error) {
            // console.log('first', error.response)
            setMessageTitle(error.response.status)
            setMessage(error.response.statusText)
            setMessageVisibility("block")
            setMessageType("danger")
            //   setCompanyProfile({
            //     companyName : "",
            //     companyLogo : ""
            // })
        }
        setTimeout(() => {
            setMessageVisibility("none")
        }, 3000)
    }
    const getMintsoftAPIKey = async () => {

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/mintsoft/getCredentials/`, config)
            setMintsoftAPIKey(response.data['data'])
        } catch (error) {
            //   setCompanyProfile({
            //     companyName : "",
            //     companyLogo : ""
            // })
        }
        setTimeout(() => {
            setMessageVisibility("none")
        }, 3000)
    }
    const updateMintsoftAPIKey = async () => {

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }

        const Body = JSON.stringify(MintsoftAPIKey)
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/mintsoft/updateCredentials/`, Body, config)
            setMessageTitle("Mintsoft Credentials Updated")
            setMessage("Your Mintsoft Credentials has been updated")
            setMessageVisibility("block")
            setMessageType("primary")
            LoadWarehouseDetails()
        } catch (error) {
            // console.log('first', error.response)
            setMessageTitle(error.response.status)
            setMessage(error.response.statusText)
            setMessageVisibility("block")
            setMessageType("danger")
            //   setCompanyProfile({
            //     companyName : "",
            //     companyLogo : ""
            // })
        }
        setTimeout(() => {
            setMessageVisibility("none")
        }, 3000)
    }
    // console.log(JSON.stringify([NewStoreData]))
    const AddNewStore = async () => {
        // if (!NewStoreProfileImage['storeLogo']){
        //     Swal.fire({
        //         position: "bottom-end",
        //         type: "error",
        //         title: "Error",
        //         html: 'You have not added the logo yet.',
        //         showConfirmButton: !1,
        //         timer: 1500,
        //         confirmButtonClass: "btn btn-primary",
        //         buttonsStyling: !1,
        //     })
        // }
        setLoader('block')
        setLoadingContent('none')
        setMessageVisibility("none")
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify(NewStoreData)
        try {

            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/takealot/updateApiKey/`, Body, config)
            // const newStoreId = response.data['storeId']
            if (NewStoreProfileImage['storeLogo']) {
                UpdateStoreProfileImage(response.data['storeId'], NewStoreProfileImage['storeLogo'])
            }
            Swal.fire({
                position: "bottom-end",
                type: "success",
                title: "Success",
                html: 'New Store has been added. Data for last 15 days are now loaded while older data upto 45 days will loaded in background.',
                showConfirmButton: !1,
                timer: 5000,
                confirmButtonClass: "btn btn-primary",
                buttonsStyling: !1,
            })
            getTakealotAPIKey()
            setNewStoreData({
                legal_name: "",
                store_name: "",
                userId: user.id,
                key: "",
                storeVAT: 15
            })
        } catch (error) {
            if (error.response.status === 401) {
                Swal.fire({
                    position: "bottom-end",
                    type: "error",
                    title: 'ERROR',
                    text: `${error.response.data['message']}. You cannot use this API Key.`,
                    showConfirmButton: !1,
                    timer: 1500,
                    confirmButtonClass: "btn btn-primary",
                    buttonsStyling: !1,
                })
            }
            if (error.response.status === 404) {
                Swal.fire({
                    position: "bottom-end",
                    type: "error",
                    title: 'ERROR',
                    text: `${error.response.data['message']}.`,
                    showConfirmButton: !1,
                    timer: 1500,
                    confirmButtonClass: "btn btn-primary",
                    buttonsStyling: !1,
                })
            }
            // console.log('first', error.response.statusText)
        }
        document.getElementById('newStoreCloseBtn').click()
        setLoader('none')
        setLoadingContent('block')
        setTimeout(() => {
            setMessageVisibility("none")
        }, 3000)
        // else {
        // }
    }
    const AddNewShopifyStore = async () => {
        setLoader('block')
        setLoadingContent('none')
        setMessageVisibility("none")
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify(NewShopifyStoreData)
        try {

            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/shopify/updateApiKey/`, Body, config)
            // const newStoreId = response.data['storeId']
            // if (NewStoreProfileImage['storeLogo']){
            //     UpdateStoreProfileImage(response.data['storeId'], NewStoreProfileImage['storeLogo'])
            // }
            Swal.fire({
                position: "bottom-end",
                type: "success",
                title: "Success",
                html: 'New Store has been added.',
                showConfirmButton: !1,
                timer: 5000,
                confirmButtonClass: "btn btn-primary",
                buttonsStyling: !1,
            })
            getShopifyAPIKey()
            setNewShopifyStoreData({
                name: "",
                api_key: "",
                access_token: "",
                storeVAT: 15,
                storeAddressStreet: "",
                storeAddressCity: "",
                storeAddressCountry: "",
                storeAddressPostalCode: "",
                storeVATNumber: "",
            })
        } catch (error) {
            if (error.response.status === 401) {
                Swal.fire({
                    position: "bottom-end",
                    type: "error",
                    title: 'ERROR',
                    text: `${error.response.data['message']}. You cannot use this API Key.`,
                    showConfirmButton: !1,
                    timer: 1500,
                    confirmButtonClass: "btn btn-primary",
                    buttonsStyling: !1,
                })
            }
            if (error.response.status === 404) {
                Swal.fire({
                    position: "bottom-end",
                    type: "error",
                    title: 'ERROR',
                    text: `${error.response.data['message']}.`,
                    showConfirmButton: !1,
                    timer: 1500,
                    confirmButtonClass: "btn btn-primary",
                    buttonsStyling: !1,
                })
            }
            // console.log('first', error.response.statusText)
        }
        document.getElementById('newShopityStoreCloseBtn').click()
        setLoader('none')
        setLoadingContent('block')
        setTimeout(() => {
            setMessageVisibility("none")
        }, 3000)
        // else {
        // }
    }
    // const onTakealotAPIKeySubmit = (e) => {
    //     e.preventDefault()
    //     updateTakealotAPIKey()   
    // }
    const onMintsoftAPIKeySubmit = (e) => {
        e.preventDefault()
        updateMintsoftAPIKey()
    }
    const onNewStoreSubmit = (e) => {
        e.preventDefault()
        AddNewStore(e)
    }
    const onNewShopifyStoreSubmit = (e) => {
        e.preventDefault()
        AddNewShopifyStore(e)
    }

    const onDeleteStore = (e, key, title) => {
        e.preventDefault()
        // alert(key)
        // DeleteStore(key)
        Swal.fire({
            title: "Are you sure?",
            text: `You won't be able to revert this and all data related to ${title} will be deleted.!`,
            type: "warning",
            showCancelButton: !0,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
            confirmButtonClass: "btn btn-primary",
            cancelButtonClass: "btn btn-danger ml-1",
            buttonsStyling: !1,
        }).then(function (t) {
            t.value
                ? DeleteStore(key, title)
                : t.dismiss === Swal.DismissReason.cancel &&
                Swal.fire({
                    title: "Cancelled",
                    text: `Your store ${title} is safe :)`,
                    type: "error",
                    confirmButtonClass: "btn btn-success",
                })
        })
    }

    const DeleteStore = async (id, store_name) => {
        setMessageVisibility("none")
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify({
            "storeId": id,
            "userId": user.id
        })
        // console.log(Body)
        try {

            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/takealot/deleteTakealotStore/`, Body, config)
            Swal.fire({
                type: "success",
                title: "Deleted!",
                text: `Your store ${store_name}  has been deleted.`,
                confirmButtonClass: "btn btn-success",
            })
        } catch (error) {
            setMessageTitle("Error")
            setMessage(error.response.statusText)
            setMessageVisibility("block")
            setMessageType("danger")
            // console.log('first', error.response.statusText)
        }

        setTimeout(() => {
            setMessageVisibility("none")
        }, 3000)
        getTakealotAPIKey()
    }

    const onDeleteShopifyStore = (e, key, title) => {
        e.preventDefault()
        // alert(key)
        // DeleteStore(key)
        Swal.fire({
            title: "Are you sure?",
            text: `You won't be able to revert this and all data related to ${title} will be deleted.!`,
            type: "warning",
            showCancelButton: !0,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
            confirmButtonClass: "btn btn-primary",
            cancelButtonClass: "btn btn-danger ml-1",
            buttonsStyling: !1,
        }).then(function (t) {
            t.value
                ? DeleteShopifyStore(key, title)
                : t.dismiss === Swal.DismissReason.cancel &&
                Swal.fire({
                    title: "Cancelled",
                    text: `Your store ${title} is safe :)`,
                    type: "error",
                    confirmButtonClass: "btn btn-success",
                })
        })
    }

    const DeleteShopifyStore = async (id, store_name) => {
        setMessageVisibility("none")
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify({
            "storeId": id,
            "userId": user.id
        })
        // console.log(Body)
        try {

            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/shopify/deleteStore/`, Body, config)
            Swal.fire({
                type: "success",
                title: "Deleted!",
                text: `Your store ${store_name}  has been deleted.`,
                confirmButtonClass: "btn btn-success",
            })
        } catch (error) {
            setMessageTitle("Error")
            setMessage(error.response.statusText)
            setMessageVisibility("block")
            setMessageType("danger")
            // console.log('first', error.response.statusText)
        }

        setTimeout(() => {
            setMessageVisibility("none")
        }, 3000)
        getShopifyAPIKey()
    }
    const onStoreUpdate = (e, id) => {
        e.preventDefault()
        // alert(key)
        updateStore(StoreDetails)
        if (UpdatedStoreProfileImage.storeLogo != null) {
            UpdateStoreProfileImage(id, UpdatedStoreProfileImage['storeLogo'])
        }

    }
    const onShopifyStoreUpdate = (e, id) => {
        e.preventDefault()
        // alert(key)
        updateShopifyStore(ShopifyStoreDetails)

    }
    // console.log((NewStoreData))
    const updateStore = async (data) => {
        setLoader('block')
        setLoadingContent('none')
        setMessageVisibility("none")
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify(data)
        try {

            await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/takealot/updateStoreDetails/`, Body, config)
            Swal.fire({
                position: "bottom-end",
                type: "success",
                title: "Store details has been updated",
                showConfirmButton: !1,
                timer: 1500,
                confirmButtonClass: "btn btn-primary",
                buttonsStyling: !1,
            })
        } catch (error) {
            if (error.response.status === 401) {
                Swal.fire({
                    position: "bottom-end",
                    type: "error",
                    title: 'ERROR',
                    text: `${error.response.data['message']}. You cannot use this API Key.`,
                    showConfirmButton: !1,
                    timer: 1500,
                    confirmButtonClass: "btn btn-primary",
                    buttonsStyling: !1,
                })
            }
            // console.log('first', error.response.statusText)
        }

        setLoader('none')
        setLoadingContent('block')
        setTimeout(() => {
            setMessageVisibility("none")
        }, 3000)
        getTakealotAPIKey()
    }

    const updateShopifyStore = async (data) => {
        setLoader('block')
        setLoadingContent('none')
        setMessageVisibility("none")
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify(data)
        try {

            await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/shopify/updateStoreDetails/`, Body, config)
            Swal.fire({
                position: "bottom-end",
                type: "success",
                title: "Store details has been updated",
                showConfirmButton: !1,
                timer: 1500,
                confirmButtonClass: "btn btn-primary",
                buttonsStyling: !1,
            })
        } catch (error) {
            if (error.response.status === 401) {
                Swal.fire({
                    position: "bottom-end",
                    type: "error",
                    title: 'ERROR',
                    text: `${error.response.data['message']}. You cannot use this API Key.`,
                    showConfirmButton: !1,
                    timer: 1500,
                    confirmButtonClass: "btn btn-primary",
                    buttonsStyling: !1,
                })
            }
            // console.log('first', error.response.statusText)
        }

        setLoader('none')
        setLoadingContent('block')
        setTimeout(() => {
            setMessageVisibility("none")
        }, 3000)
        getShopifyAPIKey()
    }
    // console.log(JSON.stringify(userData))
    const UpdateStoreProfileImage = async (store_id, logo) => {

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }

        const Body = JSON.stringify({
            storeId: store_id,
            storeLogo: logo
        })
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v4/store/update/logo/`, Body, config)
            Swal.fire({
                position: "bottom-end",
                type: "success",
                title: "Your Store profile image and details have been updated",
                showConfirmButton: !1,
                timer: 1500,
                confirmButtonClass: "btn btn-primary",
                buttonsStyling: !1,
            })
            LoadStoreProfile(store_id)
        } catch (error) {
            //   console.log('first', error.response.data['Errors'])
            setMessageTitle(error.response.status)
            setMessage(error.response.statusText)
            setMessageVisibility("block")
            setMessageType("danger")
            //   setStoreProfile({
            //     StoreName : "",
            //     StoreLogo : ""
            // })
        }
        setTimeout(() => {
            setMessageVisibility("none")
        }, 3000)
        getTakealotAPIKey()
    }
    const [StoreProfileImage, setStoreProfileImage] = useState({
        storeId: "1",
        storeLogo: null
    })
    const [UpdatedStoreProfileImage, setUpdatedStoreProfileImage] = useState({
        storeId: "1",
        storeLogo: null
    })
    const [NewStoreProfileImage, setNewStoreProfileImage] = useState({
        storeId: 1,
        storeLogo: null
    })

    const convertToBase64NewStore = (e) => {
        const reader = new FileReader()
        reader.readAsDataURL(e.target.files[0])
        console.log(reader.result)
        reader.onload = () => {
            setNewStoreProfileImage({
                storeId: 1,
                storeLogo: reader.result,
            })
        }
    }
    const convertToBase64 = (e) => {
        const reader = new FileReader()
        reader.readAsDataURL(e.target.files[0])
        reader.onload = () => {
            setUpdatedStoreProfileImage({
                storeId: StoreDetails['id'],
                storeLogo: reader.result,
            })
        }
    }
    const LoadStoreProfile = async (storeId) => {
        setStoreProfileImage({})

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        try {

            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v4/store/logo/${storeId}/`, config)
            // console.log(response.data['company']['companyLogo'])
            // console.log(response.data)
            setStoreProfileImage(response.data['data'])
        } catch (error) {

        }

    }

    const removeStoreLogo = async (e, storeId) => {
        setStoreProfileImage({ ...StoreProfileImage, ["storeLogo"]: "" })
        setUpdatedStoreProfileImage({ storeId: storeId, storeLogo: "" })

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }

        try {
            await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/v4/store/logo/${storeId}/`, config)
            Swal.fire({
                position: "bottom-end",
                type: "success",
                title: "Your Store profile image has been removed",
                showConfirmButton: !1,
                timer: 1500,
                confirmButtonClass: "btn btn-primary",
                buttonsStyling: !1,
            })

        } catch (error) {
            Swal.fire({
                position: "bottom-end",
                type: "error",
                title: "Error",
                text: "No Logo Found.",
                showConfirmButton: !1,
                timer: 1500,
                confirmButtonClass: "btn btn-primary",
                buttonsStyling: !1,
            })

        }

    }

    useEffect(() => {
        getTakealotAPIKey()
        getShopifyAPIKey()
        // getMintsoftAPIKey()
        if (user) {
            setUserName(user["name"])
            setUserData(user)
        }
    }, [userName, userData, user])

    return (
        <>
            <Helmet>
            </Helmet>

            <div>
                <div className=''>
                    <div className="">

                        <div className="card-body">


                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Connections / </span>Takealot Connection</h4>
                            {
                                user?.is_staff ?
                                    null
                                    :
                                    <p>You can add upto { userData['total_stores'] } Takealot Stores </p>
                            }
                            {
                                (userData['total_stores'] > TotalRegisteredStores) ?
                                    <>
                                        {
                                            !user?.is_staff ?
                                                <button type="button" className="btn btn-primary col-4" data-bs-toggle="modal" data-bs-target="#newStoreModal">
                                                    Add New Store
                                                </button>
                                                :
                                                null
                                        }
                                        <br />
                                        <br />
                                        <div className="modal fade" id="newStoreModal" tabindex="-1" aria-modal="true" role="dialog">
                                            <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                                                {/* <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="newStoreModalTitle">Add new Takealot Store</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <form onSubmit={(e)=>{onNewStoreSubmit(e)}}>
                                                    <div className="modal-body">
                                                        <div className="row">
                                                            <div className="col mb-3">
                                                                <label htmlfor="store_name" className="form-label">Store Name</label>
                                                                <input required type="text" name="store_name" value={NewStoreData['store_name']} onChange={(e)=>{onNewStoreChange(e)}} id="store_name" className="form-control" placeholder="Enter Store Name" />
                                                            </div>
                                                        </div>                                            
                                                        <div className="row">
                                                            <div className="col mb-3">
                                                                <label htmlfor="store_name" className="form-label">API Key</label>
                                                                <input required type="text" name="key" value={NewStoreData['key']} onChange={(e)=>{onNewStoreChange(e)}} id="key" className="form-control" placeholder="Enter Store API Key" />
                                                            </div>
                                                        </div>                                            
                                                        <div className="row">
                                                            <div className="col mb-3">
                                                                <label htmlfor="storeVAT" className="form-label">VAT (Percentage)</label>
                                                                <input required type="number" name="storeVAT" value={NewStoreData['storeVAT']} onChange={(e)=>{onNewStoreChange(e)}} id="key" className="form-control" placeholder="Enter Store API Key" />
                                                            </div>
                                                        </div>                                            
                                                    </div>  
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">
                                                            Close
                                                        </button>
                                                        <button type="submit" data-bs-dismiss="modal" className="btn btn-primary">Add</button>
                                                    </div>
                                                </form>
                                            </div> */}
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="">New Store</h5>
                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>

                                                    <div style={ { display: Loader } }>
                                                        <div style={ { textAlign: 'center', padding: '10%' } }>
                                                            <div className="spinner-border spinner-border-xl text-warning" role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={ { display: LoadingContent } }>
                                                        <form onSubmit={ (e) => { onNewStoreSubmit(e, StoreDetails.id) } }>
                                                            <div className="">
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        <div className="profile-set">
                                                                            <div className="profile-head">

                                                                            </div>
                                                                            <div className="profile-top">
                                                                                <div className="profile-content">
                                                                                    <div className="profile-contentimg">
                                                                                        <img src={ './assets/img/customer/customer5.jpg' } />
                                                                                        <div className="profileupload">
                                                                                            <input type="file" id="imgInp" multiple={ false } onChange={ (e) => (convertToBase64NewStore(e)) } />
                                                                                            <a><img src="assets/img/icons/edit-set.svg" alt="img" /></a>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="profile-contentname">
                                                                                        <h2>{ NewStoreData.store_name }</h2>
                                                                                        <h4>Update { NewStoreData.store_name }'s Details.</h4>
                                                                                    </div>
                                                                                </div>
                                                                                {/* <div className="ms-auto">
                                                                                {
                                                                                    (userData['total_stores'] > TotalRegisteredStores) ?
                                                                                        <button type="button" data-bs-toggle="modal" data-bs-target="#newStoreModal" className="btn btn-primary me-2">Add New Store</button>
                                                                                    :<></>
                                                                                }
                                                                                <a className="btn btn-success me-2">Update</a>
                                                                                <a className="btn btn-danger  me-2">Delete</a>
                                                                            </div> */}
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-lg-3 col-sm-12">
                                                                                <div className="form-group">
                                                                                    <label>Legal Name</label>
                                                                                    <input type="text" placeholder="Legal Name" name="legal_name" value={ NewStoreData.legal_name } required onChange={ (e) => { onNewStoreChange(e) } } />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-3 col-sm-12">
                                                                                <div className="form-group">
                                                                                    <label>Store Name</label>
                                                                                    <input type="text" placeholder="Store Name" name="store_name" value={ NewStoreData.store_name } required onChange={ (e) => { onNewStoreChange(e) } } />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-sm-12">
                                                                                <div className="form-group">
                                                                                    <label>Takealot API Key</label>
                                                                                    <input type="text" placeholder="Takealot API Key" name="key" value={ NewStoreData.key } required onChange={ (e) => { onNewStoreChange(e) } } />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-sm-12">
                                                                                <div className="form-group">
                                                                                    <label>Store Street Address</label>
                                                                                    <input type="text" placeholder="Store Street Address" name="storeAddressStreet" value={ NewStoreData.storeAddressStreet } onChange={ (e) => { onNewStoreChange(e) } } />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-sm-12">
                                                                                <div className="form-group">
                                                                                    <label>Store Address City</label>
                                                                                    <input type="text" placeholder="Store Address City" name="storeAddressCity" value={ NewStoreData.storeAddressCity } onChange={ (e) => { onNewStoreChange(e) } } />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-sm-12">
                                                                                <div className="form-group">
                                                                                    <label>Store Address Postal Code</label>
                                                                                    <input type="text" placeholder="Store Address Postal Code" name="storeAddressPostalCode" value={ NewStoreData.storeAddressPostalCode } onChange={ (e) => { onNewStoreChange(e) } } />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-sm-12">
                                                                                <div className="form-group">
                                                                                    <label>Store Address Country</label>
                                                                                    <input type="text" placeholder="Store Address Country" name="storeAddressCountry" value={ NewStoreData.storeAddressCountry } onChange={ (e) => { onNewStoreChange(e) } } />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-sm-12">
                                                                                <div className="form-group">
                                                                                    <label>Store Address VAT</label>
                                                                                    <input type="text" placeholder="Store Address VAT" name="storeVATNumber" value={ NewStoreData.storeVATNumber } onChange={ (e) => { onNewStoreChange(e) } } />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-sm-12">
                                                                                <div className="form-group">
                                                                                    <label>Store VAT Percentage</label>
                                                                                    <input type="text" placeholder="Store VAT" name="storeVAT" value={ NewStoreData.storeVAT } onChange={ (e) => { onNewStoreChange(e) } } />
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr />
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" className="btn btn-outline-secondary" id="newStoreCloseBtn" data-bs-dismiss="modal">
                                                                    Close
                                                                </button>
                                                                <button type="submit" className="btn btn-primary">Add</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    : <></>
                            }
                            <div className="card">
                                <div className="table-responsive text-nowrap">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Legal Name</th>
                                                <th>Store Name</th>
                                                <th>API Key</th>
                                                <th>VAT</th>
                                                {
                                                    !user?.is_staff ?
                                                        <th>Actions</th>
                                                        :
                                                        null
                                                }
                                            </tr>
                                        </thead>
                                        <tbody className="table-border-bottom-0">
                                            {
                                                APIKeyData.map(
                                                    (key) => {
                                                        return (
                                                            <tr>
                                                                <td><strong>{ key.legal_name }</strong></td>
                                                                <td><strong>{ key.store_name }</strong></td>
                                                                <td>
                                                                    { key.key.slice(0, 50) + "...." }
                                                                </td>
                                                                <td>
                                                                    { key.storeVAT }
                                                                </td>
                                                                {
                                                                    !user?.is_staff ?
                                                                        <td>
                                                                            <button style={ { marginRight: '1%' } } className='btn btn-primary' onClick={ (e) => { setStoreDetails(key); LoadStoreProfile(key.id) } } data-bs-toggle="modal" data-bs-target="#storeDetailsModal">
                                                                                Edit
                                                                            </button>
                                                                            <button className='btn btn-danger' onClick={ (e) => { onDeleteStore(e, key.id, key.store_name) } }>
                                                                                Delete
                                                                            </button>
                                                                            {/* {
                                                                                APIKeyData.length > 1 ?
                                                                                : <></>
                                                                            } */}

                                                                        </td>
                                                                        :
                                                                        null

                                                                }
                                                            </tr>
                                                        )
                                                    }
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="modal fade" id="storeDetailsModal" tabindex="-1" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="">Update Store</h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div style={ { display: Loader } }>
                                                <div style={ { textAlign: 'center', padding: '10%' } }>
                                                    <div className="spinner-border spinner-border-xl text-warning" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={ { display: LoadingContent } }>
                                                <form onSubmit={ (e) => { onStoreUpdate(e, StoreDetails.id) } }>
                                                    <div className="">
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <div className="profile-set">
                                                                    <div className="profile-head">

                                                                    </div>
                                                                    <div className="profile-top">
                                                                        <div className="profile-content">
                                                                            <div className="profile-contentimg">
                                                                                {
                                                                                    StoreProfileImage && StoreProfileImage?.storeLogo ?
                                                                                        <img src={ `${process.env.REACT_APP_BACKEND_URL}/media/` + StoreProfileImage.storeLogo } />
                                                                                        :
                                                                                        <img src={ 'assets/img/profiles/avator1.jpg' } />
                                                                                }
                                                                                <div className="profileupload">
                                                                                    <input type="file" id="imgInp" multiple={ false } onChange={ (e) => (convertToBase64(e)) } />
                                                                                    <a><img src="assets/img/icons/edit-set.svg" alt="img" /></a>
                                                                                </div>
                                                                            </div>
                                                                            <div className="profile-contentname">
                                                                                <h2>{ StoreDetails.store_name }</h2>
                                                                                <h4>Update { StoreDetails.store_name }'s Details.</h4>
                                                                                <a href='#' onClick={ (e) => { removeStoreLogo(e, StoreDetails?.id) } }>Remove { StoreDetails.store_name }'s Logo.</a>

                                                                            </div>
                                                                        </div>
                                                                        {/* <div className="ms-auto">
                                                                            {
                                                                                (userData['total_stores'] > TotalRegisteredStores) ?
                                                                                    <button type="button" data-bs-toggle="modal" data-bs-target="#newStoreModal" className="btn btn-primary me-2">Add New Store</button>
                                                                                :<></>
                                                                            }
                                                                            <a className="btn btn-success me-2">Update</a>
                                                                            <a className="btn btn-danger  me-2">Delete</a>
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className='container'>
                                                                        <div className="alert alert-success text-center" style={ { display: MessageVisibility } } role="alert">
                                                                            <p><b>{ Message } </b></p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Legal Name</label>
                                                                            <input type="text" placeholder="Legal Name" name="legal_name" required value={ StoreDetails.legal_name } onChange={ (e) => { onStoreDetailsChange(e) } } />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Store Name</label>
                                                                            <input type="text" placeholder="Store Name" name="store_name" required value={ StoreDetails.store_name } onChange={ (e) => { onStoreDetailsChange(e) } } />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Takealot API Key</label>
                                                                            <input type="text" placeholder="Takealot API Key" name="key" required value={ StoreDetails.key } onChange={ (e) => { onStoreDetailsChange(e) } } />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Store Street Address</label>
                                                                            <input type="text" placeholder="Store Street Address" name="storeAddressStreet" value={ StoreDetails.storeAddressStreet } onChange={ (e) => { onStoreDetailsChange(e) } } />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Store Address City</label>
                                                                            <input type="text" placeholder="Store Address City" name="storeAddressCity" value={ StoreDetails.storeAddressCity } onChange={ (e) => { onStoreDetailsChange(e) } } />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Store Address Postal Code</label>
                                                                            <input type="text" placeholder="Store Address Postal Code" name="storeAddressPostalCode" value={ StoreDetails.storeAddressPostalCode } onChange={ (e) => { onStoreDetailsChange(e) } } />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Store Address Country</label>
                                                                            <input type="text" placeholder="Store Address Country" name="storeAddressCountry" value={ StoreDetails.storeAddressCountry } onChange={ (e) => { onStoreDetailsChange(e) } } />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Store Address VAT</label>
                                                                            <input type="text" placeholder="Store Address VAT" name="storeVATNumber" value={ StoreDetails.storeVATNumber } onChange={ (e) => { onStoreDetailsChange(e) } } />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Store VAT Percentage</label>
                                                                            <input type="text" placeholder="Store VAT" name="storeVAT" value={ StoreDetails.storeVAT } onChange={ (e) => { onStoreDetailsChange(e) } } />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">
                                                            Close
                                                        </button>
                                                        <button type="submit" className="btn btn-primary">Update</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                userData['shopify_stores'] > 0 ?
                                    <>
                                        <hr />
                                        <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Connections / </span>Shopify Connections</h4>
                                        <p>You can add { userData['shopify_stores'] } Shopify Store </p>
                                        {
                                            (userData['shopify_stores'] > TotalRegisteredShopifyStores) ?
                                                <>
                                                    <button type="button" className="btn btn-primary col-4" data-bs-toggle="modal" data-bs-target="#newShopifyStore">
                                                        Add New Shopify Store
                                                    </button>
                                                    <br />
                                                    <br />
                                                    <div className="modal fade" id="newShopifyStore" tabindex="-1" aria-modal="true" role="dialog">
                                                        <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title" id="">New Shopify Store</h5>
                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                </div>

                                                                <div style={ { display: Loader } }>
                                                                    <div style={ { textAlign: 'center', padding: '10%' } }>
                                                                        <div className="spinner-border spinner-border-xl text-warning" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div style={ { display: LoadingContent } }>
                                                                    <form onSubmit={ (e) => { onNewShopifyStoreSubmit(e) } }>
                                                                        <div className="">
                                                                            <div className="card">
                                                                                <div className="card-body">
                                                                                    <div className="row">
                                                                                        <div className="col-lg-6 col-sm-12">
                                                                                            <div className="form-group">
                                                                                                <label>Shop Name</label>
                                                                                                <input type="text" placeholder="Store Name" name="name" value={ NewShopifyStoreData.name } required onChange={ (e) => { onNewShopifyStoreChange(e) } } />
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="col-lg-6 col-sm-12">
                                                                                            <div className="form-group">
                                                                                                <label>Shopify App API Key</label>
                                                                                                <input type="password" placeholder="App API Key" name="api_key" required value={ NewShopifyStoreData.api_key } onChange={ (e) => { onNewShopifyStoreChange(e) } } />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-6 col-sm-12">
                                                                                            <div className="form-group">
                                                                                                <label>Shopify App Access Token</label>
                                                                                                <input type="password" placeholder="App Access Token" name="access_token" required value={ NewShopifyStoreData.access_token } onChange={ (e) => { onNewShopifyStoreChange(e) } } />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-6 col-sm-12">
                                                                                            <div className="form-group">
                                                                                                <label>Store Street Address</label>
                                                                                                <input type="text" placeholder="Store Street Address" name="storeAddressStreet" value={ NewShopifyStoreData.storeAddressStreet } onChange={ (e) => { onNewShopifyStoreChange(e) } } />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-6 col-sm-12">
                                                                                            <div className="form-group">
                                                                                                <label>Store Street Address</label>
                                                                                                <input type="text" placeholder="Store Street Address" name="storeAddressStreet" value={ NewShopifyStoreData.storeAddressStreet } onChange={ (e) => { onNewShopifyStoreChange(e) } } />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-6 col-sm-12">
                                                                                            <div className="form-group">
                                                                                                <label>Store Address City</label>
                                                                                                <input type="text" placeholder="Store Address City" name="storeAddressCity" value={ NewShopifyStoreData.storeAddressCity } onChange={ (e) => { onNewShopifyStoreChange(e) } } />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-6 col-sm-12">
                                                                                            <div className="form-group">
                                                                                                <label>Store Address Postal Code</label>
                                                                                                <input type="text" placeholder="Store Address Postal Code" name="storeAddressPostalCode" value={ NewShopifyStoreData.storeAddressPostalCode } onChange={ (e) => { onNewShopifyStoreChange(e) } } />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-6 col-sm-12">
                                                                                            <div className="form-group">
                                                                                                <label>Store Address Country</label>
                                                                                                <input type="text" placeholder="Store Address Country" name="storeAddressCountry" value={ NewShopifyStoreData.storeAddressCountry } onChange={ (e) => { onNewShopifyStoreChange(e) } } />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-6 col-sm-12">
                                                                                            <div className="form-group">
                                                                                                <label>Store Address VAT</label>
                                                                                                <input type="text" placeholder="Store Address VAT" name="storeVATNumber" value={ NewShopifyStoreData.storeVATNumber } onChange={ (e) => { onNewShopifyStoreChange(e) } } />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-6 col-sm-12">
                                                                                            <div className="form-group">
                                                                                                <label>Store VAT Percentage</label>
                                                                                                <input type="text" placeholder="Store VAT" name="storeVAT" value={ NewShopifyStoreData.storeVAT } onChange={ (e) => { onNewShopifyStoreChange(e) } } />
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <hr />
                                                                        </div>
                                                                        <div className="modal-footer">
                                                                            <button type="button" className="btn btn-outline-secondary" id="newShopityStoreCloseBtn" data-bs-dismiss="modal">
                                                                                Close
                                                                            </button>
                                                                            <button type="submit" className="btn btn-primary">Add</button>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                : <></>
                                        }
                                        {
                                            ShopifyAPIKeyData.length > 0 ?
                                                <div className="card">
                                                    <div className="table-responsive text-nowrap">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Store Name</th>
                                                                    <th>VAT</th>
                                                                    <th>Actions</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="table-border-bottom-0">
                                                                {
                                                                    ShopifyAPIKeyData.map(
                                                                        (key) => {
                                                                            return (
                                                                                <tr>
                                                                                    <td><strong>{ key.name }</strong></td>
                                                                                    <td>
                                                                                        { key.storeVAT }
                                                                                    </td>
                                                                                    <td>
                                                                                        <button style={ { marginRight: '1%' } } className='btn btn-primary' onClick={ (e) => { setShopifyStoreDetails(key) } } data-bs-toggle="modal" data-bs-target="#shopifyStoreDetailsModal">
                                                                                            Edit
                                                                                        </button>
                                                                                        <button className='btn btn-danger' onClick={ (e) => { onDeleteShopifyStore(e, key.id, key.name) } }>
                                                                                            Delete
                                                                                        </button>
                                                                                        {/* {
                                                                                        APIKeyData.length > 1 ?
                                                                                        : <></>
                                                                                    } */}

                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="modal fade" id="shopifyStoreDetailsModal" tabindex="-1" aria-hidden="true">
                                                        <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title" id="">Update Shopify Store</h5>
                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                </div>
                                                                <div style={ { display: Loader } }>
                                                                    <div style={ { textAlign: 'center', padding: '10%' } }>
                                                                        <div className="spinner-border spinner-border-xl text-warning" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div style={ { display: LoadingContent } }>
                                                                    <form onSubmit={ (e) => { onShopifyStoreUpdate(e, StoreDetails.id) } }>
                                                                        <div className="">
                                                                            <div className="card">
                                                                                <div className="card-body">

                                                                                    <div className="row">
                                                                                        <div className="col-lg-6 col-sm-12">
                                                                                            <div className="form-group">
                                                                                                <label>Shop Name</label>
                                                                                                <input type="text" placeholder="Store Name" name="name" value={ ShopifyStoreDetails.name } required onChange={ (e) => { onShopifyStoreDetailsChange(e) } } />
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="col-lg-6 col-sm-12">
                                                                                            <div className="form-group">
                                                                                                <label>Shopify App API Key</label>
                                                                                                <input type="password" placeholder="App API Key" name="api_key" required value={ ShopifyStoreDetails.api_key } onChange={ (e) => { onShopifyStoreDetailsChange(e) } } />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-6 col-sm-12">
                                                                                            <div className="form-group">
                                                                                                <label>Shopify App Access Token</label>
                                                                                                <input type="password" placeholder="App Access Token" name="access_token" required value={ ShopifyStoreDetails.access_token } onChange={ (e) => { onShopifyStoreDetailsChange(e) } } />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-6 col-sm-12">
                                                                                            <div className="form-group">
                                                                                                <label>Store Street Address</label>
                                                                                                <input type="text" placeholder="Store Street Address" name="storeAddressStreet" value={ ShopifyStoreDetails.storeAddressStreet } onChange={ (e) => { onShopifyStoreDetailsChange(e) } } />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-6 col-sm-12">
                                                                                            <div className="form-group">
                                                                                                <label>Store Street Address</label>
                                                                                                <input type="text" placeholder="Store Street Address" name="storeAddressStreet" value={ ShopifyStoreDetails.storeAddressStreet } onChange={ (e) => { onShopifyStoreDetailsChange(e) } } />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-6 col-sm-12">
                                                                                            <div className="form-group">
                                                                                                <label>Store Address City</label>
                                                                                                <input type="text" placeholder="Store Address City" name="storeAddressCity" value={ ShopifyStoreDetails.storeAddressCity } onChange={ (e) => { onShopifyStoreDetailsChange(e) } } />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-6 col-sm-12">
                                                                                            <div className="form-group">
                                                                                                <label>Store Address Postal Code</label>
                                                                                                <input type="text" placeholder="Store Address Postal Code" name="storeAddressPostalCode" value={ ShopifyStoreDetails.storeAddressPostalCode } onChange={ (e) => { onShopifyStoreDetailsChange(e) } } />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-6 col-sm-12">
                                                                                            <div className="form-group">
                                                                                                <label>Store Address Country</label>
                                                                                                <input type="text" placeholder="Store Address Country" name="storeAddressCountry" value={ ShopifyStoreDetails.storeAddressCountry } onChange={ (e) => { onShopifyStoreDetailsChange(e) } } />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-6 col-sm-12">
                                                                                            <div className="form-group">
                                                                                                <label>Store Address VAT</label>
                                                                                                <input type="text" placeholder="Store Address VAT" name="storeVATNumber" value={ ShopifyStoreDetails.storeVATNumber } onChange={ (e) => { onShopifyStoreDetailsChange(e) } } />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-6 col-sm-12">
                                                                                            <div className="form-group">
                                                                                                <label>Store VAT Percentage</label>
                                                                                                <input type="text" placeholder="Store VAT" name="storeVAT" value={ ShopifyStoreDetails.storeVAT } onChange={ (e) => { onShopifyStoreDetailsChange(e) } } />
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <hr />
                                                                        </div>
                                                                        <div className="modal-footer">
                                                                            <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">
                                                                                Close
                                                                            </button>
                                                                            <button type="submit" className="btn btn-primary">Update</button>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : <></>
                                        }
                                    </>
                                    : <></>
                            }
                            {/* <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Connections / </span>User Warehouse Connection</h4>
                            <div className="col-xl-12" id="navs-tabs-line-card-profile">
                                <div className="card mb-4">
                                    <div className="tab-content p-0">
                                        <div className="card-body">
                                            {
                                                WarehouseDetails['Name'] ?
                                                <>
                                                    <div className="tab-pane fade show active" id="navs-tabs-line-card-profile" role="tabpanel">
                                                        <small className="text-muted">Warehouse Details</small>
                                                        <ul className="list-unstyled mb-4 mt-3">
                                                            <li className="d-flex align-items-center mb-3"><i className="bx bx-user"></i><span className="fw-semibold mx-2">Warehouse Name:</span> <span>{WarehouseDetails['Name']}</span></li>
                                                            <li className="d-flex align-items-center mb-3"><i className="bx bx-check"></i><span className="fw-semibold mx-2">Address:</span> 
                                                                <span>
                                                                {
                                                                    WarehouseDetails['AddressLine1'] ? 
                                                                    WarehouseDetails['AddressLine1'] : <></>
                                                                } 
                                                                {
                                                                    WarehouseDetails['AddressLine2'] ? 
                                                                    ", " + WarehouseDetails['AddressLine2'] : <></>
                                                                } 
                                                                {
                                                                    WarehouseDetails['PostCode'] ? 
                                                                    ", " + WarehouseDetails['PostCode'] : <></>
                                                                } 
                                                                {
                                                                    WarehouseDetails['City'] ? 
                                                                    ", " + WarehouseDetails['City'] : <></>
                                                                } 
                                                                {
                                                                    WarehouseDetails['Country'] ? 
                                                                    ", " + WarehouseDetails['Country'] : <></>
                                                                } 
                                                                </span>
                                                            </li>
                                                            <li className="d-flex align-items-center mb-3"><i className="bx bx-clipboard"></i><span className="fw-semibold mx-2">Warehouse Company:</span> 
                                                                <span>
                                                                {
                                                                    WarehouseDetails['CompanyName'] ? 
                                                                    WarehouseDetails['CompanyName'] : <></>
                                                                } 
                                                                </span>
                                                            </li>
                                                        </ul>                                        
                                                    </div>
                                                </> : <></>
                                            }
                                            <form onSubmit={(e)=>{onMintsoftAPIKeySubmit(e)}}>
                                                <div className="col mb-3">
                                                    <label htmlFor="Username" className="form-label">Username</label>
                                                    <input type="text" id="Username" className="form-control" name="username" value={MintsoftAPIKey['username']} onChange={(e)=>{onTakealMintsoftChange(e)}} placeholder="Mintsoft Username" />
                                                    <label htmlFor="Password" className="form-label">Password</label>
                                                    <input type="password" id="Password" className="form-control" name="password" onChange={(e)=>{onTakealMintsoftChange(e)}} placeholder="Update Mintsoft Password" />
                                                </div>
                                                <button type='submit' className="btn btn-md btn-primary col-12">Update</button>
                                            </form>
                                        </div>

                                    </div>

                                </div>
                                
                            </div> */}

                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.Auth.isAuthenticated,
    user: state.Auth.user,
})
export default connect(mapStateToProps)(API_Connections)