import axios from 'axios'
import React, {useState, useEffect} from 'react'
import StoreDashboard from './storeDashboard'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { NavLink, Outlet, useLocation } from 'react-router-dom'

const Dashboard = (props, {user}) => {
    const CompanyProfile = props.CompanyProfile
	const location = useLocation()
    const [Stores, setStores] = useState([])
    const [Loaded, setLoaded] = useState(false)
    const [CurrentStore, setCurrentStore] = useState(JSON.parse(localStorage.getItem("dashboard")))
    const [TotalStores, setTotalStores] = useState(-1)
    const LoadStores = async() => {
        
        const config = {
            headers: {
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
                'Authorization' : `JWT ${localStorage.getItem('access')}`
            }
        }
        try { 
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/takealot/getApiKey/`, config)
            // console.log(response.data['total_stores'])
            setTotalStores(response.data['total_stores'])
            setStores(response.data['data'])
        } catch (error) {
        // console.log('first', error)
            setTotalStores({
                store_name : "",
                key : null
            })
        }
        
    }
    // console.log(localStorage.getItem("dashboard"))
    useEffect(() => {
        LoadStores()
        if (localStorage.getItem("dashboard") === null){
            localStorage.setItem('dashboard', JSON.stringify("dashboard-top"))
        }
        const interval = setInterval(() => {
            setLoaded(true)
        }, 2000)
		return () => {
			clearInterval(interval);
		}
    }, [])
    return (
        <>
            {
                Loaded ?
                    <Helmet>
                        <link rel="stylesheet" href="../../../assets/css/customstyles.css"/>
                    </Helmet>
                : <></>
            }
            <div className="">
                {
                    TotalStores === 0 ?
                    <div className="content" style={{textAlign: "center"}}>
                        <div className='card'>
                            <div className="container-xxl container-p-y">
                                <div className="misc-wrapper">
                                    <h2 className="mb-2 mx-2">No Dashboard Found? 😢</h2>
                                    <p className="mb-4 mx-2">Please add your Takealot API to get the data.</p>
                                    <NavLink to="/api-connections" className="btn btn-primary">API Connections</NavLink>
                                    <div className="mt-3">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> :
                    TotalStores === 1 ?
                    <StoreDashboard CompanyProfile={CompanyProfile} storeId={Stores[0]['id']} userData={user} />
                    : 
                        <>
                            <div className=""><br/>
                            {
                                TotalStores > 1 ?
                                <>
                                    <div className='container'>
                                        <ul className="nav nav-tabs justify-content-center">
                                            <li className="nav-item">
                                                <NavLink className={
                                                    location.pathname === "/dashboard/overall-0" ?
                                                        "nav-link active"
                                                    :
                                                        "nav-link"
                                                    }  aria-current="page" to="/dashboard/overall-0">Overall</NavLink>
                                            </li>
                                            {
                                                Stores.map(
                                                    (key)=>{
                                                        return(
                                                        <li className="nav-item">
                                                            <NavLink 
                                                                type="button" 
                                                                className={
                                                                    location.pathname === `/dashboard/${key.store_name}-${key.id}` ?
                                                                    "nav-link active"
                                                                :
                                                                    "nav-link"
                                                                } 
                                                                to={`/dashboard/${key.store_name}-${key.id}`}
                                                            >
                                                                {key.store_name}
                                                            </NavLink>
                                                        </li>
                                                        )
                                                    }
                                                )
                                            }
                                        </ul>
                                    </div>
                                </>
                                : 
                                <>
                                </>
                            }
                        </div>
                    </>
                }
                <hr/>
                <Outlet />
            </div>
        </>
    )
}
const mapStateToProps = state => ({
	isAuthenticated: state.Auth.isAuthenticated,
	user: state.Auth.user,
})

export default connect(mapStateToProps ,{})(Dashboard)