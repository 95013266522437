import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'

const EditPlan = () => {
    const location = useLocation()
    const { state } = location
    const planId = state['planId']
    const [PlanDetails, setPlanDetails] = useState({
        'name' : "",
        'pricing' : "",
        'trailDays' : "",
        'takealotStores' : "",
    })
    const {
        name,
        pricing,
        trailDays,
        takealotStores,
    } = PlanDetails

    const onPlanChange = (e) => {setPlanDetails({...PlanDetails, [e.target.name]: e.target.value})}
    // API
    
    const loadPlanDetails = async(planId) => {
        const config = {
            headers: {
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
                'Authorization' : `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify({
            planId : planId
        })
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/subscription/plan/get/details/`, Body, config)
            setPlanDetails(response?.data?.data)
        } catch (error) {
            Swal.fire({
                position: "bottom-end",
                type: "error",
                title: "Success",
                html: `${error?.response?.data?.message}`,
                showConfirmButton: !1,
                timer: 5000,
                confirmButtonClass: "btn btn-primary",
                buttonsStyling: !1,
            })
        }
    }
    // 
    const updatePlanDetails = async(planId, data) => {
        const config = {
            headers: {
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
                'Authorization' : `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify({
            planId: planId,
            data: data
        })
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/subscription/plan/update/details/`, Body, config)
            Swal.fire({
                position: "bottom-end",
                type: "error",
                title: "Success",
                html: `${response?.data?.message}`,
                showConfirmButton: !1,
                timer: 5000,
                confirmButtonClass: "btn btn-primary",
                buttonsStyling: !1,
            })
        } catch (error) {
            Swal.fire({
                position: "bottom-end",
                type: "error",
                title: "Success",
                html: `${error?.response?.data?.message}`,
                showConfirmButton: !1,
                timer: 5000,
                confirmButtonClass: "btn btn-primary",
                buttonsStyling: !1,
            })
        }
    }
    // On Form Submit
    const onFormSubmit = (e) => {
        e.preventDefault()
        updatePlanDetails(planId,PlanDetails)
    }
    useEffect(() => {
        loadPlanDetails(planId)
    }, [])
    
    return (
        <div className=''>
            <div className='content'>
                <div className="page-header">
                    <div className="page-title">
                        <h4>Edit Plan</h4>
                        <h6>Edit existing plan</h6>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <form onSubmit={(e)=>{onFormSubmit(e)}}>
                            <div className="row">
                                <div className="col-lg-6 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label>Plan Name</label>
                                        <input name="name" value={name} onChange={(e)=>{onPlanChange(e)}} type="text"/>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label>Plan Price</label>
                                        <input className='form-control' name="pricing" value={pricing} onChange={(e)=>{onPlanChange(e)}} type="number"/>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label>Trail Days</label>
                                        <input className='form-control' name="trailDays" value={trailDays} onChange={(e)=>{onPlanChange(e)}} type="number"/>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label>Takealot Stores</label>
                                        <input name="takealotStores" value={takealotStores} onChange={(e)=>{onPlanChange(e)}} type="text"/>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <button type='submit' className="btn btn-submit me-2">Update</button>
                                    <NavLink to="/admin/plans-management" className="btn btn-cancel">Cancel</NavLink>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditPlan