import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import ProductListComponent from './ProductList'
import { connect } from 'react-redux'
import axios from 'axios'
import ProductKPIs from './ProductKPIs'
import SearchComponent from '../../Search'
import Swal from 'sweetalert2'

const StoreCostCalculator = (props) => {
    const storeId = props.storeId
    const user = props.user
    const CompanyProfile = props.CompanyProfile
    const [SearchQuery, setSearchQuery] = useState("")
    const [CurrentPage, setCurrentPage] = useState(1)
    const [MonthlyCurrentPage, setMonthlyCurrentPage] = useState(1)
    const [PageSize, setPageSize] = useState(localStorage.getItem('page-size') ? JSON.parse(localStorage.getItem('page-size')) || 10 : 10)
    const [Sortby, setSortby] = useState(localStorage.getItem('product-cost-sort-by') ? localStorage.getItem('product-cost-sort-by') || 'sales' : 'sales')
    const [SortDirection, setSortDirection] = useState(localStorage.getItem('sort-direction') ? localStorage.getItem('sort-direction') || 'down' : 'down')
    const [TotalPages, setTotalPages] = useState(1)
    const [KPIsData, setKPIsData] = useState([])
    const [LoadingVisibility, setLoadingVisibility] = useState("none")
    const [dataVisibility, setDataVisibility] = useState("block")
    const [NextLink, setNextLink] = useState("")
    const [ProductList, setProductList] = useState([])
    const [TotalProducts, setTotalProducts] = useState(0)
    const [PageLimit, setPageLimit] = useState(1)
    const [StatusFilters, setStatusFilters] = useState({ status: 'Buyable' })
    const [OrderBy, setOrderBy] = useState("title")

    const numberFormatter = new Intl.NumberFormat('en-ZA', {
        // These options are needed to round to whole numbers if that's what you want.
        minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    })
    const LoadProductsData = async () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify({
            "account": user.id,
            "dashboard": storeId
        })
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/products/kpis/`, Body, config)
            setKPIsData(response.data)
            // setNextLink(response.data['next'])
        } catch (error) {
            // console.log('first', error)
        }
    }


    const loadProductList = async (page_number, store, searchQuery, pageSize, sortBy, sortDirection) => {
        setCurrentPage(page_number)
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }

        const Body = JSON.stringify({
            "page_number": page_number,
            "dashboard": store,
            "search_query": searchQuery,
            "page_size": pageSize,
            "sortBy": sortBy,
            "sortDirection": sortDirection,
        })

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/products/list/`, Body, config)
            setProductList(response.data['results'])
            setTotalPages(response.data['total_pages'])
            setTotalProducts(response.data['total_records'])
            setPageLimit(response.data['pagelimit'])
            // setNextLink(response.data['next'])
        } catch (error) {
            // console.log('first', error)
        }
    }

    const onOrderByChange = (e, orderBy) => {
        e.preventDefault()
        setTotalProducts(0)
        setOrderBy(orderBy)
        loadProductList(1, storeId, StatusFilters, orderBy, storeId, user.id)
    }
    const formatter = new Intl.NumberFormat('en-ZA', {
        style: 'currency',
        currency: 'ZAR',

        // These options are needed to round to whole numbers if that's what you want.
        minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    })


    const onPageSizeChange = (e, value) => {
        setTotalProducts(0)
        localStorage.setItem('page-size', value)
        setPageSize(value)
        loadProductList(1, storeId, SearchQuery, value, Sortby, SortDirection)
    }

    const onSortChange = (e, value) => {
        setTotalProducts(0)
        localStorage.setItem('product-cost-sort', value)
        setSortby(value)
        loadProductList(1, storeId, SearchQuery, PageSize, value, SortDirection)
    }

    const onSortDirectionChange = (e, value) => {
        setTotalProducts(0)
        localStorage.setItem('sort-direction', value)
        setSortDirection(value)
        loadProductList(1, storeId, SearchQuery, PageSize, Sortby, value)
    }


    const onSearchInputChange = (e) => {
        e.preventDefault()
        setSearchQuery(e.target.value)
    }


    const [ProductInfo, setProductInfo] = useState({})
    const [CostInfo, setCostInfo] = useState({
        "dashboard": storeId
    })

    const onCostInfoUpdate = (e) => {
        e.preventDefault()
        setCostInfo({ ...CostInfo, [e.target.name]: e.target.value })
    }

    const LoadProductDetails = async (productId) => {
        setProductInfo({
            loading: true
        })
        setCostInfo({
            loading: true
        })
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/products/details/${productId}/`, config)
            setProductInfo(response.data)
            setCostInfo(response.data)
        } catch (error) {
            // console.log('first', error)
        }
    }



    const updateProductCost = async () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify(
            CostInfo
        )
        try {
            await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/products/cost/${ProductInfo?.offer_id}/`, Body, config)
            loadProductList(CurrentPage, storeId, SearchQuery, PageSize, Sortby, SortDirection)
            Swal.fire({
                position: "bottom-end",
                type: "success",
                title: "Success",
                html: 'Cost has been updated.',
                showConfirmButton: !1,
                timer: 1500,
                confirmButtonClass: "btn btn-primary",
                buttonsStyling: !1,
            })
        } catch (error) {
            Swal.fire({
                position: "bottom-end",
                type: "success",
                title: "Success",
                html: error.response.data.detail,
                showConfirmButton: !1,
                timer: 1500,
                confirmButtonClass: "btn btn-primary",
                buttonsStyling: !1,
            })
        }
    }

    const onCostInfoSubmit = (e) => {
        e.preventDefault()
        updateProductCost()
    }


    const onStatusFilterChange = (e) => {
        setTotalProducts(0)
        setStatusFilters({ ...StatusFilters, [e.target.name]: e.target.value })
        loadProductList(1, { [e.target.name]: e.target.value }, OrderBy, storeId, user.id, SearchQuery, PageSize, Sortby, SortDirection)
    }

    const searchForm = (e) => {
        e.preventDefault()
        setTotalProducts(0)
        loadProductList(1, storeId, SearchQuery, PageSize, Sortby, SortDirection)
    }

    useEffect(() => {
        LoadProductsData()
        loadProductList(1, storeId, SearchQuery, PageSize, Sortby, SortDirection)

    }, [])
    return (
        <>
            <Helmet>
                <title>{ CompanyProfile['companyName'] } Products</title>
                <meta
                    name="description"
                    content={ `${CompanyProfile['companyName']} Cost Calculator` }
                />
                <link rel="stylesheet" href="../assets/css/style.css" />
                <script src="../assets/js/script.js" />
            </Helmet>
            <div style={ { display: LoadingVisibility } }>
                <div id="global-loader" >
                    <div className="whirly-loader"> </div>
                </div>
            </div>
            <div className="content">
                <div style={ { display: dataVisibility } }>

                    <ProductKPIs
                        numberFormatter={ numberFormatter }
                        currencyFormatter={ formatter }
                        KPIsData={ KPIsData }
                    />
                    <SearchComponent
                        placeholder={ "Product Name / SKU / Offer ID / TSIN ID" }
                        SearchQuery={ SearchQuery }
                        onSearchInputChange={ onSearchInputChange }
                        searchForm={ searchForm }
                    />
                    <hr />
                    <ProductListComponent
                        storeId={ storeId }
                        dashboardId={ storeId }
                        PageSize={ PageSize }
                        onPageSizeChange={ onPageSizeChange }
                        onStatusFilterChange={ onStatusFilterChange }
                        ProductList={ ProductList }
                        TotalProducts={ TotalProducts }
                        formatter={ formatter }
                        numberFormatter={ numberFormatter }
                        TotalPages={ TotalPages }
                        loadProductList={ loadProductList }
                        OrderBy={ OrderBy }
                        StatusFilters={ StatusFilters }
                        SearchQuery={ SearchQuery }
                        CompanyProfile={ CompanyProfile }
                        Sortby={ Sortby }
                        SortDirection={ SortDirection }
                        onSortChange={ onSortChange }
                        onSortDirectionChange={ onSortDirectionChange }
                        currentPage={ CurrentPage }
                        setCurrentPage={ setCurrentPage }
                        LoadProductDetails={ LoadProductDetails }
                        ProductInfo={ ProductInfo }
                        CostInfo={ CostInfo }
                        onCostInfoUpdate={ onCostInfoUpdate }
                        onCostInfoSubmit={ onCostInfoSubmit }
                    />
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.Auth.isAuthenticated,
    user: state.Auth.user,
})

export default connect(mapStateToProps, {})(StoreCostCalculator)