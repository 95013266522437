import React from 'react'
import { Helmet } from "react-helmet"
import { connect } from 'react-redux'
import Chart from "react-apexcharts"
import MainDashboardPagination from '../../Pagination/MainDashboardPagination'
import SalesKPis from '../KPIs'
import ProductsTable from '../ProductsTable'
const YesterdayDashboard = (props) => {
    const FormData = props.kpiData
    const currencyFormatter = props.currencyFormatter
    const numberFormatter = props.numberFormatter
    const CostProfitData = props.costProfitKPIData
    const SalesTrendingData = props.trendingData
    const DcData = props.dcData
    const ProductData = props.productData
    const PageSize = props.pageSize
    const TotalProducts = props.TotalProducts
    const CurrentDate = props.currentDate
    const LoadDayProductSalesList = props.LoadDayProductSalesList
    const MonthProfile = props.MonthProfile
    const YearProfile = props.YearProfile
    const apexChartSeries = (seriesName, seriesData, seriesName1, seriesData1) =>
    (
        [
            {
                name: seriesName,
                data: seriesData,
                type: 'line'
            },
            {
                name: seriesName1,
                data: seriesData1,
                type: 'column'
            }
        ]
    )
    const apexChartDualOptions = (chartId, xaxis, seriesA, seriesB) =>
    (
        {
            chart: {
                id: chartId,
                width: "100%"
            },
            dataLabels: {
                enabled: true,
            },
            stroke: {
                width: [0, 4]
            },
            markers: {
                size: 1
            },
            xaxis: {
                categories: xaxis
            },
            yaxis: [
                {
                    title: {
                        text: seriesA
                    },
                },
                {
                    opposite: true,
                    title: {
                        text: seriesB
                    }
                }
            ],
            colors: ['#1B2850', '#FF9F43',],
            stroke: {
                curve: 'smooth'
            },
            dataLabels: {
                enabled: false,
                style: {
                    fontSize: '12px',
                    fontWeight: 'bold',
                    color: 'black'
                },
                background: {
                    enabled: true,
                    foreColor: 'black',
                    borderRadius: 2,
                    padding: 4,
                    opacity: 0.9,
                    borderWidth: 1,
                    borderColor: '#fff',
                },
            },
            plotOptions: {
                pie: {
                    legend: {
                        position: 'bottom'
                    }
                }
            },
            grid: {
                show: false
            }

        }
    )
    return (
        <>
            <Helmet>
                <link rel="stylesheet" href="../assets/css/style.css" />
                <script src="../assets/js/script.js" />
                <script src="../assets/plugins/apexchart/chart-data.js" />
            </Helmet>
            <div>
                <SalesKPis FormData={ FormData } currencyFormatter={ currencyFormatter } numberFormatter={ numberFormatter } />
                {/* <!-- Button trigger modal --> */ }

                <div className="row">
                    <div className="col-lg-7 col-sm-12 col-12 d-flex">
                        <div className="card flex-fill">
                            <div className="card-header pb-0 d-flex justify-content-between align-items-center">
                                <h5 className="card-title mb-0">Sales</h5>

                            </div>
                            <div className="card-body">
                                {/* <div id="sales_charts"></div> */ }
                                <Chart
                                    options={ apexChartDualOptions('sales-trending-data', [SalesTrendingData].map(x => x.map(a => (a[0]))).flat(2), 'Sales', 'Quantity') }
                                    // options={apexChartOptions('sales-trending-data',xaxis)}
                                    series={ apexChartSeries('Revenue', [SalesTrendingData].map(x => x.map(a => (a[1]))).flat(2), "Quantity", [SalesTrendingData].map(x => x.map(a => (a[2]))).flat(2)) }
                                    type="line"
                                    width="100%"
                                    height="100%"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-sm-12 col-12 d-flex">
                        <div className="card flex-fill">
                            {
                                DcData?.map(
                                    (dc, index) => {
                                        return (

                                            <div key={ index }>
                                                <div className="card-header pb-0 mb-0 d-flex justify-content-between align-items-center">
                                                    <h4 className="card-title mb-0">DC / { dc?.dc }</h4>
                                                </div>
                                                <div className="card-body pb-0 mb-0">
                                                    <div className='row'>
                                                        <div className="col-lg-6 col-sm-6 col-6 d-flex">
                                                            <div className="dash-count das2">
                                                                <div className="dash-counts">
                                                                    { dc?.kpis?.revenue || dc?.kpis?.revenue >= 0 ? (
                                                                        <h4>{ currencyFormatter.format(dc?.kpis?.revenue) }</h4>
                                                                    ) :
                                                                        <h5>
                                                                            <span className="skeleton-loader"></span>
                                                                        </h5>
                                                                    }
                                                                    <h5>Total Revenue</h5>
                                                                </div>
                                                                <div className="dash-imgs">
                                                                    <i data-feather="user"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-sm-6 col-6 d-flex">
                                                            <div className="dash-count">
                                                                <div className="dash-counts">
                                                                    { dc?.kpis?.revenue || dc?.kpis?.revenue >= 0 ? (
                                                                        <h4>{ numberFormatter.format(dc?.kpis?.units) }</h4>
                                                                    ) :
                                                                        <h5>
                                                                            <span className="skeleton-loader"></span>
                                                                        </h5>
                                                                    }
                                                                    <h5>Total Units</h5>
                                                                </div>
                                                                <div className="dash-imgs">
                                                                    <i data-feather="user"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className="card mb-0">
                    <div className="card-body">
                        <div className="card-header pb-0 d-flex justify-content-between align-items-center">
                            <h4 className="card-title">Products</h4>
                            <div className="graph-sets">
                                <div className="dropdown">
                                    <select className="form-select col-4" id="pageSize" value={ PageSize } onChange={ (e) => { props.onPageSizeChange(e) } } aria-label="Range">
                                        <option value="5">5</option>
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <ProductsTable ProductData={ ProductData } PageSize={ PageSize } currencyFormatter={ currencyFormatter } numberFormatter={ numberFormatter } />

                            <br />
                            <div className='pagination justify-content-center'>
                                {
                                    TotalProducts > 0 ?
                                        <MainDashboardPagination totalRecords={ TotalProducts } pageLimit={ Number(PageSize) } paginationDate={ CurrentDate } onPageChanged={ LoadDayProductSalesList } />
                                        : <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.Auth.isAuthenticated,
    user: state.Auth.user,
})

export default connect(mapStateToProps)(YesterdayDashboard)