import React, { useEffect, useState } from 'react'
import { currencyFormatter, numberFormatter } from '../../../../modules/formatter'

const ProductInventoryModal = (props) => {
    const storeId = props.storeId
    const productId = props.productId
    const ProductInfo = props.ProductInfo
    const InventoryInfo = props.InventoryInfo
    return (
        <>
            <div className="modal fade" id={ "productInventoryModal-" + storeId } tabProductInventoryModal="-1" aria-hidden="true">
                <div className="modal-dialog  modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div style={ { textAlign: "center" } }>
                                <h4 className="modal-title" id="productInventoryModalTitle" style={ { textAlign: "center" } }><span className="text-muted fw-light">Product /</span> Details and Inventory Details</h4>
                            </div>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body" style={ { wordBreak: "break-all", minWidth: '100px' } }>
                            <div className="row">
                                <div className='container'>
                                    <div className="row">
                                        <div className="col-md-3 py-3 px-3">
                                            <img className="card-img card-img-left" width="100%" src={ ProductInfo?.image_url } alt="Card image" />
                                        </div>
                                        <div className="col-md-9 py-3 px-3">
                                            <div className="card-body">
                                                <h5 className="card-title">{ ProductInfo?.title }</h5>
                                                <div className='row'>
                                                    <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12'>
                                                        <div className="row mb-3">
                                                            <p className="col-sm-6" ><b>SKU</b></p>
                                                            <p className="col-sm-6" >{ ProductInfo?.sku }</p>
                                                        </div>

                                                    </div>
                                                    <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12'>

                                                        <div className="row mb-3">
                                                            <p className="col-sm-6" ><b>Product Label</b></p>
                                                            <p className="col-sm-6" >{ ProductInfo?.product_label_number }</p>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <p className="col-sm-6" ><b>Takealot URL</b></p>
                                                        <a href={ ProductInfo?.offer_url } target="_blank" className="col-sm-6" >{ ProductInfo?.offer_url }</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <form onSubmit={ (e) => { props.onInventoryInfoSubmit(e) } } className='row'>
                                            <div className="row mb-3">
                                                <label className="col-sm-6 col-form-label" htmlFor="basic-default-name">Stock</label>
                                                <div className="col-sm-6">
                                                    <div className="input-group">
                                                        <input type="text" min={ 1 } className="form-control" value={ InventoryInfo?.stock } name="stock" onChange={ (e) => { props.onInventoryInfoUpdate(e) } } id="basic-default-name" placeholder='Supplier Name' />
                                                    </div>
                                                </div>
                                            </div>
                                            <button className="col-12 btn btn-sm btn-primary ">Update Inventory</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductInventoryModal