import React from 'react'
import { Helmet } from "react-helmet"
import { connect } from 'react-redux'
import {currencyFormatter, numberFormatter} from '../../../../modules/formatter'
const KPIs = (props) => {
	const KPIsData = props.KPIsData
    return (
        <>			
			<Helmet>
				<link rel="stylesheet" href="../assets/css/style.css"/>
                <script src="../assets/js/script.js" />
                <script src="../assets/plugins/apexchart/chart-data.js" />
            </Helmet>
            <div>
				<div className="row">
					<div className="col-lg-3 col-sm-6 col-12 d-flex">
						<div className="dash-count">
							<div className="dash-counts">
								<h4>{numberFormatter('en-ZA', 0).format(KPIsData['total_records'])}</h4>
								<h5>Total Transactions</h5>
							</div>
							<div className="dash-imgs">
								<i data-feather="user"></i> 
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-sm-6 col-12 d-flex">
						<div className="dash-count das1">
							<div className="dash-counts">
								<h4>{currencyFormatter('en-ZA', 'ZAR').format(KPIsData['total_gross'])}</h4>
								<h5>Total Gross Amount</h5>
							</div>
							<div className="dash-imgs">
								<i data-feather="user-check"></i> 
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-sm-6 col-12 d-flex">
						<div className="dash-count das2">
							<div className="dash-counts">
								<h4>{currencyFormatter('en-ZA', 'ZAR').format(KPIsData['total_fee']*-1)}</h4>
								<h5>Total Fee Deducted</h5>
							</div>
							<div className="dash-imgs">
								<i data-feather="file-text"></i>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-sm-6 col-12 d-flex">
						<div className="dash-count das3">
							<div className="dash-counts">
								<h4>{currencyFormatter('en-ZA', 'ZAR').format(KPIsData['total_net'])}</h4>
								<h5>Total Net Amount</h5>
							</div>
							<div className="dash-imgs">
								<i data-feather="file"></i>  
							</div>
						</div>
					</div>
				</div>
				{/* <!-- Button trigger modal --> */}

			</div>
        </>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.Auth.isAuthenticated,
    user: state.Auth.user,
})

export default connect(mapStateToProps)(KPIs)