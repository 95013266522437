import React, { useState } from 'react'
import LoginImage from '../../assets/img/login.jpg'
import Logo from '../../assets/img/companyLogo.png'
import LogoWhite from '../../assets/img/logo-white.png'
import MailIcon from '../../assets/img/icons/mail.svg'
import { connect } from 'react-redux'
import {Navigate, NavLink} from 'react-router-dom'
import {LoginUser} from '../../Actions/Auth'
import axios from 'axios'
import MicrosoftLogo from '../../assets/img/microsoft-icon.png'
import GoogleLogo from '../../assets/img/google.png'
import Swal from 'sweetalert2'

const SignIn = ({LoginUser, isAuthenticated}) => {
    const [eye, seteye] = useState(true)
    const [FormData, setFormData] = useState({
        email: "",
        password: ""
    })
    const [errorData, seterrorData] = useState({
        status: "",
        message: ""
    })
    const [SubmissionErrorVisibilty, setSubmissionErrorVisibilty] = useState("none")
    const {
        email,
        password
    } = FormData
    const onChange = e => setFormData({...FormData, [e.target.name]: e.target.value})
    const [errors, seterrors] = useState({
        email: "",
        password: ""
    })
    const onEyeClick = () =>{
        seteye(!eye)
    }
    const [LoadingVisibility, setLoadingVisibility] = useState("none")
    const validUser = async (status) => {
        setLoadingVisibility("block")
        
        const config = {
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        }
    
        const Body = JSON.stringify(FormData)
        try {
        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/auth/jwt/create/`,
            Body,
            config
        )
        LoginUser(FormData)
        } catch (error) {
            if (error.code == 'ERR_NETWORK') {
                Swal.fire({
                    position: "bottom-end",
                    type: "Error",
                    title: "Server Down",
                    html: error?.message,
                    showConfirmButton: !1,
                    timer: 1500,
                    confirmButtonClass: "btn btn-primary",
                    buttonsStyling: !1,
                })
            }
            Swal.fire({
                position: "bottom-end",
                type: "Error",
                title: `${error.response.status}`,
                html: error.response.data.detail,
                showConfirmButton: !1,
                timer: 1500,
                confirmButtonClass: "btn btn-primary",
                buttonsStyling: !1,
            })
            seterrorData({
                status: error.response.status,
                message: error.response.data.detail,
                errors: error.response.errors,
            })
            
            setSubmissionErrorVisibilty("block");
            setTimeout(() => {
                setSubmissionErrorVisibilty("none")
            }, 5000)
        
        }
        setLoadingVisibility("none")
    }
    const onSubmit = (e) => {
        e.preventDefault()
        validUser()
    }
    const continueWithGoogleOAuth = async(e) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/auth/o/google-oauth2/?redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`)
            window.location.replace(response.data.authorization_url)
        } catch (error) {
            console.log(error.response)
            
        }
  
    }
    const continueWithGoogle = (e) => {
        localStorage.setItem('authType', 'google')
        continueWithGoogleOAuth(e)
    }
    const continueWithFacebookOAuth = async(e) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/auth/o/facebook/?redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`)
            window.location.replace(response.data.authorization_url)
        } catch (error) {
            console.log(error.response)
            
        }
  
    }
    const continueWithFacebook = (e) => {
        localStorage.setItem('authType', 'facebook')
        continueWithFacebookOAuth(e)
    }
    if (isAuthenticated == true) {
        return <Navigate to="/" />;
    }
    return (
        <>
            <div style={{display: LoadingVisibility}}>
                <div id="global-loader" >
                    <div className="whirly-loader"> </div>
                </div>
            </div>
            <div className="main-wrapper">
                <div className="account-content">
                    <div className="login-wrapper">
                        <div className="login-content">
                            <div className="login-userset">
                                <form onSubmit={(e)=>{onSubmit(e)}}>
                                    {/* <div className="login-logo">
                                        <img src={Logo} alt="img" />
                                    </div> */}
                                    <div className="login-userheading">
                                        <h3>Sign In</h3>
                                        <h4>Please login to your account</h4>
                                    </div>
                                    <div className="alert alert-danger text-center" style={{display: SubmissionErrorVisibilty}} role="alert">
                                        <p><b>Error {errorData.status}: {errorData.message} </b></p>
                                    </div>
                                    <div className="form-login">
                                        <label>Email</label>
                                        <div className="form-addons">
                                            <input
                                                type="email"
                                                value={email}
                                                name='email'
                                                onChange={(e)=>{onChange(e)}}
                                                className={` ${errors.email ? 'is-invalid' : ''}`}
                                                placeholder="Enter your email address"
                                            />
                                            <img src={MailIcon} alt="img" />
                                            <div className="invalid-feedback">{errors.email?.message}</div>

                                        </div>
                                    </div>
                                    <div className="form-login">
                                        <label>Password</label>
                                        <div className="pass-group">
                                            <input
                                                type={eye ? "password" : "text"}
                                                value={password}
                                                name='password'
                                                onChange={(e)=>{onChange(e)}}
                                                className={` ${errors.password ? 'is-invalid' : ''}`}
                                                placeholder="Enter your password"
                                                // {...register('password')}
                                            />
                                            <span  onClick={onEyeClick} className={`fas toggle-password ${eye ? "fa-eye-slash":"fa-eye"} `} />
                                            <div className="invalid-feedback">{errors.password?.message}</div>

                                        </div>

                                    </div>
                                    <div className="form-login">
                                        <div className="alreadyuser">
                                            <h4>
                                                <NavLink to="/forgetPassword" className="hover-a">
                                                    Forgot Password?
                                                </NavLink>
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="form-login">
                                        <button type='submit' className="btn btn-login" >
                                            Sign In
                                        </button>
                                    </div>
                                </form>
                                
                                <div className="signinform text-center">
                                    <h4>
                                        Don’t have an account?{" "}
                                        <NavLink to="/signUp" className="hover-a">
                                            Sign Up
                                        </NavLink>
                                    </h4>
                                </div>
                                <div className="form-setlogin">
                                    <h4>Or continue up with</h4>
                                </div>
                                <div class="form-sociallink">
                                    <ul>
                                        <li>
                                            <a onClick={(e)=>{continueWithGoogle(e)}}>
                                                <img src="assets/img/icons/google.png" className="me-2" alt="google"/>
                                                Sign In using Google
                                            </a>
                                        </li>
                                        <li>
                                            <a onClick={(e)=>{continueWithFacebook(e)}}>
                                                <img src="assets/img/icons/facebook.png" className="me-2" alt="facebook"/>
                                                Sign In using Facebook
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="login-img">
                            <img src={LoginImage} alt="img" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.Auth.isAuthenticated,
})
  
  
export default connect(mapStateToProps, { LoginUser })(SignIn)