import React from 'react'
import { Helmet } from 'react-helmet'

const BuyBoxKPIs = (props) => {
	const KPIsData = props.KPIsData
	const numberFormatter = props.numberFormatter
	return (
		<>
			<Helmet>
				<link rel="stylesheet" href="../assets/css/style.css" />
				<script src="../assets/js/script.js" />
			</Helmet>
			<div>
				<div className="row">
					<div className="col-lg-3 col-sm-6 col-12 d-flex">
						<div className="dash-count">
							<div className="dash-counts">
								<h4>{ numberFormatter.format(KPIsData['total_products']) }</h4>
								<h5>Total Products</h5>
							</div>
							<div className="dash-imgs">
								<i data-feather="user"></i>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-sm-6 col-12 d-flex">
						<div className="dash-count das1">
							<div className="dash-counts">
								<h4>{ numberFormatter.format(KPIsData['winning_products']) }</h4>
								<h5>Winning Products</h5>
							</div>
							<div className="dash-imgs">
								<i data-feather="user-check"></i>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-sm-6 col-12 d-flex">
						<div className="dash-count das2">
							<div className="dash-counts">
								<h4>{ numberFormatter.format(KPIsData['active_products']) }</h4>
								<h5>Active Products</h5>
							</div>
							<div className="dash-imgs">
								<i data-feather="file-text"></i>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-sm-6 col-12 d-flex">
						<div className="dash-count bg-danger">
							<div className="dash-counts">
								<h4>{ numberFormatter.format(KPIsData['losing_products']) }</h4>
								<h5>Losing Products</h5>
							</div>
							<div className="dash-imgs">
								<i data-feather="file"></i>
							</div>
						</div>
					</div>
				</div>
				{/* <!-- Button trigger modal --> */ }

			</div>
		</>
	)
}

export default BuyBoxKPIs