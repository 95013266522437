import React from 'react'
import { Helmet } from "react-helmet"
import { connect } from 'react-redux'
import Chart from "react-apexcharts"
import { numberFormatter} from '../../../../modules/formatter'

const KPIS = (props) => {
    const KPIsData = props.KPIsData
    const numberFormatte = numberFormatter
    return (
        <>			
			<Helmet>
				<link rel="stylesheet" href="../assets/css/style.css"/>
                <script src="../assets/js/script.js" />
                <script src="../assets/plugins/apexchart/chart-data.js" />
            </Helmet>
            <div>
				<div className="row">
					<div className="col-lg-4 col-sm-6 col-12 d-flex">
						<div className="dash-count">
							<div className="dash-counts">
								<h4>{numberFormatter('en-ZA', 0).format(KPIsData['total'])}</h4>
								<h5>Total Plans</h5>
							</div>
							<div className="dash-imgs">
								<i data-feather="user"></i> 
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-sm-6 col-12 d-flex">
						<div className="dash-count das1">
							<div className="dash-counts">
								<h4>{numberFormatter('en-ZA', 0).format(KPIsData['active_plans'])}</h4>
								<h5>Active Plans</h5>
							</div>
							<div className="dash-imgs">
								<i data-feather="user-check"></i> 
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-sm-6 col-12 d-flex">
						<div className="dash-count das2">
							<div className="dash-counts">
								<h4>{numberFormatter('en-ZA', 0).format(KPIsData['subscribed'])}</h4>
								<h5>Subscripted to Plans</h5>
							</div>
							<div className="dash-imgs">
								<i data-feather="file-text"></i>
							</div>
						</div>
					</div>
				</div>
				

			</div>
        </>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.Auth.isAuthenticated,
    user: state.Auth.user,
})

export default connect(mapStateToProps)(KPIS)