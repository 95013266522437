import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { NavLink, useLocation } from 'react-router-dom'
import Moment from 'moment'
import { numberFormatter } from '../../../modules/formatter'
const LogDetails = () => {
    const location = useLocation()
    const { state } = location
    const log = { log_id: state.log_id }
    const [LogDetails, setLogDetails] = useState({})
    const [LogData, setLogData] = useState([])
    const [KPIs, setKPIs] = useState({})
    const getLogDetails = async () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify(log)
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/logs/details/`, Body, config)
            setLogDetails(response?.data?.logInfo)
            setLogData(response?.data?.logData)
            setKPIs(response?.data?.kpisData)
        } catch (error) {
            // console.log('first', error)
            //   setResponseError(error.response.statusText)
        }
    }

    useEffect(() => {
        getLogDetails();
        const interval = setInterval(() => {
            getLogDetails();
        }, 3000); // 3 seconds in milliseconds

        return () => {
            clearInterval(interval);
        };
    }, [state.log_id]);

    const rowsPerPage = 15

    const [currentPage, setCurrentPage] = useState(1);

    const handleClick = (newPage) => {
        setCurrentPage(newPage);
    }

    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + 2, Math.ceil(LogData?.length / rowsPerPage));

    if (endPage - startPage < 2) {
        startPage = Math.max(endPage - 2, 1);
    }

    return (
        <div className='content'>
            <Helmet>
                <title>Log Info - { LogDetails?.log_name ? LogDetails?.log_name : "" }</title>
                <meta
                    name="description"
                    content={ `${LogDetails?.log_name ? LogDetails?.log_name : ""}  Info` }
                />
                <link rel="stylesheet" href="../assets/css/style.css" />
                <script src="../assets/js/script.js" />
            </Helmet>
            <div>
                <div className="page-header">
                    <div className="page-title">
                        <h4>Logs</h4>
                        <h6>{ LogDetails?.log_name }</h6>
                    </div>
                </div>

                <div>
                    {
                        LogDetails?.log_type == 1 || LogDetails?.log_type == 2 ?
                            <div className="row">
                                <div className="col">
                                    <div className="dash-count">
                                        <div className="dash-counts">
                                            <h4>{ KPIs?.total_products ? numberFormatter('en-ZA', 0).format(KPIs?.total_products) : numberFormatter('en-ZA', 0).format(0) }</h4>
                                            <h5>Products</h5>
                                        </div>
                                        <div className="dash-imgs">
                                            <i data-feather="user"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="dash-count das1">
                                        <div className="dash-counts">
                                            <h4>{ KPIs?.total_valid_products ? numberFormatter('en-ZA', 0).format(KPIs?.total_valid_products) : numberFormatter('en-ZA', 0).format(0) }</h4>
                                            <h5>Valid</h5>
                                        </div>
                                        <div className="dash-imgs">
                                            <i data-feather="user-check"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="dash-count das2">
                                        <div className="dash-counts">
                                            <h4>{ KPIs?.total_created ? numberFormatter('en-ZA', 0).format(KPIs?.total_created) : numberFormatter('en-ZA', 0).format(0) }</h4>
                                            <h5>Created</h5>
                                        </div>
                                        <div className="dash-imgs">
                                            <i data-feather="file-text"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="dash-count das3">
                                        <div className="dash-counts">
                                            <h4>{ KPIs?.total_updated ? numberFormatter('en-ZA', 0).format(KPIs?.total_updated) : numberFormatter('en-ZA', 0).format(0) }</h4>
                                            <h5>Updated</h5>
                                        </div>
                                        <div className="dash-imgs">
                                            <i data-feather="file"></i>
                                        </div>
                                    </div>
                                </div>
                                {
                                    LogDetails?.status == 1 ?
                                        <div className="col">
                                            <div className="dash-count das3">
                                                <div className="dash-counts">
                                                    <h4>
                                                        {
                                                            KPIs?.time_taken ?
                                                                numberFormatter('en-ZA', 0).format(KPIs?.time_taken) :
                                                                numberFormatter('en-ZA', 0).format(0)
                                                        }
                                                        Mins</h4>
                                                    <h5>Time Taken</h5>
                                                </div>
                                                <div className="dash-imgs">
                                                    <i data-feather="file"></i>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="col">
                                            <div className="dash-count" style={ { backgroundColor: "red" } }>
                                                <div className="dash-counts">
                                                    <h4>
                                                        Ongoing
                                                    </h4>
                                                    <h5>Status</h5>
                                                </div>
                                                <div className="dash-imgs">
                                                    <i data-feather="file"></i>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                            :
                            LogDetails?.log_type == 3 ?

                                <div className="row">
                                    <div className="col">
                                        <div className="dash-count">
                                            <div className="dash-counts">
                                                <h4>{ KPIs?.total_stores ? numberFormatter('en-ZA', 0).format(KPIs?.total_stores) : numberFormatter('en-ZA', 0).format(0) }</h4>
                                                <h5>Stores</h5>
                                            </div>
                                            <div className="dash-imgs">
                                                <i data-feather="user"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="dash-count das1">
                                            <div className="dash-counts">
                                                <h4>{ KPIs?.total_products ? numberFormatter('en-ZA', 0).format(KPIs?.total_products) : numberFormatter('en-ZA', 0).format(0) }</h4>
                                                <h5>Products</h5>
                                            </div>
                                            <div className="dash-imgs">
                                                <i data-feather="user-check"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="dash-count das5">
                                            <div className="dash-counts">
                                                <h4>{ KPIs?.total_updated ? numberFormatter('en-ZA', 0).format(KPIs?.total_updated) : numberFormatter('en-ZA', 0).format(0) }</h4>
                                                <h5>Gone through</h5>
                                            </div>
                                            <div className="dash-imgs">
                                                <i data-feather="file-text"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="dash-count das2">
                                            <div className="dash-counts">
                                                <h4>{ KPIs?.eligible_products ? numberFormatter('en-ZA', 0).format(KPIs?.eligible_products) : numberFormatter('en-ZA', 0).format(0) }</h4>
                                                <h5>Eligible</h5>
                                            </div>
                                            <div className="dash-imgs">
                                                <i data-feather="file-text"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="dash-count das3">
                                            <div className="dash-counts">
                                                <h4>{ KPIs?.ineligible_products ? numberFormatter('en-ZA', 0).format(KPIs?.ineligible_products) : numberFormatter('en-ZA', 0).format(0) }</h4>
                                                <h5>Ineligble</h5>
                                            </div>
                                            <div className="dash-imgs">
                                                <i data-feather="file"></i>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        LogDetails?.status == 1 ?
                                            <div className="col">
                                                <div className="dash-count das3">
                                                    <div className="dash-counts">
                                                        <h4>
                                                            {
                                                                KPIs?.time_taken ?
                                                                    numberFormatter('en-ZA', 0).format(KPIs?.time_taken) :
                                                                    numberFormatter('en-ZA', 0).format(0)
                                                            }
                                                            Mins</h4>
                                                        <h5>Time Taken</h5>
                                                    </div>
                                                    <div className="dash-imgs">
                                                        <i data-feather="file"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="col">
                                                <div className="dash-count" style={ { backgroundColor: "red" } }>
                                                    <div className="dash-counts">
                                                        <h4>
                                                            Ongoing
                                                        </h4>
                                                        <h5>Status</h5>
                                                    </div>
                                                    <div className="dash-imgs">
                                                        <i data-feather="file"></i>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </div> :
                                LogDetails?.log_type == 4 ?

                                    <div className="row">
                                        <div className="col">
                                            <div className="dash-count">
                                                <div className="dash-counts">
                                                    <h4>{ KPIs?.total_products ? numberFormatter('en-ZA', 0).format(KPIs?.total_products) : 0 }</h4>
                                                    <h5>Products</h5>
                                                </div>
                                                <div className="dash-imgs">
                                                    <i data-feather="user"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="dash-count das1">
                                                <div className="dash-counts">
                                                    <h4>{ KPIs?.total_product_updated ? numberFormatter('en-ZA', 0).format(KPIs?.total_product_updated) : 0 }</h4>
                                                    <h5>Updated</h5>
                                                </div>
                                                <div className="dash-imgs">
                                                    <i data-feather="user-check"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="dash-count das2">
                                                <div className="dash-counts">
                                                    <h4>{ KPIs?.total_invalid ? numberFormatter('en-ZA', 0).format(KPIs?.total_invalid) : 0 }</h4>
                                                    <h5>Invalid</h5>
                                                </div>
                                                <div className="dash-imgs">
                                                    <i data-feather="file-text"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="dash-count das3">
                                                <div className="dash-counts">
                                                    <h4>{ KPIs?.total_winning ? numberFormatter('en-ZA', 0).format(KPIs?.total_winning) : 0 }</h4>
                                                    <h5>Winning</h5>
                                                </div>
                                                <div className="dash-imgs">
                                                    <i data-feather="file"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="dash-count">
                                                <div className="dash-counts">
                                                    <h4>{ KPIs?.total_set_to_max ? numberFormatter('en-ZA', 0).format(KPIs?.total_set_to_max) : 0 }</h4>
                                                    <h5>Updated to Max</h5>
                                                </div>
                                                <div className="dash-imgs">
                                                    <i data-feather="user"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="dash-count das1">
                                                <div className="dash-counts">
                                                    <h4>{ KPIs?.total_price_updated ? numberFormatter('en-ZA', 0).format(KPIs?.total_price_updated) : 0 }</h4>
                                                    <h5>Price Updated</h5>
                                                </div>
                                                <div className="dash-imgs">
                                                    <i data-feather="user-check"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="dash-count das2">
                                                <div className="dash-counts">
                                                    <h4>{ KPIs?.total_not_updated ? numberFormatter('en-ZA', 0).format(KPIs?.total_not_updated) : 0 }</h4>
                                                    <h5>Not Updated</h5>
                                                </div>
                                                <div className="dash-imgs">
                                                    <i data-feather="file-text"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="dash-count das3">
                                                <div className="dash-counts">
                                                    <h4>{ KPIs?.total_ineligible ? numberFormatter('en-ZA', 0).format(KPIs?.total_ineligible) : 0 }</h4>
                                                    <h5>Ineligible</h5>
                                                </div>
                                                <div className="dash-imgs">
                                                    <i data-feather="file"></i>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            LogDetails?.status == 1 ?
                                                <div className="col">
                                                    <div className="dash-count das3">
                                                        <div className="dash-counts">
                                                            <h4>
                                                                {
                                                                    KPIs?.time_taken ?
                                                                        numberFormatter('en-ZA', 0).format(KPIs?.time_taken) :
                                                                        numberFormatter('en-ZA', 0).format(0)
                                                                }
                                                                Mins</h4>
                                                            <h5>Time Taken</h5>
                                                        </div>
                                                        <div className="dash-imgs">
                                                            <i data-feather="file"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="col">
                                                    <div className="dash-count" style={ { backgroundColor: "red" } }>
                                                        <div className="dash-counts">
                                                            <h4>
                                                                Ongoing
                                                            </h4>
                                                            <h5>Status</h5>
                                                        </div>
                                                        <div className="dash-imgs">
                                                            <i data-feather="file"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                    :
                                    LogDetails?.log_type == 5 ?

                                        <></>
                                        :
                                        <div className="row">
                                            <div className="col">
                                                <div className="dash-count">
                                                    <div className="dash-counts">
                                                        <h4>{ KPIs?.total_store ? numberFormatter('en-ZA', 0).format(KPIs?.total_store) : 0 }</h4>
                                                        <h5>Stores</h5>
                                                    </div>
                                                    <div className="dash-imgs">
                                                        <i data-feather="user"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="dash-count das1">
                                                    <div className="dash-counts">
                                                        <h4>{ KPIs?.total_products ? numberFormatter('en-ZA', 0).format(KPIs?.total_products) : 0 }</h4>
                                                        <h5>Total Products</h5>
                                                    </div>
                                                    <div className="dash-imgs">
                                                        <i data-feather="user-check"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="dash-count das2">
                                                    <div className="dash-counts">
                                                        <h4>{ KPIs?.total_updated ? numberFormatter('en-ZA', 0).format(KPIs?.total_updated) : 0 }</h4>
                                                        <h5>Total Updated</h5>
                                                    </div>
                                                    <div className="dash-imgs">
                                                        <i data-feather="file-text"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="dash-count das3">
                                                    <div className="dash-counts">
                                                        <h4>{ KPIs?.total_new_added ? numberFormatter('en-ZA', 0).format(KPIs?.total_new_added) : 0 }</h4>
                                                        <h5>Newly Added</h5>
                                                    </div>
                                                    <div className="dash-imgs">
                                                        <i data-feather="file"></i>
                                                    </div>
                                                </div>
                                            </div>

                                            {
                                                LogDetails?.status == 1 ?
                                                    <div className="col">
                                                        <div className="dash-count das3">
                                                            <div className="dash-counts">
                                                                <h4>
                                                                    {
                                                                        KPIs?.time_taken ?
                                                                            numberFormatter('en-ZA', 0).format(KPIs?.time_taken) :
                                                                            numberFormatter('en-ZA', 0).format(0)
                                                                    }
                                                                    Mins</h4>
                                                                <h5>Time Taken</h5>
                                                            </div>
                                                            <div className="dash-imgs">
                                                                <i data-feather="file"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="col">
                                                        <div className="dash-count" style={ { backgroundColor: "red" } }>
                                                            <div className="dash-counts">
                                                                <h4>
                                                                    Ongoing
                                                                </h4>
                                                                <h5>Status</h5>
                                                            </div>
                                                            <div className="dash-imgs">
                                                                <i data-feather="file"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                            }
                                        </div>


                    }
                    {/* <!-- Button trigger modal --> */ }

                </div>
                <div className="card">
                    <div className="card-body">
                        <p htmlFor="store" className="form-label">Log Details</p>
                        <div className="card-body">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Log</th>
                                        <th scope="col">Type</th>
                                        <th scope="col">Date and Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        LogData?.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage).map((data, index) => {
                                            return (
                                                <tr key={ index }>
                                                    <th scope="row">{ index + 1 }</th>
                                                    <td>{ data?.log_description }</td>
                                                    {
                                                        LogDetails?.log_type === 1 ?
                                                            <td>
                                                                {
                                                                    data?.log_type == 1 ? "Cost Updated" :
                                                                        "Cost Added"
                                                                }
                                                            </td>
                                                            :
                                                            LogDetails?.log_type === 2 ?
                                                                <td>
                                                                    {
                                                                        data?.log_type == 1 ? "Inventory Updated" :
                                                                            "Inventory Added"
                                                                    }
                                                                </td>
                                                                :
                                                                LogDetails?.log_type === 2 ?
                                                                    <td>
                                                                        {
                                                                            data?.log_type == 0 ? "Update Started" :
                                                                                data?.log_type == 1 ? "Being Refreshed" :
                                                                                    data?.log_type == 2 ? "Product Updated" :
                                                                                        data?.log_type == 3 ? "Product Added" :
                                                                                            "Refreshed"
                                                                        }
                                                                    </td>
                                                                    :
                                                                    LogDetails?.log_type === 3 ?
                                                                        <td>
                                                                            {
                                                                                data?.log_type == 1 ? "Eligible" :
                                                                                    data?.log_type == 2 ? "Ineligible" :
                                                                                        data?.log_type == 3 ? "Product Updated" :
                                                                                            data?.log_type == 15 ? "Variants Check" :
                                                                                                data?.log_type == 20 ? "Variants Exists" :
                                                                                                    data?.log_type == 25 ? "Variant Found" :
                                                                                                        "Refreshed"
                                                                            }
                                                                        </td>
                                                                        :
                                                                        LogDetails?.log_type === 4 ?
                                                                            <td>
                                                                                {
                                                                                    data?.log_type == 1 ? "Product Inactive" :
                                                                                        data?.log_type == 2 ? "Not Found" :
                                                                                            data?.log_type == 3 ? "Not Seller Found" :
                                                                                                data?.log_type == 4 ? "Winner Updated" :
                                                                                                    data?.log_type == 5 ? "Winning" :
                                                                                                        data?.log_type == 6 ? "Product Updated" :
                                                                                                            data?.log_type == 7 ? "Product Updated" :
                                                                                                                data?.log_type == 8 ? "Added best hour" :
                                                                                                                    data?.log_type == 9 ? "Set to Max" :
                                                                                                                        data?.log_type == 10 ? "Skipped" :
                                                                                                                            data?.log_type == 11 ? "Same Price" :
                                                                                                                                data?.log_type == 12 ? "Price Updated" :
                                                                                                                                    data?.log_type == 13 ? "Ineligible" :
                                                                                                                                        "No products found"
                                                                                }
                                                                            </td>
                                                                            :
                                                                            LogDetails?.log_type === 5 ?
                                                                                <td>
                                                                                    {
                                                                                        data?.log_type == 1 ? "Database Created" :
                                                                                            data?.log_type == 2 ? "S3 Connection" :
                                                                                                data?.log_type == 3 ? "Uploading to S3" :
                                                                                                    data?.log_type == 4 ? "Uploaded to S3" :
                                                                                                        data?.log_type == 5 ? "Old Backup Deleted" :
                                                                                                            "S3 Connection Closed"
                                                                                    }
                                                                                </td>
                                                                                :
                                                                                LogDetails?.log_type === 6 ?
                                                                                    <td>
                                                                                        {
                                                                                            data?.log_type == 0 ? "Refresh Started" :
                                                                                                data?.log_type == 5 ? "Refresh Completed" :
                                                                                                    data?.log_type == 1 ? "Store Product Refresh Started" :
                                                                                                        data?.log_type == 4 ? "Store Product Refresh Completed" :
                                                                                                            data?.log_type == 3 ? "Product Created" :
                                                                                                                data?.log_type == 2 ? "Product Update" :
                                                                                                                    ""
                                                                                        }
                                                                                    </td>
                                                                                    :
                                                                                    <></>

                                                    }
                                                    <td>{ Moment(data?.created_at).format('hh:mm A') } at { Moment(data?.created_at).format('DD MMM YYYY') }</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            <br />
                            <div>
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination justify-content-center">
                                        {
                                            currentPage != 1 ?
                                                <>
                                                    <li className={ `page-item ${currentPage === 1 && 'disabled'}` }>
                                                        <button className="page-link" onClick={ () => handleClick(1) }>First</button>
                                                    </li>
                                                    <li className={ `page-item ${currentPage === 1 && 'disabled'}` }>
                                                        <button className="page-link" onClick={ () => handleClick(currentPage - 1) }>Previous</button>
                                                    </li>
                                                </>
                                                :
                                                null
                                        }
                                        {
                                            [...Array(endPage - startPage + 1)].map((e, i) => (
                                                <li key={ i + startPage } className={ `page-item ${currentPage === i + startPage ? 'active' : ''}` }>
                                                    <button className="page-link" onClick={ () => handleClick(i + startPage) }>{ i + startPage }</button>
                                                </li>
                                            ))
                                        }
                                        {
                                            currentPage != Math.ceil(LogData?.length / rowsPerPage) ?
                                                <>
                                                    <li className={ `page-item ${currentPage === Math.ceil(LogData?.length / rowsPerPage) && 'disabled'}` }>
                                                        <button className="page-link" onClick={ () => handleClick(currentPage + 1) }>Next</button>
                                                    </li>
                                                    <li className={ `page-item ${currentPage === Math.ceil(LogData?.length / rowsPerPage) && 'disabled'}` }>
                                                        <button className="page-link" onClick={ () => handleClick(Math.ceil(LogData?.length / rowsPerPage)) }>Last</button>
                                                    </li>
                                                </>
                                                :
                                                null
                                        }
                                    </ul>
                                </nav>
                            </div>
                        </div>



                    </div>

                </div>
            </div>
        </div>
    )
}

export default LogDetails