import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import Helmet from 'react-helmet'
import Swal from 'sweetalert2'
const InventoryCalculator = (props) => {
    const CompanyProfile = props.CompanyProfile
    const location = useLocation();
    const { state } = location
    const storeId = state['dashboard']
    const [ProductSKU, setProductSKU] = useState(state['tsin_id'])
    const [productDetails, setproductDetails] = useState(state['tsin_id'])
    const [productInventory, setproductInventory] = useState({
        tsin_id: state['tsin_id'],
        SKU: "",
        TotalStockLevel : 0
    })
    const [ProductInfo, setProductInfo] = useState({
        tsin_id : "",
        offer_id : "",
        sku : "",
        barcode : "",
        product_label_number : "",
        selling_price : 0,
        rrp: 0,
        status : "",
        title : "",
        offer_url : "",
        CPT : 0,
        JHB: 0,
        offerPID: "",
        Level : 0,
        TotalStockLevel : 0
    })
    const LoadProductDetails = async(tsin_id) => {
        const config = {
            headers: {
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
                'Authorization' : `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify({
            "dashboard" : storeId,
            "tsin_id" : tsin_id,
            "storeId" : storeId
        })

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/offerDetails/`, Body,config)
            setProductInfo(response.data['product'])
            
        } catch (error) {
            // console.log('first', error)
        //   setResponseError(error.response.statusText)
        }
    }
    const [errorData, setErrorData] = useState({
        status: "",
        message: "",
        errors: []  
    })
    const onChange = e => {
        setproductInventory({...productInventory, [e.target.name]: e.target.value})    
    }

    const [LoadingVisibility, setLoadingVisibility] = useState("none")
    const [dataVisibility, setDataVisibility] = useState("block")
    
    const formatter = new Intl.NumberFormat('en-ZA', {
        style: 'currency',
        currency: 'ZAR',
    
        // These options are needed to round to whole numbers if that's what you want.
        minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    })

    const loadProductDetails = async() => {
        setLoadingVisibility("block")
        setDataVisibility("none")
        const config = {
            headers: {
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
                'Authorization' : `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify(
            {
                "dashboard" : state['dashboard'],
                "tsin_id" : state['tsin_id'],
            }
        )
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/inventory/get/level/`, Body,config)
        if (response.data['data'] !== 0) {
            setproductInventory(response.data['data'])
        } 
        } catch (error) {
        // console.log('first', error)
        }
        setLoadingVisibility("none")
        setDataVisibility("block")
    }
    
    const [SuccessMessage, setSuccessMessage] = useState("")
    const [SuccessMessageVisibility, setSuccessMessageVisibility] = useState("none")
    const [SubmissionErrorVisibilty, setSubmissionErrorVisibilty] = useState("none")
    const insertProductPrice = async() => {
        setLoadingVisibility("block")
        setDataVisibility("none")
        const config = {
            headers: {
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
                'Authorization' : `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify(
            productInventory
        )
        try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/inventory/update/level/`, Body,config)
        Swal.fire({
            position: "bottom-end",
            type: "success",
            title: "Success",
            html: 'Inventory has been updated.',
            showConfirmButton: !1,
            timer: 1500,
            confirmButtonClass: "btn btn-primary",
            buttonsStyling: !1,
        })
        } catch (error) {
            console.log(error.response)
            // console.log('first', error)
            setErrorData({
                status: error.response.status,
                message: error.response.data.detail,
                errors: error.response.errors,
            });
        
            setSubmissionErrorVisibilty("block");
            setTimeout(() => {
                setSubmissionErrorVisibilty("none")
            }, 5000)
        }
        setLoadingVisibility("none")
        setDataVisibility("block")
    }
      
    const onSubmit = e => {
        e.preventDefault()
        insertProductPrice()
    
        // window.location.reload();
    }
      
    const functionsCalledOnLoad = () => {
        loadProductDetails()
        LoadProductDetails(state['tsin_id'])
    }
    useEffect(() => {
        
        functionsCalledOnLoad()
        setProductSKU(state['tsin_id'])
    }, [])

    return (
        <>
            
            <Helmet>				
				<title>{CompanyProfile['companyName']} Products</title>
				<link rel="stylesheet" href="../assets/css/style.css"/>
                <script src="../assets/js/script.js" />
            </Helmet>
            <div style={{display: LoadingVisibility}}>
                <div id="global-loader" >
                    <div className="whirly-loader"> </div>
                </div>
            </div>
            <div className="content">
                <div style={{display: dataVisibility}}>
                <div className="row mb-4">
                    <div className="col-md">
                        <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">{ProductInfo.title} / </span> Inventory Update</h4>
                        <div className="card mb-4">
                            <div className="alert alert-danger text-center" style={{display: SubmissionErrorVisibilty}} role="alert">
                                <p><b>Error {errorData.status}: {errorData.message} </b></p>
                            </div>
                            <div className="card-body">
                                <form onSubmit={e => onSubmit(e)}>
                                <div className='row'>
                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label" htmlFor="basic-default-name">TotalStockLevel</label>
                                        <div className="col-sm-10">
                                            <div className="input-group">
                                            <input type="text" min={1} className="form-control" value={productInventory['TotalStockLevel']} name="TotalStockLevel" onChange={(e)=>{onChange(e)}} id="basic-default-name" placeholder='Supplier Name'/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="" style={{textAlign:"center"}}>
                                        <button className="col-6 btn btn-sm btn-outline-primary form-select ">Update Inventory</button>
                                    </div>
                                </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InventoryCalculator