import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import Swal from 'sweetalert2'

const NotificationSettings = () => {
    const [userName, setUserName] = useState("")
    const [MessageTitle, setMessageTitle] = useState("")
    const [Message, setMessage] = useState("")
    const [MessageType, setMessageType] = useState("")
    const [MessageVisibility, setMessageVisibility] = useState("none")
    const [NotificationsData, setNotificationsData] = useState({
        "id": 1,
        "notification": "",
        "status": true
    })
    const [NewEmailData, setNewEmailData] = useState({
        "email": "",
        "status": true
    })
    const onNewEmailChange = (e) => { setNewEmailData({ ...NewEmailData, [e.target.name]: e.target.value }) }
    const [EmailNotificationsData, setEmailNotificationsData] = useState([])
    const [EmailNotificationsListData, setEmailNotificationsListData] = useState([])
    const [EmailData, setEmailData] = useState({
        "email": "",
        "status": true
    })
    const onEmailDataChange = (e) => { setEmailData({ ...EmailData, [e.target.name]: e.target.value }) }
    const LoadNotifications = async () => {

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        try {

            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/notifications/settings/getNotifications/`, config)
            // console.log(response.data['company']['companyLogo'])
            setNotificationsData(response.data['data'][0])
            // if (response.data['data']){
            //     response.data['data'].forEach(function(value) {
            //         if (typeof value === "Email Notifications") {
            //             LoadEmailNotificationsType()
            //         }
            //     })
            // }
            LoadEmailNotificationsType()
            LoadEmailNotificationList()
        } catch (error) {
            // console.log('first', error)
        }

    }
    const UpdateNotificationsType = async (id, status, notification_type) => {
        setMessageVisibility("none")
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify({
            "id": id,
            "status": status
        })
        try {

            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/notifications/settings/updateNotifications/`, Body, config)
            setMessageTitle("Update")
            setMessage(notification_type + " has been updated")
            setMessageVisibility("block")
            setMessageType("primary")
            setNewEmailData({
                "email": "",
                "status": true
            })
        } catch (error) {
            setMessageTitle("Error")
            setMessage(error.response.statusText)
            setMessageVisibility("block")
            setMessageType("danger")
            console.log('first', error.response.statusText)
        }

        setTimeout(() => {
            setMessageVisibility("none")
        }, 3000)
        LoadNotifications()
    }
    const LoadEmailNotificationList = async () => {

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        try {

            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/notifications/settings/getEmailNotificationList/`, config)
            // console.log(response.data['company']['companyLogo'])
            setEmailNotificationsListData(response.data['data'])
            // if (response.data['data']){
            //     response.data['data'].forEach(function(value) {
            //         if (typeof value === "Email Notifications") {
            //             LoadEmailNotificationsType()
            //         }
            //     })
            // }
            LoadEmailNotificationsType()
        } catch (error) {
            // console.log('first', error)
        }

    }
    const onNewEmailSubmit = (e) => {
        e.preventDefault()
        AddEmailNotificationList(e)
    }
    const onEmailUpdate = (e) => {
        e.preventDefault()
        UpdateEmailNotificationList(e)
    }
    const onDeleteEmail = (e, key, email) => {
        e.preventDefault()
        // alert(key)
        DeleteEmailNotificationList(key, email)
    }
    const AddEmailNotificationList = async () => {
        setMessageVisibility("none")
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify([NewEmailData])
        try {

            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/notifications/settings/addEmailNotificationList/`, Body, config)
            setMessageTitle("New Email is added")
            setMessage(NewEmailData['email'] + " has been added")
            setMessageVisibility("block")
            setMessageType("primary")
            setNewEmailData({
                email: "",
                status: true
            })
        } catch (error) {
            setMessageTitle("Error")
            setMessage(error.response.statusText)
            setMessageVisibility("block")
            setMessageType("danger")
            console.log('first', error.response.statusText)
        }

        setTimeout(() => {
            setMessageVisibility("none")
        }, 3000)
        LoadNotifications()
    }
    const UpdateEmailNotificationList = async () => {
        setMessageVisibility("none")
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify(EmailData)
        try {

            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v4/user/email/notification/add/`, Body, config)
            setEmailData({
                email: "",
                status: true
            })
        } catch (error) {
            setMessageTitle("Error")
            setMessage(error.response.statusText)
            setMessageVisibility("block")
            setMessageType("danger")
            // console.log('first', error.response.statusText)
        }

        setTimeout(() => {
            setMessageVisibility("none")
        }, 3000)
        LoadNotifications()
    }
    const DeleteEmailNotificationList = async (id, email) => {
        setMessageVisibility("none")
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify({
            "id": id
        })
        try {

            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/notifications/settings/deleteEmailNotificationList/`, Body, config)
            setMessageTitle("Update")
            setMessage(email + " has been deleted")
            setMessageVisibility("block")
            setMessageType("primary")
        } catch (error) {
            setMessageTitle("Error")
            setMessage(error.response.statusText)
            setMessageVisibility("block")
            setMessageType("danger")
            // console.log('first', error.response.statusText)
        }

        setTimeout(() => {
            setMessageVisibility("none")
        }, 3000)
        LoadNotifications()
    }
    const LoadEmailNotificationsType = async () => {

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        try {

            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v4/user/email/notification/get/`, config)
            // console.log(response.data['company']['companyLogo'])
            setEmailNotificationsData(response.data['data'])
        } catch (error) {
            // console.log('first', error)
        }

    }
    const UpdateEmailNotificationsType = async (id, status, notification_type) => {
        setMessageVisibility("none")
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify({
            "notification_id": id,
            "status": status
        })
        try {

            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v4/user/email/notification/add/`, Body, config)
            Swal.fire({
                position: "bottom-end",
                type: "success",
                title: ` ${notification_type} status updated.`,
                showConfirmButton: !1,
                timer: 1500,
                confirmButtonClass: "btn btn-primary",
                buttonsStyling: !1,
            })
            setMessageType("primary")
        } catch (error) {
            setMessageTitle("Error")
            setMessage(error.response.statusText)
            setMessageVisibility("block")
            setMessageType("danger")
            console.log('first', error.response.statusText)
        }

        setTimeout(() => {
            setMessageVisibility("none")
        }, 3000)
        LoadNotifications()
    }

    useEffect(() => {
        LoadNotifications()

    }, [])

    return (
        <div className="">
            <div className=''>
                <div className='card-body'>
                    <div style={ { display: MessageVisibility } }>
                        {/* <PushNotification messageTitle={MessageTitle} message={Message} type={MessageType}  /> */ }
                    </div>
                    <h4 className="">
                        <span className="text-muted fw-light">Notifications / </span> Takealot Notifications
                    </h4>
                    <h6 className="mb-2 mx-2">In case Notifications Panel is empty? 😢</h6>
                    <p className="mb-2 mx-2">Please enter the following Webhook link in Takealot Seller Profile:</p>
                    <h5 className="mb-4 mx-2">{ `${process.env.REACT_APP_BACKEND_URL}/api/v1/webhooks/notification/` }</h5>

                    <h4 className="">
                        <span className="text-muted fw-light">Notifications / </span> Email Notifications
                    </h4>
                    {/* <div className="container-xxl flex-grow-1 container-p-y">
                        <div className="form-check form-switch mb-2">
                            <input className="form-check-input" onClick={(e)=>{UpdateNotificationsType(NotificationsData['id'], !NotificationsData['status'], NotificationsData['notification'])}} type="checkbox" id="flexSwitchCheckChecked" checked={NotificationsData['status']}/>
                            <label className="form-check-label" htmlfor="flexSwitchCheckChecked">{NotificationsData['notification']}</label>
                        </div>
                    </div>
                    <hr/> */}
                    {
                        NotificationsData['status'] ?
                            <>
                                {
                                    EmailNotificationsData.map(
                                        (key) => {
                                            return (
                                                <div className="container-xxl flex-grow-1 container-p-y">
                                                    <div className="form-check form-switch mb-2">
                                                        <input className="form-check-input" type="checkbox" onClick={ (e) => { UpdateEmailNotificationsType(key.id, !key.status, key.notification_type) } } id="flexSwitchCheckChecked" checked={ key.status } />
                                                        <label className="form-check-label" htmlfor="flexSwitchCheckChecked">{ key.notification_type }</label>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    )
                                }
                                <h4 className="">
                                    <span className="text-muted fw-light">Notifications / </span> Email Notification List
                                </h4>
                                <p className="">
                                    <span className="text-muted fw-light">You can add upto 4 emails and don't add your registered mail here as it is already added</span>
                                </p>

                                <div className='col-lg-6 col-md-12 col-sm-12'>
                                    {
                                        EmailNotificationsListData.length <= 4 ?
                                            <>
                                                <button type="button" className="btn btn-primary col-4" data-bs-toggle="modal" data-bs-target="#newEmailModal">
                                                    Add New Email
                                                </button>

                                                <div className="modal fade" id="newEmailModal" tabindex="-1" aria-modal="true" role="dialog">
                                                    <div className="modal-dialog modal-dialog-centered" role="document">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="newEmailModalTitle">Add new Email in Notification List</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <form onSubmit={ (e) => { onNewEmailSubmit(e) } }>
                                                                <div className="modal-body">
                                                                    <div className="row">
                                                                        <div className="col mb-3">
                                                                            <label htmlfor="notificationEmail" className="form-label">Email</label>
                                                                            <input required type="email" name="email" value={ NewEmailData['email'] } onChange={ (e) => { onNewEmailChange(e) } } id="notificationEmail" className="form-control" placeholder="Enter Email" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">
                                                                        Close
                                                                    </button>
                                                                    <button type="submit" className="btn btn-primary">Add</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            : <></>
                                    }
                                </div><br />
                                {
                                    EmailNotificationsListData.length > 0 ?
                                        <div className="card">
                                            <div className="table-responsive text-nowrap">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>Email</th>
                                                            <th>Status</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="table-border-bottom-0">
                                                        {
                                                            EmailNotificationsListData.map(
                                                                (key) => {
                                                                    return (
                                                                        <tr>
                                                                            <td><strong>{ key.email }</strong></td>
                                                                            <td><label className="form-check-label" htmlfor="flexSwitchCheckChecked">{ key.status ? "Active" : "Inactive" }</label>
                                                                                {/* <div className="form-check form-switch mb-2">
                                                                                <input className="form-check-input" type="checkbox" onClick={(e)=>{UpdateEmailNotificationsType(key.id, !key.status, key.notification_type)}} id="flexSwitchCheckChecked" checked={key.status}/>
                                                                            </div> */}
                                                                            </td>
                                                                            <td>
                                                                                <i className='bx bx-edit-alt' onClick={ (e) => { setEmailData(key) } } data-bs-toggle="modal" data-bs-target="#emailDetailsModal"></i>
                                                                                <i className='bx bx-trash-alt' value={ key.id } onClick={ (e) => { onDeleteEmail(e, key.id, key.email) } }></i>

                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="modal fade" id="emailDetailsModal" tabindex="-1" aria-modal="true" role="dialog">
                                                <div className="modal-dialog modal-dialog-centered" role="document">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title" id="emailDetailsModalTitle">Add new Email in Notification List</h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <form onSubmit={ (e) => { onEmailUpdate(e) } }>
                                                            <div className="modal-body">
                                                                <div className="row">
                                                                    <div className="col mb-3">
                                                                        <label htmlfor="notificationEmail" className="form-label">Email</label>
                                                                        <input required type="email" name="email" onChange={ (e) => { onEmailDataChange(e) } } value={ EmailData['email'] } id="notificationEmail" className="form-control" placeholder="Enter Email" />
                                                                    </div>
                                                                    <div className="col mb-3">
                                                                        <label className="form-label">Status</label>
                                                                        <select className="form-select" name="status" value={ EmailData['status'] } onChange={ (e) => { onEmailDataChange(e) } } aria-label="Default select example">
                                                                            <option value="true">Active</option>
                                                                            <option value="false">Inactive</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">
                                                                    Close
                                                                </button>
                                                                <button type="submit" className="btn btn-primary">Save</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : <></>
                                }
                            </>
                            : <></>
                    }
                </div>
            </div>

        </div>
    )
}

export default NotificationSettings