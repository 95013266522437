import React from 'react'
import Pagination from '../SalesPagination'
import Moment from 'moment'
const SalesList = (props) => {
    const storeId = props.storeId
    const SalesData = props.SalesData
    const LoadSaleDetailsData = props.LoadSaleDetailsData
    const formatter = props.formatter
    const numberFormatter = props.numberFormatter
    const TotalProducts = props.TotalProducts
    const PageLimit = props.PageLimit
    const SalesQuery = props.SalesQuery
    const LoadQueryData = props.onLoadQueryData
    return (
        <>

            <div className="row mb-5">
                {
                    !SalesData?.loading ?
                        SalesData.length > 0 ?
                            SalesData.map(
                                (key) => {
                                    return (
                                        <>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                <div className="card mb-3" key={ key.order_id }>
                                                    <div className="row g-0">
                                                        <div className="col-md">
                                                            <div className="card-body">
                                                                <div className='text-center'>
                                                                    <h4 className="card-title">{ key.product_title }</h4>
                                                                    <span className="card-text">
                                                                        Customer: <strong>{ key.customer }</strong><br />
                                                                        <strong>
                                                                            { key.sale_status }
                                                                        </strong><br />
                                                                    </span>
                                                                </div>
                                                                <div className='d-none d-lg-block'>
                                                                    <div className='row'>
                                                                        <div className='col-xl col-lg col-md col-sm' style={ { textAlign: "left" } }>
                                                                            <p>Order Number: { (key.order_id) }</p>
                                                                            <p>SKU: { (key.sku) }</p>
                                                                        </div>
                                                                        <div className='col-xl col-lg col-md col-sm' style={ { textAlign: "center" } }>
                                                                            <p>Quantity: { numberFormatter.format(key.quantity) }</p>
                                                                            <p>Selling Price: { formatter.format(key.selling_price) }</p>
                                                                            <div>
                                                                                <button onClick={ (e) => { LoadSaleDetailsData(key.order_id, key.order_item_id, key.dashboard_id) } } className='btn btn-primary' data-bs-toggle="modal" data-bs-target={ "#modalCenter-" + storeId }>Create Invoice</button>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-xl col-lg col-md col-sm' style={ { textAlign: "right" } }>
                                                                            <p>DC: { key.dc }</p>
                                                                            <p>Date: { Moment(key.order_date).format("hh:mm A") } at { Moment(key.order_date).format("DD-MMM-YYYY") }</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='d-block d-sm-none'>
                                                                    <div className='row'>
                                                                        <div className='col-xl col-lg col-md col-sm'>
                                                                            <p>Order Number: { (key.order_id) }</p>
                                                                            <p>SKU: { (key.sku) }</p>
                                                                        </div>
                                                                        <div className='col-xl col-lg col-md col-sm'>
                                                                            <p>Quantity: { numberFormatter.format(key.quantity) }</p>
                                                                            <p>Selling Price: { formatter.format(key.selling_price) }</p>
                                                                        </div>
                                                                        <div className='col-xl col-lg col-md col-sm'>
                                                                            <p>DC: { key.dc }</p>
                                                                            <p>Date: { Moment(key.order_date).format("hh:mm A") } at { Moment(key.order_date).format("DD-MMM-YYYY") }</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className='col-xl col-lg col-md col-sm'>
                                                                            <p style={ { color: 'transparent' } }>Order Number: { (key.order_id) }</p>
                                                                        </div>
                                                                        <div className='col-xl col-lg col-md col-sm' style={ { textAlign: "center" } }>
                                                                            <div>
                                                                                <button onClick={ (e) => { LoadSaleDetailsData(key.order_id, key.order_item_id, key.dashboard_id) } } className='btn btn-primary' data-bs-toggle="modal" data-bs-target={ "#invoiceModal-" + storeId }>Create Invoice</button>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-xl col-lg col-md col-sm'>
                                                                            <p style={ { color: 'transparent' } }>DC: { key.dc }</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                            :
                            <>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <h4 className="card-title">No data found</h4>
                                        </div>
                                    </div>
                                </div>
                            </>
                        :
                        Array.from({ length: 15 }).map((_, index) => (
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12" >
                                <div className="card mb-3">
                                    <div className="row g-0">
                                        <div className="col-md">
                                            <div className="card-body">
                                                <div className='text-center'>
                                                    <h4 className="card-title"><span className="skeleton-loader"></span></h4>
                                                    <span className="card-text">
                                                        Customer: <strong><span className="skeleton-loader"></span></strong><br />
                                                        <strong>
                                                            <span className="skeleton-loader"></span>
                                                        </strong><br />
                                                    </span>
                                                </div>
                                                <div className='d-none d-lg-block'>
                                                    <div className='row'>
                                                        <div className='col-xl col-lg col-md col-sm' style={ { textAlign: "left" } }>
                                                            <p>Order Number: <span className="skeleton-loader"></span></p>
                                                            <p>SKU: <span className="skeleton-loader"></span></p>
                                                        </div>
                                                        <div className='col-xl col-lg col-md col-sm' style={ { textAlign: "center" } }>
                                                            <p>Quantity: <span className="skeleton-loader"></span></p>
                                                            <p>Selling Price: <span className="skeleton-loader"></span></p>
                                                            <div>
                                                                <button className='btn btn-primary'><span className="skeleton-loader"></span></button>
                                                            </div>
                                                        </div>
                                                        <div className='col-xl col-lg col-md col-sm' style={ { textAlign: "right" } }>
                                                            <p>DC: <span className="skeleton-loader"></span></p>
                                                            <p>Date: <span className="skeleton-loader"></span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-block d-sm-none'>
                                                    <div className='row'>
                                                        <div className='col-xl col-lg col-md col-sm'>
                                                            <p>Order Number: <span className="skeleton-loader"></span></p>
                                                            <p>SKU: <span className="skeleton-loader"></span></p>
                                                        </div>
                                                        <div className='col-xl col-lg col-md col-sm'>
                                                            <p>Quantity: <span className="skeleton-loader"></span></p>
                                                            <p>Selling Price: <span className="skeleton-loader"></span></p>
                                                        </div>
                                                        <div className='col-xl col-lg col-md col-sm'>
                                                            <p>DC: <span className="skeleton-loader"></span></p>
                                                            <p>Date: <span className="skeleton-loader"></span></p>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-xl col-lg col-md col-sm'>
                                                            <p style={ { color: 'transparent' } }>Order Number: <span className="skeleton-loader"></span></p>
                                                        </div>
                                                        <div className='col-xl col-lg col-md col-sm' style={ { textAlign: "center" } }>
                                                            <div>
                                                                <button className='btn btn-primary'><span className="skeleton-loader"></span></button>
                                                            </div>
                                                        </div>
                                                        <div className='col-xl col-lg col-md col-sm'>
                                                            <p style={ { color: 'transparent' } }>DC: <span className="skeleton-loader"></span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                }
                <div className='row'>
                    <div className='pagination justify-content-center'>
                        {
                            TotalProducts > 10 ?
                                <Pagination totalRecords={ TotalProducts } pageLimit={ PageLimit } paginationSearchQuery={ SalesQuery } onPageChanged={ LoadQueryData } />
                                : <></>
                        }
                    </div>
                </div>

            </div>
        </>
    )
}

export default SalesList