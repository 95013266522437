import React from 'react'
import UserProfile from '../../components/User/UserProfile'
import APIConnections from '../../components/User/APIConnections'
import NotificationSettings from '../../components/User/NotificationSettings'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'

const Settings = (props) => {
    const user = props.user
    return (
        <>
            <div className='content '>

                <Helmet>
                    <title>Settings - Buybox</title>
                </Helmet>
                <div className="page-header">
                    <div className="page-title">
                        <h4>User Settings</h4>
                        <h6>Manage your data</h6>
                    </div>
                </div>
                {
                    user?.is_staff ?
                        <div className="card bg-white">
                            <div className="card-body">
                                <UserProfile />

                            </div>
                        </div>
                        :
                        <>
                            <ul className="nav nav-tabs nav-tabs-solid nav-justified">
                                <li className="nav-item"><a className="nav-link active" href="#solid-justified-user-info" data-bs-toggle="tab">User Profile</a></li>
                                <li className="nav-item"><a className="nav-link" href="#solid-justified-api-connections" data-bs-toggle="tab">API Connections</a></li>
                                <li className="nav-item"><a className="nav-link" href="#solid-justified-notifications" data-bs-toggle="tab">Notification Settings</a></li>

                            </ul>
                            <div className="card bg-white">
                                <div className="card-body">
                                    <div className="tab-content">
                                        <div className="tab-pane show active" id="solid-justified-user-info">
                                            <UserProfile />
                                        </div>
                                        <div className="tab-pane" id="solid-justified-api-connections">
                                            <APIConnections />
                                        </div>
                                        <div className="tab-pane" id="solid-justified-notifications">
                                            <NotificationSettings />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </>
                }
            </div>
        </>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.Auth.isAuthenticated,
    user: state.Auth.user,
})
export default connect(mapStateToProps)(Settings)