import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { NavLink, Navigate } from 'react-router-dom'
import KPIs from './KPIs'
import axios from 'axios'
import { currencyFormatter } from '../../../modules/formatter'
import Shopify from '../../../assets/img/shopify.png'
import Woo from '../../../assets/img/woocommerce.png'
const SubscriptionManagement = (props) => {
    const user = props.user
    const [Plans, setPlans] = useState([])
    const [KPIsData, setKPIsData] = useState({
        total : "",
        active_plans : "",
        subscribed : ""
    })
    const loadPlans = async() => {
        const config = {
            headers: {
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
                'Authorization' : `JWT ${localStorage.getItem('access')}`
            }
        }
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/subscription/plans/load/`, config)
            setPlans(response?.data?.data)
        } catch (error) {
        // console.log('first', error)
        //   setResponseError(error.response.statusText)
        }
    }
    useEffect(() => {
        loadPlans()
    }, [])
    
    if (!user?.is_superuser) {
        return <Navigate to="/"/> 
    }
    return (
        <div className='content'>
            <div className="page-header">
                <div className="page-title">
                    <h4>Plans Management</h4>
                    <h6>Manage your plans</h6>
                </div>
            </div>
            <div className="row row-cols-1 row-cols-md-3 mb-3 text-center">
                {
                    Plans.map(
                        (row, i) => {
                            return(
                                <>
                                    <div key={i} className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="card mb-4 rounded-3 shadow-sm">
                                            <div className="card-header py-3">
                                                <h4 className="my-0 fw-normal">{row?.name}</h4>
                                            </div>
                                            <div className="card-body">
                                                <h1 className="card-title pricing-card-title">{currencyFormatter('en-ZA','ZAR').format(row?.pricing)}<small className="text-muted fw-light">/mo</small></h1>
                                                <ul className="list-unstyled mt-3 mb-4">
                                                    <li>Total Subscribers {row?.subscribers}</li>
                                                    <li>Total Active Subscribers {row?.activeSubscribers}</li>
                                                    <li>Trail: {row?.trailDays} days.</li>
                                                    <li>Upto <strong>{row?.totalProducts}</strong> SKUs can be added</li>
                                                    <li>{row?.takealotStores} store{row?.takealotStores > 1 ? "s" : ""} included</li>
                                                    {
                                                        row?.shopify ?
                                                        <li><img src={Shopify} width={'20%'} ></img></li>
                                                        : <></>
                                                    }
                                                    {
                                                        row?.woocommerce ?
                                                        <li><img src={Woo} width={'30%'} ></img></li>
                                                        : <></>
                                                    }
                                                    <li>Unlimited Invoice Downloading</li>
                                                    <li>Unlimited BuyBox Monitoring</li>
                                                    <li>Unlimited Email Reports</li>
                                                    <li>Help center access</li>
                                                </ul>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 col-xs-12'>
                                                        <NavLink 
                                                            to="/plans/edit"  
                                                            state={
                                                                {
                                                                    planId : row?.id
                                                                }
                                                            } 
                                                            className="w-100 btn btn-lg btn-outline-primary"
                                                        >
                                                            Edit
                                                        </NavLink>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 col-xs-12'>
                                                        <NavLink 
                                                            to="/plans/view"  
                                                            state={
                                                                {
                                                                    planId : row?.id
                                                                }
                                                            } 
                                                            className="w-100 btn btn-lg btn-outline-success"
                                                        >
                                                            View
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    )
                }
                
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
	isAuthenticated: state.Auth.isAuthenticated,
	user: state.Auth.user
})
  
export default connect(mapStateToProps, {})(SubscriptionManagement)