import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { NavLink, useLocation } from 'react-router-dom'
import Moment from 'moment'
const Logs = () => {
    const location = useLocation()
    const [LogData, setLogData] = useState([])
    const getLogs = async () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/logs/list/`, config)
            setLogData(response?.data?.logData)
        } catch (error) {
            // console.log('first', error)
            //   setResponseError(error.response.statusText)
        }
    }


    useEffect(() => {
        getLogs()
    }, [location.id])

    const rowsPerPage = 15

    const [currentPage, setCurrentPage] = useState(1);

    const handleClick = (newPage) => {
        setCurrentPage(newPage);
    }

    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + 2, Math.ceil(LogData?.length / rowsPerPage));

    if (endPage - startPage < 2) {
        startPage = Math.max(endPage - 2, 1);
    }

    return (
        <div className='content'>
            <Helmet>
                <title>Logs</title>
                <meta
                    name="description"
                    content={ `Logs` }
                />
                <link rel="stylesheet" href="../assets/css/style.css" />
                <script src="../assets/js/script.js" />
            </Helmet>
            <div>
                <div className="page-header">
                    <div className="page-title">
                        <h4>Logs</h4>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header pb-0 d-flex justify-content-between align-items-center">
                        <h4 className="card-title">Logs List</h4>
                        {/* <div className="graph-sets">
                            <div className="dropdown mx-1">
                                <button className="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Page Size
                                </button>
                                <ul className="dropdown-menu">
                                    <li><a className={ PageSize === 10 ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onPageSizeChange(e, "10") } } href="#">10</a></li>
                                    <li><a className={ PageSize === 20 ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onPageSizeChange(e, "20") } } href="#">20</a></li>
                                    <li><a className={ PageSize === 50 ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onPageSizeChange(e, "50") } } href="#">50</a></li>
                                    <li><a className={ PageSize === 100 ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onPageSizeChange(e, "100") } } href="#">100</a></li>
                                </ul>
                            </div>
                            <div className="dropdown">
                                <button className="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Sort by
                                </button>
                                <ul className="dropdown-menu">
                                    <li><a className={ Sortby === "sales" ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onSortChange(e, "sales") } } href="#">Sales (Last 30 days)</a></li>
                                    <li><a className={ Sortby === "units" ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onSortChange(e, "units") } } href="#">Units (Last 30 days)</a></li>
                                    <li><a className={ Sortby === "title" ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onSortChange(e, "title") } } href="#">Product Title</a></li>

                                </ul>
                            </div>
                            <div className='py-1'>
                                <button
                                    onClick={ (e) => { onSortDirectionChange(e, "up") } }
                                    className={
                                        SortDirection === "up" ?
                                            'btn btn-primary mx-1 my-1'
                                            :
                                            'btn btn-outline-primary mx-1'
                                    }
                                >
                                    <i class="fa-solid fa-arrow-up-wide-short"></i>
                                </button>
                            </div>
                            <div className='py-1'>
                                <button
                                    onClick={ (e) => { onSortDirectionChange(e, "down") } }
                                    className={
                                        SortDirection === "down" ?
                                            'btn btn-primary mx-1'
                                            :
                                            'btn btn-outline-primary mx-1 my-1'
                                    }
                                >
                                    <i class="fa-solid fa-arrow-down-wide-short"></i>
                                </button>
                            </div>
                            <div className='px-1 w-50'>
                                <NavLink to="/costCSV" className='btn btn-primary'>Update from CSV</NavLink>
                            </div>
                        </div> */}
                    </div>
                    <div className="card-body">
                        <p htmlFor="store" className="form-label">Log Details</p>
                        <div className="card-body">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Log</th>
                                        <th scope="col">Type</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Date and Time</th>
                                        <th scope="col">View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        LogData?.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage).map((data, index) => {
                                            return (
                                                <tr key={ index }>
                                                    <th scope="row">{ index + 1 }</th>
                                                    <td>{ data?.log_name }</td>
                                                    <td>
                                                        {
                                                            data?.log_type == 1 ? "Cost Update" :
                                                                data?.log_type == 2 ? "Inventory Update" :
                                                                    data?.log_type == 3 ? "Buybox Eligibilty" :
                                                                        data?.log_type == 4 ? "Buybox Update" :
                                                                            data?.log_type == 5 ? "Database backup" :
                                                                                "Product Data update"
                                                        }</td>
                                                    <td>{ data?.status == 0 ? "Ongoing" : "Completed" }</td>
                                                    <td>{ Moment(data?.created_at).format('hh:mm A') } at { Moment(data?.created_at).format('DD MMM YYYY') }</td>
                                                    <td>
                                                        <NavLink
                                                            to={ `/logs/details` }
                                                            state={ { log_id: data?.id } }
                                                        >
                                                            <button
                                                                className='btn btn-primary btn-sm'
                                                            >
                                                                <i className="fa fa-eye"></i>
                                                            </button>
                                                        </NavLink></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            <br />
                            <div>
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination justify-content-center">
                                        {
                                            currentPage != 1 ?
                                                <>
                                                    <li className={ `page-item ${currentPage === 1 && 'disabled'}` }>
                                                        <button className="page-link" onClick={ () => handleClick(1) }>First</button>
                                                    </li>
                                                    <li className={ `page-item ${currentPage === 1 && 'disabled'}` }>
                                                        <button className="page-link" onClick={ () => handleClick(currentPage - 1) }>Previous</button>
                                                    </li>
                                                </>
                                                :
                                                null
                                        }
                                        {
                                            [...Array(endPage - startPage + 1)].map((e, i) => (
                                                <li key={ i + startPage } className={ `page-item ${currentPage === i + startPage ? 'active' : ''}` }>
                                                    <button className="page-link" onClick={ () => handleClick(i + startPage) }>{ i + startPage }</button>
                                                </li>
                                            ))
                                        }
                                        {
                                            currentPage != Math.ceil(LogData?.length / rowsPerPage) ?
                                                <>
                                                    <li className={ `page-item ${currentPage === Math.ceil(LogData?.length / rowsPerPage) && 'disabled'}` }>
                                                        <button className="page-link" onClick={ () => handleClick(currentPage + 1) }>Next</button>
                                                    </li>
                                                    <li className={ `page-item ${currentPage === Math.ceil(LogData?.length / rowsPerPage) && 'disabled'}` }>
                                                        <button className="page-link" onClick={ () => handleClick(Math.ceil(LogData?.length / rowsPerPage)) }>Last</button>
                                                    </li>
                                                </>
                                                :
                                                null
                                        }
                                    </ul>
                                </nav>
                            </div>
                        </div>



                    </div>

                </div>
            </div>
        </div>
    )
}

export default Logs