import axios from 'axios'
import React, { useState, useEffect } from 'react'
import CompanyProducts from '../../Products/CompanyProducts'
import StoreProducts from '../../Products/StoreProducts'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { NavLink } from 'react-router-dom'

const MultiProducts = (props) => {
    const CompanyProfile = props.CompanyProfile
    const [Stores, setStores] = useState([])
    const [website, setWebsite] = useState({})
    const [Loaded, setLoaded] = useState(false)
    const [TotalStores, setTotalStores] = useState(-1)
    const [activeTab, setActiveTab] = useState('finance-top')

    const LoadStores = async () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/stores/get/`, config)
            setTotalStores(response.data['total_stores'])
            setStores(response.data['data'])
            setWebsite(response.data['website'])
        } catch (error) {
            setTotalStores({
                legal_name: "",
                key: null
            })
        }
    }

    useEffect(() => {
        LoadStores()
        if (localStorage.getItem("finance") === null) {
            localStorage.setItem('finance', JSON.stringify("finance-top"))
        }
        const storedTab = JSON.parse(localStorage.getItem("finance"));
        setActiveTab(storedTab)
        setLoaded(true)
    }, [])

    const handleTabClick = (tabName) => {
        setActiveTab(tabName)
        localStorage.setItem('finance', JSON.stringify(tabName))
    }

    if (TotalStores === 0) {
        return (
            <div className="content" style={ { textAlign: "center" } }>
                <div className='card'>
                    <div className="container-xxl container-p-y">
                        <div className="misc-wrapper">
                            <h2 className="mb-2 mx-2">No Products Found? 😢</h2>
                            <p className="mb-4 mx-2">Please add your Takealot API to get the data.</p>
                            <NavLink to="/api-connections" className="btn btn-primary">API Connections</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            { Loaded && (
                <Helmet>
                    <link rel="stylesheet" href="../../../assets/css/customstyles.css" />
                </Helmet>
            ) }
            <div className="">
                <div className="nav-align-top mb-4">
                    <ul className="nav nav-pills mb-3 justify-content-center" role="tablist">
                        <li className="nav-item">
                            <button
                                type="button"
                                onClick={ () => handleTabClick("finance-top") }
                                className={ activeTab === "finance-top" ? "nav-link custom-nav-link active" : "nav-link custom-nav-link" }
                                role="tab"
                                aria-selected={ activeTab === "finance-top" }
                            >
                                Overall
                            </button>
                        </li>
                        { Stores.map((store) => (
                            <li className="nav-item" key={ store.id }>
                                <button
                                    type="button"
                                    onClick={ () => handleTabClick(`finance-${store.id}`) }
                                    className={ activeTab === `finance-${store.id}` ? "nav-link custom-nav-link active" : "nav-link custom-nav-link" }
                                    role="tab"
                                    aria-selected={ activeTab === `finance-${store.id}` }
                                >
                                    { store.legal_name }
                                </button>
                            </li>
                        )) }
                        {/* Uncomment and modify the following code if you want to include the website finance */ }
                        {/* {website && (
                            <li className="nav-item">
                                <button
                                    type="button"
                                    onClick={() => handleTabClick("finance-website")}
                                    className={activeTab === "finance-website" ? "nav-link custom-nav-link active" : "nav-link custom-nav-link"}
                                    role="tab"
                                    aria-selected={activeTab === "finance-website"}
                                >
                                    {website.name} - website
                                </button>
                            </li>
                        )} */}
                    </ul>
                    <div className="tab-content">
                        { activeTab === "finance-top" && (
                            <div className="tab-pane fade active show" role="tabpanel">
                                <CompanyProducts CompanyProfile={ CompanyProfile } />
                            </div>
                        ) }
                        { Stores.map((store) => (
                            activeTab === `finance-${store.id}` && (
                                <div className="tab-pane fade active show" key={ store.id } role="tabpanel">
                                    <StoreProducts CompanyProfile={ CompanyProfile } storeId={ store.id } />
                                </div>
                            )
                        )) }
                        {/* Uncomment and modify the following code if you want to include the website finance */ }
                        {/* {activeTab === "finance-website" && (
                            <div className="tab-pane fade active show" role="tabpanel">
                                <WebsiteProducts CompanyProfile={CompanyProfile} storeId={website.name} storeType={website.type} />
                            </div>
                        )} */}
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.Auth.isAuthenticated,
    user: state.Auth.user,
})

export default connect(mapStateToProps, {})(MultiProducts)
