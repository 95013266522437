import React from 'react'
import { Helmet } from "react-helmet"
import { connect } from 'react-redux'
import Chart from "react-apexcharts"
import MainDashboardPagination from '../../Pagination/MainDashboardPagination'
import MonthlyProductPagination from '../../../modules/ProductPagination/MonthlyProductPagination'
const MonthFinance = (props) => {
    const FormData = props.kpiData
    const ExpectedData = props.expectedKPIs
    const currencyFormatter = props.currencyFormatter
    const numberFormatter = props.numberFormatter
    const SalesTrendingData = props.SalesTrendingData
    const ProductData = props.ProductsData
    const PageSize = props.pageSize
    const TotalProducts = props.TotalProducts
    const Month = props.month
    const MonthYear = props.monthYear
    const loadMonthProductList = props.loadMonthProductList
    const MonthProfile = props.MonthProfile
    const YearProfile = props.YearProfile
    const Sortby = props.Sortby
    const SortDirection = props.SortDirection
    const onSortChange = props.onSortChange
    const onPageSizeChange = props.onPageSizeChange
    const onSortDirectionChange = props.onSortDirectionChange
    const apexChartSeries = (seriesName, seriesData, seriesName1, seriesData1, seriesName2, seriesData2, seriesName3, seriesData3, seriesName4, seriesData4, seriesName5, seriesData5) => ([
        {
            name: seriesName,
            data: seriesData
        },
        {
            name: seriesName1,
            data: seriesData1
        },
        {
            name: seriesName2,
            data: seriesData2
        },
        {
            name: seriesName3,
            data: seriesData3
        },
        {
            name: seriesName4,
            data: seriesData4
        },
        {
            name: seriesName5,
            data: seriesData5
        },
    ])
    const apexChartOptions = (chartId, xaxis) =>
    ({
        chart: {
            id: chartId,
            width: "100%"

        },
        xaxis: {
            categories: xaxis
        },
        colors: ['#FF9F43', '#00CFE8', '#FF0000', '#fcb6e4', '#1B2850', '#28C76F'],
        dataLabels: {
            enabled: false,
            style: {
                fontSize: '12px',
                fontWeight: 'bold',
                color: 'black'
            },
            background: {
                enabled: true,
                foreColor: 'black',
                borderRadius: 2,
                padding: 4,
                opacity: 0.9,
                borderWidth: 1,
                borderColor: '#fff',
            },
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    enabled: false
                }
            }
        },
        grid: {
            show: false
        }

    })
    return (
        <>
            <Helmet>
                <link rel="stylesheet" href="../assets/css/style.css" />
                <script src="../assets/js/script.js" />
                <script src="../assets/plugins/apexchart/chart-data.js" />
            </Helmet>
            <div>
                <div className="row">
                    <div className="col-lg-2 col-sm-6 col-6">
                        <div className="dash-widget">
                            {/* <div className="dash-widgetimg">
								<span><img src="assets/img/icons/dash1.svg" alt="img"/></span>
							</div> */}
                            <div className="dash-widgetcontent">
                                { FormData?.kpis?.revenue || FormData?.kpis?.revenue >= 0 ? (
                                    <h5><span >{ currencyFormatter.format(FormData?.kpis?.revenue) }</span></h5>
                                ) :
                                    <h5>
                                        <span className="skeleton-loader"></span>
                                    </h5>
                                }
                                <h6>Total Revenue</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-6">
                        <div className="dash-widget dash1">
                            {/* <div className="dash-widgetimg">
								<span><img src="assets/img/icons/dash2.svg" alt="img"/></span>
							</div> */}
                            <div className="dash-widgetcontent">
                                { FormData?.kpis?.revenue || FormData?.kpis?.revenue >= 0 ? (
                                    <h5><span >{ currencyFormatter.format(FormData?.kpis?.net_revenue) }</span><br /> (<span >{ (Math.round(FormData?.kpis?.net_revenue / FormData?.kpis?.revenue * 100)) }%</span>)</h5>
                                ) :
                                    <h5>
                                        <span className="skeleton-loader"></span>
                                    </h5>
                                }
                                <h6>Total Net Revenue</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-6">
                        <div className="dash-widget dash2">
                            {/* <div className="dash-widgetimg">
								<span><img src="assets/img/icons/dash3.svg" alt="img"/></span>
							</div> */}
                            <div className="dash-widgetcontent">
                                { FormData?.kpis?.revenue || FormData?.kpis?.revenue >= 0 ? (
                                    <h5><span >{ currencyFormatter.format(FormData?.kpis?.fee) }</span><br /> (<span >{ (Math.round(Number(FormData?.kpis?.fee / Number(FormData?.kpis?.revenue) * 100))) }%</span>)</h5>
                                ) :
                                    <h5>
                                        <span className="skeleton-loader"></span>
                                    </h5>
                                }
                                <h6>Total Fee</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-6">
                        <div className="dash-widget dash3">
                            {/* <div className="dash-widgetimg">
								<span><img src="assets/img/icons/dash4.svg" alt="img"/></span>
							</div> */}
                            <div className="dash-widgetcontent">
                                { FormData?.kpis?.revenue || FormData?.kpis?.revenue >= 0 ? (
                                    <h5><span >{ currencyFormatter.format(Math.round(FormData?.returns_kpis?.revenue || 0)) }</span><br /> (<span >{ (Math.round(Number(FormData?.returns_kpis?.revenue || 0) / Number(FormData?.kpis?.revenue) * 100)) }%</span>)</h5>
                                ) :
                                    <h5>
                                        <span className="skeleton-loader"></span>
                                    </h5>
                                }
                                <h6>Sales Returns</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-6">
                        <div className="dash-widget dash3">
                            {/* <div className="dash-widgetimg">
								<span><img src="assets/img/icons/dash4.svg" alt="img"/></span>
							</div> */}
                            <div className="dash-widgetcontent">
                                { FormData?.kpis?.revenue || FormData?.kpis?.revenue >= 0 ? (
                                    <h5><span >{ currencyFormatter.format(Math.round(FormData?.kpis?.total_cost)) }</span><br /><span> ({ Math.round(FormData?.kpis?.total_cost / FormData?.kpis?.revenue * 100) }%)</span></h5>
                                ) :
                                    <h5>
                                        <span className="skeleton-loader"></span>
                                    </h5>
                                }
                                <h6>Total Cost</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-6">
                        <div className="dash-widget dash3">
                            {/* <div className="dash-widgetimg">
								<span><img src="assets/img/icons/dash4.svg" alt="img"/></span>
							</div> */}
                            <div className="dash-widgetcontent">
                                { FormData?.kpis?.revenue || FormData?.kpis?.revenue >= 0 ? (
                                    <h5><span >{ currencyFormatter.format(Math.round(FormData?.kpis?.total_total_profit)) }</span><br /><span> ({ Math.round(FormData?.kpis?.total_total_profit / FormData?.kpis?.revenue * 100) }%)</span></h5>
                                ) :
                                    <h5>
                                        <span className="skeleton-loader"></span>
                                    </h5>
                                }
                                <h6>Total Profit</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12 d-flex">
                        <div className="dash-count">
                            <div className="dash-counts">
                                { ExpectedData?.total_units || ExpectedData?.total_units >= 0 ? (
                                    <h4>{ numberFormatter.format(ExpectedData?.total_units) }</h4>
                                ) :
                                    <h5>
                                        <span className="skeleton-loader"></span>
                                    </h5>
                                }
                                <h5>Units Remaining</h5>
                            </div>
                            <div className="dash-imgs">
                                <i data-feather="user"></i>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12 d-flex">
                        <div className="dash-count das1">
                            <div className="dash-counts">
                                { ExpectedData?.total_units || ExpectedData?.total_units >= 0 ? (
                                    <h4>{ currencyFormatter.format(ExpectedData?.total_sales) }</h4>
                                ) :
                                    <h5>
                                        <span className="skeleton-loader"></span>
                                    </h5>
                                }
                                <h5>Expected Revenue</h5>
                            </div>
                            <div className="dash-imgs">
                                <i data-feather="user-check"></i>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12 d-flex">
                        <div className="dash-count das2">
                            <div className="dash-counts">
                                { ExpectedData?.total_units || ExpectedData?.total_units >= 0 ? (
                                    <h4>{ currencyFormatter.format(ExpectedData?.total_cost) }</h4>
                                ) :
                                    <h5>
                                        <span className="skeleton-loader"></span>
                                    </h5>
                                }
                                <h5>Expected Cost</h5>
                            </div>
                            <div className="dash-imgs">
                                <i data-feather="file-text"></i>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12 d-flex">
                        <div className="dash-count das3">
                            <div className="dash-counts">
                                { ExpectedData?.total_units || ExpectedData?.total_units >= 0 ? (
                                    <h4>{ currencyFormatter.format(ExpectedData?.total_total_profit) }</h4>
                                ) :
                                    <h5>
                                        <span className="skeleton-loader"></span>
                                    </h5>
                                }
                                <h5>Expected Profit</h5>
                            </div>
                            <div className="dash-imgs">
                                <i data-feather="file"></i>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Button trigger modal --> */ }


                {
                    FormData?.kpis?.revenue === 0 ? <></>
                        :
                        <>
                            <div className="row">
                                <div className="col-lg-12 col-sm-12 col-12 d-flex">
                                    <div className="card flex-fill">
                                        <div className="card-header pb-0 d-flex justify-content-between align-items-center">
                                            <h5 className="card-title mb-0">Sales</h5>
                                            {/* <div className="graph-sets">
                                            <ul>
                                                <li>
                                                    <span>Revenue</span>
                                                </li>
                                                <li>
                                                    <span>Quantity</span>
                                                </li>
                                            </ul>
                                            <div className="dropdown">
                                                <button className="btn btn-white btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                                    2022 <img src="assets/img/icons/dropdown.svg" alt="img" className="ms-2"/>
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <li>
                                                        <a href="#" className="dropdown-item">2022</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="dropdown-item">2021</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="dropdown-item">2020</a>
                                                    </li>												
                                                </ul>
                                            </div>
                                        </div> */}

                                        </div>
                                        <div className="card-body">
                                            {/* <div id="sales_charts"></div> */ }
                                            <Chart
                                                options={ apexChartOptions('sales-trending-data', [SalesTrendingData].map(x => x.map(a => (a?.trend))).flat(2)) }
                                                series={ apexChartSeries('Revenue', [SalesTrendingData].map(x => x.map(a => (a?.revenue))).flat(2), "Fee", [SalesTrendingData].map(x => x.map(a => (a?.fee))).flat(2), "Net Revenue", [SalesTrendingData].map(x => x.map(a => (a?.net_revenue))).flat(2), "Cost", [SalesTrendingData].map(x => x.map(a => (a?.total_cost))).flat(2), "Profit", [SalesTrendingData].map(x => x.map(a => (a?.total_total_profit))).flat(2)) }
                                                type="bar"
                                                width="100%"
                                                height="150%"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-0">
                                <div className="card-body">
                                    <div className="card-header pb-0 d-flex justify-content-between align-items-center">
                                        <h4 className="card-title">Day wise Breakdown</h4>
                                        <div className="graph-sets">
                                            {/* <div className="dropdown">
                                            <select className="form-select col-4" id="pageSize" value={PageSize} onChange={(e)=>{props.onPageSizeChange(e)}} aria-label="Range">
                                                <option value="5">5</option>
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </div> */}
                                            {/* <div className=''>
                                            <button onClick={(e) => {props.ExportBreakdownCsv(e)}} className="btn btn-md btn-primary">Export CSV</button>
                                        </div>  */}
                                        </div>
                                    </div>
                                    <div className="table-responsive dataview">
                                        <table className="table datatable">
                                            <thead>
                                                <tr>
                                                    <th>Day</th>
                                                    <th>Sales</th>
                                                    <th>Fee</th>
                                                    <th>Net Sales</th>
                                                    <th>FPS</th>
                                                    <th>Cost</th>
                                                    <th>Profit</th>
                                                    <th>POS</th>
                                                    <th>POC</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    SalesTrendingData.length > 0 ?
                                                        SalesTrendingData.map(
                                                            (key) => {
                                                                return (
                                                                    <>
                                                                        <tr key={ key }>
                                                                            <td>{ (key?.trend) }</td>
                                                                            <td>{ currencyFormatter.format(key?.revenue) }</td>
                                                                            <td>{ currencyFormatter.format(key?.fee) }</td>
                                                                            <td>{ currencyFormatter.format(key?.net_revenue) }</td>
                                                                            <td>
                                                                                {
                                                                                    Number(key?.fee / key?.revenue) < 79 ?
                                                                                        <span className="">
                                                                                            { numberFormatter.format(Number(key?.fee / key?.revenue)) } %
                                                                                        </span> :
                                                                                        Number(key?.fee / key?.revenue) > 79 && Number(key?.fee / key?.revenue) < 60 ?
                                                                                            <span className="">
                                                                                                { numberFormatter.format(Number(key?.fee / key?.revenue)) } %
                                                                                            </span>
                                                                                            :
                                                                                            <span className="">
                                                                                                { numberFormatter.format(Number(key?.fee / key?.revenue)) } %
                                                                                            </span>
                                                                                }
                                                                            </td>
                                                                            <td>{ currencyFormatter.format(key?.total_cost) }</td>
                                                                            <td>{ currencyFormatter.format(key?.revenue - key?.total_cost) }</td>
                                                                            {/* <td>{numberFormatter.format(Number((key?.revenue-key?.total_cost)/key?.revenue*100).toFixed(2))} %</td> */ }
                                                                            <td>
                                                                                {
                                                                                    Number((key?.revenue - key?.total_cost) / key?.revenue * 100).toFixed(2) > 79 ?
                                                                                        <span className="">
                                                                                            { numberFormatter.format(Number((key?.revenue - key?.total_cost) / key?.revenue * 100).toFixed(2)) } %
                                                                                        </span> :
                                                                                        Number((key?.revenue - key?.total_cost) / key?.revenue * 100).toFixed(2) < 79 && Number((key?.revenue - key?.total_cost) / key?.revenue * 100).toFixed(2) > 60 ?
                                                                                            <span className="">
                                                                                                { numberFormatter.format(Number((key?.revenue - key?.total_cost) / key?.revenue * 100).toFixed(2)) } %
                                                                                            </span>
                                                                                            :
                                                                                            <span className="">
                                                                                                { numberFormatter.format(Number((key?.revenue - key?.total_cost) / key?.revenue * 100).toFixed(2)) } %
                                                                                            </span>
                                                                                }
                                                                            </td>
                                                                            {/* <td>{numberFormatter.format(Number((key?.revenue-key?.total_cost)/(key?.total_cost)*100).toFixed(2))} %</td> */ }
                                                                            <td>
                                                                                {
                                                                                    Number((key?.revenue - key?.total_cost) / key?.total_cost * 100).toFixed(2) > 200 ?
                                                                                        <span className="">
                                                                                            { numberFormatter.format(Number((key?.revenue - key?.total_cost) / key?.total_cost * 100).toFixed(2)) } %
                                                                                        </span> :
                                                                                        Number((key?.revenue - key?.total_cost) / key?.total_cost * 100).toFixed(2) < 199 && Number((key?.revenue - key?.total_cost) / key?.revenue * 100).toFixed(2) > 100 ?
                                                                                            <span className="">
                                                                                                { numberFormatter.format(Number((key?.revenue - key?.total_cost) / key?.total_cost * 100).toFixed(2)) } %
                                                                                            </span>
                                                                                            :
                                                                                            <span className="">
                                                                                                { numberFormatter.format(Number((key?.revenue - key?.total_cost) / key?.total_cost * 100).toFixed(2)) } %
                                                                                            </span>
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })
                                                        :
                                                        Array.from({ length: 5 }).map((_, index) => (
                                                            <tr key={ index }>
                                                                <td><span className="skeleton-loader"></span></td>
                                                                <td><span className="skeleton-loader"></span></td>
                                                                <td><span className="skeleton-loader"></span></td>
                                                                <td><span className="skeleton-loader"></span></td>
                                                                <td><span className="skeleton-loader"></span></td>
                                                                <td><span className="skeleton-loader"></span></td>
                                                                <td><span className="skeleton-loader"></span></td>
                                                                <td><span className="skeleton-loader"></span></td>
                                                                <td><span className="skeleton-loader"></span></td>
                                                            </tr>
                                                        ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="card mb-0">
                                <div className="card-body">
                                    <div className="card-header pb-0 d-flex justify-content-between align-items-center">
                                        <h4 className="card-title">Product wise Breakdown</h4>
                                        <div className="graph-sets">
                                            <div className="dropdown mx-1">
                                                <button className="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    Page Size
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li><a className={ PageSize == 10 ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onPageSizeChange(e, "10") } } href="#">10</a></li>
                                                    <li><a className={ PageSize == 20 ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onPageSizeChange(e, "20") } } href="#">20</a></li>
                                                    <li><a className={ PageSize == 50 ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onPageSizeChange(e, "50") } } href="#">50</a></li>
                                                    <li><a className={ PageSize == 100 ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onPageSizeChange(e, "100") } } href="#">100</a></li>
                                                </ul>
                                            </div>
                                            <div className="dropdown">
                                                <button className="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    Sort by
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li><a className={ Sortby === "units" ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onSortChange(e, "units") } } href="#">Units</a></li>
                                                    <li><a className={ Sortby === "sales" ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onSortChange(e, "sales") } } href="#">Sales</a></li>
                                                    {/* <li><a className={Sortby === "cost" ? "dropdown-item active" : "dropdown-item"} onClick={(e)=>{onSortChange(e, "cost")}} href="#">Cost</a></li>
                                                <li><a className={Sortby === "total_profit" ? "dropdown-item active" : "dropdown-item"} onClick={(e)=>{onSortChange(e, "total_profit")}} href="#">Profit</a></li> */}
                                                </ul>
                                            </div>
                                            <div className='py-1'>
                                                <button
                                                    onClick={ (e) => { onSortDirectionChange(e, "up") } }
                                                    className={
                                                        SortDirection === "up" ?
                                                            'btn btn-primary mx-1 my-1'
                                                            :
                                                            'btn btn-outline-primary mx-1'
                                                    }
                                                >
                                                    <i class="fa-solid fa-arrow-up-wide-short"></i>
                                                </button>
                                            </div>
                                            <div className='py-1'>
                                                <button
                                                    onClick={ (e) => { onSortDirectionChange(e, "down") } }
                                                    className={
                                                        SortDirection === "down" ?
                                                            'btn btn-primary mx-1'
                                                            :
                                                            'btn btn-outline-primary mx-1 my-1'
                                                    }
                                                >
                                                    <i class="fa-solid fa-arrow-down-wide-short"></i>
                                                </button>
                                            </div>
                                            {/* <div className=''>
                                            <button onClick={(e) => {props.ExportProductBreakdownCsv(e)}} className="btn btn-md btn-primary">Export CSV</button>
                                        </div>  */}
                                        </div>
                                    </div>
                                    <div className="table-responsive text-wrap dataview">
                                        <table className="table datatable">
                                            <thead>
                                                <tr>
                                                    <th className='col-md-3'>Product</th>
                                                    <th className='col-md-3'>SKU</th>
                                                    <th className='col-md'>Sales</th>
                                                    <th className='col-md'>Fee</th>
                                                    <th className='col-md'>FPS</th>
                                                    <th className='col-md'>Net Sales</th>
                                                    <th className='col-md'>Units</th>
                                                    <th className='col-md'>Cost</th>
                                                    <th className='col-md'>Profit</th>
                                                    <th className='col-md'>POS</th>
                                                    <th className='col-md'>POC</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    ProductData.length > 0 ? ProductData.map(
                                                        (key) => {
                                                            return (
                                                                <>
                                                                    {
                                                                        key?.product_title?.substring(0, 30)
                                                                            ?
                                                                            <tr>
                                                                                <td>{ (key?.product_title?.substring(0, 40)) }...</td>
                                                                                <td>{ key?.sku }</td>
                                                                                <td>{ currencyFormatter.format(key['revenue']) }</td>
                                                                                <td>{ currencyFormatter.format(key['fee']) }</td>
                                                                                {/* <td>{numberFormatter.format(key['fee_per_sale'])}</td> */ }
                                                                                <td>
                                                                                    <span className="">
                                                                                        { numberFormatter.format(Number(key['fee_per_sale'])) } %
                                                                                    </span>

                                                                                </td>
                                                                                <td>{ currencyFormatter.format(key['net_revenue']) }</td>
                                                                                <td>{ numberFormatter.format(key['units']) }</td>
                                                                                <td>{ key['total_cost'] ? currencyFormatter.format(key['total_cost']) : currencyFormatter.format(0) }</td>
                                                                                <td>{ key['total_profit'] ? currencyFormatter.format(key['total_profit']) : currencyFormatter.format(0) }</td>
                                                                                {/* <td>{numberFormatter.format(Number(key['total_profit']/key['revenue']*100).toFixed(2))+ " %"}</td> */ }
                                                                                <td>
                                                                                    {
                                                                                        !key['total_profit'] ?
                                                                                            <span className="">
                                                                                                100 %
                                                                                            </span>
                                                                                            :
                                                                                            Number(key['total_profit'] / key['revenue'] * 100).toFixed(2) >= 80 ?
                                                                                                <span className="">
                                                                                                    { numberFormatter.format(Number(key['total_profit'] / key['revenue'] * 100).toFixed(2)) } %
                                                                                                </span> :
                                                                                                Number(key['total_profit'] / key['revenue'] * 100).toFixed(2) <= 79 && Number(key['total_profit'] / key['revenue'] * 100).toFixed(2) > 60 ?
                                                                                                    <span className="">
                                                                                                        { numberFormatter.format(Number(Math.round(key['total_profit'] / key['revenue'] * 100)).toFixed(2)) } %
                                                                                                    </span>
                                                                                                    :
                                                                                                    <span className="">
                                                                                                        { numberFormatter.format(Number(Math.round(key['total_profit'] / key['revenue'] * 100)).toFixed(2)) } %
                                                                                                    </span>
                                                                                    }
                                                                                </td>
                                                                                {/* <td>{numberFormatter.format(Number(key['total_profit']/(key['revenue']*0.2)*100).toFixed(2))+ " %"}</td> */ }
                                                                                <td>
                                                                                    {
                                                                                        !key['total_cost'] ?
                                                                                            <span className="">
                                                                                                100 %
                                                                                            </span>
                                                                                            :
                                                                                            Number(key['total_profit'] / (key['revenue'] * 0.2) * 100).toFixed(2) > 199 ?
                                                                                                <span className="">
                                                                                                    { numberFormatter.format(Math.round(key['total_profit'] / (key['total_cost'] * 0.2) * 100).toFixed(2)) } %
                                                                                                </span> :
                                                                                                Number(key['total_profit'] / (key['revenue'] * 0.2) * 100).toFixed(2) < 199 && Number(key['total_profit'] / (key['revenue'] * 0.2) * 100).toFixed(2) > 99 ?
                                                                                                    <span className="">
                                                                                                        { numberFormatter.format(Math.round(key['total_profit'] / (key['total_cost'] * 0.2) * 100).toFixed(2)) } %
                                                                                                    </span>
                                                                                                    :
                                                                                                    <span className="">
                                                                                                        { numberFormatter.format(Math.round(key['total_profit'] / (key['total_cost'] * 0.2) * 100).toFixed(2)) } %
                                                                                                    </span>
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                            :
                                                                            <>
                                                                            </>
                                                                    }
                                                                </>
                                                            )
                                                        }
                                                    ) :

                                                        Array.from({ length: PageSize }).map((_, index) => (
                                                            <tr key={ index }>
                                                                <td><span className="skeleton-loader"></span></td>
                                                                <td><span className="skeleton-loader"></span></td>
                                                                <td><span className="skeleton-loader"></span></td>
                                                                <td><span className="skeleton-loader"></span></td>
                                                                <td><span className="skeleton-loader"></span></td>
                                                                <td>
                                                                    <span className="skeleton-loader"></span>
                                                                </td>
                                                                <td><span className="skeleton-loader"></span></td>
                                                                <td><span className="skeleton-loader"></span></td>
                                                                <td><span className="skeleton-loader"></span></td>
                                                                <td><span className="skeleton-loader"></span></td>
                                                                <td><span className="skeleton-loader"></span></td>
                                                                <td><span className="skeleton-loader"></span></td>
                                                                <td><span className="skeleton-loader"></span></td>
                                                                <td><span className="skeleton-loader"></span></td>
                                                            </tr>
                                                        ))
                                                }
                                            </tbody>
                                        </table>
                                        <br />
                                        <div className='pagination justify-content-center'>
                                            {
                                                TotalProducts > 0 ?
                                                    <MonthlyProductPagination
                                                        currentPage={ props.currentPage }
                                                        setPage={ props.setCurrentPage }
                                                        totalPages={ props.TotalPages }
                                                        pageSize={ PageSize }
                                                        sBy={ Sortby }
                                                        month={ props.month }
                                                        year={ props.monthYear }
                                                        sDirection={ SortDirection }
                                                        onPageChange={ loadMonthProductList }
                                                    />
                                                    // <MainDashboardPagination  totalRecords={TotalProducts} pageLimit={Number(PageSize)} sortBy={Sortby} sortDirection={SortDirection} paginationDate={MonthYear+'-'+Month} onPageChanged={loadMonthProductList}  />
                                                    : <></>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                }
            </div>
        </>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.Auth.isAuthenticated,
    user: state.Auth.user,
})

export default connect(mapStateToProps)(MonthFinance)