import React, { useState } from 'react'
import Header from './Header'
import { useEffect } from 'react'
import Sidebar from './Sidebar'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { Helmet } from "react-helmet"
import '../assets/css/customstyles.css'
const DashboardLayout = ({ logo, TotalUnreadNotifications, CompanyProfile, isAuthenticated, loadSettings, user }) => {
    const [Loader, setLoader] = useState("block")
    let scriptEle = document.createElement("script")
    useEffect(() => {
        if (user) {

            document.querySelector("#body").classList.add("mini-sidebar")

            setTimeout(() => {
                setLoader("none")
            }, 3000)
            loadSettings()
        }

    }, [isAuthenticated, user])
    const location = useLocation()
    if (isAuthenticated === null || isAuthenticated === false) {
        return <Navigate to={ `/signin?next=${location.pathname}` } />
    }
    return (
        <>
            {
                user ?
                    <Helmet>
                        <link rel="stylesheet" href="../assets/css/style.css" />
                        <script src="../assets/js/script.js" />
                        <link rel="icon" href={ logo } />
                    </Helmet>
                    :
                    <></>
            }
            <div style={ { display: Loader } }>
                <div id="global-loader" >
                    <div className="whirly-loader"> </div>
                </div>
            </div>
            {
                user ?
                    <div className="main-wrapper" id="main-wrapper">
                        {/* Header */ }
                        <Header
                            CompanyLogo={ logo }
                        />
                        {/* Sidebar */ }
                        <div>

                        </div>
                        <Sidebar />
                        <div className="page-wrapper">
                            <Outlet />
                        </div>
                    </div>
                    : <></>
            }
        </>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.Auth.isAuthenticated,
    user: state.Auth.user,
})

export default connect(mapStateToProps)(DashboardLayout)