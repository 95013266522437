import axios from 'axios'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { NavLink, Navigate } from 'react-router-dom'
import SearchComponent from '../../Search'
import { Helmet } from 'react-helmet'
import Moment from 'moment'
const UserManagement = (props) => {
    const user = props.user
    const [UserList, setUserList] = useState([])
    // APIs
    const loadUsers = async (searchQuery) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify({
            'search_query': searchQuery
        })
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/users/list/`, Body, config)
            setUserList(response?.data?.users)
        } catch (error) {
            // console.log('first', error)
            //   setResponseError(error.response.statusText)
        }
    }
    // Search
    const [SearchQuery, setSearchQuery] = useState("")
    const onSearchInputChange = (e) => {
        e.preventDefault()
        setSearchQuery(e.target.value)
        loadUsers(e.target.value)
    }
    useEffect(() => {
        loadUsers(SearchQuery)
    }, [])

    if (!user?.is_superuser) {
        return <Navigate to="/" />
    }
    return (
        <div className='content'>
            <Helmet>
                <title>
                    User Accounts - Buybox
                </title>
            </Helmet>
            <div className="page-header">
                <div className="page-title">
                    <h4>Users Management</h4>
                    <h6>Manage Users</h6>
                </div>
            </div>
            <hr />
            <SearchComponent
                placeholder={ "User Name / Email" }
                SearchQuery={ SearchQuery }
                onSearchInputChange={ onSearchInputChange }
            />
            <hr />
            <div className="card mb-0">
                <div className="card-body">
                    <div className="card-header pb-0 d-flex justify-content-between align-items-center">
                        <h4 className="card-title">User List</h4>
                        <div className="graph-sets">
                            {/* <div className="dropdown px-1 w-50">
                                <label htmlFor="pageSize" className="form-label">Per Page</label>
                                <select className="form-select" id="pageSize" value={PageSize} onChange={(e)=>{props.onPageSizeChange(e)}} aria-label="Range">
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                            <div className="dropdown px-1 w-50">
                                <label htmlFor="pageSize" className="form-label">Sort By</label>
                                <select className="form-select" id="customFilter" value={props.OrderBy} onChange={(e)=>{props.onCustomOrderByChange(e)}}  aria-label="Range">
                                    <option value="title">Title</option>
                                    <option value="sales">Sales (In last 21 days)</option>
                                    <option value="buyBox">BuyBox</option>
                                    <option value="selling_price">Price</option>
                                    <option value="total_cost_per_unit">Cost</option>
                                    <option value="-title">Desc. Title</option>
                                    <option value="-sales">Desc. Sales (In last 21 days)</option>
                                    <option value="-buyBox">Desc. BuyBox</option>
                                    <option value="-selling_price">Desc. Price</option>
                                    <option value="-total_cost_per_unit">Desc. Cost</option>
                                </select>
                            </div> */}
                            <div className='px-1 w-100'>
                                <NavLink to="/admin/users/staff" className='btn btn-primary'>Staff Accounts Management</NavLink>
                            </div>
                        </div>
                    </div>

                    <div className="table-responsive text-wrap">
                        <table className="table mb-0">
                            <thead>

                                <tr>
                                    <th>
                                        Name
                                    </th>
                                    <th >
                                        Email
                                    </th>
                                    <th >
                                        Applications
                                    </th>
                                    <th>
                                        Total Stores
                                    </th>
                                    <th>
                                        Type
                                    </th>
                                    <th>
                                        Status
                                    </th>
                                    <th>
                                        Subscribed To
                                    </th>
                                    <th>
                                        Valid Until
                                    </th>
                                    <th>
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    UserList.map(
                                        (key) => {
                                            return (
                                                <>
                                                    <tr>
                                                        <td>
                                                            { key?.first_name + " " + key?.last_name }
                                                        </td>
                                                        <td>
                                                            { key?.email }
                                                        </td>
                                                        <td>
                                                            2
                                                        </td>
                                                        <td>
                                                            { key?.total_stores }
                                                        </td>
                                                        <td>
                                                            { key?.is_superuser ? 'Admin' : 'User' }
                                                        </td>
                                                        <td>
                                                            {
                                                                key?.is_active == 1 ?
                                                                    <span >
                                                                        Active
                                                                    </span>
                                                                    : <>Not Active</>
                                                            }
                                                        </td>
                                                        <td>
                                                            { key?.subscribed ? key?.subscribed : 'Basic Trail' }
                                                        </td>
                                                        <td>
                                                            { key?.valid_until }
                                                        </td>
                                                        <td>
                                                            {/* <NavLink 
                                                        to={{pathname:"/admin/users/edit/user"}} 
                                                        state={
                                                            {
                                                                account : key?.id
                                                            }
                                                        } 
                                                        className="me-3" href="product-details.html">
                                                        <img src="../../assets/img/icons/eye.svg" alt="img"/>
                                                    </NavLink> */}
                                                            <NavLink
                                                                to={ { pathname: "/users/edit/user" } }
                                                                state={
                                                                    {
                                                                        account: key?.id
                                                                    }
                                                                }
                                                                className="me-3" >
                                                                <img src="../../assets/img/icons/edit.svg" alt="img" />
                                                            </NavLink>
                                                        </td>
                                                    </tr>
                                                </>
                                            )
                                        })
                                }
                            </tbody>
                        </table>
                        <br />
                        <div className='pagination justify-content-center'>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.Auth.isAuthenticated,
    user: state.Auth.user
})

export default connect(mapStateToProps)(UserManagement)