import React from 'react'

const SearchComponent = (props) => {


    return (
        <>
            <div className="card">
                <div className="card-body">
                    <form onSubmit={ (e) => { props.searchForm(e) } }>
                        <div className='row'>
                            <div className='col-lg-10 col-md-6 col-sm-12'>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="defaultFormControlInput"
                                    placeholder={ props.placeholder }
                                    onChange={ (e) => { props.onSearchInputChange(e) } }
                                    value={ props.SearchQuery }
                                    aria-describedby="defaultFormControlHelp"
                                />
                            </div>
                            <div className='col-lg-2 col-md-6 col-sm-12'>
                                <button className='btn btn-primary w-100' type='submit'>Search</button>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </>
    )
}

export default SearchComponent