import React from 'react'

const ProductsTable = (props) => {
    const ProductData = props.ProductData
    const PageSize = props.PageSize
    const currencyFormatter = props.currencyFormatter
    const numberFormatter = props.numberFormatter
    return (
        <table className="table datatable ">
            <thead>
                <tr>
                    <th>Product Name</th>
                    <th>Store</th>
                    <th>Units</th>
                    <th>Revenue</th>
                    <th>Total Fee</th>
                    <th>Net Revenue</th>
                    <th>Cost</th>
                    <th>Profit</th>
                    {/* <th>PpS</th>
                        <th>PpC</th> */}
                </tr>
            </thead>
            <tbody>

                {
                    ProductData.length > 0 ?
                        ProductData.map(
                            (key, i) => {
                                return (
                                    <>
                                        {
                                            key?.product_title ?
                                                <tr key={ i }>
                                                    <td className="productimgname">
                                                        <a href={ key.product_url } target='_blank'>{ key.product_title } - ({ key.offer_id })</a>
                                                    </td>
                                                    <td>
                                                        { key?.store }
                                                    </td>
                                                    <td>
                                                        <span>
                                                            { numberFormatter.format(key.units) } Units
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span>
                                                            { currencyFormatter.format(key.revenue) }
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span>
                                                            { currencyFormatter.format(key.fee) }
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span>
                                                            { currencyFormatter.format(key.net_revenue) }
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span>
                                                            { key.product_total_cost ? currencyFormatter.format(key.product_total_cost) : currencyFormatter.format(0) }
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span>
                                                            { currencyFormatter.format(key.product_total_profit) }
                                                        </span>
                                                    </td>
                                                    {/* <td>
                                                                            <span>
                                                                                <span className="">
                                                                                    { numberFormatter.format(key?.profit_per_sale || 0) }%
                                                                                </span>

                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <span>
                                                                                <span className="">
                                                                                    { numberFormatter.format(key?.profit_per_cost || 0) }%
                                                                                </span>

                                                                            </span>
                                                                        </td> */}
                                                </tr>
                                                :
                                                <></>
                                        }
                                    </>
                                )
                            }
                        )
                        :

                        Array.from({ length: PageSize }).map((_, index) => (
                            <tr key={ index }>
                                <td className="productimgname">
                                    <h5>
                                        <span className="skeleton-loader"></span>
                                    </h5>
                                </td>
                                <td>
                                    <h5>
                                        <span className="skeleton-loader"></span>
                                    </h5>
                                </td>
                                <td>
                                    <h5>
                                        <span className="skeleton-loader"></span>
                                    </h5>
                                </td>
                                <td>
                                    <h5>
                                        <span className="skeleton-loader"></span>
                                    </h5>
                                </td>
                                <td>
                                    <h5>
                                        <span className="skeleton-loader"></span>
                                    </h5>
                                </td>
                                <td>
                                    <h5>
                                        <span className="skeleton-loader"></span>
                                    </h5>
                                </td>
                                <td>
                                    <h5>
                                        <span className="skeleton-loader"></span>
                                    </h5>
                                </td>
                                <td>
                                    <h5>
                                        <span className="skeleton-loader"></span>
                                    </h5>
                                </td>
                            </tr>
                        ))
                }

            </tbody>
        </table>
    )
}

export default ProductsTable