import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

const Notifications = (props) => {
    const user = props.user
    const CompanyProfile = props.CompanyProfile
    const [LoadingVisibility, setLoadingVisibility] = useState("none")
    const [dataVisibility, setDataVisibility] = useState("block")
    const [NextLink, setNextLink] = useState("")
    const [PreviousLink, setPreviousLink] = useState("")
    const [NotificationsData, setNotificationsData] = useState([])
    const LoadNotificationsData = async () => {
        setLoadingVisibility("block")
        setDataVisibility("none")
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify({
            "page_size": 50,
            "page_number": 1
        })

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v2/notifications/get/`, Body, config)
            setNotificationsData(response.data['results'])
            setPreviousLink(response.data['previous'])
            setNextLink(response.data['next'])
        } catch (error) {
            // console.log('first', error)
            //   setResponseError(error.response.statusText)
        }
        setLoadingVisibility("none")
        setDataVisibility("block")
    }
    const formatter = new Intl.NumberFormat('en-ZA', {
        style: 'currency',
        currency: 'ZAR',

        // These options are needed to round to whole numbers if that's what you want.
        minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    })

    const LoadNextNotificationsData = async () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify({
            "account": user.id,
            "dashboard": 1
        })

        try {
            const response = await axios.post(NextLink, Body, config)
            // await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/finance/currentMonthSales/`,config)
            // console.log(response.data)
            setNotificationsData(response.data['results'])
            setPreviousLink(response.data['previous'])
            setNextLink(response.data['next'])
            // setSalesTrendingData(response.data['stats']['sales_trending_data'])
            //   console.log('Users', JSON.stringify(response.data.Data))
        } catch (error) {
            // console.log('first', error)
            //   setResponseError(error.response.statusText)
        }

    }

    const LoadPreviousNotificationsData = async () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify({
            "account": user.id,
            "dashboard": 1,
            "page_size": 10,
            "page_number": 1
        })

        try {
            const response = await axios.post(PreviousLink, Body, config)
            // await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/finance/currentMonthSales/`,config)
            // console.log(response.data['stats']['JHB'])
            setNotificationsData(response.data['results'])
            setPreviousLink(response.data['previous'])
            setNextLink(response.data['next'])
            // setSalesTrendingData(response.data['stats']['sales_trending_data'])
            //   console.log('Users', JSON.stringify(response.data.Data))
        } catch (error) {
            // console.log('first', error)
            //   setResponseError(error.response.statusText)
        }
    }

    const numberFormatter = new Intl.NumberFormat('en-ZA', {
        // These options are needed to round to whole numbers if that's what you want.
        minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    const formatDateTime = (dateString) => {
        const options = { year: "numeric", month: "short", day: "numeric", hour: '2-digit', minute: '2-digit', hour12: true }
        return new Date(dateString + "+02:00").toLocaleString(Intl.NumberFormat('en-ZA'), options)
        // return new Date(dateString).toLocaleDateString(Intl.NumberFormat('en-ZA'), options)
    }
    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "short", day: "numeric", hour: '2-digit', minute: '2-digit', hour12: true }
        // return new Date(dateString+"+02:00").toLocaleString(Intl.NumberFormat('en-ZA'), options)
        return new Date(dateString).toLocaleDateString(Intl.NumberFormat('en-ZA'), options)
    }
    const onNextClick = (e) => {
        e.preventDefault();
        LoadNextNotificationsData();
    }
    const onPreviousClick = (e) => {
        e.preventDefault();
        LoadPreviousNotificationsData();
    }
    useEffect(() => {
        LoadNotificationsData()
        const interval = setInterval(() => { LoadNotificationsData() }, 20000)
        return () => {
            clearInterval(interval);
        }
    }, [])
    return (
        <>
            <Helmet>

                <title>{ CompanyProfile['companyName'] } Notifications</title>
                <meta
                    name="description"
                    content={ `${CompanyProfile['companyName']} Notifications` }
                />
                <link rel="stylesheet" href="../../assets/css/style.css" />
                <link rel="stylesheet" href="../../assets/css/customstyles.css" />
                <script src="../assets/js/script.js" />
            </Helmet>
            <div style={ { display: LoadingVisibility } }>
                <div id="global-loader" >
                    <div className="whirly-loader"> </div>
                </div>
            </div>
            <div className="content" style={ { display: dataVisibility } }>
                <div className="page-header">
                    <div className="page-title">
                        <h4>All Notifications</h4>
                        <h6>View your all activities</h6>
                    </div>
                </div>
                <div className="activity">
                    <div className="activity-box">
                        <ul className="activity-list">
                            {
                                NotificationsData ? NotificationsData.map(
                                    (key, index) => {
                                        return (
                                            <>
                                                <li className={
                                                    key?.type == "System" ?
                                                        "notification-message bg-dark text-light"
                                                        :
                                                        key?.type == "Staff" ?
                                                            "notification-message bg-success text-light"
                                                            :
                                                            "notification-message"
                                                } key={ index }>
                                                    <div className={
                                                        key?.type == "System" ?
                                                            " bg-dark text-light"
                                                            :
                                                            key?.type == "Staff" ?
                                                                " bg-success text-light"
                                                                :
                                                                ""
                                                    }>
                                                        <div className="timeline-content">
                                                            {
                                                                key?.type == "System" ?
                                                                    <>
                                                                        <a href="#"
                                                                            className={
                                                                                key?.type == "System" ?
                                                                                    "name text-light"
                                                                                    :
                                                                                    key?.type == "System" ?
                                                                                        "name text-light"
                                                                                        :
                                                                                        "name"
                                                                            }
                                                                        >{ key.title }</a>
                                                                        <p>
                                                                            { key.message }
                                                                        </p>
                                                                        {
                                                                            key.downloading_link ?
                                                                                <>
                                                                                    <a target='_blank' href={ `${process.env.REACT_APP_BACKEND_URL}/${key.downloading_link}` } className="btn btn-primary btn-lg" style={ { color: 'white' } }>Download</a>
                                                                                </>
                                                                                :
                                                                                <></>
                                                                        }
                                                                        {
                                                                            key?.log ?
                                                                                <>
                                                                                    <NavLink to="/logs/details"
                                                                                        state={
                                                                                            {
                                                                                                log_id: key?.log
                                                                                            }
                                                                                        } className="btn btn-primary btn-lg text-light">View Logs for { key?.log_name }</NavLink>
                                                                                </>
                                                                                :
                                                                                <></>
                                                                        }
                                                                    </>
                                                                    :
                                                                    key?.type == "Staff" ?
                                                                        <>
                                                                            <a href="#" className="name">{ key.title }</a>
                                                                            <p>
                                                                                { key.message }
                                                                            </p>
                                                                        </>

                                                                        :
                                                                        key?.type == "Admin" ?
                                                                            <>
                                                                                <span className="name">{ key.title } { key.user_name }</span>    { key.message }  { key.plan ? <span className="noti-title"> { key.plan }</span> : "" }
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <a href="#" className="name">{ key.title }</a>
                                                                                <p>
                                                                                    { key.message }
                                                                                </p>
                                                                            </>
                                                            }
                                                            <span className={
                                                                key?.type == "System" ?
                                                                    "time text-light"
                                                                    :
                                                                    key?.type == "System" ?
                                                                        "time text-light"
                                                                        :
                                                                        "time"
                                                            }>{ key.time }</span>
                                                        </div>
                                                    </div>
                                                </li>
                                            </>
                                        )
                                    }
                                ) : <></>
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = state => ({
    isAuthenticated: state.Auth.isAuthenticated,
    user: state.Auth.user,
})

export default connect(mapStateToProps, {})(Notifications)