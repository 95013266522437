import React, { useEffect, useState } from 'react'
import { currencyFormatter, numberFormatter } from '../../../../modules/formatter'

const ProductCostModal = (props) => {
    const storeId = props.storeId
    const productId = props.productId
    const ProductInfo = props.ProductInfo
    const CostInfo = props.CostInfo
    return (
        <>
            <div className="modal fade" id={ "productCostModal-" + storeId } tabProductCostModal="-1" aria-hidden="true">
                <div className="modal-dialog  modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div style={ { textAlign: "center" } }>
                                <h4 className="modal-title" id="productCostModalTitle" style={ { textAlign: "center" } }><span className="text-muted fw-light">Product /</span> Details and Cost Details</h4>
                            </div>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body" style={ { wordBreak: "break-all", minWidth: '100px' } }>
                            <div className="row">
                                <div className='container'>
                                    <div className="row">
                                        <div className="col-md-3 py-3 px-3">
                                            <img className="card-img card-img-left" width="100%" src={ ProductInfo?.image_url } alt="Card image" />
                                        </div>
                                        <div className="col-md-9 py-3 px-3">
                                            <div className="card-body">
                                                <h5 className="card-title">{ ProductInfo?.title }</h5>
                                                <div className='row'>
                                                    <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12'>
                                                        <div className="row mb-3">
                                                            <p className="col-sm-6" ><b>SKU</b></p>
                                                            <p className="col-sm-6" >{ ProductInfo?.sku }</p>
                                                        </div>

                                                    </div>
                                                    <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12'>

                                                        <div className="row mb-3">
                                                            <p className="col-sm-6" ><b>Product Label</b></p>
                                                            <p className="col-sm-6" >{ ProductInfo?.product_label_number }</p>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <p className="col-sm-6" ><b>Takealot URL</b></p>
                                                        <a href={ ProductInfo?.offer_url } target="_blank" className="col-sm-6" >{ ProductInfo?.offer_url }</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <form onSubmit={ (e) => { props.onCostInfoSubmit(e) } } className='row'>
                                            <label className="col-form-label h4" style={ { textAlign: "center" } } htmlFor="basic-default-name">Currency ZAR</label>
                                            <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12'>
                                                <div className="row mb-3">
                                                    <label className="col-sm-6 col-form-label" htmlFor="basic-default-name">Supplier</label>
                                                    <div className="col-sm-6">
                                                        <div className="input-group">
                                                            <input type="text" min={ 1 } className="form-control" value={ CostInfo?.supplier } name="supplier" onChange={ (e) => { props.onCostInfoUpdate(e) } } id="basic-default-name" placeholder='Supplier Name' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12'>
                                                <div className="row mb-3">
                                                    <label className="col-sm-6 col-form-label" htmlFor="basic-default-name">Total Unit Ordered</label>
                                                    <div className="col-sm-6">
                                                        <div className="input-group">
                                                            <span className="input-group-text">R</span>
                                                            <input type="number" className="form-control" value={ CostInfo?.total_units_ordered } name="total_units_ordered" onChange={ (e) => { props.onCostInfoUpdate(e) } } id="basic-default-name" placeholder='Cost Per Unit' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12'>
                                                <div className="row mb-3">
                                                    <label className="col-sm-6 col-form-label" htmlFor="basic-default-company">Cost per Unit (USD)</label>
                                                    <div className="col-sm-6">
                                                        <div className="input-group">
                                                            <span className="input-group-text">$</span>
                                                            <input type="number" className="form-control" value={ CostInfo?.cost_per_unit_usd } name="cost_per_unit_usd" onChange={ (e) => { props.onCostInfoUpdate(e) } } id="basic-default-name" placeholder='Cost Per Unit' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12'>
                                                <div className="row mb-3">
                                                    <label className="col-sm-6 col-form-label" htmlFor="basic-default-company">Cost per Unit</label>
                                                    <div className="col-sm-6">
                                                        <div className="input-group">
                                                            <span className="input-group-text">R</span>
                                                            <input type="number" className="form-control" value={ CostInfo?.cost_per_unit } name="cost_per_unit" onChange={ (e) => { props.onCostInfoUpdate(e) } } id="basic-default-name" placeholder='Cost Per Unit' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12'>
                                                <div className="row mb-3">
                                                    <label className="col-sm-6 col-form-label" htmlFor="basic-default-company">Storage Cost per Unit</label>
                                                    <div className="col-sm-6">
                                                        <div className="input-group">
                                                            <span className="input-group-text">R</span>
                                                            <input type="number" className="form-control" value={ CostInfo?.storage_cost_per_unit } name="storage_cost_per_unit" onChange={ (e) => { props.onCostInfoUpdate(e) } } id="basic-default-name" placeholder='Cost Per Unit' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12'>
                                                <div className="row mb-3">
                                                    <label className="col-sm-6 col-form-label" htmlFor="basic-default-company">Shipment Cost per Unit (to Takealot)</label>
                                                    <div className="col-sm-6">
                                                        <div className="input-group">
                                                            <span className="input-group-text">R</span>
                                                            <input type="number" className="form-control" value={ CostInfo?.shipment_cost_per_unit } name="shipment_cost_per_unit" onChange={ (e) => { props.onCostInfoUpdate(e) } } id="basic-default-name" placeholder='Cost Per Unit' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-xl-12 col-lg-6 col-md-12 col-sm-12 col-xs-12'>
                                                <div className="row mb-3">
                                                    <label className="col-sm-6 col-form-label" htmlFor="basic-default-company">Total Cost / Unit</label>
                                                    <div className="col-sm-6">
                                                        <div className="input-group">
                                                            <label>{ currencyFormatter('en-ZA', 'ZAR').format(Number(CostInfo?.cost_per_unit) + Number(CostInfo?.shipment_cost_per_unit) + Number(CostInfo?.storage_cost_per_unit)) }</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="" style={ { textAlign: "center" } }>
                                                </div>
                                            </div>
                                            <button className="col-12 btn btn-sm btn-primary ">Update Cost</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductCostModal