import React, { useState } from 'react'
import LoginImage from '../../assets/img/login.jpg'
import Logo from '../../assets/img/companyLogo.png'
import LogoWhite from '../../assets/img/logo-white.png'
import {resetPasswordConfirm} from '../../Actions/Auth'
import MailIcon from '../../assets/img/icons/mail.svg'
import { connect } from 'react-redux'
import {Navigate, NavLink, useSearchParams} from 'react-router-dom'
import axios from 'axios'

const ResetPasswordConfirm = ({resetPasswordConfirm, isAuthenticated}) => {
    const [eye, seteye] = useState(true)
    const [searchParams, setSearchParams] = useSearchParams()
    const [FormData, setFormData] = useState({
        new_password: "",
        re_new_password: "" 
    })
    const [errorData, setErrorData] = useState({
        status: "",
        message: ""
    })
    const [SuccessData, setSuccessData] = useState({
        status: "",
        message: ""
    })
    const [RequestSent, setRequestSent] = useState(false)
    const [SuccessVisibilty, setSuccessVisibilty] = useState("none")
    const [SubmissionErrorVisibilty, setSubmissionErrorVisibilty] = useState("none")
    const {
        new_password, re_new_password
    } = FormData
    const onChange = e => setFormData({...FormData, [e.target.name]: e.target.value})
    const [errors, seterrors] = useState({
        new_password: "",
        re_new_password: ""
    })
    const onEyeClick = () =>{
        seteye(!eye)
    }
    const validateToken = async (uid, token) => {
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios.defaults.xsrfCookieName = "csrftoken"
    
        const config = {
          headers: {
              'Content-Type' : 'application/json',
              "X-CSRFToken": "csrfToken"
          }
        }
        const Body = JSON.stringify({
            "uid":uid, 
            "token":token
        })
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/email/validateUIDToken/`, Body ,config)
        }catch (error) {
    
        }
    }
    const [LoadingVisibility, setLoadingVisibility] = useState("none")
    const onSubmit = async(e) => {
        e.preventDefault()
        const uid = searchParams.get("uid")
        const token = searchParams.get("token")
        if (new_password == re_new_password){
        const response = await resetPasswordConfirm(uid, token, new_password, re_new_password)
        if (response.status === 400) {
            if (response.data['new_password']) {
                setErrorData({
                    status: "API Error",
                    message: response.data['new_password']  ,
                    errors: ""
                })
            }
                if (response.data['token']) {
                setErrorData({
                    status: "Expired Token",
                    message: response.data['token']  ,
                    errors: ""
                })
                setTimeout(() => {
                    setRequestSent(true)
                }, 2000)
            }

            setSubmissionErrorVisibilty("block")
            setTimeout(() => {
            setSubmissionErrorVisibilty("none")
            }, 5000)
        } else if (response.status === 204){
            setSuccessData({
                status: "Success",
                message: "You password is now reset."
            })
            setSuccessVisibilty("block")
            setSubmissionErrorVisibilty("none")
            setTimeout(() => {
                setRequestSent(true)
                setSuccessVisibilty("none")
            }, 5000)
        }
        // console.log(response)
        } else {
        setErrorData({
            status: "Password Matching Error",
            message: "Passwords don't match",
            errors: ""
        })
        setSubmissionErrorVisibilty("block")
        setTimeout(() => {
            setSubmissionErrorVisibilty("none")
        }, 5000)
        }
    }
    
    if (RequestSent) {
        return <Navigate to='/signin' />
    }
    if (isAuthenticated == true) {
        return <Navigate to="/" />;
    }
    return (
        <>
            <div style={{display: LoadingVisibility}}>
                <div id="global-loader" >
                    <div className="whirly-loader"> </div>
                </div>
            </div>
            <div className="main-wrapper">
                <div className="account-content">
                    <div className="login-wrapper">
                        <div className="login-content">
                            <div className="login-userset">
                                <form onSubmit={(e)=>{onSubmit(e)}}>
                                    {/* <div className="login-logo">
                                        <img src={Logo} alt="img" />
                                    </div> */}
                                    <div className="login-userheading">
                                        <h3>Reset Password</h3>
                                    </div>
                                    <div className="alert alert-success text-center" style={{display: SuccessVisibilty}} role="alert">
                                        <p><b>{SuccessData.status}: {SuccessData.message} </b></p>
                                    </div>
                                    <div className="alert alert-danger text-center" style={{display: SubmissionErrorVisibilty}} role="alert">
                                        <p><b>Error {errorData.status}: {errorData.message} </b></p>
                                    </div>
                                    <div className="form-login">
                                        <label>New Passowrd</label>
                                        <div className="form-addons">
                                            <input
                                                type="password"
                                                value={new_password}
                                                name='new_password'
                                                onChange={(e)=>{onChange(e)}}
                                                className={` ${errors.new_password ? 'is-invalid' : ''}`}
                                                placeholder="Enter your new password"
                                            />
                                            <img src={MailIcon} alt="img" />
                                            <div className="invalid-feedback">{errors.new_password?.message}</div>

                                        </div>
                                    </div>
                                    <div className="form-login">
                                        <label>Confirm New Password</label>
                                        <div className="form-addons">
                                            <input
                                                type="password"
                                                value={re_new_password}
                                                name='re_new_password'
                                                onChange={(e)=>{onChange(e)}}
                                                className={` ${errors.re_new_password ? 'is-invalid' : ''}`}
                                                placeholder="Enter your new password"
                                            />
                                            <img src={MailIcon} alt="img" />
                                            <div className="invalid-feedback">{errors.re_new_password?.message}</div>

                                        </div>
                                    </div>
                                    <div className="form-login">
                                        <div className="alreadyuser">
                                            <h4>
                                                <NavLink to="/signin" className="hover-a">
                                                    Sign In?
                                                </NavLink>
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="form-login">
                                        <button type='submit' className="btn btn-login" >
                                            Reset Password
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="login-img">
                            <img src={LoginImage} alt="img" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.Auth.isAuthenticated,
})
  
  
export default connect(mapStateToProps, {resetPasswordConfirm})(ResetPasswordConfirm)