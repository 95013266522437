import React, { useState } from 'react'
import LoginImage from '../../assets/img/login.jpg'
import Logo from '../../assets/img/companyLogo.png'
import LogoWhite from '../../assets/img/logo-white.png'
import MailIcon from '../../assets/img/icons/mail.svg'
import { connect } from 'react-redux'
import {Navigate, NavLink} from 'react-router-dom'
import axios from 'axios'
import Swal from 'sweetalert2'
const SignUp = ({isAuthenticated}) => {
    const [eye, seteye] = useState(true)
    const [FormData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        re_password: "",
        is_superuser: false
    })
    const [errorData, seterrorData] = useState({
        status: "",
        message: ""
    })
    const [SuccessVisibilty, setSuccessVisibilty] = useState("none")
    const [SuccessData, setSuccessData] = useState("")
    const [RequestStatus, setRequestStatus] = useState(false)
    const [SubmissionErrorVisibilty, setSubmissionErrorVisibilty] = useState("none")
    const {
        first_name,
        last_name,
        email,
        password,
        re_password,
    } = FormData
    const onChange = e => setFormData({...FormData, [e.target.name]: e.target.value})
    const [errors, seterrors] = useState({
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        re_password: ""
    })
    const onEyeClick = () =>{
        seteye(!eye)
    }
    const [LoadingVisibility, setLoadingVisibility] = useState("none")
    const signUser = async () => {
        if (password !== re_password) {
            seterrorData({
                status: 404,
                message: "Password do not match"
            })
            
            setSubmissionErrorVisibilty("block");
            setTimeout(() => {
                setSubmissionErrorVisibilty("none")
            }, 5000)
        
        } else {

            setLoadingVisibility("block")
            
            const config = {
                headers: {
                    "Content-Type": "application/json"
                },
            }
        
            const Body = JSON.stringify(FormData)
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_BACKEND_URL}/auth/users/`,
                    Body,
                    config
                )
                Swal.fire({
                    position: "bottom-end",
                    type: "success",
                    title: "Confirmation Email has been sent to your given email.",
                    showConfirmButton: !1,
                    timer: 1500,
                    confirmButtonClass: "btn btn-primary",
                    buttonsStyling: !1,
                })
                setSuccessData({
                    message: "Confirmation Email has been sent to your given email."
                })
                setSubmissionErrorVisibilty("none");
                setSuccessVisibilty("block")
                setTimeout(() => {
                    setSuccessVisibilty("none")
                    setRequestStatus(true)
                }, 3000)
            } catch (error) {
                if (error.response.data['email']) {
                    seterrorData({
                        status: error.response.status,
                        message: error.response.data['email'],
                        errors: error.response.errors,
                    })
                    Swal.fire({
                        position: "bottom-end",
                        type: "success",
                        title: "Error",
                        html: `<b>Email:</b>${error.response.data['email']}`,
                        showConfirmButton: !1,
                        timer: 1500,
                        confirmButtonClass: "btn btn-primary",
                        buttonsStyling: !1,
                    })
                    
                }
                if (error.response.data['password']) {
                    seterrorData({
                        status: error.response.status,
                        message: error.response.data['password'],
                        errors: error.response.errors,
                    })
                    Swal.fire({
                        position: "bottom-end",
                        type: "success",
                        title: "Error",
                        html: `<b>Password:</b>${error.response.data['password']}`,
                        showConfirmButton: !1,
                        timer: 1500,
                        confirmButtonClass: "btn btn-primary",
                        buttonsStyling: !1,
                    })
                    
                }
                if (error.response.data['non_field_errors']) {
                    seterrorData({
                        status: error.response.status,
                        message: error.response.data['non_field_errors'],
                        errors: error.response.errors,
                    })
                    Swal.fire({
                        position: "bottom-end",
                        type: "success",
                        title: "Error",
                        html: `<b>Other Errors:</b>${error.response.data['non_field_errors']}`,
                        showConfirmButton: !1,
                        timer: 1500,
                        confirmButtonClass: "btn btn-primary",
                        buttonsStyling: !1,
                    })
                    
                }
                
                setSubmissionErrorVisibilty("block");
                setTimeout(() => {
                    setSubmissionErrorVisibilty("none")
                }, 5000)
            
            }
            setLoadingVisibility("none")
        }
    }
    const onSubmit = (e) => {
        e.preventDefault()
        signUser()
    }
    if (RequestStatus == true) {
        return <Navigate to="/signin" />;
    }
    if (isAuthenticated == true) {
        return <Navigate to="/" />;
    }
    return (
        <>
            <div style={{display: LoadingVisibility}}>
                <div id="global-loader" >
                    <div className="whirly-loader"> </div>
                </div>
            </div>
            <div className="main-wrapper">
                <div className="account-content">
                    <div className="login-wrapper">
                        <div className="login-content">
                            <div className="login-userset">
                                <form onSubmit={(e)=>{onSubmit(e)}}>
                                    {/* <div className="login-logo">
                                        <img src={Logo} alt="img" />
                                    </div> */}
                                    <div className="login-userheading">
                                        <h3>Sign Up</h3>
                                        <h4>Please create your account to get detailed Analytics</h4>
                                    </div>
                                    <div className="alert alert-success text-center" style={{display: SuccessVisibilty}} role="alert">
                                        <p><b>{SuccessData.message} </b></p>
                                    </div>
                                    <div className="alert alert-danger text-center" style={{display: SubmissionErrorVisibilty}} role="alert">
                                        <p><b>Error {errorData.status}: {errorData.message} </b></p>
                                    </div>
                                    <div className="form-login">
                                        <label>First Name</label>
                                        <div className="form-addons">
                                            <input
                                                type="text"
                                                value={first_name}
                                                name='first_name'
                                                onChange={(e)=>{onChange(e)}}
                                                className={` ${errors.firstname ? 'is-invalid' : ''}`}
                                                placeholder="Enter your first name"
                                            />
                                            <img src={MailIcon} alt="img" />
                                            <div className="invalid-feedback">{errors.firstname?.message}</div>
                                        </div>
                                    </div>
                                    <div className="form-login">
                                        <label>Last Name</label>
                                        <div className="form-addons">
                                            <input
                                                type="text"
                                                value={last_name}
                                                name='last_name'
                                                onChange={(e)=>{onChange(e)}}
                                                className={` ${errors.lastname ? 'is-invalid' : ''}`}
                                                placeholder="Enter your last name"
                                            />
                                            <img src={MailIcon} alt="img" />
                                            <div className="invalid-feedback">{errors.firstname?.message}</div>
                                        </div>
                                    </div>
                                    <div className="form-login">
                                        <label>Email</label>
                                        <div className="form-addons">
                                            <input
                                                type="email"
                                                value={email}
                                                name='email'
                                                onChange={(e)=>{onChange(e)}}
                                                className={` ${errors.email ? 'is-invalid' : ''}`}
                                                placeholder="Enter your email address"
                                            />
                                            <img src={MailIcon} alt="img" />
                                            <div className="invalid-feedback">{errors.email?.message}</div>

                                        </div>
                                    </div>
                                    <div className="form-login">
                                        <label>Password</label>
                                        <div className="pass-group">
                                            <input
                                                type={eye ? "password" : "text"}
                                                value={password}
                                                name='password'
                                                onChange={(e)=>{onChange(e)}}
                                                className={` ${errors.password ? 'is-invalid' : ''}`}
                                                placeholder="Enter your password"
                                                // {...register('password')}
                                            />
                                            <span  onClick={onEyeClick} className={`fas toggle-password ${eye ? "fa-eye-slash":"fa-eye"} `} />
                                            <div className="invalid-feedback">{errors.password?.message}</div>

                                        </div>

                                    </div>
                                    <div className="form-login">
                                        <label>Confirm Password</label>
                                        <div className="pass-group">
                                            <input
                                                type={eye ? "password" : "text"}
                                                value={re_password}
                                                name='re_password'
                                                onChange={(e)=>{onChange(e)}}
                                                className={` ${errors.password ? 'is-invalid' : ''}`}
                                                placeholder="Enter your password"
                                                // {...register('password')}
                                            />
                                            <span  onClick={onEyeClick} className={`fas toggle-password ${eye ? "fa-eye-slash":"fa-eye"} `} />
                                            <div className="invalid-feedback">{errors.password?.message}</div>

                                        </div>

                                    </div>
                                    {/* <div className="form-login">
                                        <div className="alreadyuser">
                                            <h4>
                                                <NavLink to="/forgetPassword" className="hover-a">
                                                    Forgot Password?
                                                </NavLink>
                                            </h4>
                                        </div>
                                    </div> */}
                                    <div className="form-login">
                                        <button type='submit' className="btn btn-login" >
                                            Sign Up
                                        </button>
                                    </div>
                                </form>
                                
                                <div className="signinform text-center">
                                    <h4>
                                        Already have account?{" "}
                                        <NavLink to="/signin" className="hover-a">
                                            Sign In
                                        </NavLink>
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className="login-img">
                            <img src={LoginImage} alt="img" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.Auth.isAuthenticated,
})
  
  
export default connect(mapStateToProps)(SignUp)