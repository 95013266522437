import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Form, Navigate, NavLink, useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'
const EditStaffUser = () => {
    const location = useLocation()
    const { state } = location
    const userId = state['account']
    // User Data
    const [UserData, setUserData] = useState({})
    const onUserDataChange = (e) => { setUserData({ ...UserData, [e.target.name]: e.target.value }) }
    // Load User Data
    const LoadUser = async (id) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify({
            'userId': id
        })
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/staff/get/`, Body, config)
            setUserData(response?.data?.user)
            console.log(response?.data)
        } catch (error) {
            Swal.fire({
                position: "bottom-end",
                type: "error",
                title: "Error",
                html: `Something went wrong.`,
                showConfirmButton: !1,
                timer: 1500,
                confirmButtonClass: "btn btn-primary",
                buttonsStyling: !1,
            })
        }
    }
    // Update User Data
    const UpdateUser = async (data) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify(data)
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/staff/update/`, Body, config)
            Swal.fire({
                position: "bottom-end",
                type: "success",
                title: "Success",
                html: `User details are now updated.`,
                showConfirmButton: !1,
                timer: 1500,
                confirmButtonClass: "btn btn-primary",
                buttonsStyling: !1,
            })
            LoadUser(userId)
        } catch (error) {
            Swal.fire({
                position: "bottom-end",
                type: "error",
                title: "Error",
                html: `Something went wrong.`,
                showConfirmButton: !1,
                timer: 1500,
                confirmButtonClass: "btn btn-primary",
                buttonsStyling: !1,
            })
        }
    }
    // On Update Button Click
    const onUpdateButtonClick = (e) => {
        e.preventDefault()
        UpdateUser(UserData)
    }
    // Stores
    const [Stores, setStores] = useState([])
    const LoadStores = async () => {

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/takealot/getApiKey/`, config)
            setStores(response.data['data'])
        } catch (error) {
        }

    }
    // Call Functions
    useEffect(() => {
        LoadUser(userId)
        LoadStores()
    }, [])

    return (
        <>
            <div className="content">
                <Helmet>
                    <title>
                        User Account Details - Buybox
                    </title>
                </Helmet>
                <div className="page-header">
                    <div className="page-title">
                        <h4>User Management</h4>
                        <h6>Edit/Update User</h6>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <h4 className="fw-bold mb-4"><span className="text-muted fw-light">{ UserData?.first_name } { UserData?.last_name } / </span> Profile</h4>
                        <div className="row">
                            <div className="col-lg-6 col-sm-6 col-12">
                                <div className="form-group">
                                    <label>First Name</label>
                                    <input type="text" value={ UserData?.first_name } disabled />
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-6 col-12">
                                <div className="form-group">
                                    <label>Last Name</label>
                                    <input type="text" value={ UserData?.last_name } disabled />
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-6 col-12">
                                <div className="form-group">
                                    <label>Email</label>
                                    <input type="text" value={ UserData?.email } disabled />
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-6 col-12">
                                <div className="form-group">
                                    <label>Store</label>
                                    <select className='form-select' value={ UserData?.dashboard_id }>
                                        <option value={ true }>Select Store</option>
                                        {
                                            Stores.map(
                                                (row, index) => {
                                                    return (
                                                        <>
                                                            <option key={ index } value={ row?.id }>{ row?.store_name }</option>
                                                        </>
                                                    )
                                                }
                                            )
                                        }
                                    </select>
                                </div>
                            </div>


                            <div className="col-lg-12">
                                <button type='button' onClick={ (e) => { onUpdateButtonClick(e) } } className="btn btn-submit me-2">Update</button>
                                <NavLink to="/admin/users/staff" className="btn btn-cancel me-2">Cancel</NavLink>
                            </div>
                            <br />
                            <br />
                            <br />
                            {
                                UserData?.storesRegistered > 0 ?
                                    <div>
                                        <h4 className="fw-bold mb-4"><span className="text-muted fw-light">{ UserData?.first_name } { UserData?.last_name } / </span> Registered Store{ UserData?.total_stores > 1 ? 's' : "" }</h4>
                                        <div className="table-responsive text-wrap">
                                            <table className="table mb-0">
                                                <thead>

                                                    <tr>
                                                        <th>
                                                            Name
                                                        </th>
                                                        <th>
                                                            Total Sales
                                                        </th>
                                                        <th>
                                                            Total Products
                                                        </th>
                                                        <th>
                                                            Total Buybox Products
                                                        </th>
                                                        <th>
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        UserData?.stores.map(
                                                            (row, index) => {
                                                                return (
                                                                    <>
                                                                        <tr key={ index }>
                                                                            <td>
                                                                                { row?.name }
                                                                            </td>
                                                                            <td>
                                                                                { row?.sales }
                                                                            </td>
                                                                            <td>
                                                                                { row?.products }
                                                                            </td>
                                                                            <td>
                                                                                { row?.bb_products }
                                                                            </td>
                                                                            <td>
                                                                                <NavLink
                                                                                    to={ { pathname: "/user/dashboard" } }
                                                                                    state={
                                                                                        {
                                                                                            store: row?.id,
                                                                                            store_name: row?.name,
                                                                                            userId: userId,
                                                                                        }
                                                                                    }
                                                                                    style={ { color: 'white' } }
                                                                                    className="btn btn-primary me-2 btn-md"
                                                                                >
                                                                                    Visit Store
                                                                                </NavLink>
                                                                                <NavLink
                                                                                    to={ { pathname: "/user/products" } }
                                                                                    state={
                                                                                        {
                                                                                            store: row?.id,
                                                                                            store_name: row?.name,
                                                                                            userId: userId,
                                                                                        }
                                                                                    }
                                                                                    style={ { color: 'white' } }
                                                                                    className="btn btn-success me-2 btn-md"
                                                                                >
                                                                                    View Products
                                                                                </NavLink>
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            }
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                            <br />
                                        </div>
                                    </div>
                                    : <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditStaffUser