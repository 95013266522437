import React, { useEffect, useState } from 'react'
import { Helmet } from "react-helmet"
import FilterComponent from './Filters/index'
import DayDashboard from './DayDashboard/index'
import MonthDashboard from './MonthDashboard/index'
import AnnualDashboard from './AnnualDashboard/index'
import CustomDateDashboard from './CustomDateDashboard/index'
import YesterdayDashboard from './YesterdayDashboard'
import Swal from 'sweetalert2'
import { connect } from 'react-redux'
import axios from 'axios'
import Moment from 'moment'
import '../Styles/styles.css'
const CompanyDashboard = (props) => {
	const user = props.user
	const config = {
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			'Authorization': `JWT ${localStorage.getItem('access')}`
		}
	}
	const storeId = 0
	const visible = props.visible
	const [CurrentPage, setCurrentPage] = useState(1)
	const [MonthlyCurrentPage, setMonthlyCurrentPage] = useState(1)
	const [DayCurrentPage, setDayCurrentPage] = useState(1)
	const [CustomCurrentPage, setCustomCurrentPage] = useState(1)
	const [TotalPages, setTotalPages] = useState(2)
	const [Loading, setLoading] = useState({
		total_orders: false,
		revenue: false,
		fee: false,
		net_revenue: false,
		quantity: false,
		revenue_change: false,
		cnr_revenue: false,
		cnr_quantity: false
	})
	const CompanyProfile = props.CompanyProfile
	const [LoadingVisibility, setLoadingVisibility] = useState("none")
	const [dataVisibility, setDataVisibility] = useState("block")
	const [FilterType, setFilterType] = useState(localStorage.getItem(`filterType-dashboard-${storeId}`) ? localStorage.getItem(`filterType-dashboard-${storeId}`) : 3)
	const [CustomFilterType, setCustomFilterType] = useState(localStorage.getItem(`custom-filterType-dashboard-${storeId}`) ? localStorage.getItem(`custom-filterType-dashboard-${storeId}`) : 1)
	const Date_Var = new Date()
	const yesterday = Moment(new Date(Date.now() - 86400000)).format('YYYY-MM-DD')
	const currentYear = Date_Var.getFullYear()
	const [Month, setMonth] = useState(localStorage.getItem(`month-dashboard-${storeId}`) ? localStorage.getItem(`month-dashboard-${storeId}`) : ("0" + (Date_Var.getMonth() + 1)).slice(-2))
	const [Year, setYear] = useState(localStorage.getItem(`year-dashboard-${storeId}`) ? localStorage.getItem(`year-dashboard-${storeId}`) : currentYear)
	const [MonthYear, setMonthYear] = useState(localStorage.getItem(`monthyear-dashboard-${storeId}`) ? localStorage.getItem(`monthyear-dashboard-${storeId}`) : currentYear)
	const [CurrentDate, setCurrentDate] = useState(localStorage.getItem(`date-dashboard-${storeId}`) ? localStorage.getItem(`date-dashboard-${storeId}`) : Date_Var.getFullYear() + "-" + ("0" + (Date_Var.getMonth() + 1)).slice(-2) + "-" + ("0" + Date_Var.getDate()).slice(-2))
	const [FromDate, setFromDate] = useState(localStorage.getItem(`fromdate-dashboard-${storeId}`) ? localStorage.getItem(`fromdate-dashboard-${storeId}`) : Date_Var.getFullYear() + "-" + ("0" + (Date_Var.getMonth() + 1)).slice(-2) + "-" + ("0" + Date_Var.getDate()).slice(-2))
	const [ToDate, setToDate] = useState(localStorage.getItem(`todate-dashboard-${storeId}`) ? localStorage.getItem(`todate-dashboard-${storeId}`) : Date_Var.getFullYear() + "-" + ("0" + (Date_Var.getMonth() + 1)).slice(-2) + "-" + ("0" + Date_Var.getDate()).slice(-2))
	const year = 2021
	const years = Array.from(new Array(currentYear - year + 1), (val, index) => index + year)
	// Data
	// localStorage.removeItem('sort-by') 
	const [FormData, setFormData] = useState({})
	const [CostProfitData, setCostProfitData] = useState({})
	const [SalesVsQuantityTrendingData, setSalesVsQuantityTrendingData] = useState([])
	const [PageSize, setPageSize] = useState(localStorage.getItem('page-size') ? localStorage.getItem('page-size') || 10 : 10)
	const [Sortby, setSortby] = useState(localStorage.getItem('sort-by') ? localStorage.getItem('sort-by') || 'units' : 'units')
	const [SortDirection, setSortDirection] = useState(localStorage.getItem('sort-direction') ? localStorage.getItem('sort-direction') || 'down' : 'down')
	const [PageLimit, setPageLimit] = useState(1)
	const [YearProfile, setYearProfile] = useState({ "revenue_change": "+" })
	const [MonthProfile, setMonthProfile] = useState({ "revenue": 0, "revenue_change": "+0" })
	const [ProductsData, setProductsData] = useState([])
	const [TotalProducts, setTotalProducts] = useState(0)
	const [ProductsBody, setProductsBody] = useState([])
	const [TakealotStore, setTakealotStore] = useState({
		"store_name": ""
	})
	const [DCData, setDCData] = useState([])
	const [JHBData, setJHBData] = useState({
		"revenue_change": "+",
		"net_revenue_change": "+",
		"quantity_change": "+",
		"fee_change": "+",
		"fee": 0,
		"revenue": 0,
		"quantity": 0,
		"net_revenue": 0,
		"normal_orders_percentage": 0,
		"cancelled_revenue": 0,
		"cnr_quantity": 0,
		"fee_percentage": 0,
	})
	const [CPTData, setCPTData] = useState({
		"revenue_change": "+",
		"net_revenue_change": "+",
		"quantity_change": "+",
		"fee_change": "+",
		"fee": 0,
		"revenue": 0,
		"quantity": 0,
		"net_revenue": 0,
		"normal_orders_percentage": 0,
		"cancelled_revenue": 0,
		"cnr_quantity": 0,
		"fee_percentage": 0,
	})
	// APIs
	const LoadDashboardData = async (TYPE, RequestBody) => {
		// setLoadingVisibility("block")
		// setDataVisibility("none")
		setLoading({
			total_orders: false,
			revenue: false,
			fee: false,
			net_revenue: false,
			quantity: false,
			revenue_change: false,
			cnr_revenue: false,
			cnr_quantity: false
		})
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `JWT ${localStorage.getItem('access')}`
			}
		}
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_BACKEND_URL}/api/v4/${TYPE}/kpis/updated/`,
				RequestBody,
				config
			)
			setFormData(response?.data)
			console.log("KPIS", response?.data)
			// const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v4/overall/${TYPE}/kpis/updated/`, {
			// 	method: 'POST',
			// 	body: RequestBody,
			// 	headers: {
			// 		'Content-Type': 'application/json',
			// 		'Accept': 'application/json',
			// 		'Authorization': `JWT ${localStorage.getItem('access')}`
			// 	}
			// });

			// if (!response.ok || !response.body) {
			// 	throw response.statusText;
			// }

			// // Here we start prepping for the streaming response
			// const reader = response.body.getReader();
			// const decoder = new TextDecoder();
			// const loopRunner = true;

			// let incompleteChunk = "";
			// while (loopRunner) {
			// 	// Here we start reading the stream, until its done.
			// 	try {
			// 		const { value, done } = await reader.read();
			// 		if (done) {
			// 			break;
			// 		}
			// 		let decodedChunk = decoder.decode(value, { stream: true });

			// 		if (!decodedChunk.endsWith('\n\n')) {
			// 			incompleteChunk += decodedChunk;
			// 			continue;
			// 		}

			// 		if (incompleteChunk) {
			// 			if (!decodedChunk.startsWith('data:')) {
			// 				decodedChunk = incompleteChunk + decodedChunk;
			// 			}
			// 			incompleteChunk = '';
			// 		}
			// 		const lines = decodedChunk.split('\n\n');
			// 		for (const line of lines) {
			// 			if (line.startsWith('data:')) {

			// 				const responseData = line.slice(5); // Remove 'data:' prefix
			// 				if (responseData.trim() === '[DONE]') {
			// 					continue
			// 					// const data = JSON.parse(jsonData);
			// 				}
			// 				const JSONResponse = JSON.parse(responseData)
			// 				setFormData(JSONResponse?.KPIStats)
			// 			}
			// 		}




			// 		// Process the chunk...

			// 	} catch (error) {
			// 		console.error('Error reading stream:', error);
			// 	}

			// }
			// fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v4/overall/${TYPE}/kpis/updated/`, {
			// 	method: 'POST',
			// 	body: RequestBody,
			// 	headers: {
			// 		'Content-Type': 'application/json',
			// 		'Accept': 'application/json',
			// 		'Authorization': `JWT ${localStorage.getItem('access')}`
			// 	}
			// }).then(response => {
			// 	const reader = response.body.getReader();
			// 	const decoder = new TextDecoder();
			// 	return reader.read().then(function processText({ done, value }) {
			// 		if (done) {
			// 			return;
			// 		}

			// 		const result = decoder.decode(value);
			// 		const lines = result.split('\n\n');
			// 		for (const line of lines) {
			// 			if (line.startsWith('data:')) {

			// 				const responseData = line.slice(5); // Remove 'data:' prefix
			// 				if (responseData.trim() === '[DONE]') { 
			// 					continue
			// 					// const data = JSON.parse(jsonData);
			// 				}
			// 				const JSONResponse = JSON.parse(responseData)
			// 				// setCostProfitData(JSONResponse)
			// 				setFormData(JSONResponse?.KPIStats)
			// 			}
			// 		}
			// 		// Update your CostData here

			// 		return reader.read().then(processText);
			// 	});
			// });

		} catch (error) {
			setLoading(false)
			console.log("Error", error)
		}
		// try {
		//   const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v2/overall/${TYPE}/cityKPIs/`, RequestBody,config)
		//   setJHBData(response.data['cityStats']['JHB'])
		//   setCPTData(response.data['cityStats']['CPT'])
		// } catch (error) {
		//   // console.log('first', error)
		// //   setResponseError(error.response.statusText)
		// }
		// setLoadingVisibility("none")
		// setDataVisibility("block")
		// try {
		//   const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v2/overall/${TYPE}/costProfit/`, RequestBody,config)
		//   setCostProfitData(response.data['costProfitStats'])
		// } catch (error) {
		//   // console.log('first', error)
		// //   setResponseError(error.response.statusText)
		// }
		// try {
		//   const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v4/overall/${TYPE}/trendingData/`, RequestBody,config)
		//   setSalesVsQuantityTrendingData((response.data['trendingStats']['sales_vs_quantity_trending_data']))
		// } catch (error) {
		//   // console.log('first', error)
		// //   setResponseError(error.response.statusText)
		// }
	}
	const LoadDashboardCityKPIs = async (TYPE, RequestBody) => {
		try {
			const config = {
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
					'Authorization': `JWT ${localStorage.getItem('access')}`
				}
			}
			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v4/${TYPE}/cityKPIs/updated/`, RequestBody, config)
			setDCData(response.data)
			// const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v4/overall/${TYPE}/cityKPIs/updated/`, {
			// 	method: 'POST',
			// 	body: RequestBody,
			// 	headers: {
			// 		'Content-Type': 'application/json',
			// 		'Accept': 'application/json',
			// 		'Authorization': `JWT ${localStorage.getItem('access')}`
			// 	}
			// });

			// if (!response.ok || !response.body) {
			// 	throw response.statusText;
			// }

			// // Here we start prepping for the streaming response
			// const reader = response.body.getReader();
			// const decoder = new TextDecoder();
			// const loopRunner = true;

			// let incompleteChunk = "";
			// while (loopRunner) {
			// 	// Here we start reading the stream, until its done.
			// 	try {
			// 		const { value, done } = await reader.read();
			// 		if (done) {
			// 			break;
			// 		}
			// 		let decodedChunk = decoder.decode(value, { stream: true });

			// 		if (!decodedChunk.endsWith('\n\n')) {
			// 			incompleteChunk += decodedChunk;
			// 			continue;
			// 		}

			// 		if (incompleteChunk) {
			// 			if (!decodedChunk.startsWith('data:')) {
			// 				decodedChunk = incompleteChunk + decodedChunk;
			// 			}
			// 			incompleteChunk = '';
			// 		}
			// 		const lines = decodedChunk.split('\n\n');
			// 		for (const line of lines) {
			// 			if (line.startsWith('data:')) {

			// 				const responseData = line.slice(5); // Remove 'data:' prefix
			// 				if (responseData.trim() === '[DONE]') {
			// 					continue
			// 					// const data = JSON.parse(jsonData);
			// 				}
			// 				const JSONResponse = JSON.parse(responseData)
			// 				setJHBData(JSONResponse?.KPIStats?.cityStats?.JHB)
			// 				setCPTData(JSONResponse?.KPIStats?.cityStats?.CPT)
			// 			}
			// 		}




			// 		// Process the chunk...

			// 	} catch (error) {
			// 		console.error('Error reading stream:', error);
			// 	}

			// }
			// fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v4/overall/${TYPE}/cityKPIs/updated/`, {
			// 	method: 'POST',
			// 	body: RequestBody,
			// 	headers: {
			// 		'Content-Type': 'application/json',
			// 		'Accept': 'application/json',
			// 		'Authorization': `JWT ${localStorage.getItem('access')}`
			// 	}
			// }).then(response => {
			// 	const reader = response.body.getReader();
			// 	const decoder = new TextDecoder();
			// 	return reader.read().then(function processText({ done, value }) {
			// 		if (done) {
			// 			return;
			// 		}

			// 		const result = decoder.decode(value);
			// 		const lines = result.split('\n\n');
			// 		for (const line of lines) {
			// 			if (line.startsWith('data:')) {

			// 				const responseData = line.slice(5); // Remove 'data:' prefix
			// 				if (responseData.trim() === '[DONE]') { 
			// 					continue
			// 					// const data = JSON.parse(jsonData);
			// 				}
			// 				const JSONResponse = JSON.parse(responseData)
			// 				// setCostProfitData(JSONResponse)
			// 				setJHBData(JSONResponse?.KPIStats?.cityStats?.JHB)
			// 				setCPTData(JSONResponse?.KPIStats?.cityStats?.CPT)
			// 			}
			// 		}
			// 		// Update your CostData here

			// 		return reader.read().then(processText);
			// 	});
			// });
		} catch (error) {
			// console.log('first', error)
			//   setResponseError(error.response.statusText)
		}
	}
	const LoadDashboardCostKPIs = async (TYPE, RequestBody) => {
		try {
			// const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v4/overall/${TYPE}/costProfit/updated/`, RequestBody,config)
			const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v4/overall/${TYPE}/costProfit/updated/`, {
				method: 'POST',
				body: RequestBody,
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
					'Authorization': `JWT ${localStorage.getItem('access')}`
				}
			});

			if (!response.ok || !response.body) {
				throw response.statusText;
			}

			// Here we start prepping for the streaming response
			const reader = response.body.getReader();
			const decoder = new TextDecoder();
			const loopRunner = true;

			let incompleteChunk = "";
			while (loopRunner) {
				// Here we start reading the stream, until its done.
				try {
					const { value, done } = await reader.read();
					if (done) {
						break;
					}
					let decodedChunk = decoder.decode(value, { stream: true });

					if (!decodedChunk.endsWith('\n\n')) {
						incompleteChunk += decodedChunk;
						continue;
					}

					if (incompleteChunk) {
						if (!decodedChunk.startsWith('data:')) {
							decodedChunk = incompleteChunk + decodedChunk;
						}
						incompleteChunk = '';
					}
					const lines = decodedChunk.split('\n\n');
					for (const line of lines) {
						if (line.startsWith('data:')) {

							const responseData = line.slice(5); // Remove 'data:' prefix
							if (responseData.trim() === '[DONE]') {
								continue
								// const data = JSON.parse(jsonData);
							}
							const JSONResponse = JSON.parse(responseData)
							setCostProfitData(JSONResponse)
						}
					}




					// Process the chunk...

				} catch (error) {
					console.error('Error reading stream:', error);
				}

			}
			// fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v4/overall/${TYPE}/costProfit/updated/`, {
			// 	method: 'POST',
			// 	body: RequestBody,
			// 	headers: {
			// 		'Content-Type': 'application/json',
			// 		'Accept': 'application/json',
			// 		'Authorization': `JWT ${localStorage.getItem('access')}`
			// 	},
			// 	responseData: 'stream'
			// }).then(response => {
			// 	const reader = response.body.getReader();
			// 	const decoder = new TextDecoder();
			// 	return reader.read().then(function processText({ done, value }) {
			// 		if (done) {
			// 			return;
			// 		}

			// 		const result = decoder.decode(value);
			// 		const lines = result.split('\n\n');
			// 		for (const line of lines) {
			// 			if (line.startsWith('data:')) {

			// 				const responseData = line.slice(5); // Remove 'data:' prefix
			// 				if (responseData.trim() === '[DONE]') { 
			// 					continue
			// 					// const data = JSON.parse(jsonData);
			// 				}
			// 				const JSONResponse = JSON.parse(responseData)
			// 				setCostProfitData(JSONResponse)
			// 			}
			// 		}
			// 		// Update your CostData here

			// 		return reader.read().then(processText);
			// 	});
			// });


			// setCostProfitData(response.data['costProfitStats'])
		} catch (error) {
			// console.log('first', error)
			//   setResponseError(error.response.statusText)
		}
	}
	const LoadDashboardTrendingData = async (TYPE, RequestBody) => {

		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `JWT ${localStorage.getItem('access')}`
			}
		}
		try {
			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v4/${TYPE}/trendingData/updated/`, RequestBody, config)
			setSalesVsQuantityTrendingData(response.data)
		} catch (error) {
		}

		// try {
		// 	const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v4/overall/${TYPE}/trendingData/updated/`, {
		// 		method: 'POST',
		// 		body: RequestBody,
		// 		headers: {
		// 			'Content-Type': 'application/json',
		// 			'Accept': 'application/json',
		// 			'Authorization': `JWT ${localStorage.getItem('access')}`
		// 		}
		// 	});

		// 	if (!response.ok || !response.body) {
		// 		throw response.statusText;
		// 	}

		// 	// Here we start prepping for the streaming response
		// 	const reader = response.body.getReader();
		// 	const decoder = new TextDecoder();
		// 	const loopRunner = true;

		// 	let incompleteChunk = "";
		// 	while (loopRunner) {
		// 		// Here we start reading the stream, until its done.
		// 		try {
		// 			const { value, done } = await reader.read();
		// 			if (done) {
		// 				break;
		// 			}
		// 			let decodedChunk = decoder.decode(value, { stream: true });

		// 			if (!decodedChunk.endsWith('\n\n')) {
		// 				incompleteChunk += decodedChunk;
		// 				continue;
		// 			}

		// 			if (incompleteChunk) {
		// 				if (!decodedChunk.startsWith('data:')) {
		// 					decodedChunk = incompleteChunk + decodedChunk;
		// 				}
		// 				incompleteChunk = '';
		// 			}
		// 			const lines = decodedChunk.split('\n\n');
		// 			for (const line of lines) {
		// 				if (line.startsWith('data:')) {

		// 					const responseData = line.slice(5); // Remove 'data:' prefix
		// 					if (responseData.trim() === '[DONE]') {
		// 						continue
		// 						// const data = JSON.parse(jsonData);
		// 					}
		// 					const JSONResponse = JSON.parse(responseData)
		// 					setSalesVsQuantityTrendingData((JSONResponse?.trendingStats?.sales_vs_quantity_trending_data))
		// 				}
		// 			}




		// 			// Process the chunk...

		// 		} catch (error) {
		// 			console.error('Error reading stream:', error);
		// 		}

		// 	}
		// 	// fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v4/overall/${TYPE}/trendingData/updated/`, {
		// 	// 	method: 'POST',
		// 	// 	body: RequestBody,
		// 	// 	headers: {
		// 	// 		'Content-Type': 'application/json',
		// 	// 		'Accept': 'application/json',
		// 	// 		'Authorization': `JWT ${localStorage.getItem('access')}`
		// 	// 	}
		// 	// }).then(response => {
		// 	// 	const reader = response.body.getReader();
		// 	// 	const decoder = new TextDecoder();
		// 	// 	return reader.read().then(function processText({ done, value }) {
		// 	// 		if (done) {
		// 	// 			return;
		// 	// 		}

		// 	// 		const result = decoder.decode(value);
		// 	// 		const lines = result.split('\n\n');
		// 	// 		for (const line of lines) {
		// 	// 			if (line.startsWith('data:')) {

		// 	// 				const responseData = line.slice(5); // Remove 'data:' prefix
		// 	// 				if (responseData.trim() === '[DONE]') { 
		// 	// 					continue
		// 	// 					// const data = JSON.parse(jsonData);
		// 	// 				}
		// 	// 				const JSONResponse = JSON.parse(responseData)
		// 	// 				setSalesVsQuantityTrendingData((JSONResponse?.trendingStats?.sales_vs_quantity_trending_data))
		// 	// 			}
		// 	// 		}
		// 	// 		// Update your CostData here

		// 	// 		return reader.read().then(processText);
		// 	// 	});
		// 	// });
		// } catch (error) {

		// }
	}
	const LoadDayStats = async (inputDate, pSize, sortBy, sortDirection) => {
		setFormData({})
		setJHBData({})
		setCPTData({})
		setProductsData([])
		setSalesVsQuantityTrendingData([])
		const Body = JSON.stringify({
			"date": inputDate,
			"account": user.id,

		})
		await Promise.all([
			LoadDashboardData("day", Body),
			LoadDashboardCityKPIs("day", Body),
			LoadDashboardCostKPIs("day", Body),
			LoadDashboardTrendingData("day", Body)
		])
		LoadSaleProfile(inputDate.substring(0, 4) + "-" + inputDate.substring(5, 7))
		// LoadYearlyProfile(CurrentDate.substring(0, 4))
		LoadDayProductSalesList(1, pSize, inputDate, sortBy, sortDirection)
	}
	const LoadDayProductSalesList = async (page_number, pageSize, inputDate, sortBy, sortDirection) => {
		setDayCurrentPage(page_number)
		const Body = JSON.stringify({
			"page_number": page_number,
			"page_size": pageSize,
			"date": inputDate,
			"sortBy": sortBy,
			"sortDirection": sortDirection,
		})
		setProductsData([])
		try {
			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v4/product/sales/day/updated/`, Body, config)
			setProductsData(response.data?.results)
			setTotalProducts(response.data?.total_records)
			setPageLimit(response.data?.pagelimit)
			setTotalPages(response.data?.total_pages)
		} catch (error) {

		}
		// const config = {
		// 	method: 'post',
		// 	maxBodyLength: Infinity,
		// 	url: `${process.env.REACT_APP_BACKEND_URL}/api/v4/overall/product/sales/day/updated/`,
		// 	headers: { 
		// 	  'Authorization': `JWT ${localStorage.getItem('access')}`, 
		// 	  'Content-Type': 'application/json'
		// 	},
		// 	data : Body
		// };

		// axios.request(config).then(
		// 	(response) => {
		// 		const result = (response.data);
		// 		const lines = result.split('\n\n');
		// 		// console.log(lines);
		// 		for (const line of lines) {
		// 			if (line.startsWith('data:')) {

		// 				const responseData = line.slice(5); // Remove 'data:' prefix
		// 				if (responseData.trim() === '[DONE]') { 
		// 					continue
		// 					// const data = JSON.parse(jsonData);
		// 				}
		// 				const JSONResponse = JSON.parse(responseData)
		// 				setProductsData(JSONResponse['results'])
		// 				setTotalProducts(JSONResponse['total_records'])
		// 				setTotalProducts(JSONResponse['total_records'])
		// 				setTotalPages(JSONResponse['has_pages'])
		// 			}
		// 		}
		// 	}
		// ).catch((error) => {
		// 	console.log(error);
		// });
	}

	const LoadMonthlyStats = async (date, pSize, sortBy, sortDirection) => {
		setFormData({})
		setJHBData({})
		setCPTData({})
		setProductsData([])
		setSalesVsQuantityTrendingData([])
		const Body = JSON.stringify(
			{
				"date": date,
				"account": user.id,

			}
		)

		await Promise.all([
			LoadDashboardData("month", Body),
			LoadDashboardCityKPIs("month", Body),
			LoadDashboardCostKPIs("month", Body),
			LoadDashboardTrendingData("month", Body)
		])
		LoadSaleProfile(date)
		// LoadYearlyProfile(date.substring(0, 4))
		LoadMonthProductSalesList(1, pSize, date, sortBy, sortDirection)
		try {
			setProductsBody({
				"date": date,
				"account": user.id,

			})
		} catch (error) {
			// console.log('first', error)
			//   setResponseError(error.response.statusText)
		}

	}
	const LoadMonthProductSalesList = async (page_number, pageSize, date, sortBy, sortDirection) => {
		setMonthlyCurrentPage(page_number)

		const Body = JSON.stringify({
			"page_number": page_number,
			"page_size": pageSize,
			"date": date,
			"sortBy": sortBy,
			"sortDirection": sortDirection,

		})

		try {
			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v4/product/sales/month/updated/`, Body, config)
			setProductsData(response.data?.results)
			setTotalProducts(response.data?.total_records)
			setPageLimit(response.data?.pagelimit)
			setTotalPages(response.data?.total_pages)
		} catch (error) {

		}

		// setProductsData([])
		// try {
		// 	const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v4//product/sales/month/updated/`, {
		// 		method: 'POST',
		// 		body: Body,
		// 		headers: {
		// 			'Content-Type': 'application/json',
		// 			'Accept': 'application/json',
		// 			'Authorization': `JWT ${localStorage.getItem('access')}`
		// 		}
		// 	});

		// 	if (!response.ok || !response.body) {
		// 		throw response.statusText;
		// 	}

		// 	// Here we start prepping for the streaming response
		// 	const reader = response.body.getReader();
		// 	const decoder = new TextDecoder();
		// 	const loopRunner = true;

		// 	let incompleteChunk = "";
		// 	while (loopRunner) {
		// 		// Here we start reading the stream, until its done.
		// 		try {
		// 			const { value, done } = await reader.read();
		// 			if (done) {
		// 				break;
		// 			}
		// 			let decodedChunk = decoder.decode(value, { stream: true });

		// 			if (!decodedChunk.endsWith('\n\n')) {
		// 				incompleteChunk += decodedChunk;
		// 				continue;
		// 			}

		// 			if (incompleteChunk) {
		// 				if (!decodedChunk.startsWith('data:')) {
		// 					decodedChunk = incompleteChunk + decodedChunk;
		// 				}
		// 				incompleteChunk = '';
		// 			}
		// 			const lines = decodedChunk.split('\n\n');
		// 			for (const line of lines) {
		// 				if (line.startsWith('data:')) {

		// 					const responseData = line.slice(5); // Remove 'data:' prefix
		// 					if (responseData.trim() === '[DONE]') {
		// 						continue
		// 						// const data = JSON.parse(jsonData);
		// 					}
		// 					const JSONResponse = JSON.parse(responseData)
		// 					if (JSONResponse?.results != {}) {
		// 						setProductsData((a) => [...a, JSONResponse?.results])
		// 						setTotalProducts(JSONResponse?.total_records)
		// 						setPageLimit(JSONResponse?.pagelimit)
		// 						setTotalPages(JSONResponse?.total_pages)
		// 					}
		// 				}
		// 			}




		// 			// Process the chunk...

		// 		} catch (error) {
		// 			console.error('Error reading stream:', error);
		// 		}

		// 	}
		// 	// fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v4/overall/product/sales/month/updated/`, {
		// 	// 	method: 'POST',
		// 	// 	body: Body,
		// 	// 	headers: {
		// 	// 		'Content-Type': 'application/json',
		// 	// 		'Accept': 'application/json',
		// 	// 		'Authorization': `JWT ${localStorage.getItem('access')}`
		// 	// 	}
		// 	// }).then(response => {
		// 	// 	const reader = response.body.getReader();
		// 	// 	const decoder = new TextDecoder();
		// 	// 	return reader.read().then(function processText({ done, value }) {
		// 	// 		if (done) {
		// 	// 			return;
		// 	// 		}

		// 	// 		const result = decoder.decode(value);
		// 	// 		const lines = result.split('\n\n');
		// 	// 		for (const line of lines) {
		// 	// 			if (line.startsWith('data:')) {

		// 	// 				const responseData = line.slice(5); // Remove 'data:' prefix
		// 	// 				if (responseData.trim() === '[DONE]') { 
		// 	// 					continue
		// 	// 					// const data = JSON.parse(jsonData);
		// 	// 				}
		// 	// 				const JSONResponse = JSON.parse(responseData)
		// 	// 				if (JSONResponse?.results != {}){
		// 	// 					setProductsData((a)=>[...a, JSONResponse?.results])
		// 	// 					setTotalProducts(JSONResponse?.total_records)
		// 	// 					setPageLimit(JSONResponse?.pagelimit)
		// 	// 					setTotalPages(JSONResponse?.total_pages)
		// 	// 				}
		// 	// 			}
		// 	// 		}
		// 	// 		// Update your CostData here

		// 	// 		return reader.read().then(processText);
		// 	// 	});
		// 	// });
		// } catch (error) {
		// 	// console.log('first', error)
		// }
		// const config = {
		// 	method: 'post',
		// 	maxBodyLength: Infinity,
		// 	url: `${process.env.REACT_APP_BACKEND_URL}/api/v4/overall/product/sales/month/updated/`,
		// 	headers: { 
		// 		'Authorization': `JWT ${localStorage.getItem('access')}`, 
		// 		'Content-Type': 'application/json'
		// 	},
		// 	data : Body
		// };

		// axios.request(config).then(
		// 	(response) => {
		// 		const result = (response.data);
		// 		const lines = result.split('\n\n');
		// 		// console.log(lines);
		// 		for (const line of lines) {
		// 			if (line.startsWith('data:')) {

		// 				const responseData = line.slice(5); // Remove 'data:' prefix
		// 				if (responseData.trim() === '[DONE]') { 
		// 					continue
		// 					// const data = JSON.parse(jsonData);
		// 				}
		// 				const JSONResponse = JSON.parse(responseData)
		// 				setProductsData(JSONResponse['results'])
		// 				setTotalProducts(JSONResponse['total_records'])
		// 				setTotalProducts(JSONResponse['total_records'])
		// 				setTotalPages(JSONResponse['has_pages'])
		// 			}
		// 		}
		// 	}
		// ).catch((error) => {
		// 	console.log(error);
		// });
	}


	const formatter = new Intl.NumberFormat('en-ZA', {
		style: 'currency',
		currency: 'ZAR',
		minimumFractionDigits: 0
	})

	const numberFormatter = new Intl.NumberFormat('en-ZA', {
		// These options are needed to round to whole numbers if that's what you want.
		minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
		//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
	})

	const onPageSizeChange = (e, value) => {
		e.preventDefault()
		setPageSize(value)
		localStorage.setItem(`page-size`, value)

		if (FilterType == 1) {
			LoadAnnualProductSalesList(1, value, Year, Sortby, SortDirection)
		} else if (FilterType == 2) {
			LoadMonthProductSalesList(1, value, MonthYear + "-" + Month, Sortby, SortDirection)
		} else if (FilterType == 3) {
			LoadDayProductSalesList(1, value, CurrentDate, Sortby, SortDirection)
		} else if (FilterType == 4) {
			LoadCustomProductSalesList(1, value, Sortby, SortDirection)
		}
		else if (FilterType == 5) {
			LoadDayProductSalesList(1, value, yesterday, Sortby, SortDirection)
			// LoadSaleProfile(Moment(yesterday).format('YYYY-MM'))
		}
	}

	const onSortChange = (e, value) => {
		e.preventDefault()
		setTotalProducts(0)
		localStorage.setItem('sort-by', value)

		setSortby(value)
		if (FilterType == 1) {
			LoadAnnualProductSalesList(1, PageSize, Year, value, SortDirection)
		} else if (FilterType == 2) {
			LoadMonthProductSalesList(1, PageSize, MonthYear + "-" + Month, value, SortDirection)
		} else if (FilterType == 3) {
			LoadDayProductSalesList(1, PageSize, CurrentDate, value, SortDirection)
		} else if (FilterType == 4) {
			LoadCustomProductSalesList(1, PageSize, value, SortDirection)
		}
		else if (FilterType == 5) {
			LoadDayProductSalesList(1, PageSize, yesterday, value, SortDirection)
			// LoadSaleProfile(Moment(yesterday).format('YYYY-MM'))
		}
	}

	const onSortDirectionChange = (e, value) => {
		e.preventDefault()
		setTotalProducts(0)
		localStorage.setItem('sort-direction', value)
		setSortDirection(value)
		if (FilterType == 1) {
			LoadAnnualProductSalesList(1, PageSize, Year, Sortby, value)
		} else if (FilterType == 2) {
			LoadMonthProductSalesList(1, PageSize, MonthYear + "-" + Month, Sortby, value)
		} else if (FilterType == 3) {
			LoadDayProductSalesList(1, PageSize, CurrentDate, Sortby, value)
		} else if (FilterType == 4) {
			LoadCustomProductSalesList(1, PageSize, Sortby, value)
		}
		else if (FilterType == 5) {
			LoadDayProductSalesList(1, PageSize, yesterday, Sortby, value)
			// LoadSaleProfile(Moment(yesterday).format('YYYY-MM'))
		}
	}


	const LoadCustomProductSalesList = async (page_number, pageSize, sortBy, sortDirection) => {
		setCustomCurrentPage(page_number)
		const Body = JSON.stringify(
			{
				"page_number": page_number,
				"page_size": pageSize,
				"fromDate": FromDate,
				"toDate": ToDate,
				"sortBy": sortBy,
				"sortDirection": sortDirection,
			}
		)
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `JWT ${localStorage.getItem('access')}`
			}
		}
		setProductsData([])
		try {
			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v4/product/sales/custom/updated/`, Body, config)
			setProductsData(response.data?.results)
			setTotalProducts(response.data?.total_records)
			setPageLimit(response.data?.pagelimit)
			setTotalPages(response.data?.total_pages)
		} catch (error) {

		}
		// const config = {
		// 	method: 'post',
		// 	maxBodyLength: Infinity,
		// 	url: `${process.env.REACT_APP_BACKEND_URL}/api/v4/overall/product/sales/custom/updated/`,
		// 	headers: { 
		// 	  'Authorization': `JWT ${localStorage.getItem('access')}`, 
		// 	  'Content-Type': 'application/json'
		// 	},
		// 	data : Body
		// };

		// axios.request(config).then((response) => {
		// 	const result = (response.data);
		// 	const lines = result.split('\n\n');
		// 	// console.log(lines);
		// 	for (const line of lines) {
		// 		if (line.startsWith('data:')) {

		// 			const responseData = line.slice(5); // Remove 'data:' prefix
		// 			if (responseData.trim() === '[DONE]') { 
		// 				continue
		// 				// const data = JSON.parse(jsonData);
		// 			}
		// 			const JSONResponse = JSON.parse(responseData)
		// 			setProductsData(JSONResponse['results'])
		// 			setTotalProducts(JSONResponse['total_records'])
		// 			setTotalProducts(JSONResponse['total_records'])
		// 			setTotalPages(JSONResponse['has_pages'])
		// 		}
		// 	}
		// })
		// .catch((error) => {
		// 	console.log(error);
		// });
	}

	const LoadCustomStats = async (fromDate, toDate, pSize, sortBy, sortDirection, fType) => {
		setFormData({})
		setJHBData({})
		setCPTData({})
		setProductsData([])
		setSalesVsQuantityTrendingData([])
		const Body = JSON.stringify(
			{
				"fromDate": fromDate,
				"toDate": toDate,
				"filterType": fType,
				"account": user.id,
				"dashboard": storeId
			}
		)
		await Promise.all([
			LoadDashboardData("custom", Body),
			LoadDashboardCityKPIs("custom", Body),
			LoadDashboardCostKPIs("custom", Body),
			LoadDashboardTrendingData("custom", Body)
		])
		LoadCustomProductSalesList(1, pSize, sortBy, sortDirection)
	}

	const LoadAnnualStats = async (year, pSize, sortBy, sortDirection) => {
		setFormData({})
		setJHBData({})
		setCPTData({})
		setProductsData([])
		setSalesVsQuantityTrendingData([])
		const Body = JSON.stringify(
			{
				"year": year,
				"account": user.id,

			}
		)

		await Promise.all([
			LoadDashboardData("annual", Body),
			LoadDashboardCityKPIs("annual", Body),
			LoadDashboardCostKPIs("annual", Body),
			LoadDashboardTrendingData("annual", Body)
		])
		LoadAnnualProductSalesList(1, pSize, year, sortBy, sortDirection)
		// LoadYearlyProfile(year)
	}


	const LoadAnnualProductSalesList = async (page_number, pageSize, year, sortBy, sortDirection) => {
		setCurrentPage(page_number)
		const Body = JSON.stringify({
			"page_number": page_number,
			"page_size": pageSize,
			"year": year,
			"sortBy": sortBy,
			"sortDirection": sortDirection,
		})
		setProductsData([])
		try {
			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v4/product/sales/annual/updated/`, Body, config)
			setProductsData(response.data?.results)
			setTotalProducts(response.data?.total_records)
			setPageLimit(response.data?.pagelimit)
			setTotalPages(response.data?.total_pages)
		} catch (error) {

		}

		// const config = {
		// 	method: 'post',
		// 	maxBodyLength: Infinity,
		// 	url: `${process.env.REACT_APP_BACKEND_URL}/api/v4/overall/product/sales/annual/updated/`,
		// 	headers: { 
		// 	  'Authorization': `JWT ${localStorage.getItem('access')}`, 
		// 	  'Content-Type': 'application/json'
		// 	},
		// 	data : Body
		// };

		// axios.request(config).then(
		// 	(response) => {
		// 		const result = (response.data);
		// 		const lines = result.split('\n\n');
		// 		// console.log(lines);
		// 		for (const line of lines) {
		// 			if (line.startsWith('data:')) {

		// 				const responseData = line.slice(5); // Remove 'data:' prefix
		// 				if (responseData.trim() === '[DONE]') { 
		// 					continue
		// 					// const data = JSON.parse(jsonData);
		// 				}
		// 				const JSONResponse = JSON.parse(responseData)
		// 				setProductsData(JSONResponse['results'])
		// 				setTotalProducts(JSONResponse['total_records'])
		// 				setTotalProducts(JSONResponse['total_records'])
		// 				setTotalPages(JSONResponse['has_pages'])
		// 			}
		// 		}
		// 	}
		// ).catch((error) => {
		// 	console.log(error);
		// });

	}

	const refreshData = async () => {

		setLoadingVisibility("block")
		setDataVisibility("none")
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `JWT ${localStorage.getItem('access')}`
			}
		}
		const Body = JSON.stringify({
			"account": user.id,
		})
		try {
			await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/overall/refreshWeeklyData/`, Body, config)

			if (FilterType == 1) {
				LoadAnnualStats(Year, PageSize, Sortby, SortDirection)
			} else if (FilterType == 2) {
				LoadMonthlyStats(MonthYear + "-" + Month, PageSize, Sortby, SortDirection)
			} else if (FilterType == 3) {
				LoadDayStats(CurrentDate, PageSize, Sortby, SortDirection)
			} else if (FilterType == 4) {
				LoadCustomStats(FromDate, ToDate, PageSize, CustomFilterType)
			}
			if (FilterType == 5) {
				LoadDayStats(yesterday, PageSize, Sortby, SortDirection)
				// LoadSaleProfile(Moment(yesterday).format('YYYY-MM'))
			}
			//   console.log('Users', JSON.stringify(response.data.Data))
		} catch (error) {
		}


	}

	const LoadSaleProfile = async (date) => {
		setMonthProfile(0)
		setYearProfile(0)
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `JWT ${localStorage.getItem('access')}`
			}
		}
		const Body = JSON.stringify(
			{
				"date": "" + date
			}
		)
		try {
			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v4/day/sale-profile/`, Body, config)

			setMonthProfile(response.data?.month_revenue)
			setYearProfile(response.data?.year_revenue)

		} catch (error) {
		}

	}
	const LoadYearlyProfile = async (year) => {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `JWT ${localStorage.getItem('access')}`
			}
		}
		const Body = JSON.stringify(
			{
				"year": "" + year,
				"account": user.id,

			}
		)
		try {
			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/overall/yearlyProfile/`, Body, config)
			setYearProfile(response.data['stats'])
		} catch (error) {
			// console.log('first', error)
			//   setResponseError(error.response.statusText)
		}

	}
	// 

	// 
	const OnMonthChange = async (e) => {
		e.preventDefault()
		setMonth(e.target.value)
		LoadMonthlyStats(MonthYear + "-" + e.target.value)
	}
	const OnMonthYearChange = async (e) => {
		e.preventDefault()
		setMonthYear(e.target.value)
		LoadMonthlyStats(e.target.value + "-" + Month)
	}
	const OnDayChange = async (e) => {
		e.preventDefault()
		setCurrentDate(e.target.value)
		LoadDayStats(e.target.value)
	}
	const onFromDateChange = async (e) => {
		e.preventDefault()
		LoadCustomStats(e.target.value, ToDate)
		setFromDate(e.target.value)
	}
	const onToDateChange = async (e) => {
		e.preventDefault()
		LoadCustomStats(FromDate, e.target.value)
		setToDate(e.target.value)
	}
	const OnYearChange = async (e) => {
		e.preventDefault()
		LoadAnnualStats(e.target.value)
		setYear(e.target.value)
		// await delay(2000)
	}
	const ChangeFilter = (e) => {
		setFilterType(e.target.value)
		if (e.target.value == 1) {
			LoadAnnualStats(Year)
		} else if (e.target.value == 2) {
			LoadMonthlyStats(MonthYear + "-" + Month)
		} else if (e.target.value == 3) {
			LoadDayStats(CurrentDate)
		} else if (e.target.value == 4) {
			LoadCustomStats(FromDate, ToDate)
		}
		if (e.target.value == 5) {
			LoadDayStats(yesterday)
			// LoadSaleProfile(Moment(yesterday).format('YYYY-MM'))
		}
	}
	// 

	// Report


	const DownloadReport = async (e) => {
		e.preventDefault()
		downloadDetailedReport()
		// await delay(1000)

	}

	const downloadDetailedReport = async () => {

		setLoadingVisibility("block")
		setDataVisibility("none")
		try {
			if (FilterType == 1) {
				DownloadAnnualReport(Year)
			} else if (FilterType == 2) {
				if (Month === (Date_Var.getMonth()) + 1) {
					DownloadCurrentMonthlyRepor()
				} else {
					DownloadMonthlyReport(MonthYear + "-" + Month)
				}
			} else if (FilterType == 3) {
				if (CurrentDate === Date_Var.getFullYear() + "-" + ("0" + (Date_Var.getMonth() + 1)).slice(-2) + "-" + ("0" + Date_Var.getDate()).slice(-2)) {
					DownloadDayReport(Date_Var.getFullYear() + "-" + ("0" + (Date_Var.getMonth() + 1)).slice(-2) + "-" + ("0" + Date_Var.getDate()).slice(-2))
				} else {
					DownloadDayReport(CurrentDate)
				}
			} else if (FilterType == 4) {
				DownloadCustomReport(FromDate, ToDate)
			}
			if (FilterType == 5) {
				DownloadDayReport(yesterday)
			}
		} catch (error) {
			// console.log('first', error)
			//   setResponseError(error.response.statusText)
		}

		setLoadingVisibility("none")
		setDataVisibility("block")
	}

	const DownloadDayReport = async (date) => {
		setLoadingVisibility("block")
		setDataVisibility("none")
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `JWT ${localStorage.getItem('access')}`
			}
		}
		const Body = JSON.stringify(
			{
				"date": date,
				"account": user.id,
				reportType: 1

			}
		)
		try {
			// return axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/invoice/pdf/`,Body, config).then(function (response) {
			//     return response
			// })
			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/email/reporting/`, Body, config)
			Swal.fire({
				position: "bottom-end",
				type: "error",
				title: "Success",
				html: `${response?.data?.message}`,
				showConfirmButton: !1,
				timer: 5000,
				confirmButtonClass: "btn btn-primary",
				buttonsStyling: !1,
			})
		} catch (error) {
			// console.log('first', error)
		}
		setLoadingVisibility("none")
		setDataVisibility("block")
	}
	const DownloadCustomReport = async (fromDate, toDate) => {
		setLoadingVisibility("block")
		setDataVisibility("none")
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `JWT ${localStorage.getItem('access')}`
			}
		}
		const Body = JSON.stringify(
			{
				"fromDate": fromDate,
				"toDate": toDate,
				"account": user.id,
				"reportType": 3
			}
		)
		try {
			// return axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/invoice/pdf/`,Body, config).then(function (response) {
			//     return response
			// })
			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/email/reporting/`, Body, config)
			Swal.fire({
				position: "bottom-end",
				type: "error",
				title: "Success",
				html: `${response?.data?.message}`,
				showConfirmButton: !1,
				timer: 5000,
				confirmButtonClass: "btn btn-primary",
				buttonsStyling: !1,
			})
		} catch (error) {
			// console.log('first', error)
		}
		setLoadingVisibility("none")
		setDataVisibility("block")
	}
	const DownloadAnnualReport = async (year) => {
		setLoadingVisibility("block")
		setDataVisibility("none")
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `JWT ${localStorage.getItem('access')}`
			}
		}
		const Body = JSON.stringify(
			{
				"year": year,
				"account": user.id,
				"reportType": 4
			}
		)
		// console.log(`${process.env.REACT_APP_BACKEND_URL}/api/v1/email/reporting/`)
		try {
			// return axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/invoice/pdf/`,Body, config).then(function (response) {
			//     return response
			// })
			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/email/reporting/`, Body, config)
			console.log(response?.data)
			Swal.fire({
				position: "bottom-end",
				type: "error",
				title: "Success",
				html: `${response?.data?.message}`,
				showConfirmButton: !1,
				timer: 5000,
				confirmButtonClass: "btn btn-primary",
				buttonsStyling: !1,
			})
		} catch (error) {
			console.log('first', error)
		}
		setLoadingVisibility("none")
		setDataVisibility("block")
	}
	const DownloadMonthlyReport = async (date) => {
		setLoadingVisibility("block")
		setDataVisibility("none")
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `JWT ${localStorage.getItem('access')}`
			}
		}
		const Body = JSON.stringify(
			{
				"date": date,
				"account": user.id,
				reportType: 2
			}
		)
		try {
			// return axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/invoice/pdf/`,Body, config).then(function (response) {
			//     return response
			// })
			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/email/reporting/`, Body, config)
			Swal.fire({
				position: "bottom-end",
				type: "error",
				title: "Success",
				html: `${response?.data?.message}`,
				showConfirmButton: !1,
				timer: 5000,
				confirmButtonClass: "btn btn-primary",
				buttonsStyling: !1,
			})
		} catch (error) {
			// console.log('first', error)
		}
		setLoadingVisibility("none")
		setDataVisibility("block")
	}
	const DownloadCurrentMonthlyRepor = async () => {
		setLoadingVisibility("block")
		setDataVisibility("none")
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `JWT ${localStorage.getItem('access')}`
			}
		}
		try {
			// return axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/invoice/pdf/`,Body, config).then(function (response) {
			//     return response
			// })
			const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/overall/report/currentmonth/`, config)
			const url = `${process.env.REACT_APP_BACKEND_URL}/${response.data['file']}`
			window.open(url, '_blank').focus()
		} catch (error) {
			// console.log('first', error)
		}
		setLoadingVisibility("none")
		setDataVisibility("block")
	}
	// End Report

	localStorage.setItem(`filterType-dashboard-${storeId}`, FilterType)
	localStorage.setItem(`custom-filterType-dashboard-${storeId}`, CustomFilterType)
	localStorage.setItem(`page-size`, PageSize)
	localStorage.setItem(`sort-by`, Sortby)
	localStorage.setItem(`sort-direction`, SortDirection)
	localStorage.setItem(`year-dashboard-${storeId}`, Year)
	localStorage.setItem(`month-dashboard-${storeId}`, Month)
	localStorage.setItem(`monthyear-dashboard-${storeId}`, MonthYear)
	localStorage.setItem(`date-dashboard-${storeId}`, CurrentDate)
	localStorage.setItem(`yesterday-dashboard-${storeId}`, yesterday)
	localStorage.setItem(`fromdate-dashboard-${storeId}`, FromDate)
	localStorage.setItem(`todate-dashboard-${storeId}`, ToDate)

	// 
	useEffect(() => {
		if (user) {
			if (localStorage.getItem('page-size')) {
				setPageSize(localStorage.getItem('page-size'))
				if (localStorage.getItem(`filterType-dashboard-${storeId}`) == 1) {
					LoadAnnualStats(localStorage.getItem(`year-dashboard-${storeId}`), localStorage.getItem('page-size'), localStorage.getItem(`sort-by`), localStorage.getItem(`sort-direction`))
				} else if (localStorage.getItem(`filterType-dashboard-${storeId}`) == 2) {
					LoadMonthlyStats(localStorage.getItem(`monthyear-dashboard-${storeId}`) + "-" + localStorage.getItem(`month-dashboard-${storeId}`), localStorage.getItem('page-size'), localStorage.getItem(`sort-by`), localStorage.getItem(`sort-direction`))
				} else if (localStorage.getItem(`filterType-dashboard-${storeId}`) == 3) {
					LoadDayStats(localStorage.getItem(`date-dashboard-${storeId}`), localStorage.getItem('page-size'), localStorage.getItem(`sort-by`), localStorage.getItem(`sort-direction`))
				} else if (localStorage.getItem(`filterType-dashboard-${storeId}`) == 4) {
					LoadCustomStats(localStorage.getItem(`fromdate-dashboard-${storeId}`), localStorage.getItem(`todate-dashboard-${storeId}`), localStorage.getItem('page-size'), localStorage.getItem(`sort-by`), localStorage.getItem(`sort-direction`), localStorage.getItem(`custom-filterType-dashboard-${storeId}`))
				}
				if (localStorage.getItem(`filterType-dashboard-${storeId}`) == 5) {
					LoadDayStats(yesterday, localStorage.getItem('page-size'), localStorage.getItem(`sort-by`), localStorage.getItem(`sort-direction`))
					// LoadSaleProfile(Moment(yesterday).format('YYYY-MM'))
				}
			} else {
				if (localStorage.getItem(`filterType-dashboard-${storeId}`) == 1) {
					LoadAnnualStats(localStorage.getItem(`year-dashboard-${storeId}`), PageSize, Sortby, SortDirection)
				} else if (localStorage.getItem(`filterType-dashboard-${storeId}`) == 2) {
					LoadMonthlyStats(localStorage.getItem(`monthyear-dashboard-${storeId}`) + "-" + localStorage.getItem(`month-dashboard-${storeId}`), PageSize, Sortby, SortDirection)
				} else if (localStorage.getItem(`filterType-dashboard-${storeId}`) == 3) {
					LoadDayStats(localStorage.getItem(`date-dashboard-${storeId}`), PageSize, Sortby, SortDirection)
				} else if (localStorage.getItem(`filterType-dashboard-${storeId}`) == 4) {
					LoadCustomStats(localStorage.getItem(`fromdate-dashboard-${storeId}`), localStorage.PageSize, localStorage.getItem('page-size'), Sortby, SortDirection, CustomFilterType)
				}
				if (localStorage.getItem(`filterType-dashboard-${storeId}`) == 5) {
					LoadDayStats(yesterday, PageSize, Sortby, SortDirection)
					// LoadSaleProfile(Moment(yesterday).format('YYYY-MM'))
				}
			}
		}
		const interval = setInterval(() => {
			if (localStorage.getItem(`page-size`)) {
				setPageSize(localStorage.getItem(`page-size`))
				if (localStorage.getItem(`filterType-dashboard-${storeId}`) == 1) {
					LoadAnnualStats(localStorage.getItem(`year-dashboard-${storeId}`), localStorage.getItem(`page-size`), localStorage.getItem(`sort-by`), localStorage.getItem(`sort-direction`))
				} else if (localStorage.getItem(`filterType-dashboard-${storeId}`) == 2) {
					LoadMonthlyStats(localStorage.getItem(`monthyear-dashboard-${storeId}`) + "-" + localStorage.getItem(`month-dashboard-${storeId}`), localStorage.getItem(`page-size`), localStorage.getItem(`sort-by`), localStorage.getItem(`sort-direction`))
				} else if (localStorage.getItem(`filterType-dashboard-${storeId}`) == 3) {
					LoadDayStats(localStorage.getItem(`date-dashboard-${storeId}`), localStorage.getItem(`page-size`), localStorage.getItem(`sort-by`), localStorage.getItem(`sort-direction`))
				} else if (localStorage.getItem(`filterType-dashboard-${storeId}`) == 4) {
					LoadCustomStats(localStorage.getItem(`fromdate-dashboard-${storeId}`), localStorage.getItem(`todate-dashboard-${storeId}`), localStorage.getItem(`page-size`), localStorage.getItem(`sort-by`), localStorage.getItem(`sort-direction`), localStorage.getItem(`custom-filterType-dashboard-${storeId}`))
				}
				if (localStorage.getItem(`filterType-dashboard-${storeId}`) == 5) {
					LoadDayStats(yesterday, localStorage.getItem(`page-size`), localStorage.getItem(`sort-by`), localStorage.getItem(`sort-direction`))
					// LoadSaleProfile(Moment(yesterday).format('YYYY-MM'))
				}
			} else {
				if (localStorage.getItem(`filterType-dashboard-${storeId}`) == 1) {
					LoadAnnualStats(localStorage.getItem(`year-dashboard-${storeId}`), localStorage.getItem(`page-size`), localStorage.getItem(`sort-by`), localStorage.getItem(`sort-direction`))
				} else if (localStorage.getItem(`filterType-dashboard-${storeId}`) == 2) {
					LoadMonthlyStats(localStorage.getItem(`monthyear-dashboard-${storeId}`) + "-" + localStorage.getItem(`month-dashboard-${storeId}`), localStorage.getItem(`page-size`), localStorage.getItem(`sort-by`), localStorage.getItem(`sort-direction`))
				} else if (localStorage.getItem(`filterType-dashboard-${storeId}`) == 3) {
					LoadDayStats(localStorage.getItem(`date-dashboard-${storeId}`), localStorage.getItem(`page-size`), localStorage.getItem(`sort-by`), localStorage.getItem(`sort-direction`))
				} else if (localStorage.getItem(`filterType-dashboard-${storeId}`) == 4) {
					LoadCustomStats(localStorage.getItem(`fromdate-dashboard-${storeId}`), localStorage.getItem(`todate-dashboard-${storeId}`), localStorage.getItem(`page-size`), localStorage.getItem(`sort-by`), localStorage.getItem(`sort-direction`), localStorage.getItem(`custom-filterType-dashboard-${storeId}`))
				}
				if (localStorage.getItem(`filterType-dashboard-${storeId}`) == 5) {
					LoadDayStats(yesterday, localStorage.getItem(`page-size`), localStorage.getItem(`sort-by`), localStorage.getItem(`sort-direction`))
					// LoadSaleProfile(Moment(yesterday).format('YYYY-MM'))
				}
			}
		}, 300000)
		return () => {
			clearInterval(interval);
		}
	}, [])


	return (
		<>
			<Helmet>

				<title>{ CompanyProfile['companyName'] } Sales Dashboard</title>
				<meta
					name="description"
					content={ `${CompanyProfile['companyName']} Sales Dashboard` }
				/>
				<link rel="stylesheet" href="../assets/css/style.css" />
				<script src="../assets/js/script.js" />
			</Helmet>
			<div style={ { display: LoadingVisibility } }>
				<div id="global-loader" >
					<div className="whirly-loader"> </div>
				</div>
			</div>
			<div className="content">
				{/* Filter starts */ }
				<FilterComponent
					storeId={ 0 }
					pageSize={ PageSize }
					filterType={ FilterType }
					updateFilter={ setFilterType }
					updateTotalProducts={ setTotalProducts }
					Month={ Month }
					updateMonth={ setMonth }
					Year={ Year }
					updateYear={ setYear }
					MonthYear={ MonthYear }
					updateMonthYear={ setMonthYear }
					CurrentDate={ CurrentDate }
					updateCurrentDate={ setCurrentDate }
					FromDate={ FromDate }
					updateFromDate={ setFromDate }
					ToDate={ ToDate }
					updateToDate={ setToDate }
					years={ years }
					dayStats={ LoadDayStats }
					monthStats={ LoadMonthlyStats }
					annualStats={ LoadAnnualStats }
					customStats={ LoadCustomStats }
					refreshData={ refreshData }
					MonthProfile={ MonthProfile }
					YearProfile={ YearProfile }
					currencyFormatter={ formatter }
					Sortby={ Sortby }
					SortDirection={ SortDirection }
					CustomFilterType={ CustomFilterType }
					setCustomFilterType={ setCustomFilterType }
				/>
				{/* Filter Ends */ }
				<div style={ { display: dataVisibility } }>
					{
						FilterType == 3 ?
							<>
								<div>
									<DayDashboard
										numberFormatter={ numberFormatter }
										currencyFormatter={ formatter }
										kpiData={ FormData }
										costProfitKPIData={ CostProfitData }
										trendingData={ SalesVsQuantityTrendingData }
										dcData={ DCData }
										cptData={ CPTData }
										productData={ ProductsData }
										pageSize={ PageSize }
										onPageSizeChange={ onPageSizeChange }
										TotalProducts={ TotalProducts }
										currentDate={ CurrentDate }
										LoadDayProductSalesList={ LoadDayProductSalesList }
										MonthProfile={ MonthProfile }
										YearProfile={ YearProfile }
										Sortby={ Sortby }
										SortDirection={ SortDirection }
										onSortChange={ onSortChange }
										currentPage={ DayCurrentPage }
										setCurrentPage={ setDayCurrentPage }
										onSortDirectionChange={ onSortDirectionChange }
										TotalPages={ TotalPages }
									/>
								</div>
							</>
							: FilterType == 5 ?
								<>
									<div>
										<DayDashboard
											numberFormatter={ numberFormatter }
											currencyFormatter={ formatter }
											kpiData={ FormData }
											costProfitKPIData={ CostProfitData }
											trendingData={ SalesVsQuantityTrendingData }
											dcData={ DCData }
											cptData={ CPTData }
											productData={ ProductsData }
											pageSize={ PageSize }
											onPageSizeChange={ onPageSizeChange }
											TotalProducts={ TotalProducts }
											currentDate={ CurrentDate }
											LoadDayProductSalesList={ LoadDayProductSalesList }
											MonthProfile={ MonthProfile }
											YearProfile={ YearProfile }
											Sortby={ Sortby }
											SortDirection={ SortDirection }
											onSortChange={ onSortChange }
											onSortDirectionChange={ onSortDirectionChange }
											currentPage={ DayCurrentPage }
											setCurrentPage={ setDayCurrentPage }
											TotalPages={ TotalPages }
										/>
									</div>
								</>
								: FilterType == 2 ?
									<>
										<div>
											<MonthDashboard
												numberFormatter={ numberFormatter }
												currencyFormatter={ formatter }
												kpiData={ FormData }
												costProfitKPIData={ CostProfitData }
												trendingData={ SalesVsQuantityTrendingData }
												dcData={ DCData }
												cptData={ CPTData }
												productData={ ProductsData }
												pageSize={ PageSize }
												onPageSizeChange={ onPageSizeChange }
												TotalProducts={ TotalProducts }
												month={ Month }
												monthYear={ MonthYear }
												loadMonthProductSalesList={ LoadMonthProductSalesList }
												Sortby={ Sortby }
												SortDirection={ SortDirection }
												onSortChange={ onSortChange }
												currentPage={ MonthlyCurrentPage }
												setCurrentPage={ setMonthlyCurrentPage }
												onSortDirectionChange={ onSortDirectionChange }
												TotalPages={ TotalPages }
											/>
										</div>
									</>
									: FilterType == 1 ?
										<>
											<div>
												<AnnualDashboard
													numberFormatter={ numberFormatter }
													currencyFormatter={ formatter }
													kpiData={ FormData }
													costProfitKPIData={ CostProfitData }
													trendingData={ SalesVsQuantityTrendingData }
													dcData={ DCData }
													cptData={ CPTData }
													productData={ ProductsData }
													pageSize={ PageSize }
													onPageSizeChange={ onPageSizeChange }
													TotalProducts={ TotalProducts }
													year={ Year }
													loadAnnualProductSalesList={ LoadAnnualProductSalesList }
													Sortby={ Sortby }
													SortDirection={ SortDirection }
													onSortChange={ onSortChange }
													onSortDirectionChange={ onSortDirectionChange }
													currentPage={ CurrentPage }
													setCurrentPage={ setCurrentPage }
													TotalPages={ TotalPages }
												/>
											</div>
										</>
										: FilterType == 4 ?
											<>
												<div>
													<CustomDateDashboard
														numberFormatter={ numberFormatter }
														currencyFormatter={ formatter }
														kpiData={ FormData }
														costProfitKPIData={ CostProfitData }
														trendingData={ SalesVsQuantityTrendingData }
														dcData={ DCData }
														cptData={ CPTData }
														productData={ ProductsData }
														pageSize={ PageSize }
														onPageSizeChange={ onPageSizeChange }
														TotalProducts={ TotalProducts }
														fromDate={ FromDate }
														toDate={ ToDate }
														loadCustomProductSalesList={ LoadCustomProductSalesList }
														Sortby={ Sortby }
														SortDirection={ SortDirection }
														onSortChange={ onSortChange }
														currentPage={ CustomCurrentPage }
														setCurrentPage={ setCustomCurrentPage }
														onSortDirectionChange={ onSortDirectionChange }
														TotalPages={ TotalPages }
													/>
												</div>
											</>
											: <></>
					}
				</div>
				<hr />

				<div className=''>
					<div className='card'>
						<div className='card-body'>
							<div className=''>
								<div >
									<div className="card-header pb-0 d-flex justify-content-between align-items-center">
										<h4 className="card-title">Download Report</h4>
									</div>
									<div>
										<button
											onClick={ (e) => { DownloadReport(e) } }
											className="btn btn-md btn-primary col-12"
										>
											Download {
												FilterType == 1 ?
													Year :
													FilterType == 2 ?
														Moment(MonthYear + "-" + Month + "-01").format('MMMM YY') :
														FilterType == 3 ?
															Moment(CurrentDate).format('D MMM YY') :
															FilterType == 5 ?
																Moment(yesterday).format('D MMM YY') :
																Moment(FromDate).format('D MMM') + " to " + Moment(ToDate).format('D MMM YY')
											} Report
										</button>
									</div>

								</div>
								<div >
									<div className="card-header pb-0 d-flex justify-content-between align-items-center">
										<h4 className="card-title">Notes</h4>
									</div>
									<ol className="list-group list-group">
										<li className="list-group-item"><strong>Net Revenue*: </strong>It is difference of Total Revenue, Total Fee and CnR Revenue.</li>
										<li className="list-group-item"><strong>Total Fee*: </strong>It is total sum of takealot fee.</li>
										<li className="list-group-item"><strong>Total Cost*: </strong>It depends on the cost declared by User and sum of sold products' cost.</li>
										<li className="list-group-item"><strong>Total Profit*: </strong>It depends on the cost declared by User and then it is difference of Total Revenue and Total Cost.</li>
										<li className="list-group-item"><strong>CnR*: </strong>Cancelled+Returned Orders.</li>
										<li className="list-group-item">%Total Revenue = Change as comapared
											{
												FilterType == 1 ? ` between Years (${Year} and ${parseInt(Year) - 1})` :
													FilterType == 2 & Month === Date_Var.getMonth() + 1 ? ` between same number of days of this and last month` :
														FilterType == 2 & Month != Date_Var.getMonth() + 1 ? ` between selected and last month` :
															FilterType == 3 ? ` between ${Moment(CurrentDate).format('D MMM YY')} and ${Moment(CurrentDate).subtract(1, 'months').format('D MMM YY')}` :
																FilterType == 4 ? ` from ${Moment(FromDate).format('D MMM YY') + " to " + Moment(ToDate).format('D MMM YY')}` :
																	FilterType == 5 ? ` yesterday and day before yesterday.` : <></>
											}
										</li>
										<li className="list-group-item">%Total Fee = Ratio of Takealot Fee to Total Revenue</li>
										<li className="list-group-item">%Total Profit = Ratio of Takealot Profit to Total Revenue</li>
									</ol>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

const mapStateToProps = state => ({
	isAuthenticated: state.Auth.isAuthenticated,
	user: state.Auth.user,
})

export default connect(mapStateToProps, {})(CompanyDashboard)