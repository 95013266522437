import React, { useEffect, useState } from 'react'
import logo from '../assets/img/logo.png'
import logoWhite from '../assets/img/logo-white.png'
import logoSmall from '../assets/img/logo-small.png'
import { connect } from 'react-redux'
import { LogOut } from '../Actions/Auth'
import { NavLink, useLocation } from 'react-router-dom'
import { Helmet } from "react-helmet"
import axios from 'axios'

const Header = (props) => {
	const location = useLocation();
	const user = props.user
	const CompanyLogo = props.CompanyLogo
	const LogOut = props.LogOut
	const logout = e => {
		e.preventDefault()
		LogOut()
	}
	const [TotalUnreadNotifications, setTotalUnreadNotifications] = useState(0)
	const [NotificationsData, setNotificationsData] = useState([])
	const [User, setUser] = useState()
	const [searchBar, SetSearchBar] = useState(false)
	const [toggle, SetToggle] = useState(false)
	const handlesidebar = () => {
		document.body.classList.toggle("mini-sidebar")
		SetToggle((current) => !current)
	}
	const expandMenu = () => {
		document.body.classList.remove("expand-menu")
	}
	const expandMenuOpen = () => {
		document.body.classList.add("expand-menu")
	}
	const sidebarOverlay = () => {
		document.getElementById('main-wrapper').classList.toggle("slide-nav")
		document.querySelector(".main-wrapper").classList.toggle("slide-nav")
		document.querySelector(".sidebar-overlay").classList.toggle("opened")
		document.querySelector("html").classList.toggle("menu-opened")
		document.getElementById("html").classList.toggle("menu-opened")
	}
	const handleClick = (e) => {
		e.preventDefault();
		MarkNotificationRead()
	}
	const LoadNotificationsData = async () => {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `JWT ${localStorage.getItem('access')}`
			}
		}
		const body = JSON.stringify({ page_number: 1, page_size: 5 })
		try {
			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v2/notifications/get/`, body, config)
			// console.log(response.data)
			// console.log(response.data)
			setNotificationsData(response?.data?.results)
		} catch (error) {
			// console.log('first', error.response.status)
			if (error.response.status === 401) {
				LogOut()
			}
			//   setResponseError(error.response.statusText)
		}
	}
	const LoadUnreadNotifications = async () => {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `JWT ${localStorage.getItem('access')}`
			}
		}
		try {
			const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v2/notifications/get/unread/`, config)
			// console.log(response.data)
			setTotalUnreadNotifications(response.data['total'])
		} catch (error) {
			// console.log('first', error)
			//   setResponseError(error.response.statusText)
		}
	}
	const MarkNotificationRead = async () => {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `JWT ${localStorage.getItem('access')}`
			}
		}
		try {
			await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v2/notifications/update/all/`, config).then(
				LoadNotificationsData(),
				LoadUnreadNotifications()
			)
		} catch (error) {
			// console.log('first', error)
			//   setResponseError(error.response.statusText)
		}
	}
	// useEffect(() => {
	// 	if (user) {
	// 		LoadNotificationsData()
	// 		LoadUnreadNotifications()
	// 		const interval = setInterval(() => { LoadNotificationsData(); LoadUnreadNotifications(); }, 10000)
	// 		return () => {
	// 			clearInterval(interval);
	// 		}
	// 	}

	// }, [user])

	return (
		<>

			<Helmet>
				<link rel="stylesheet" href="../assets/css/style.css" />
				<script src="../assets/js/script.js" />
				<script src="../assets/js/bootstrap.bundle.min.js"></script>
			</Helmet>
			{/* <!-- Header --> */ }
			{
				user ?
					<div className="header">

						{/* <!-- Logo --> */ }
						<div
							className={ `header-left ${toggle ? "" : "active"}` }
							onMouseLeave={ expandMenu }
							onMouseOver={ expandMenuOpen }
						>
							<NavLink to="/" className="logo logo-normal">
								<img src={ CompanyLogo } style={ { maxWidth: '100%', maxHeight: '100%' } } alt="" />
							</NavLink>
							<NavLink to="/" className="logo logo-white">
								<img src={ CompanyLogo } style={ { maxWidth: '100%', maxHeight: '100%' } } alt="" />
							</NavLink>
							<NavLink to="/" className="logo-small">
								<img src={ CompanyLogo } style={ { maxWidth: '100%', maxHeight: '100%' } } alt="" />
							</NavLink>
							<a
								id="toggle_btn"
								onClick={ handlesidebar }
							>
								<i data-feather="chevrons-left" className="feather-16"></i>
							</a>
						</div>
						{/* <!-- /Logo --> */ }

						<a
							id="mobile_btn"
							className="mobile_btn"
							href="#sidebar"
							onClick={ () => { sidebarOverlay() } }
						>
							<span className="bar-icon">
								<span></span>
								<span></span>
								<span></span>
							</span>
						</a>

						{/* <!-- Header Menu --> */ }
						<ul className="nav user-menu">

							{/* <!-- Search --> */ }
							<li className="nav-item nav-searchinputs">
								{/* <div className="top-nav-search">
										
										<a href="javascript:void(0)" className="responsive-search">
											<i className="fa fa-search"></i>
										</a>
										<form action="#">
											<div className="searchinputs">
												<input type="text" placeholder="Search" />
												<div className="search-addon">
													<span><i data-feather="search" className="feather-14"></i></span>
												</div>
											</div>
											<!-- <a className="btn"  id="searchdiv"><img src="assets/img/icons/search.svg" alt="img"></a> -->
										</form>
									</div> */}
							</li>
							{/* <!-- /Search --> */ }

							{/* <!-- Flag --> */ }
							{/* <li className="nav-item dropdown has-arrow flag-nav nav-item-box">
									<a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="javascript:void(0)" role="button">
										<i data-feather="globe"></i>
									</a>
									<div className="dropdown-menu dropdown-menu-right">
										<a href="javascript:void(0)" className="dropdown-item active">
											<img src="assets/img/flags/us.png" alt="" height="16" /> English
										</a>
										<a href="javascript:void(0)" className="dropdown-item">
											<img src="assets/img/flags/fr.png" alt="" height="16" /> French
										</a>
										<a href="javascript:void(0)" className="dropdown-item">
											<img src="assets/img/flags/es.png" alt="" height="16" /> Spanish
										</a>
										<a href="javascript:void(0)" className="dropdown-item">
											<img src="assets/img/flags/de.png" alt="" height="16" /> German
										</a>
									</div>
								</li> */}
							{/* <!-- /Flag --> */ }

							<li className="nav-item nav-item-box">
								<a href="javascript:void(0)" id="btnFullscreen">
									<i data-feather="maximize"></i>
								</a>
							</li>
							{/* <li className="nav-item nav-item-box">
									<a href="email.html">
										<i data-feather="mail"></i>
										<span className="badge rounded-pill">1</span>
									</a>
								</li> */}
							{/* <!-- Notifications --> */ }
							<li className="nav-item dropdown nav-item-box">
								<a href="javascript:void(0)" className="dropdown-toggle nav-link" data-bs-toggle="dropdown">
									<i data-feather="bell"></i><span className="badge rounded-pill">{ TotalUnreadNotifications }</span>
								</a>
								<div className="dropdown-menu notifications">
									<div className="topnav-dropdown-header">
										<span className="notification-title">Notifications</span>
										<a href="#" className="clear-noti" onClick={ (e) => { handleClick(e) } }> Clear All </a>
									</div>
									<div className="noti-content">
										<ul className="notification-list">
											{
												NotificationsData ? NotificationsData.map(
													(key, i) => {
														return (
															<>
																<li
																	key={ i }>
																	<a href="#">
																		<div className="media d-flex">
																			<div className="media-body flex-grow-1">
																				<p className="noti-details">
																					{
																						key?.type == "System" ?
																							<>
																								<span href="#" className="noti-title">{ key.title }</span>
																								<p>
																									{ key.message }
																								</p>
																								{
																									key.downloading_link ?
																										<>
																											<a target='_blank' href={ `${process.env.REACT_APP_BACKEND_URL}/${key.downloading_link}` } className="btn btn-primary btn-sm">Download</a>
																										</>
																										:
																										<></>
																								}
																								{
																									key?.log ?
																										<>
																											<NavLink to="/logs/details"
																												key={ location.key }
																												state={
																													{
																														log_id: key?.log
																													}
																												} className="btn btn-primary btn-sm">View Logs for { key?.log_name }</NavLink>
																										</>
																										:
																										<></>
																								}
																							</>
																							:
																							key?.type == "Staff" ?
																								<>
																									<span href="#" className="noti-title">{ key.title }</span>
																									<p>
																										{ key.message }
																									</p>
																								</>

																								:
																								key?.type == "Admin" ?
																									<>
																										<span className="noti-title">{ key.title } { key.user_name }</span> { key.message } { key.plan ? <span className="noti-title"> { key.plan }</span> : "" }
																									</>
																									:
																									<>
																										<a href="#" className="noti-title">{ key.title }</a>
																										<p>
																											{ key.message }
																										</p>
																									</>
																					}
																				</p>
																				<p className="noti-time"><span className="notification-time">{ key.time }</span></p>
																			</div>
																		</div>
																	</a>
																</li>
															</>
														)
													}
												) : <></>
											}
										</ul>
									</div>
									<div className="topnav-dropdown-footer">
										<NavLink to="/notifications">View all Notifications</NavLink>
									</div>
								</div>
							</li>
							{/* <!-- /Notifications --> */ }

							{/* <li className="nav-item nav-item-box">
									<a href="generalsettings.html"><i data-feather="settings"></i></a>
								</li> */}
							<li className="nav-item dropdown has-arrow main-drop">
								<a href="#" className="dropdown-toggle nav-link userset" data-bs-toggle="dropdown">
									<span className="user-info">
										<span className="user-letter" style={ { backgroundColor: 'silver' } }>
											<img src={ CompanyLogo } style={ { maxWidth: '100%', maxHeight: '100%' } } alt="" className="img-fluid" />
										</span>
										<span className="user-detail">
											<span className="user-name">{ user.first_name + " " + user.last_name }</span>
											{/* <span className="user-role">Super Admin</span> */ }
										</span>
									</span>
								</a>
								<div className="dropdown-menu menu-drop-user">
									<div className="profilename">
										<div className="profileset">
											<span className="user-img">
												<img src={ CompanyLogo } style={ { maxWidth: '100%', maxHeight: '100%' } } alt="" />
												<span className="status online"></span>
											</span>
											<div className="profilesets">
												<h6>{ user.first_name + " " + user.last_name }</h6>
												{/* <h6>Super Admin</h6> */ }
											</div>
										</div>
										<hr className="m-0" />
										<NavLink className="dropdown-item" to="/user-profile"> <i className="me-2" data-feather="settings"></i> Settings</NavLink>
										{/* <a className="dropdown-item" href="generalsettings.html"><i className="me-2" data-feather="settings"></i>Settings</a> */ }
										<hr className="m-0" />
										<a className="dropdown-item logout pb-0" href="#" onClick={ (e) => { logout(e) } }><img src="assets/img/icons/log-out.svg" className="me-2" alt="img" />Logout</a>
									</div>
								</div>
							</li>
						</ul>
						{/* <!-- /Header Menu --> */ }

						{/* <!-- Mobile Menu --> */ }
						<div className="dropdown mobile-user-menu">
							<a href="javascript:void(0)" className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v"></i></a>
							<div className="dropdown-menu dropdown-menu-right">
								<NavLink className="dropdown-item" to="/user-profile">My Profile</NavLink>
								<a className="dropdown-item" href="#" onClick={ (e) => { logout(e) } }>Logout</a>
							</div>
						</div>
						{/* <!-- /Mobile Menu --> */ }
					</div>
					:
					<div className="header">

						{/* <!-- Logo --> */ }
						<div className="header-left active">
							<NavLink to="/" className="logo logo-normal">
								<img src={ CompanyLogo } style={ { maxWidth: '100%', maxHeight: '100%' } } alt="" />
							</NavLink>
							<NavLink to="/" className="logo logo-white">
								<img src={ CompanyLogo } style={ { maxWidth: '100%', maxHeight: '100%' } } alt="" />
							</NavLink>
							<NavLink to="/" className="logo-small">
								<img src={ CompanyLogo } style={ { maxWidth: '100%', maxHeight: '100%' } } alt="" />
							</NavLink>
							<a id="toggle_btn">
								<i data-feather="chevrons-left" className="feather-16"></i>
							</a>
						</div>
						{/* <!-- /Logo --> */ }

						<a id="mobile_btn" className="mobile_btn" href="#sidebar">
							<span className="bar-icon">
								<span></span>
								<span></span>
								<span></span>
							</span>
						</a>

						{/* <!-- Header Menu --> */ }
						<ul className="nav user-menu">

							{/* <!-- Search --> */ }
							<li className="nav-item nav-searchinputs">
								{/* <div className="top-nav-search">
									
									<a href="javascript:void(0)" className="responsive-search">
										<i className="fa fa-search"></i>
									</a>
									<form action="#">
										<div className="searchinputs">
											<input type="text" placeholder="Search" />
											<div className="search-addon">
												<span><i data-feather="search" className="feather-14"></i></span>
											</div>
										</div>
										<!-- <a className="btn"  id="searchdiv"><img src="assets/img/icons/search.svg" alt="img"></a> -->
									</form>
								</div> */}
							</li>
							{/* <!-- /Search --> */ }

							{/* <!-- Flag --> */ }
							{/* <li className="nav-item dropdown has-arrow flag-nav nav-item-box">
								<a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="javascript:void(0)" role="button">
									<i data-feather="globe"></i>
								</a>
								<div className="dropdown-menu dropdown-menu-right">
									<a href="javascript:void(0)" className="dropdown-item active">
										<img src="assets/img/flags/us.png" alt="" height="16" /> English
									</a>
									<a href="javascript:void(0)" className="dropdown-item">
										<img src="assets/img/flags/fr.png" alt="" height="16" /> French
									</a>
									<a href="javascript:void(0)" className="dropdown-item">
										<img src="assets/img/flags/es.png" alt="" height="16" /> Spanish
									</a>
									<a href="javascript:void(0)" className="dropdown-item">
										<img src="assets/img/flags/de.png" alt="" height="16" /> German
									</a>
								</div>
							</li> */}
							{/* <!-- /Flag --> */ }

							{/* <li className="nav-item nav-item-box">
								<a href="javascript:void(0)" id="btnFullscreen">
									<i data-feather="maximize"></i>
								</a>
							</li>
							<li className="nav-item nav-item-box">
								<a href="email.html">
									<i data-feather="mail"></i>
									<span className="badge rounded-pill">1</span>
								</a>
							</li> */}
							{/* <!-- Notifications --> */ }
							<li className="nav-item dropdown nav-item-box">
								<a href="javascript:void(0)" className="dropdown-toggle nav-link" data-bs-toggle="dropdown">
									<i data-feather="bell"></i><span className="badge rounded-pill">2</span>
								</a>
								<div className="dropdown-menu notifications">
									<div className="topnav-dropdown-header">
										<span className="notification-title">Notifications</span>
										<a href="#" className="clear-noti"> Clear All </a>
									</div>
									<div className="noti-content">
										<ul className="notification-list">
											<li className="notification-message">
												<a href="activities.html">
													<div className="media d-flex">
														<span className="avatar flex-shrink-0">
															<img alt="" src="assets/img/profiles/avatar-02.jpg" />
														</span>
														<div className="media-body flex-grow-1">
															<p className="noti-details"><span className="noti-title">John Doe</span> added new task <span className="noti-title">Patient appointment booking</span></p>
															<p className="noti-time"><span className="notification-time">4 mins ago</span></p>
														</div>
													</div>
												</a>
											</li>
											<li className="notification-message">
												<a href="activities.html">
													<div className="media d-flex">
														<span className="avatar flex-shrink-0">
															<img alt="" src="assets/img/profiles/avatar-03.jpg" />
														</span>
														<div className="media-body flex-grow-1">
															<p className="noti-details"><span className="noti-title">Tarah Shropshire</span> changed the task name <span className="noti-title">Appointment booking with payment gateway</span></p>
															<p className="noti-time"><span className="notification-time">6 mins ago</span></p>
														</div>
													</div>
												</a>
											</li>
											<li className="notification-message">
												<a href="activities.html">
													<div className="media d-flex">
														<span className="avatar flex-shrink-0">
															<img alt="" src="assets/img/profiles/avatar-06.jpg" />
														</span>
														<div className="media-body flex-grow-1">
															<p className="noti-details"><span className="noti-title">Misty Tison</span> added <span className="noti-title">Domenic Houston</span> and <span className="noti-title">Claire Mapes</span> to project <span className="noti-title">Doctor available module</span></p>
															<p className="noti-time"><span className="notification-time">8 mins ago</span></p>
														</div>
													</div>
												</a>
											</li>
											<li className="notification-message">
												<a href="activities.html">
													<div className="media d-flex">
														<span className="avatar flex-shrink-0">
															<img alt="" src="assets/img/profiles/avatar-17.jpg" />
														</span>
														<div className="media-body flex-grow-1">
															<p className="noti-details"><span className="noti-title">Rolland Webber</span> completed task <span className="noti-title">Patient and Doctor video conferencing</span></p>
															<p className="noti-time"><span className="notification-time">12 mins ago</span></p>
														</div>
													</div>
												</a>
											</li>
											<li className="notification-message">
												<a href="activities.html">
													<div className="media d-flex">
														<span className="avatar flex-shrink-0">
															<img alt="" src="assets/img/profiles/avatar-13.jpg" />
														</span>
														<div className="media-body flex-grow-1">
															<p className="noti-details"><span className="noti-title">Bernardo Galaviz</span> added new task <span className="noti-title">Private chat module</span></p>
															<p className="noti-time"><span className="notification-time">2 days ago</span></p>
														</div>
													</div>
												</a>
											</li>
										</ul>
									</div>
									<div className="topnav-dropdown-footer">
										<a href="activities.html">View all Notifications</a>
									</div>
								</div>
							</li>
							{/* <!-- /Notifications --> */ }

							<li className="nav-item nav-item-box">
								<a href="generalsettings.html"><i data-feather="settings"></i></a>
							</li>
							<li className="nav-item dropdown has-arrow main-drop">
								<a href="javascript:void(0)" className="dropdown-toggle nav-link userset" data-bs-toggle="dropdown">
									<span className="user-info">
										<span className="user-letter">
											<img src="assets/img/profiles/avator1.jpg" alt="" className="img-fluid" />
										</span>
										<span className="user-detail">
											<span className="user-name">John</span>
											<span className="user-role">Super Admin</span>
										</span>
									</span>
								</a>
								<div className="dropdown-menu menu-drop-user">
									<div className="profilename">
										<div className="profileset">
											<span className="user-img"><img src="assets/img/profiles/avator1.jpg" alt="" />
												<span className="status online"></span></span>
											<div className="profilesets">
												<h6>John Smilga</h6>
												<h5>Super Admin</h5>
											</div>
										</div>
										<hr className="m-0" />
										<a className="dropdown-item" href="profile.html"> <i className="me-2" data-feather="user"></i> My Profile</a>
										<a className="dropdown-item" href="generalsettings.html"><i className="me-2" data-feather="settings"></i>Settings</a>
										<hr className="m-0" />
										<a className="dropdown-item logout pb-0" href="signin.html"><img src="assets/img/icons/log-out.svg" className="me-2" alt="img" />Logout</a>
									</div>
								</div>
							</li>
						</ul>
						{/* <!-- /Header Menu --> */ }

						{/* <!-- Mobile Menu --> */ }
						<div className="dropdown mobile-user-menu">
							<a href="javascript:void(0)" className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v"></i></a>
							<div className="dropdown-menu dropdown-menu-right">
								<a className="dropdown-item" href="profile.html">My Profile</a>
								<a className="dropdown-item" href="generalsettings.html">Settings</a>
								<a className="dropdown-item" href="signin.html">Logout</a>
							</div>
						</div>
						{/* <!-- /Mobile Menu --> */ }
					</div>
			}
			{/* <!-- Header --> */ }
		</>
	)
}
const mapStateToProps = (state) => ({
	isAuthenticated: state.Auth.isAuthenticated,
	user: state.Auth.user
})

export default connect(mapStateToProps, { LogOut })(Header)