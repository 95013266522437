import axios from 'axios'
import React, { useEffect, useState } from 'react'
import {currencyFormatter} from '../../modules/formatter'
import { LoadUser } from '../../Actions/Auth'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import Shopify from '../../assets/img/shopify.png'
import Woo from '../../assets/img/woocommerce.png'
const Subscriptions = (props) => {
    const user = props.user
    const [PaymentID, setPaymentID] = useState()
    const [Plans, setPlans] = useState([])
    const LoadPlans = async () => {
        const config = {
            headers: {
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
                'Authorization' : `JWT ${localStorage.getItem('access')}` 
            }
        } 
        try { 
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/subscription/plans/get/user/`, config)
            setPlans(response?.data?.data)
        } catch (error) {
        //   setCompanyProfile({
        //     companyName : "",
        //     companyLogo : ""
        // })
        }     
    }
    // Payment
    const payment = async(amount, item_name, planId) => {
        const config = {
            headers: {
                'Content-Type' : 'application/json',
                'Authorization' : `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify({
            "amount" : amount,
            "planId" : planId,
            "item_name" : item_name,
        })
        
        try { 
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/subscription/payment/pay/`, Body, config)
            setPaymentID(response?.data?.m_payment_id)
            let UUID = response?.data?.UUID
            let ReturnURL = response?.data?.return_url
            let CancelURL = response?.data?.cancel_url
            let NotifyURL = response?.data?.notify_url
            Swal.fire({
                position: "bottom-end",
                type: "success",
                title: "ALERT",
                html: `DO NOT CLOSE THE PASFAST PAYMENT WINDOW.`,
                showConfirmButton: !1,
                timer: 5000,
                confirmButtonClass: "btn btn-primary",
                buttonsStyling: !1,
            })
            // console.log(response?.data)
            window.payfast_do_onsite_payment(
                {
                    "uuid": UUID,
                    'notify_url': NotifyURL,  
                }, function (result) {
                    if (result === true) {
                        // Call API here to confirm
                        // Payment Completed
                        checkPayment(response?.data?.m_payment_id)
                    }
                    else {
                        // Payment Window Closed
                    }
                    
                }
            )
          // setNextLink(response.data['next'])
        } catch (error) {
          // console.log('first', error)
        }
    }
    // Check Payment
    const checkPayment = async(paymentId) => {
        const config = {
            headers: {
                'Content-Type' : 'application/json',
                'Authorization' : `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify({
            "m_payment_id" : paymentId
        })
        try { 
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/subscription/payment/check/`, Body, config)
            if (response?.status == 201) {
                Swal.fire({
                    position: "bottom-end",
                    type: "success",
                    title: "Success",
                    html: `${response?.data?.message}`,
                    showConfirmButton: !1,
                    timer: 5000,
                    confirmButtonClass: "btn btn-primary",
                    buttonsStyling: !1,
                })
                LoadPlans()
                props.LoadUser()
            }
        } catch (error) {
            console.log(error?.response?.data)
        }
    }
    const onClick = (e, amount, item_name, planId) => {
        e.preventDefault()
        payment(amount, item_name, planId)
    }
    // 
    useEffect(() => {
        LoadPlans()
    }, [])
    
    return (
        <div className='content'>
            <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
                <h1 className="display-4 fw-normal">Plans</h1>
                <p className="fs-5 text-muted">Following Plans are available.</p>
            </div>
            <hr/>
            <div className="row row-cols-1 row-cols-md-3 mb-3 text-center">
                {
                    Plans.map(
                        (row, i) => {
                            return(
                                <>
                                    <div key={i} className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="card mb-4 rounded-3 shadow-sm">
                                            <div className="card-header py-3">
                                                <h4 className="my-0 fw-normal">{row.name}</h4>
                                            </div>
                                            <div className="card-body">
                                                <h1 className="card-title pricing-card-title">{currencyFormatter('en-ZA','ZAR').format(row.pricing)}<small className="text-muted fw-light">/mo</small></h1>
                                                <ul className="list-unstyled mt-3 mb-4">
                                                    <li>Total Subscribers {row?.subscribers}</li>
                                                    <li>Total Active Subscribers {row?.activeSubscribers}</li>
                                                    <li>Trail: {row?.trailDays} days.</li>
                                                    <li>Upto <strong>{row?.totalProducts}</strong> SKUs can be added</li>
                                                    <li>{row?.takealotStores} store{row?.takealotStores > 1 ? "s" : ""} included</li>
                                                    {
                                                        row?.shopify ?
                                                        <li><img src={Shopify} width={'20%'} ></img></li>
                                                        : <></>
                                                    }
                                                    {
                                                        row?.woocommerce ?
                                                        <li><img src={Woo} width={'30%'} ></img></li>
                                                        : <></>
                                                    }
                                                    <li>Unlimited Invoice Downloading</li>
                                                    <li>Unlimited BuyBox Monitoring</li>
                                                    <li>Unlimited Email Reports</li>
                                                    <li>Help center access</li>
                                                </ul>
                                                {
                                                    user?.is_superuser ?
                                                    <></>
                                                    :
                                                    row?.joinedId == row.id ?
                                                    <button
                                                        type="button" 
                                                        className="w-100 btn btn-lg btn-outline-primary"
                                                    >
                                                        Joined
                                                    </button>
                                                    : 
                                                    <button
                                                        type="button" 
                                                        onClick={(e)=>{onClick(e, row.pricing, row.name, row.id)}}
                                                        className="w-100 btn btn-lg btn-outline-primary"
                                                    >
                                                        {
                                                            Number(row?.joinedId) > Number(row.id) ?
                                                            'Downgrade' : 'Upgrade'
                                                        }
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    )
                }
                
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
	isAuthenticated: state.Auth.isAuthenticated,
	user: state.Auth.user
})
  
export default connect(mapStateToProps, {LoadUser})(Subscriptions)