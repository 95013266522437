
import {
  // HashRouter as Router,
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Outlet
} from "react-router-dom"
import './assets/css/customstyles.css'
import MultiDashboard from './components/Multistore/MultiDashboard'
import SignIn from './components/Authentication/SignIn'
import Layout from "./Layout/Layout"
import DashboardLayout from "./Layout/DashboardLayout"
import NormalLayout from "./Layout/NormalLayout"
import ActivateAccount from './components/Authentication/ActivateAccount'
import './components/Styles/styles.css'
// Auth

import { Provider } from 'react-redux'
import store from './Store'
import { useEffect, useState } from "react"
import axios from "axios"
import MultiFinance from "./components/Multistore/MultiFinance"
import MultiProducts from "./components/Multistore/MultiProducts"
import MultiBuyBox from "./components/Multistore/MultiBuyBox"
import MultiCostCalculator from "./components/Multistore/MultiCostCalculator"
import Calculator from "./components/Products/CostCalculator/Calculator"
import CostCSV from "./components/Products/CostCalculator/CostCSV"
import MultiSales from "./components/Multistore/MultiSales"
import InvoiceComponent from "./components/Invoice"
import CombinedInvoice from "./components/CombinedInvoice"
// import Buybox from "./components/BuyBox"
import PaymentModule from "./components/Payment"
import UserProfile from "./components/User/UserProfile"
import CompanyProfileComponent from "./components/User/CompanyProfileComponent"
import API_Connections from "./components/User/APIConnections"
import NotificationSettings from "./components/User/NotificationSettings"
import UserProfileLayout from "./Layout/UserProfileLayout"
import Notifications from "./components/Notifications"
import ForgotPassword from "./components/Authentication/ForgotPassword"
import ResetPasswordConfirm from "./components/Authentication/ResetPasswordConfirm"
import SignUp from "./components/Authentication/SignUp"
import Subscriptions from "./components/Subscriptions"
import SubscriptionsManagement from "./components/AdminComponents/SubscriptionsManagement"
import UserManagement from "./components/AdminComponents/UserManagement"
import EditPlan from "./components/AdminComponents/SubscriptionsManagement/components/Edit"
import ViewPlan from "./components/AdminComponents/SubscriptionsManagement/components/View"
import MultiInventory from "./components/Multistore/MultiInventory"
import InventoryCalculator from "./components/Products/Inventory/InventoryCalculator"
import InventoryCSV from "./components/Products/Inventory/InventoryCSV"
import Transactions from "./components/Subscriptions/Transactions"
import StaffAccount from "./components/AdminComponents/UserManagement/components/StaffAccount"
import EditUser from "./components/AdminComponents/UserManagement/components/Edit"
// import AdminStoreProducts from "./components/AdminComponents/UserManagement/components/AdminStoreProducts"
import Settings from "./components/Settings"
// import AdminStoreSalesDashboard from "./components/AdminComponents/UserManagement/components/AdminStoreSalesDashboard"
import AddStaffUser from "./components/AdminComponents/UserManagement/components/StaffAccount/Components/AddStaffUser"
import EditStaffUser from "./components/AdminComponents/UserManagement/components/StaffAccount/Components/EditStaffUser"
import TestDashboard from "./components/Dashboard/testDashboard"
import Dashboard from "./components/Dashboard"
import Logs from "./components/Logs"
import LogDetails from "./components/Logs/Details"
// import Inventory from "./components/Inventory"

function App() {
  let link = document.querySelector("link[rel~='icon']")
  if (!link) {
    link = document.createElement('link')
    link.rel = 'icon'
    document.getElementsByTagName('head')[0].appendChild(link)
  }
  const [CompanyLogo, setCompanyLogo] = useState("")
  const [CompanyProfile, setCompanyProfile] = useState({
    id: "1",
    companyName: "",
    companyAddressStreet: "",
    companyAddressCity: "",
    companyAddressCountry: "",
    companyAddressPostalCode: "",
    companyVAT: ""
  })
  const LoadCompanyProfile = async () => {

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `JWT ${localStorage.getItem('access')}`
      }
    }
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/companyProfile/`, config)
      // console.log(response.data['company']['companyLogo'])
      setCompanyProfile(response.data['company'])

      const responseLogo = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/companyProfileImage/`, config)
      setCompanyLogo(`${process.env.REACT_APP_BACKEND_URL}/media/${responseLogo.data['companyLogo']['companyLogo']}`)

      link.href = `${process.env.REACT_APP_BACKEND_URL}/media/${responseLogo.data['companyLogo']['companyLogo']}`
    } catch (error) {
      // // console.log('first', error)
      setCompanyProfile({
        id: "1",
        companyName: "",
        companyAddressStreet: "1",
        companyAddressCity: "Lahore",
        companyAddressCountry: "Pakistan",
        companyAddressPostalCode: "54000",
        companyVAT: "123456"
      })
      setCompanyLogo('./assets/img/transparent.png')
      link.href = './assets/img/transparent.png'
    }


  }
  useEffect(() => {
    LoadCompanyProfile()
  }, [])
  return (
    <>
      <Provider store={ store }>
        <Router>
          <Layout>
            <Routes>
              <Route element={ <DashboardLayout loadSettings={ LoadCompanyProfile } logo={ CompanyLogo } CompanyProfile={ CompanyProfile } TotalUnreadNotifications={ 0 } /> }>
                <Route exact path="/" element={ <MultiDashboard CompanyProfile={ CompanyProfile } /> } />
                <Route exact path="/dashboard/:id" element={ <TestDashboard CompanyProfile={ CompanyProfile } /> } />
                <Route exact path="/finance" element={ <MultiFinance CompanyProfile={ CompanyProfile } /> } />
                <Route exact path="/products" element={ <MultiProducts CompanyProfile={ CompanyProfile } /> } />
                <Route exact path="/cost_calculator" element={ <MultiCostCalculator CompanyProfile={ CompanyProfile } /> } />
                <Route exact path="/product_cost_calculator" element={ <Calculator CompanyProfile={ CompanyProfile } /> } />
                <Route exact path="/costCSV" element={ <CostCSV CompanyProfile={ CompanyProfile } /> } />
                <Route exact path="/sales" element={ <MultiSales CompanyProfile={ CompanyProfile } /> } />
                <Route exact path="/invoice" element={ <InvoiceComponent CompanyProfile={ CompanyProfile } CompanyLogo={ CompanyLogo } /> } />
                <Route exact path="/combinedInvoice" element={ <CombinedInvoice CompanyProfile={ CompanyProfile } CompanyLogo={ CompanyLogo } /> } />
                <Route exact path="/buybox" element={ <MultiBuyBox CompanyProfile={ CompanyProfile } CompanyLogo={ CompanyLogo } /> } />
                <Route exact path="/notifications" element={ <Notifications CompanyProfile={ CompanyProfile } CompanyLogo={ CompanyLogo } /> } />
                <Route exact path="/payment" element={ <PaymentModule CompanyProfile={ CompanyProfile } CompanyLogo={ CompanyLogo } /> } />
                <Route exact path="/subscription-management" element={ <Subscriptions CompanyProfile={ CompanyProfile } CompanyLogo={ CompanyLogo } /> } />
                <Route exact path="/inventory" element={ <MultiInventory CompanyProfile={ CompanyProfile } CompanyLogo={ CompanyLogo } /> } />
                <Route exact path="/product_inventory" element={ <InventoryCalculator CompanyProfile={ CompanyProfile } /> } />
                <Route exact path="/inventoryCSV" element={ <InventoryCSV CompanyProfile={ CompanyProfile } /> } />
                {/* Admin Routes */ }
                <Route exact path="/transactions" element={ <Transactions CompanyProfile={ CompanyProfile } CompanyLogo={ CompanyLogo } /> } />
                <Route exact path="/plans-management" element={ <SubscriptionsManagement CompanyProfile={ CompanyProfile } CompanyLogo={ CompanyLogo } /> } />
                <Route exact path="/users-management" element={ <UserManagement CompanyProfile={ CompanyProfile } CompanyLogo={ CompanyLogo } /> } />
                <Route exact path="/users/staff/add" element={ <AddStaffUser CompanyProfile={ CompanyProfile } CompanyLogo={ CompanyLogo } /> } />
                <Route exact path="/users/staff/edit" element={ <EditStaffUser CompanyProfile={ CompanyProfile } CompanyLogo={ CompanyLogo } /> } />
                <Route exact path="/admin/users/staff" element={ <StaffAccount CompanyProfile={ CompanyProfile } CompanyLogo={ CompanyLogo } /> } />
                <Route exact path="/users/edit/user" element={ <EditUser CompanyProfile={ CompanyProfile } CompanyLogo={ CompanyLogo } /> } />
                <Route exact path="/plans/edit" element={ <EditPlan CompanyProfile={ CompanyProfile } CompanyLogo={ CompanyLogo } /> } />
                <Route exact path="/plans/view" element={ <ViewPlan CompanyProfile={ CompanyProfile } CompanyLogo={ CompanyLogo } /> } />
                {/* <Route exact path="/user/dashboard" element={ <AdminStoreSalesDashboard CompanyProfile={ CompanyProfile } CompanyLogo={ CompanyLogo } /> } /> */ }
                {/* <Route exact path="/user/products" element={ <AdminStoreProducts CompanyProfile={ CompanyProfile } CompanyLogo={ CompanyLogo } /> } /> */ }
                <Route exact path="/logs" element={ <Logs CompanyProfile={ CompanyProfile } CompanyLogo={ CompanyLogo } /> } />
                <Route exact path="/logs/details" element={ <LogDetails CompanyProfile={ CompanyProfile } CompanyLogo={ CompanyLogo } /> } />
                <Route exact path="/settings" element={ <Settings CompanyProfile={ CompanyProfile } CompanyLogo={ CompanyLogo } /> } />
                <Route element={ <UserProfileLayout loadSettings={ LoadCompanyProfile } logo={ CompanyLogo } CompanyProfile={ CompanyProfile } TotalUnreadNotifications={ 0 } /> }>
                  <Route exact path="/company-profile" element={ <CompanyProfileComponent loadSettings={ LoadCompanyProfile } CompanyProfile={ CompanyProfile } CompanyLogo={ CompanyLogo } /> } />
                  <Route exact path="/api-connections" element={ <API_Connections CompanyProfile={ CompanyProfile } CompanyLogo={ CompanyLogo } /> } />
                  <Route exact path="/notification-settings" element={ <NotificationSettings CompanyProfile={ CompanyProfile } CompanyLogo={ CompanyLogo } /> } />
                </Route>
              </Route>
              {/* <Route exact path="/" element={<SignIn /> } /> */ }
              <Route element={ <NormalLayout /> }>
                <Route exact path="/signin" element={ <SignIn /> } />
                <Route exact path="/signup" element={ <SignUp /> } />
                <Route exact path="/confirm" element={ <ActivateAccount /> } />
                <Route exact path="/forgetPassword" element={ <ForgotPassword /> } />
                <Route exact path="/reset-password-confirm" element={ <ResetPasswordConfirm /> } />
              </Route>
            </Routes>
          </Layout>
        </Router>
      </Provider>
    </>
  )
}

export default App
