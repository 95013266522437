import React, { useState } from 'react'
import LoginImage from '../../assets/img/login.jpg'
import Logo from '../../assets/img/companyLogo.png'
import LogoWhite from '../../assets/img/logo-white.png'
import {resetPassword} from '../../Actions/Auth'
import MailIcon from '../../assets/img/icons/mail.svg'
import { connect } from 'react-redux'
import {Navigate, NavLink} from 'react-router-dom'
import axios from 'axios'

const ForgotPassword = ({resetPassword, isAuthenticated}) => {
    const [eye, seteye] = useState(true)
    const [FormData, setFormData] = useState({
        email: ""
    })
    const [errorData, setErrorData] = useState({
        status: "",
        message: ""
    })
    const [SuccessData, setSuccessData] = useState({
        status: "",
        message: ""
    })
    const emailValidation = () =>{
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (regex.test(FormData['email']) === false){
            setErrorData({
                status: "Email Validity",
                message: "Email is not valid, Please enter a valid email"
            })
            setSubmissionErrorVisibilty("block")
            setTimeout(() => {
                setSubmissionErrorVisibilty("none")
            }, 5000)
            return false
        }
        return true
    }
    const [RequestSent, setRequestSent] = useState(false)
    const [SuccessVisibilty, setSuccessVisibilty] = useState("none")
    const [SubmissionErrorVisibilty, setSubmissionErrorVisibilty] = useState("none")
    const {
        email
    } = FormData
    const onChange = e => setFormData({...FormData, [e.target.name]: e.target.value})
    const [errors, seterrors] = useState({
        email: ""
    })
    const onEyeClick = () =>{
        seteye(!eye)
    }
    const [LoadingVisibility, setLoadingVisibility] = useState("none")
    const onSubmit = async(e) => {
        e.preventDefault()
        if (emailValidation()){
            const response = await resetPassword(FormData)
            if (response.status === 204){
                setSuccessData({
                    status: "Success",
                    message: "Reset Link is sent your given Email."
                })
                setSuccessVisibilty("block")
                setSubmissionErrorVisibilty("none")
                setTimeout(() => {
                    setRequestSent(true)
                    setSuccessVisibilty("none")
                }, 5000)
            } else {
              
                if (response.data) {
                    setErrorData({
                    status: "Account",
                    message: response.data  ,
                    errors: ""
                    })
                }
        
                setSubmissionErrorVisibilty("block")
                setTimeout(() => {
                    setSubmissionErrorVisibilty("none")
                }, 5000)
            }
        }
    }
    
    if (RequestSent) {
        return <Navigate to='/signin' />
    }
    if (isAuthenticated == true) {
        return <Navigate to="/" />;
    }
    return (
        <>
            <div style={{display: LoadingVisibility}}>
                <div id="global-loader" >
                    <div className="whirly-loader"> </div>
                </div>
            </div>
            <div className="main-wrapper">
                <div className="account-content">
                    <div className="login-wrapper">
                        <div className="login-content">
                            <div className="login-userset">
                                <form onSubmit={(e)=>{onSubmit(e)}}>
                                    {/* <div className="login-logo">
                                        <img src={Logo} alt="img" />
                                    </div> */}
                                    <div className="login-userheading">
                                        <h3>Forgot your Password?</h3>
                                        <h4>Enter your email to reset the password</h4>
                                    </div>
                                    <div className="alert alert-success text-center" style={{display: SuccessVisibilty}} role="alert">
                                        <p><b>{SuccessData.status}: {SuccessData.message} </b></p>
                                    </div>
                                    <div className="alert alert-danger text-center" style={{display: SubmissionErrorVisibilty}} role="alert">
                                        <p><b>Error {errorData.status}: {errorData.message} </b></p>
                                    </div>
                                    <div className="form-login">
                                        <label>Email</label>
                                        <div className="form-addons">
                                            <input
                                                type="email"
                                                value={email}
                                                name='email'
                                                onChange={(e)=>{onChange(e)}}
                                                className={` ${errors.email ? 'is-invalid' : ''}`}
                                                placeholder="Enter your email address"
                                            />
                                            <img src={MailIcon} alt="img" />
                                            <div className="invalid-feedback">{errors.email?.message}</div>

                                        </div>
                                    </div>
                                    <div className="form-login">
                                        <div className="alreadyuser">
                                            <h4>
                                                <NavLink to="/signin" className="hover-a">
                                                    Sign In?
                                                </NavLink>
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="form-login">
                                        <button type='submit' className="btn btn-login" >
                                            Reset Password
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="login-img">
                            <img src={LoginImage} alt="img" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.Auth.isAuthenticated,
})
  
  
export default connect(mapStateToProps, {resetPassword})(ForgotPassword)