import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'

const UserProfileLayout = () => {
    return (
        <>
            <div className='content '>
                <nav className="navbar navbar-expand-lg navbar-light" style={{backgroundColor: 'transparent'}}>
                    <div className="container-fluid">
                        <div className='justify-content-center'>
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <NavLink className="nav-link" aria-current="page" to="/user-profile">User Profile</NavLink>
                                </li>
                                {/* <li className="nav-item">
                                    <NavLink className="nav-link" aria-current="page" to="/company-profile">Company Profile</NavLink>
                                </li> */}
                                <li className="nav-item">
                                    <NavLink className="nav-link" aria-current="page" to="/api-connections">API Connections</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" aria-current="page" to="/notification-settings">Notification Settings</NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                    </nav>
                <Outlet />
            </div>
        </>
    )
}

export default UserProfileLayout