import React from 'react'
import { Helmet } from "react-helmet"
import { connect } from 'react-redux'
import Chart from "react-apexcharts"
import MainDashboardPagination from '../../Pagination/MainDashboardPagination'
const ProductKPIs = (props) => {
	const KPIsData = props.KPIsData
	const currencyFormatter = props.currencyFormatter
	const numberFormatter = props.numberFormatter
	const ProductData = props.ProductsData
	const PageSize = props.pageSize
	const TotalProducts = props.TotalProducts
	const loadDayProductList = props.loadDayProductList
	return (
		<>
			<Helmet>
				<link rel="stylesheet" href="../assets/css/style.css" />
				<script src="../assets/js/script.js" />
				<script src="../assets/plugins/apexchart/chart-data.js" />
			</Helmet>
			<div>
				<div className="row">
					<div className="col-lg-3 col-sm-6 col-12 d-flex">
						<div className="dash-count">
							<div className="dash-counts">
								<h4>{ numberFormatter.format(KPIsData?.totalProducts || 0) }</h4>
								<h5>Total Products</h5>
							</div>
							<div className="dash-imgs">
								<i data-feather="user"></i>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-sm-6 col-12 d-flex">
						<div className="dash-count das1">
							<div className="dash-counts">
								<h4>{ numberFormatter.format(KPIsData?.buyableProducts || 0) }</h4>
								<h5>Buyable Products</h5>
							</div>
							<div className="dash-imgs">
								<i data-feather="user-check"></i>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-sm-6 col-12 d-flex">
						<div className="dash-count das2">
							<div className="dash-counts">
								<h4>{ numberFormatter.format(KPIsData?.notbuyableProducts || 0) }</h4>
								<h5>Not Buyable</h5>
							</div>
							<div className="dash-imgs">
								<i data-feather="file-text"></i>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-sm-6 col-12 d-flex">
						<div className="dash-count das3">
							<div className="dash-counts">
								<h4>{ numberFormatter.format(KPIsData?.disabledProducts || 0) }</h4>
								<h5>Disabled Products</h5>
							</div>
							<div className="dash-imgs">
								<i data-feather="file"></i>
							</div>
						</div>
					</div>
				</div>
				{/* <!-- Button trigger modal --> */ }

			</div>
		</>
	)
}

const mapStateToProps = state => ({
	isAuthenticated: state.Auth.isAuthenticated,
	user: state.Auth.user,
})

export default connect(mapStateToProps)(ProductKPIs)