import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { currencyFormatter, numberFormatter } from '../../../../modules/formatter'
import ProductPagination from '../../../../modules/Pagination'
import ProductCostModal from '../ProductCostModal'
const ProductListComponent = (props) => {
    const ProductList = props.ProductList
    const TotalProducts = props.TotalProducts
    const formatter = props.formatter
    const storeId = props.storeId

    const PageSize = props.PageSize
    const Sortby = props.Sortby
    const SortDirection = props.SortDirection
    const onSortChange = props.onSortChange
    const onPageSizeChange = props.onPageSizeChange
    const onSortDirectionChange = props.onSortDirectionChange
    return (
        <>

            <div className="card mb-0">
                <div className="card-body">
                    <div className="card-header pb-0 d-flex justify-content-between align-items-center">
                        <h4 className="card-title">Product List</h4>
                        <div className="graph-sets">
                            <div className="dropdown mx-1">
                                <button className="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Page Size
                                </button>
                                <ul className="dropdown-menu">
                                    <li><a className={ PageSize === 10 ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onPageSizeChange(e, "10") } } href="#">10</a></li>
                                    <li><a className={ PageSize === 20 ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onPageSizeChange(e, "20") } } href="#">20</a></li>
                                    <li><a className={ PageSize === 50 ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onPageSizeChange(e, "50") } } href="#">50</a></li>
                                    <li><a className={ PageSize === 100 ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onPageSizeChange(e, "100") } } href="#">100</a></li>
                                </ul>
                            </div>
                            <div className="dropdown">
                                <button className="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Sort by
                                </button>
                                <ul className="dropdown-menu">
                                    <li><a className={ Sortby === "sales" ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onSortChange(e, "sales") } } href="#">Sales (Last 30 days)</a></li>
                                    <li><a className={ Sortby === "units" ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onSortChange(e, "units") } } href="#">Units (Last 30 days)</a></li>
                                    <li><a className={ Sortby === "title" ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onSortChange(e, "title") } } href="#">Product Title</a></li>
                                    <li><a className={ Sortby === "selling_price" ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onSortChange(e, "selling_price") } } href="#">Selling Price</a></li>
                                    <li><a className={ Sortby === "stock_at_takealot_total" ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onSortChange(e, "stock_at_takealot_total") } } href="#">Stock at Takealot</a></li>
                                    <li><a className={ Sortby === "cost" ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onSortChange(e, "cost") } } href="#">Cost</a></li>
                                    <li><a className={ Sortby === "stock" ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onSortChange(e, "stock") } } href="#">Stock at Warehouse</a></li>
                                </ul>
                            </div>
                            <div className='py-1'>
                                <button
                                    onClick={ (e) => { onSortDirectionChange(e, "up") } }
                                    className={
                                        SortDirection === "up" ?
                                            'btn btn-primary mx-1 my-1'
                                            :
                                            'btn btn-outline-primary mx-1'
                                    }
                                >
                                    <i class="fa-solid fa-arrow-up-wide-short"></i>
                                </button>
                            </div>
                            <div className='py-1'>
                                <button
                                    onClick={ (e) => { onSortDirectionChange(e, "down") } }
                                    className={
                                        SortDirection === "down" ?
                                            'btn btn-primary mx-1'
                                            :
                                            'btn btn-outline-primary mx-1 my-1'
                                    }
                                >
                                    <i class="fa-solid fa-arrow-down-wide-short"></i>
                                </button>
                            </div>
                            <div className='px-1 w-50'>
                                <NavLink to="/costCSV" className='btn btn-primary'>Update from CSV</NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive text-wrap">
                        <table className="table">
                            <thead>

                                <tr>
                                    <th>
                                        Title
                                    </th>
                                    {
                                        Sortby === "sales" || Sortby === "units" ?
                                            <>
                                                <th>
                                                    Units
                                                </th>
                                                <th>
                                                    Sales
                                                </th>
                                            </>
                                            : <></>
                                    }
                                    <th>
                                        Cost
                                    </th>
                                    <th >
                                        Cost (USD)
                                    </th>
                                    {
                                        storeId == 0 ?
                                            <th>
                                                Store
                                            </th>
                                            : <></>
                                    }
                                    <th>
                                        Status
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    !ProductList?.loading ?
                                        ProductList.map(
                                            (row, i) => {
                                                return (
                                                    <>
                                                        <tr key={ i }>
                                                            <td>
                                                                {/* <img src={row?.image_url} alt="Avatar" className="rounded-circle avatar avatar-md pull-up" /> */ }
                                                                {/* <NavLink
                                                                    to={ { pathname: "/product_cost_calculator" } }
                                                                    state={
                                                                        {
                                                                            tsin_id: row?.tsin_id,
                                                                            sku: row?.sku,
                                                                            dashboard: row?.dashboard,
                                                                            CompanyProfile: props.CompanyProfile
                                                                        }
                                                                    }>
                                                                    { row?.title } ({ row?.sku })
                                                                </NavLink> */}
                                                                <p data-bs-toggle="modal" onClick={ () => { props.LoadProductDetails(row?.offer_id) } } data-bs-target={ "#productCostModal-" + storeId }>{ row?.title } { row?.sku ? `(${row?.sku})` : null }</p>
                                                            </td>
                                                            {
                                                                Sortby === "sales" || Sortby === "units" ?
                                                                    <>
                                                                        <td>
                                                                            { numberFormatter('en-ZA').format(row?.units) }
                                                                        </td>
                                                                        <td>
                                                                            { currencyFormatter('en-ZA', 'ZAR').format(row?.sales) }
                                                                        </td>
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }
                                                            <td>
                                                                <a onClick={ (e) => { props.LoadProductCostDetails(e, row?.tsin_id) } } data-bs-toggle="modal" data-bs-target={ "#editProductCostModal-" + 1 } value={ numberFormatter('en-ZA', 0).format(row?.cost) }>{ formatter.format(row?.cost) }</a>
                                                            </td>
                                                            <td>
                                                                <a onClick={ (e) => { props.LoadProductCostDetails(e, row?.tsin_id) } } data-bs-toggle="modal" data-bs-target={ "#editProductCostModal-" + 1 } value={ `$ ${row?.cost_per_unit_usd}` }>${ row?.cost_per_unit_usd ? row?.cost_per_unit_usd : 0 }</a>
                                                            </td>
                                                            {
                                                                storeId == 0 ?
                                                                    <td>
                                                                        { row?.store_name }
                                                                    </td>
                                                                    : <></>
                                                            }
                                                            <td>
                                                                {
                                                                    row?.status === "Buyable" ?
                                                                        <span className="bg-label-primary ">
                                                                            { row?.status }
                                                                        </span>
                                                                        :
                                                                        row?.status === "Not Buyable" ?
                                                                            <span className="bg-label-danger ">
                                                                                { row?.status }
                                                                            </span>
                                                                            :
                                                                            <span className="bg-label-secondary ">
                                                                                { row?.status }
                                                                            </span>
                                                                }
                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                            })
                                        :
                                        Array.from({ length: PageSize }).map((_, index) => (
                                            <tr key={ index }>
                                                <td className="productimgname">
                                                    <h5>
                                                        <span className="skeleton-loader"></span>
                                                    </h5>
                                                </td>
                                                <td>
                                                    <h5>
                                                        <span className="skeleton-loader"></span>
                                                    </h5>
                                                </td>
                                                <td>
                                                    <h5>
                                                        <span className="skeleton-loader"></span>
                                                    </h5>
                                                </td>
                                                <td>
                                                    <h5>
                                                        <span className="skeleton-loader"></span>
                                                    </h5>
                                                </td>
                                                <td>
                                                    <h5>
                                                        <span className="skeleton-loader"></span>
                                                    </h5>
                                                </td>
                                                <td>
                                                    <h5>
                                                        <span className="skeleton-loader"></span>
                                                    </h5>
                                                </td>
                                            </tr>
                                        ))
                                }
                            </tbody>
                        </table>
                        <ProductCostModal
                            storeId={ props.storeId }
                            productId={ 0 }
                            ProductInfo={ props.ProductInfo }
                            CostInfo={ props.CostInfo }
                            onCostInfoUpdate={ props.onCostInfoUpdate }
                            onCostInfoSubmit={ props.onCostInfoSubmit }
                        />
                        <br />
                        <div className='pagination justify-content-center'>
                            {
                                TotalProducts > 0 ?
                                    <>
                                        {/* <Pagination totalPages={ props.TotalPages } totalRecords={ TotalProducts } pageLimit={ PageSize } sortBy={ Sortby } sortDirection={ SortDirection } paginationFilters={ props.StatusFilters } paginationSearchQuery={ props.SearchQuery } paginationPageSize={ props.PageSize } accountId={ props.user.id } dashboard={ props.dashboardId } paginationOrderBy={ props.OrderBy } onPageChanged={ props.onLoadProductList } /> */ }
                                        <ProductPagination
                                            currentPage={ props.currentPage }
                                            storeId={ props.storeId }
                                            setPage={ props.setCurrentPage }
                                            totalPages={ props.TotalPages }
                                            pageSize={ PageSize }
                                            sBy={ Sortby }
                                            sQuery={ props.SearchQuery }
                                            sDirection={ SortDirection }
                                            onPageChange={ props.loadProductList }
                                        />
                                    </>
                                    // <Pagination  totalRecords={TotalProducts} pageLimit={props.PageSize} paginationFilters={props.StatusFilters} paginationSearchQuery={props.SearchQuery} accountId={props.user.id} dashboard={1} paginationOrderBy={props.OrderBy} onPageChanged={props.loadProductList} />
                                    : <></>
                            }
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.Auth.isAuthenticated,
    user: state.Auth.user,
})

export default connect(mapStateToProps, {})(ProductListComponent)