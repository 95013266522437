import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'

const UserProfile = (props) => {
    const user = props.user
    const [userName, setUserName] = useState("")
    const [MessageTitle, setMessageTitle] = useState("")
    const [Message, setMessage] = useState("")
    const [MessageVisibility, setMessageVisibility] = useState("none")
    const [MessageType, setMessageType] = useState("primary")
    const [userData, setUserData] = useState([])
    const [FormData, setFormData] = useState({
        new_password :  "",
        re_new_password : "",
        current_password : ""
      })
    const onChange = e => setFormData({...FormData, [e.target.name]: e.target.value})
    const updatePassword = async() => {
        setMessageVisibility("none")
        if (FormData['current_password'] === FormData['new_password']){
            Swal.fire({
                position: "bottom-end",
                type: "error",
                title: 'ERROR',
                text: `New passwords can't be same as old password.`,
                showConfirmButton: !1,
                timer: 1500,
                confirmButtonClass: "btn btn-primary",
                buttonsStyling: !1,
            })
        }
        else if (FormData['new_password'] === FormData['re_new_password']){
        const config = {
            headers: {
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
                'Authorization' : `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify(FormData)
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/users/set_password/`, Body ,config)
            // console.log(response.data)
            if (response.status === 204){
                Swal.fire({
                    position: "bottom-end",
                    type: "success",
                    title: 'Success',
                    text: `Your password has been updated.`,
                    showConfirmButton: !1,
                    timer: 3000,
                    confirmButtonClass: "btn btn-primary",
                    buttonsStyling: !1,
                })
                
                setFormData({
                    new_password :  "",
                    re_new_password : "",
                    current_password : ""
                })
            }
            // setSubmissionMessageVisibility("block")
        } catch (error) {
            // alert(error.response.data["current_password"])
            // setErrorData({
            //     status: error.response.status,
            //     message: error.response.statusText,
            //     errors: error.response.errors
            // })
            if ("current_password" in error.response.data){
                Swal.fire({
                    position: "bottom-end",
                    type: "error",
                    title: 'ERROR',
                    text: `Current Password is invalid.`,
                    showConfirmButton: !1,
                    timer: 1500,
                    confirmButtonClass: "btn btn-primary",
                    buttonsStyling: !1,
                })
            }
            // if (error.response.data["non_field_errors"]){
            //   setErrorMessage("")
            // }
        }}else{
            Swal.fire({
                position: "bottom-end",
                type: "error",
                title: 'ERROR',
                text: `Passwords do not match.`,
                showConfirmButton: !1,
                timer: 1500,
                confirmButtonClass: "btn btn-primary",
                buttonsStyling: !1,
            })
        }
    }
    const onSubmit = e => {
        e.preventDefault()
        updatePassword()
        // window.location.reload();
    }
    const deleteAccount = async(userPassword) => {
        const config = {
            data : {current_password : userPassword},
            headers: {
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
                'Authorization' : `JWT ${localStorage.getItem('access')}`
            }
        }
        try {
            const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/auth/users/me/`, config)
            Swal.fire({
                position: "bottom-end",
                type: "success",
                title: "Your account and stores data has been deleted permanently.",
                showConfirmButton: !1,
                timer: 1500,
                confirmButtonClass: "btn btn-primary",
                buttonsStyling: !1,
            })
            // setSubmissionMessageVisibility("block")
        } catch (error) {
            if (error?.response?.status == 401) {
                Swal.fire({
                    position: "bottom-end",
                    type: "error",
                    title: 'ERROR',
                    text: `Something went wrong.`,
                    html: `${error.response.data.detail}`,
                    showConfirmButton: !1,
                    timer: 1500,
                    confirmButtonClass: "btn btn-primary",
                    buttonsStyling: !1,
                })
            }
            if (error?.response?.data?.current_password){
                Swal.fire({
                    position: "bottom-end",
                    type: "error",
                    title: 'ERROR',
                    text: `Something went wrong.`,
                    html: `Password field: ${error.response.data.current_password}`,
                    showConfirmButton: !1,
                    timer: 1500,
                    confirmButtonClass: "btn btn-primary",
                    buttonsStyling: !1,
                })
            }
            // if (error.response.data["non_field_errors"]){
            //   setErrorMessage("")
            // }
        }
    }
    const onDelete = e => {
        Swal.fire({
            title: "Are you sure?",
            text: `You won't be able to revert back.!`,
            type: "warning",
            showCancelButton: !0,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
            confirmButtonClass: "btn btn-primary",
            cancelButtonClass: "btn btn-danger ml-1",
            buttonsStyling: !1,
        }).then(function (t) {
            t.value
              ? 
              Swal.fire({
                    title: "Account deletion?",
                    text: `Enter your password.!`,
                    type: "warning",
                    html: '<input type="password" className="swal2-input" placeholder="Enter your password!" id="password" type="text" style="display: flex;">',
                    showCancelButton: !0,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                    confirmButtonClass: "btn btn-primary",
                    cancelButtonClass: "btn btn-danger ml-1",
                    buttonsStyling: !1,
                    preConfirm: () => {
                        const password = Swal.getPopup().querySelector('#password').value
                        if (!password) {
                            Swal.showValidationMessage(`Please enter password`)
                        }
                        return { password: password }

                    }
                })
                .then(t.value ? 
                    (result) => {
                        if (result?.value?.password){
                            deleteAccount(result?.value?.password)
                        }
                    }
                : t.dismiss === Swal.DismissReason.cancel &&
                    Swal.fire({
                    title: "Cancelled",
                    text: `Your account is safe :)`,
                    type: "error",
                    confirmButtonClass: "btn btn-success",
                    })
                )
              : t.dismiss === Swal.DismissReason.cancel &&
                Swal.fire({
                  title: "Cancelled",
                  text: `Your account is safe :)`,
                  type: "error",
                  confirmButtonClass: "btn btn-success",
                })
        })
    
    }
    // console.log(JSON.stringify(CompanyProfileImage))
    useEffect(() => {
        if (user){
          setUserName(user["first_name"] + " " + user['last_name'])
          setUserData(user)
        }
    },[userName, userData, user])
    return (
        <>
            <div className="">
                {/* <div style={{display: MessageVisibility}}>
                    <PushNotification messageTitle={MessageTitle} message={Message} type={MessageType}  />
                </div> */}
                <div className="" id="">
                    <div className=''>
                        <div className='card-body'>
                            <div className="container-xxl flex-grow-1 container-p-y">
                                
                                
                                <h4 className="fw-bold py-3 mb-4">
                                    <span className="text-muted fw-light">{userName} / </span> Profile
                                </h4>

                            
                                    <div className="row">
                                                
                                                <div className="col-xl-12" id="navs-tabs-line-card-profile">
                                                    {/* <!-- About User --> */}
                                                    <div className="card mb-4">
                                                        <div className="tab-content p-0">
                                                            <div className="card-body">
                                                                <div className="tab-pane fade show active" id="navs-tabs-line-card-profile" role="tabpanel">
                                                                    <small className="text-muted text-uppercase">About</small>
                                                                    <ul className="list-unstyled mb-4 mt-3">
                                                                        <li className="d-flex align-items-center mb-3"><i className="bx bx-user"></i><span className="fw-semibold mx-2">Full Name:</span> <span>{userName}</span></li>
                                                                        <li className="d-flex align-items-center mb-3"><i className="bx bx-check"></i><span className="fw-semibold mx-2">Email:</span> <span>{userData['email']}</span></li>
                                                                    </ul>                                        
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                    {/* <!--/ About User -->
                                                    <!-- Profile Overview --> */}
                                                    {/* <!--/ Profile Overview --> */}
                                                </div>
                                                <h4 className="fw-bold py-3 mb-4">
                                                    <span className="text-muted fw-light">{userName} / </span> Update Password
                                                </h4>
                                                <div className='card mb-4'>
                                                    <div className="tab-content p-0">
                                                        <div className="card-body">
                                                            <div className="tab-pane fade show active" id="navs-tabs-line-card-profile" role="tabpanel">
                                                                <small className="text-muted text-uppercase">Change Password</small>
                                                                <div className='list-unstyled mb-4 mt-3'>
                                                                    <form onSubmit={e => onSubmit(e)}>
                                                                        <div className="mb-3">
                                                                            <label htmlFor="CurrentPassword" className="form-label">Current Password</label>
                                                                            <input type="password" className="form-control" name='current_password' value={FormData['current_password']} onChange={(e) => {onChange(e)}} required />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label htmlFor="NewPassword" className="form-label">New Password</label>
                                                                            <input type="password" className="form-control" name="new_password" value={FormData['new_password']} onChange={(e) => {onChange(e)}} id="NewPassword" aria-describedby="emailHelp" required />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label htmlFor="ConfirmNewPassword" className="form-label">Confirm New Password</label>
                                                                            <input type="password" className="form-control" name="re_new_password" value={FormData['re_new_password']} onChange={(e) => {onChange(e)}} id="ConfirmNewPassword" aria-describedby="emailHelp" required />
                                                                        </div>
                                                                        <button className='btn btn-primary'>Update Password</button>

                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h4 className="fw-bold py-3 mb-4">
                                                    <span className="text-muted fw-light">{userName} / </span> Delete Account
                                                </h4>
                                                <div className='card mb-4'>
                                                    <div className="tab-content p-0">
                                                        <div className="card-body">
                                                            <div className="tab-pane fade show active" id="navs-tabs-line-card-profile" role="tabpanel">
                                                                <small className="text-muted text-uppercase">Account Deletion</small>
                                                                <div className='list-unstyled mb-4 mt-3'>
                                                                    <button onClick={(e)=>{onDelete(e)}} className='btn btn-danger'>Delete your account</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                    {/* <!--/ Navbar pills -->

                                    <!-- User Profile Content --> */}
                                    <div className="row">
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.Auth.isAuthenticated,
    user: state.Auth.user,
})
export default connect(mapStateToProps)(UserProfile)