import axios from 'axios'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { NavLink, Navigate } from 'react-router-dom'
import SearchComponent from '../../../../../../Search'
import { Helmet } from 'react-helmet'
import Swal from 'sweetalert2'
const AddStaffUser = (props) => {
    const user = props.user
    const [UserList, setUserList] = useState([])
    // APIs
    const loadUsers = async (searchQuery) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify({
            'search_query': searchQuery
        })
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/staff_list/`, Body, config)
            console.log(response?.data?.users)
            setUserList(response?.data?.users)
        } catch (error) {
            // console.log('first', error)
            //   setResponseError(error.response.statusText)
        }
    }
    // Search
    const [SearchQuery, setSearchQuery] = useState("")
    const onSearchInputChange = (e) => {
        e.preventDefault()
        setSearchQuery(e.target.value)
        loadUsers(e.target.value)
    }
    // Stores
    const [TotalStores, setTotalStores] = useState(-1)
    const [Stores, setStores] = useState([])
    const LoadStores = async () => {

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/takealot/getApiKey/`, config)
            // console.log(response.data['total_stores'])
            setTotalStores(response.data['total_stores'])
            setStores(response.data['data'])
        } catch (error) {
            // console.log('first', error)
            setTotalStores({
                store_name: "",
                key: null
            })
        }

    }
    // Update Row
    const onUserRowChange = (e, i) => {
        let newUserList = [...UserList]
        newUserList[i][e.target.name] = e.target.value
        setUserList(newUserList)
    }
    // Add Staff User
    const addStaffUser = async (data) => {
        if (data.storeId == 0) {
            Swal.fire({
                position: "bottom-end",
                type: "error",
                title: "Error",
                html: `Select a store before.`,
                showConfirmButton: !1,
                timer: 1500,
                confirmButtonClass: "btn btn-primary",
                buttonsStyling: !1,
            })
        } else {

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `JWT ${localStorage.getItem('access')}`
                }
            }
            const Body = JSON.stringify(data)
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/staff/add/`, Body, config)
                Swal.fire({
                    position: "bottom-end",
                    type: "success",
                    title: "Success",
                    html: `${response.data.message}`,
                    showConfirmButton: !1,
                    timer: 1500,
                    confirmButtonClass: "btn btn-primary",
                    buttonsStyling: !1,
                })
                loadUsers(SearchQuery)
            } catch (error) {
                // console.log('first', error)
                Swal.fire({
                    position: "bottom-end",
                    type: "error",
                    title: "Error",
                    html: `Something went wrong.`,
                    showConfirmButton: !1,
                    timer: 1500,
                    confirmButtonClass: "btn btn-primary",
                    buttonsStyling: !1,
                })
            }
        }
    }
    useEffect(() => {
        loadUsers(SearchQuery)
        LoadStores()
    }, [])

    if (!user?.is_superuser) {
        return <Navigate to="/" />
    }
    return (
        <div className='content'>
            <Helmet>
                <title>
                    User Accounts - Buybox
                </title>
            </Helmet>
            <div className="page-header">
                <div className="page-title">
                    <h4>Users Management</h4>
                    <h6>Manage Users</h6>
                </div>
            </div>
            <hr />
            <SearchComponent
                placeholder={ "User Name / Email" }
                SearchQuery={ SearchQuery }
                onSearchInputChange={ onSearchInputChange }
            />
            <hr />
            <div className="card mb-0">
                <div className="card-body">
                    <div className="card-header pb-0 d-flex justify-content-between align-items-center">
                        <h4 className="card-title">User List</h4>
                        <div className="graph-sets">
                        </div>
                    </div>

                    <div className="table-responsive text-wrap">
                        <table className="table mb-0">
                            <thead>

                                <tr>
                                    <th>
                                        Name
                                    </th>
                                    <th >
                                        Email
                                    </th>
                                    <th>
                                        Store
                                    </th>
                                    <th>
                                        Status
                                    </th>
                                    <th>
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    UserList.map(
                                        (row, index) => {
                                            return (
                                                <>
                                                    <tr key={ index }>
                                                        <td>
                                                            { row?.first_name + " " + row?.last_name }
                                                        </td>
                                                        <td>
                                                            { row?.email }
                                                        </td>
                                                        <td>
                                                            <select key={ index } className='form-select' name="storeId" onChange={ (e) => { onUserRowChange(e, index) } } value={ row?.storeId }>
                                                                <option value={ 0 }>Select Store</option>
                                                                {
                                                                    Stores.map(
                                                                        (store, storeIndex) => {
                                                                            return (
                                                                                <>
                                                                                    <option key={ storeIndex } value={ store.id }>{ store.store_name }</option>
                                                                                </>
                                                                            )
                                                                        }
                                                                    )
                                                                }
                                                            </select>
                                                        </td>
                                                        <td>
                                                            { row?.total_stores }
                                                        </td>
                                                        <td>
                                                            { row?.is_superuser ? 'Admin' : 'User' }
                                                        </td>
                                                        <td>
                                                            {
                                                                row?.is_active ?
                                                                    <span >
                                                                        Active
                                                                    </span>
                                                                    : <>Not Active</>
                                                            }
                                                        </td>
                                                        <td>
                                                            { row?.subscribed }
                                                        </td>
                                                        <td>
                                                            { row?.validUntil }
                                                        </td>
                                                        <td>

                                                            <button
                                                                style={ { color: 'white' } }
                                                                onClick={ (e) => { addStaffUser(row) } }
                                                                className="btn btn-primary me-2 btn-md"
                                                            >
                                                                Make Staff User
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </>
                                            )
                                        })
                                }
                            </tbody>
                        </table>
                        <br />
                        <div className='pagination justify-content-center'>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.Auth.isAuthenticated,
    user: state.Auth.user
})

export default connect(mapStateToProps)(AddStaffUser)