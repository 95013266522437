import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import Swal from 'sweetalert2'
import BBPagination from '../../../modules/BuyboxPagination'

const BuyBoxData = (props) => {
    const BuyBoxList = props.BuyBoxList
    const LoadBuyBoxDetails = props.LoadBuyBoxDetails
    const formatter = props.formatter
    const PageSize = props.PageSize
    const TotalProducts = props.TotalProducts
    const SearchQuery = props.SearchQuery
    const LoadQueryData = props.LoadQueryData
    const storeId = props.storeId
    const orderBy = props.orderBy
    const account = props.accountId
    const [CurrentRow, setCurrentRow] = useState(0)
    const on_Table_value_update = props.on_Table_value_update
    const on_Table_status_update = props.on_Table_status_update
    const onProductStatusUpdate = props.onProductStatusUpdate
    const onProductBBDataUpdate = props.onProductBBDataUpdate
    const Sortby = props.Sortby
    const SortDirection = props.SortDirection
    const onSortChange = props.onSortChange
    const onPageSizeChange = props.onPageSizeChange
    const onSortDirectionChange = props.onSortDirectionChange
    const activeInactive = props.activeInactive
    const activeInactiveChange = props.activeInactiveChange


    const updateStatus = (e, minPrice, maxPrice, rrp, tsin_id, sku, dashboard, i, status, product) => {
        e.preventDefault()
        if (minPrice === "" || minPrice === 0) {
            Swal.fire({
                position: "bottom-end",
                type: "success",
                title: "Error",
                html: 'Minimum price is not set.',
                showConfirmButton: !1,
                timer: 1500,
                confirmButtonClass: "btn btn-primary",
                buttonsStyling: !1,
            })
        }
        else if (maxPrice === "" || maxPrice === 0) {
            Swal.fire({
                position: "bottom-end",
                type: "success",
                title: "Error",
                html: 'Max price is not set.',
                showConfirmButton: !1,
                timer: 1500,
                confirmButtonClass: "btn btn-primary",
                buttonsStyling: !1,
            })
        } else {
            if (Number(maxPrice) < Number(minPrice)) {
                Swal.fire({
                    position: "bottom-end",
                    type: "success",
                    title: "Error",
                    html: 'Max price should be greater than minimum price.',
                    showConfirmButton: !1,
                    timer: 1500,
                    confirmButtonClass: "btn btn-primary",
                    buttonsStyling: !1,
                })
            } if (Number(maxPrice) > Number(rrp)) {
                Swal.fire({
                    position: "bottom-end",
                    type: "success",
                    title: "Error",
                    html: 'Max price should be less than RRP.',
                    showConfirmButton: !1,
                    timer: 1500,
                    confirmButtonClass: "btn btn-primary",
                    buttonsStyling: !1,
                })
            } else {
                onProductStatusUpdate(e, tsin_id, sku, dashboard, product)
                on_Table_status_update(e, i, status)
            }
        }
    }

    return (
        <>
            {
                BuyBoxList ? <>
                    <div className="card">
                        <div className="card-header pb-0 d-flex justify-content-between align-items-center">
                            <h4 className="card-title">Product List</h4>
                            <div className="graph-sets">
                                <div class="form-check form-switch">
                                    <input checked={ activeInactive } onChange={ (e) => { activeInactiveChange(e) } } class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                    <label class="form-check-label" for="flexSwitchCheckDefault">Status</label>
                                </div>

                                <div className="dropdown mx-1">
                                    <button className="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Page Size
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><a className={ PageSize == 10 ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onPageSizeChange(e, "10") } } href="#">10</a></li>
                                        <li><a className={ PageSize == 20 ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onPageSizeChange(e, "20") } } href="#">20</a></li>
                                        <li><a className={ PageSize == 50 ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onPageSizeChange(e, "50") } } href="#">50</a></li>
                                        <li><a className={ PageSize == 100 ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onPageSizeChange(e, "100") } } href="#">100</a></li>
                                    </ul>
                                </div>
                                <div className="dropdown">
                                    <button className="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Sort by
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><a className={ Sortby === "title" ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onSortChange(e, "title") } } href="#">Product Title</a></li>
                                        <li><a className={ Sortby === "winner" ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onSortChange(e, "winner") } } href="#">Winner</a></li>
                                    </ul>
                                </div>
                                <div className='py-1'>
                                    <button
                                        onClick={ (e) => { onSortDirectionChange(e, "up") } }
                                        className={
                                            SortDirection === "up" ?
                                                'btn btn-primary mx-1 my-1'
                                                :
                                                'btn btn-outline-primary mx-1'
                                        }
                                    >
                                        <i class="fa-solid fa-arrow-up-wide-short"></i>
                                    </button>
                                </div>
                                <div className='py-1'>
                                    <button
                                        onClick={ (e) => { onSortDirectionChange(e, "down") } }
                                        className={
                                            SortDirection === "down" ?
                                                'btn btn-primary mx-1'
                                                :
                                                'btn btn-outline-primary mx-1 my-1'
                                        }
                                    >
                                        <i class="fa-solid fa-arrow-down-wide-short"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* <h5 className="card-header">Table Basic</h5> */ }
                        <div className="table-responsive text-wrap">
                            <table className="table w-100">
                                <thead>
                                    <tr>
                                        <th scope="col">Title</th>
                                        {
                                            storeId == 0 ?
                                                <th scope="col">Store</th>
                                                : <></>
                                        }
                                        <th scope="col">SKU</th>
                                        <th scope="col">Offered By</th>
                                        <th scope="col">Winner</th>
                                        <th scope="col">Winner Price</th>
                                        <th scope="col">Stock</th>
                                        <th scope="col">Minimum Price</th>
                                        <th scope="col">Maximum Price</th>
                                        <th scope="col">Hour</th>
                                        <th scope="col">Current Price</th>
                                        <th scope="col">RRP</th>
                                        <th scope="col">Offers</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Takealot</th>
                                        <th scope="col">Pricing Logs</th>
                                        {/* <th>LT Stock</th>
                                    <th>LT Days</th>
                                    <th>Past month</th>
                                    <th>Past year</th>
                                    <th>Total</th> */}
                                    </tr>
                                </thead>
                                <tbody className="table-border-bottom-0">
                                    {
                                        !BuyBoxList?.loading ?
                                            BuyBoxList.map(
                                                (key, i) => {
                                                    return (
                                                        <>
                                                            <tr key={ i }>
                                                                <td >

                                                                    <a href='#'  >
                                                                        { key?.title.substring(0, 30) }...
                                                                    </a>
                                                                </td>
                                                                {
                                                                    storeId == 0 ?
                                                                        <td >
                                                                            { key?.store_name }
                                                                        </td>
                                                                        : <></>
                                                                }
                                                                <td >
                                                                    { key?.sku }
                                                                </td>
                                                                <td >
                                                                    { key?.store_name }
                                                                </td>
                                                                <td >
                                                                    <span className={ key?.winning ? "me-1 bg-label-success" : "me-1 bg-label-danger" }  >
                                                                        {
                                                                            key?.winning ?
                                                                                <>
                                                                                    <span style={ { color: 'green' } }>{ key?.store_name ? key?.store_name : "N/A" }</span>
                                                                                </> :
                                                                                <>
                                                                                    <span style={ { color: 'red' } }>{ key?.winner_name ? key?.winner_name : "N/A" }</span>
                                                                                </>
                                                                        }
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    {
                                                                        formatter.format(key?.winner_price)
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        key?.in_stock ?
                                                                            <>
                                                                                <i class="fa-solid fa-box" style={ { color: "green" } }></i>
                                                                                <br />In<br />stock
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <i class="fa-solid fa-box" style={ { color: "red" } }></i>
                                                                                <br />No<br />stock
                                                                            </>
                                                                    }
                                                                </td>
                                                                <td onMouseOver={ () => { setCurrentRow(`${key?.offer_id}.2`) } } >
                                                                    {
                                                                        // key?.minPrice ? 
                                                                        CurrentRow === `${key?.offer_id}.2` ?
                                                                            <div className="input-group">
                                                                                {/* <button className="input-group-text" type="button"><i class="fa-solid fa-pen-to-square"></i></button> */ }
                                                                                <input type="text" className="form-control" autoFocus placeholder="" onChange={ (e) => { on_Table_value_update(e, i) } } name="minPrice" value={ key?.minPrice } aria-label="Example text with button addon" aria-describedby="button-addon1" />
                                                                                <button class="input-group-text" onClick={ (e) => { onProductBBDataUpdate(e, key?.offer_id, "minPrice", key?.minPrice, key?.dashboard, key?.rrp); setCurrentRow(0) } } type="button"><i class="fa-solid fa-check-to-slot"></i></button>
                                                                            </div>
                                                                            :
                                                                            key?.minPrice ?
                                                                                formatter.format(key?.minPrice)
                                                                                : formatter.format(0)
                                                                        // : formatter.format(0)
                                                                    }
                                                                </td>
                                                                <td onMouseOver={ (e) => { setCurrentRow(`${key?.offer_id}.1`) } }>
                                                                    {
                                                                        // key?.maxPrice ? 
                                                                        CurrentRow === `${key?.offer_id}.1` ?
                                                                            <div className="input-group">
                                                                                {/* <button className="input-group-text" type="button"><i class="fa-solid fa-pen-to-square"></i></button> */ }
                                                                                <input type="text" className="form-control" placeholder="" onChange={ (e) => { on_Table_value_update(e, i) } } name="maxPrice" value={ key?.maxPrice } aria-label="Example text with button addon" aria-describedby="button-addon1" />
                                                                                <button class="input-group-text" onClick={ (e) => { onProductBBDataUpdate(e, key?.offer_id, "maxPrice", key?.maxPrice, key?.dashboard, key?.rrp); setCurrentRow(0) } } type="button"><i class="fa-solid fa-check-to-slot"></i></button>
                                                                            </div>
                                                                            :
                                                                            key?.maxPrice ?
                                                                                formatter.format(key?.maxPrice)
                                                                                : formatter.format(0)
                                                                        // : formatter.format(0)
                                                                    }
                                                                </td>
                                                                <td onMouseOver={ () => { setCurrentRow(`${key?.offer_id}.3`) } } >
                                                                    {
                                                                        // key?.bestHour ? 
                                                                        CurrentRow === `${key?.offer_id}.3` ?
                                                                            <div className="input-group">
                                                                                {/* <button className="input-group-text" type="button"><i class="fa-solid fa-pen-to-square"></i></button> */ }
                                                                                {/* <input type="text" className="form-control" autoFocus placeholder="" onChange={(e)=>{on_Table_value_update(e, i)}} name="bestHour" value={key?.bestHour} aria-label="Example text with button addon" aria-describedby="button-addon1" /> */ }
                                                                                <select autoFocus onChange={ (e) => { on_Table_value_update(e, i) } } name="bestHour" value={ key?.bestHour } className="form-select">
                                                                                    <option value="">Automated</option>
                                                                                    <option value="0">12:00 AM</option>
                                                                                    <option value="1">01:00 AM</option>
                                                                                    <option value="2">02:00 AM</option>
                                                                                    <option value="3">03:00 AM</option>
                                                                                    <option value="4">04:00 AM</option>
                                                                                    <option value="5">05:00 AM</option>
                                                                                    <option value="6">06:00 AM</option>
                                                                                    <option value="7">07:00 AM</option>
                                                                                    <option value="8">08:00 AM</option>
                                                                                    <option value="9">09:00 AM</option>
                                                                                    <option value="10">10:00 AM</option>
                                                                                    <option value="11">11:00 AM</option>
                                                                                    <option value="12">12:00 PM</option>
                                                                                    <option value="13">01:00 PM</option>
                                                                                    <option value="14">02:00 PM</option>
                                                                                    <option value="15">03:00 PM</option>
                                                                                    <option value="16">04:00 PM</option>
                                                                                    <option value="17">05:00 PM</option>
                                                                                    <option value="18">06:00 PM</option>
                                                                                    <option value="19">07:00 PM</option>
                                                                                    <option value="20">08:00 PM</option>
                                                                                    <option value="21">09:00 PM</option>
                                                                                    <option value="22">10:00 PM</option>
                                                                                    <option value="23">11:00 PM</option>
                                                                                </select>
                                                                                <button class="input-group-text" onClick={ (e) => { onProductBBDataUpdate(e, key?.offer_id, "bestHour", key?.bestHour, key?.dashboard, key?.rrp); setCurrentRow(0) } } type="button"><i class="fa-solid fa-check-to-slot"></i></button>
                                                                            </div>
                                                                            :
                                                                            key?.bestHour == "" ? "Automated" :
                                                                                key?.bestHour == 0 ? "12:00 AM" :
                                                                                    key?.bestHour == 1 ? "01:00 AM" :
                                                                                        key?.bestHour == 2 ? "02:00 AM" :
                                                                                            key?.bestHour == 3 ? "03:00 AM" :
                                                                                                key?.bestHour == 4 ? "04:00 AM" :
                                                                                                    key?.bestHour == 5 ? "05:00 AM" :
                                                                                                        key?.bestHour == 6 ? "06:00 AM" :
                                                                                                            key?.bestHour == 7 ? "07:00 AM" :
                                                                                                                key?.bestHour == 8 ? "08:00 AM" :
                                                                                                                    key?.bestHour == 9 ? "09:00 AM" :
                                                                                                                        key?.bestHour == 10 ? "10:00 AM" :
                                                                                                                            key?.bestHour == 11 ? "11:00 AM" :
                                                                                                                                key?.bestHour == 12 ? "12:00 PM" :
                                                                                                                                    key?.bestHour == 13 ? "01:00 PM" :
                                                                                                                                        key?.bestHour == 14 ? "02:00 PM" :
                                                                                                                                            key?.bestHour == 15 ? "03:00 PM" :
                                                                                                                                                key?.bestHour == 16 ? "04:00 PM" :
                                                                                                                                                    key?.bestHour == 17 ? "05:00 PM" :
                                                                                                                                                        key?.bestHour == 18 ? "06:00 PM" :
                                                                                                                                                            key?.bestHour == 19 ? "07:00 PM" :
                                                                                                                                                                key?.bestHour == 20 ? "08:00 PM" :
                                                                                                                                                                    key?.bestHour == 21 ? "09:00 PM" :
                                                                                                                                                                        key?.bestHour == 22 ? "10:00 PM" :
                                                                                                                                                                            key?.bestHour == 23 ? "11:00 PM" : "Automated"
                                                                        // : formatter.format(0)
                                                                    }
                                                                </td>
                                                                <td >
                                                                    {
                                                                        key?.selling_price ?
                                                                            formatter.format(key?.selling_price)
                                                                            : formatter.format(0)
                                                                    }
                                                                </td>
                                                                <td >
                                                                    {
                                                                        key?.rrp ?
                                                                            formatter.format(key?.rrp)
                                                                            : formatter.format(0)
                                                                    }
                                                                </td>
                                                                <td >{ key?.other_offers ? key?.other_offers : 0 }</td>
                                                                <td >
                                                                    {/* <a href='#' className={key?.status === 1 ? "me-1 bg-label-success" : "me-1 bg-label-danger"}  data-bs-toggle="modal" data-bs-target="#productBuyBoxDetailsModal">
                                                    {
                                                        key?.status === 1 ? 
                                                        <>
                                                            <span style={{color: 'green'}}>Active</span>
                                                        </>:
                                                        <>
                                                            <span style={{color: 'red'}}>Inactive</span>
                                                        </>
                                                    }
                                                </a> */}
                                                                    <div className="form-check form-switch form-switch-lg mb-2">
                                                                        <input className="form-check-input form-switch-lg" type="checkbox" name='autoPricing' onClick={ (e) => { onProductBBDataUpdate(e, key?.offer_id, "autoPricing", !key?.autoPricing, key?.dashboard, key?.rrp, key); setCurrentRow(0) } } id="flexSwitchCheckChecked" checked={ key?.autoPricing } />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <a href={ key?.offer_url } target='_blank' style={ { color: 'white' } } className={ "btn btn-primary me-1 bg-label-success" }>
                                                                        <span>Takealot</span>
                                                                    </a>
                                                                </td>
                                                                {/* <td >
                                            <a href='#' className={key?.autoPricing === 1 ? "me-1 bg-label-success" : "me-1 bg-label-danger"}  data-bs-toggle="modal" data-bs-target="#productBuyBoxDetailsModal">
                                                {
                                                key?.autoPricing === 1 ? 
                                                <>
                                                    <span style={{color: 'green'}}>Active</span>
                                                </>:
                                                <>
                                                    <span style={{color: 'red'}}>Inactive</span>
                                                </>
                                                }
                                                </a>
                                            
                                            </td> 
                                        */}
                                                                <td>
                                                                    <button onClick={ (e) => { props.LoadPricingLogs(key?.offer_id, key?.dashboard) } } type='button' className='btn btn-primary' style={ { color: 'white' } } data-bs-toggle="modal" data-bs-target={ `#productBuyBoxPricingLogModal-${storeId}` } >
                                                                        View
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )
                                                }
                                            )
                                            :
                                            Array.from({ length: PageSize }).map((_, index) => (
                                                <tr key={ index }>
                                                    <td>
                                                        <h5>
                                                            <span className="skeleton-loader"></span>
                                                        </h5>
                                                    </td>
                                                    <td>
                                                        <h5>
                                                            <span className="skeleton-loader"></span>
                                                        </h5>
                                                    </td>
                                                    <td>
                                                        <h5>
                                                            <span className="skeleton-loader"></span>
                                                        </h5>
                                                    </td>
                                                    <td>
                                                        <h5>
                                                            <span className="skeleton-loader"></span>
                                                        </h5>
                                                    </td>
                                                    <td>
                                                        <h5>
                                                            <span className="skeleton-loader"></span>
                                                        </h5>
                                                    </td>
                                                    <td>
                                                        <h5>
                                                            <span className="skeleton-loader"></span>
                                                        </h5>
                                                    </td>
                                                    <td>
                                                        <h5>
                                                            <span className="skeleton-loader"></span>
                                                        </h5>
                                                    </td>
                                                    <td>
                                                        <h5>
                                                            <span className="skeleton-loader"></span>
                                                        </h5>
                                                    </td>
                                                    <td>
                                                        <h5>
                                                            <span className="skeleton-loader"></span>
                                                        </h5>
                                                    </td>
                                                    <td>
                                                        <h5>
                                                            <span className="skeleton-loader"></span>
                                                        </h5>
                                                    </td>
                                                    <td>
                                                        <h5>
                                                            <span className="skeleton-loader"></span>
                                                        </h5>
                                                    </td>
                                                    <td>
                                                        <h5>
                                                            <span className="skeleton-loader"></span>
                                                        </h5>
                                                    </td>
                                                    <td>
                                                        <h5>
                                                            <span className="skeleton-loader"></span>
                                                        </h5>
                                                    </td>
                                                    <td>
                                                        <h5>
                                                            <span className="skeleton-loader"></span>
                                                        </h5>
                                                    </td>
                                                </tr>
                                            ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='pagination justify-content-center'>
                        {
                            TotalProducts > PageSize ?
                                // <BuyBoxPagination totalPages={ props.TotalPages } totalRecords={ TotalProducts } pageLimit={ PageSize } sortBy={ Sortby } sortDirection={ SortDirection } paginationSearchQuery={ SearchQuery } paginationPageSize={ PageSize } accountId={ account } dashboard={ storeId } paginationOrderBy={ orderBy } onPageChanged={ LoadQueryData } />
                                <BBPagination
                                    currentPage={ props.currentPage }
                                    totalPages={ props.TotalPages }
                                    setCurrentPage={ props.setCurrentPage }
                                />
                                : <></>
                        }
                    </div>
                </>
                    :
                    <>
                        <div style={ { textAlign: "center" } }>
                            <div className="container-xxl container-p-y">
                                <div className="misc-wrapper">
                                    <h2 className="mb-2 mx-2">No Data? 😢</h2>
                                    <p className="mb-4 mx-2">Please enable Buybox tracking for at least one eligible product.</p>
                                    <NavLink to="/" className="btn btn-primary">Back to home</NavLink>
                                    <div className="mt-3">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default BuyBoxData