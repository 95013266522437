
import axios from 'axios'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import {useSearchParams} from 'react-router-dom'

const PaymentModule = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [ReturnURL, setReturnURL] = useState("")
    const [CancelURL, setCancelURL] = useState("")
    const [NotifyURL, setNotifyURL] = useState("")
    const [UUID, setUUID] = useState()
    const payment = async(amount, item_name) => {
        const config = {
            headers: {
                'Content-Type' : 'application/json',
                'Authorization' : `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify({
            "amount" : "1",
            "planId" : "1",
            "item_name" : item_name
        })
        
        try { 
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/subscription/payment/test/`, Body, config)
            console.log(response?.data)
            setUUID(response?.data?.UUID)
            setReturnURL(response?.data?.return_url)
            setCancelURL(response?.data?.cancel_url)
            setNotifyURL(response?.data?.notify_url)
          // setNextLink(response.data['next'])
        } catch (error) {
          // console.log('first', error)
        }
    }
    const onClick = (uuid) => {
        window.payfast_do_onsite_payment({
            "uuid": uuid,
            'return_url': ReturnURL,
            'cancel_url': CancelURL,
            'notify_url': NotifyURL,
            
        })
    }
    useEffect(() => {
        // console.log(searchParams.getItem)
    }, [])
    
    return (
        <>
            <div className='content'>
                <button className='btn btn-primary' onClick={(e)=>{payment("10", "Plan 1")}}>
                    Generate UUID
                </button>
                <p>{UUID}</p>
                {
                    UUID ?
                        <button className='btn btn-primary' onClick={(e)=>{onClick(UUID)}}>
                            Pay
                        </button>
                    :<></>
                }
            </div>
        </>
    )
}

export default PaymentModule