import React, { useState, useEffect } from 'react'
import './Styles/Invoice.css'
import { Form, Navigate, NavLink, useLocation } from 'react-router-dom'
import logo from '../../assets/img/logo.png'
import axios from 'axios'
import Moment from 'moment'
import { Helmet } from 'react-helmet'

const InvoiceComponent = (props) => {
    const CompanyProfile = props.CompanyProfile
    const CompanyLogo = props.CompanyLogo
    const location = useLocation()
    const { state } = location
    const CustomerDetails = state['CustomerDetails']
    const OrderDetails = state['OrderDetails']
    const [OrderData, setOrderData] = useState([])
    const [totalSaleAmount, settotalSaleAmount] = useState(0)
    const [totalUnits, settotalUnits] = useState(0)
    const DownloadPDF = async (e) => {
        e.preventDefault()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            },
            responseType: 'blob'
        }
        const Body = JSON.stringify(
            {
                "order_id": OrderDetails['order_id'],
                "dashboard": 1,
                "CustomerDetails": {
                    "customerBusinessName": CustomerDetails['customerBusinessName'] ? CustomerDetails['customerBusinessName'] : "",
                    "customerName": CustomerDetails['customerName'] ? CustomerDetails['customerName'] : "",
                    "customerAddressStreet": CustomerDetails['customerAddressStreet'] ? CustomerDetails['customerAddressStreet'] : "",
                    "customerAddressCity": CustomerDetails['customerAddressCity'] ? CustomerDetails['customerAddressCity'] : "",
                    "customerAddressPostalCode": CustomerDetails['customerAddressPostalCode'] ? CustomerDetails['customerAddressPostalCode'] : "",
                    "customerAddressCountry": CustomerDetails['customerAddressCountry'] ? CustomerDetails['customerAddressCountry'] : "",
                    "customerVAT": CustomerDetails['customerVAT'] ? CustomerDetails['customerVAT'] : "",
                    "customerReg": CustomerDetails['customerReg'] ? CustomerDetails['customerReg'] : "",
                },
                "OrderDetails": {
                    "order_date": OrderDetails["order_date"],
                    "store": OrderDetails["store"],
                    "order_id": OrderDetails["order_id"],
                    "product_title": OrderDetails["product_title"],
                    "sales": OrderDetails['sales'],
                    "units": OrderDetails['units'],
                    "totals": OrderDetails['sales'],
                    "subtotals": OrderDetails['sales'] - Number(OrderDetails['sales'] * 0.15),
                    "vat": Number(OrderDetails['sales'] * 0.15),
                }
            }
        )
        try {
            // return axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/invoice/pdf/`,Body, config).then(function (response) {
            //     return response
            // })
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v2/combinedInvoicePDF/`, Body, config)
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Invoice for ${CustomerDetails['customerName']}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            // console.log('first', error)
        }
    }
    const numberFormatter = new Intl.NumberFormat('en-ZA', {
        // These options are needed to round to whole numbers if that's what you want.
        minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    })
    const formatter = new Intl.NumberFormat('en-ZA', {
        style: 'currency',
        currency: 'ZAR',

        // These options are needed to round to whole numbers if that's what you want.
        minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    })
    const LoadSaleDetailsData = async (orderNo) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify({
            "order_id": orderNo
        })

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v2/multiProductOrderDetails/`, Body, config)
            setOrderData(response.data['data']['data'])
            //   console.log(response.data['data'])
            settotalSaleAmount(response.data['data']['totalSaleAmount'])
            settotalUnits(response.data['data']['totalUnits'])
            //   setFeeDetails(response.data['fee'])
        } catch (error) {
            // console.log('first', error)
            //   setResponseError(error.response.statusText)
        }

    }
    useEffect(() => {
        LoadSaleDetailsData(OrderDetails['order_id'])
    }, [])
    return (
        <>
            <Helmet>
                <title>{ CompanyProfile['companyName'] } Sales</title>
                <meta
                    name="description"
                    content={ `${CompanyProfile['companyName']} Sales Dashboard` }
                />
                <link rel="stylesheet" href="../assets/css/style.css" />
                <script src="../assets/js/script.js" />
            </Helmet>
            <div className="content">
                <div className="card mb-0">
                    <div className="card-body">
                        <div className="card-header pb-0 d-flex justify-content-between align-items-center">
                            <h4 className="card-title">Sale Invoice</h4>
                            <div className="graph-sets">
                                <div className="px-1 w-100">
                                    <button type='button' onClick={ (e) => { DownloadPDF(e) } } className="btn btn-md btn-primary">Download Invoice</button><br />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xs-12">
                                <br />
                                <div className="invoice-title row">
                                    <div className="col-1"></div>
                                    <h2 className='col-5'>Invoice</h2>
                                    <h3 className="pull-right col-5" style={ { textAlign: "right" } }>Order # { OrderDetails['order_id'] }</h3>
                                    <div className="col-1"></div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-1"></div>
                                    <div className="col-5">
                                        <address>
                                            <strong>Order date: { OrderDetails['order_date'] }</strong>
                                        </address>
                                        <address>
                                            <strong>Invoice date: { Moment((new Date).getFullYear() + "-" + ("0" + ((new Date).getMonth() + 1)).slice(-2) + "-" + ("0" + (new Date).getDate())).format('D MMM YYYY') }</strong>
                                        </address>
                                        <address>
                                            <strong>Invoice Number: { OrderDetails['order_id'] }</strong>
                                        </address>
                                        <address>
                                            <strong>Invoice for:</strong><br />
                                            { CustomerDetails['customerBusinessName'] ? <><b>{ CustomerDetails['customerBusinessName'] }</b></> : <></> } <br />
                                            { CustomerDetails['customerName'] } <br />
                                            {
                                                CustomerDetails['customerAddressStreet'] ?
                                                    <>
                                                        Street: { CustomerDetails['customerAddressStreet'] } <br />
                                                        City: { CustomerDetails['customerAddressCity'] } <br />
                                                        Postal Code: { CustomerDetails['customerAddressPostalCode'] } <br />
                                                        Country: { CustomerDetails['customerAddressCountry'] } <br />
                                                    </> : <></>
                                            }
                                        </address>
                                        <address>
                                            {
                                                CustomerDetails['customerVAT'] ?
                                                    <>
                                                        <strong>Customer VAT: { CustomerDetails['customerVAT'] }</strong><br />
                                                    </> : <></>
                                            }
                                            {
                                                CustomerDetails['customerReg'] ?
                                                    <>
                                                        <strong>Customer Reg No: { CustomerDetails['customerReg'] }</strong><br />
                                                    </> : <></>
                                            }
                                        </address>
                                    </div>

                                    <div className="col-5" style={ { textAlign: "right" } }>
                                        <address>
                                            <strong>Issued By:</strong><br />
                                            <img src={ CompanyLogo } width="100" />
                                            <h3>{ CompanyProfile['companyName'] }</h3>
                                            { CompanyProfile['companyAddressStreet'] }<br />
                                            { CompanyProfile['companyAddressCity'] }<br />
                                            { CompanyProfile['companyAddressCountry'] }<br />
                                            { CompanyProfile['companyAddressPostalCode'] }<br />
                                            <strong>VAT: </strong>{ CompanyProfile['companyVAT'] }
                                        </address>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-1"></div>
                                    <div className="col-10">
                                        <div className="panel-heading">
                                            <h3 className="panel-title"><strong>Order summary</strong></h3>
                                        </div>
                                        <div className="panel-body">
                                            <div className="table-responsive">
                                                <table className="table table-condensed">
                                                    <thead>
                                                        <tr>
                                                            <td><strong>Description</strong></td>
                                                            <td className="text-center"><strong>Price</strong></td>
                                                            <td className="text-center"><strong>Quantity</strong></td>
                                                            <td className="text-right"><strong>Totals</strong></td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            OrderData.map(
                                                                (key) => {
                                                                    return (
                                                                        <tr>
                                                                            <td>{ key.product_title }</td>
                                                                            <td className="text-center">{ formatter.format(key.selling_price) }</td>
                                                                            <td className="text-center">{ numberFormatter.format(key.quantity) }</td>
                                                                            <td className="text-right">{ formatter.format(key.selling_price) }</td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-1"></div>
                                </div>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-10">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InvoiceComponent