import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import ProductKPIs from './ProductKPIs'
import ProductsList from './ProductsList'
import ProductDetails from './ProductModals/ProductDetails'
import BuyboxModal from './ProductModals/BuyboxModal'
import axios from 'axios'
import SearchComponent from '../Search'
import Swal from 'sweetalert2'
const StoreProducts = (props) => {
  const user = props.user
  const CompanyProfile = props.CompanyProfile
  const [LoadingVisibility, setLoadingVisibility] = useState("none")
  const [KPIsData, setKPIsData] = useState([])
  const [TotalPages, setTotalPages] = useState(1)
  const [PageSize, setPageSize] = useState(localStorage.getItem('page-size') ? JSON.parse(localStorage.getItem('page-size')) || 10 : 10)
  const [Sortby, setSortby] = useState(localStorage.getItem('products-data-sort-by') ? localStorage.getItem('products-data-sort-by') || 'sales' : 'sales')
  const [SortDirection, setSortDirection] = useState(localStorage.getItem('sort-direction') ? localStorage.getItem('sort-direction') || 'down' : 'down')
  const [dataVisibility, setDataVisibility] = useState("block")
  const [ProductList, setProductList] = useState([])
  const [SearchQuery, setSearchQuery] = useState("")
  const [TotalProducts, setTotalProducts] = useState(0)
  const [WarehouseDetails, setWarehouseDetails] = useState({})
  const storeId = 0
  const [MessageTitle, setMessageTitle] = useState("")
  const [Message, setMessage] = useState("")
  const [MessageVisibility, setMessageVisibility] = useState("none")
  const [MessageType, setMessageType] = useState("primary")
  const [ProductInfo, setProductInfo] = useState({
    tsin_id: "",
    offer_id: "",
    sku: "",
    barcode: "",
    product_label_number: "",
    selling_price: 0,
    rrp: 0,
    status: "",
    title: "",
    offer_url: "",
    CPT: 0,
    JHB: 0,
    offerPID: "",
    Level: 0,
    TotalStockLevel: 0
  })
  const [PageLimit, setPageLimit] = useState(1)
  const [StatusFilters, setStatusFilters] = useState({ status: 'Buyable' })
  const [OrderBy, setOrderBy] = useState("title")
  const formatter = new Intl.NumberFormat('en-ZA', {
    style: 'currency',
    currency: 'ZAR',
    minimumFractionDigits: 0
  })

  const numberFormatter = new Intl.NumberFormat('en-ZA', {
    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  })
  // APIs
  const LoadProductsData = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `JWT ${localStorage.getItem('access')}`
      }
    }
    const Body = JSON.stringify()
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/products/kpis/`, Body, config)
      setKPIsData(response.data)
    } catch (error) {
      // console.log('first', error)
    }
  }

  const onLoadProductList = async (page_number, filter, orderBy, store, accountId, searchQuery, page_size, sortBy, sortDirection) => {
    setLoadingVisibility("block")
    setDataVisibility("none")
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `JWT ${localStorage.getItem('access')}`
      }
    }
    const Body = JSON.stringify({
      "page_number": page_number,
      "account": accountId,
      "order_by": orderBy,
      "search_query": searchQuery,
      "page_size": page_size,
      "sortBy": sortBy,
      "sortDirection": sortDirection,
    })

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/products/list/`, Body, config)
      setProductList(response.data['results'])
      setTotalProducts(response.data['total_records'])
      setPageLimit(response.data['pagelimit'])
      // setNextLink(response.data['next'])
    } catch (error) {
      // console.log('first', error)
    }
    setLoadingVisibility("none")
    setDataVisibility("block")
  }

  const loadProductList = async (page_number, filter, orderBy, store, accountId, searchQuery, pageSize, sortBy, sortDirection) => {
    setProductCost({
      "loading": true,
    })
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `JWT ${localStorage.getItem('access')}`
      }
    }
    const Body = JSON.stringify({
      "page_number": page_number,
      "account": accountId,
      "filters": filter,
      "order_by": orderBy,
      "search_query": searchQuery,
      "page_size": pageSize,
      "sortBy": sortBy,
      "sortDirection": sortDirection,
    })
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/products/list/`, Body, config)
      setProductList(response.data['results'])
      setTotalPages(response.data['total_pages'])
      setTotalProducts(response.data['total_records'])
      setPageLimit(response.data['pagelimit'])
      // setNextLink(response.data['next'])
    } catch (error) {
      // console.log('first', error)
    }
  }

  const onSearchInputChange = (e) => {
    e.preventDefault()
    setSearchQuery(e.target.value)
  }


  const onPageSizeChange = (e, value) => {
    localStorage.setItem(`page-size`, value)
    setTotalProducts(0)
    setPageSize(value)
    loadProductList(1, StatusFilters, OrderBy, storeId, user.id, SearchQuery, value, Sortby, SortDirection)
  }

  const onSortChange = (e, value) => {
    localStorage.setItem(`products-data-sort-by`, e.target.value)
    setTotalProducts(0)
    setSortby(value)
    loadProductList(1, StatusFilters, OrderBy, storeId, user.id, SearchQuery, PageSize, value, SortDirection)
  }

  const onSortDirectionChange = (e, value) => {
    localStorage.setItem(`sort-direction`, e.target.value)
    setTotalProducts(0)
    setSortDirection(value)
    loadProductList(1, StatusFilters, OrderBy, storeId, user.id, SearchQuery, PageSize, Sortby, value)
  }


  const onStatusFilterChange = (e) => {
    setTotalProducts(0)
    setStatusFilters({ ...StatusFilters, [e.target.name]: e.target.value })
    loadProductList(1, { [e.target.name]: e.target.value }, OrderBy, storeId, user.id, SearchQuery, PageSize, Sortby, SortDirection)
  }


  const onCustomOrderByChange = (e) => {
    setTotalProducts(0)
    setOrderBy(e.target.value)
    loadProductList(1, StatusFilters, e.target.value, 1, user.id, SearchQuery, PageSize, Sortby, SortDirection)
  }

  const [LoadingSalesDetailsVisibility, setLoadingSalesDetailsVisibility] = useState("none")
  const [SalesDetailsVisibility, setSalesDetailsVisibility] = useState("block")
  const [OtherOffers, setOtherOffers] = useState([])
  const [HourDetails, setHourDetails] = useState([])
  const LoadProductDetails = async (offer_id) => {
    setLoadingSalesDetailsVisibility("block")
    setSalesDetailsVisibility("none")
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `JWT ${localStorage.getItem('access')}`
      }
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/products/details/${offer_id}/`, config)
      setProductInfo(response.data)
      setUpdatedProductDetails({
        offerId: response.data['id'],
        sku: response.data['sku'],
        selling_price: response.data['selling_price'],
        rrp: response.data['rrp'],
      })
    } catch (error) {
      // console.log('first', error)
      //   setResponseError(error.response.statusText)
    }

    setLoadingSalesDetailsVisibility("none")
    setSalesDetailsVisibility("block")
  }
  const LoadProductInfo = async (e, tsin_id) => {
    e.preventDefault()
    console.log(tsin_id)
    await LoadProductDetails(tsin_id)
    if (FilterType === "1") {
      loadYearStats(Year, tsin_id)
    } else if (FilterType === "2") {
      if ((Year + "-" + Month) === Date_Var.getFullYear() + "-" + ("0" + (Date_Var.getMonth() + 1)).slice(-2) + "-" + ("0" + Date_Var.getDate()).slice(-2)) {
        LoadCurrentMonthlyStats(tsin_id)
      } else {
        LoadMonthlyStats(Year + "-" + Month, tsin_id)
      }
    } else if (FilterType === "3") {
      if (CurrentDate === Date_Var.getFullYear() + "-" + (('0' + Date_Var.getMonth() + 1).slice(-2)) + "-" + Date_Var.getDate()) {
        LoadDayStats(Date_Var.getFullYear() + "-" + (('0' + Date_Var.getMonth() + 1).slice(-2)) + "-" + Date_Var.getDate(), tsin_id)
      } else {
        LoadDayStats(CurrentDate, tsin_id)
      }
    } else if (FilterType === "4") {
      LoadCustomStats(FromDate, ToDate, tsin_id)
    }
  }

  const [UpdatedProductDetails, setUpdatedProductDetails] = useState({
    offer_id: "",
    selling_price: 0,
    rrp: 0
  })
  const onUpdatedProductDetailsChange = (e) => { setUpdatedProductDetails({ ...UpdatedProductDetails, [e.target.name]: e.target.value }) }
  const updateProductTakealotDetails = async (data) => {
    setMessageVisibility('none')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `JWT ${localStorage.getItem('access')}`
      }
    }
    const Body = JSON.stringify(data)
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/product/updateTakealotPrice/`, Body, config)
      Swal.fire({
        position: "bottom-end",
        type: "success",
        title: "Success",
        html: `${ProductInfo['sku']} has been updated`,
        showConfirmButton: !1,
        timer: 5000,
        confirmButtonClass: "btn btn-primary",
        buttonsStyling: !1,
      })
    } catch (error) {
      Swal.fire({
        position: "bottom-end",
        type: "success",
        title: error.response.status,
        html: error.response.statusText,
        showConfirmButton: !1,
        timer: 5000,
        confirmButtonClass: "btn btn-primary",
        buttonsStyling: !1,
      })
    }
    // setBuyBox({
    //   ...BuyBox,
    //   "avgPrice": SellingPrice
    // })


    // setBuyBox({...BuyBox, ["bestHour"]: HourDetails['bestHour'], ["mediumHour"]: HourDetails['mediumHour'], ["worstHour"]: HourDetails['worstHour']})

    setTimeout(() => {
      setMessageVisibility("none")
    }, 3000)
  }

  const loadProductHourDetails = async (tsin_id) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `JWT ${localStorage.getItem('access')}`
      }
    }
    const Body = JSON.stringify(
      {
        "tsin_id": tsin_id
      }
    )
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/buyBox/hourDetails/`, Body, config)

      setHourDetails(response.data)
    } catch (error) {
    }
  }
  const onUpdatedProductDetailsSubmit = async (e) => {
    e.preventDefault()
    await updateProductTakealotDetails(UpdatedProductDetails)
  }
  const [BuyBox, setBuyBox] = useState({
    account: user.id,
    tsin_id: "",
    bestHour: "",
    minPrice: "",
    maxPrice: "",
    avgPrice: "",
    autoPricing: 1,
    status: 0
  })
  const onBuyBoxChange = e => {
    setBuyBox({ ...BuyBox, [e.target.name]: e.target.value })
  }
  const LoadBuyBoxDetails = (e, tsin_id) => {
    e.preventDefault()
    LoadProductDetails(tsin_id)
    loadProductHourDetails(tsin_id)
    GetBuyBoxDetails(tsin_id)
  }
  const GetBuyBoxDetails = async (tsin_id) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `JWT ${localStorage.getItem('access')}`
      }
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v2/buybox/detail/${tsin_id}/`, config)
      // console.log(response.data['buybox_details'])
      setBuyBox(response.data['buybox_details'])
    } catch (error) {
      // console.log('first', error)
    }
  }

  const UpdateBuyBoxDetails = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `JWT ${localStorage.getItem('access')}`
      }
    }
    const Body = JSON.stringify(
      BuyBox
    )
    try {
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/v2/buybox/detail/${BuyBox['tsin_id']}/`, Body, config)
      Swal.fire({
        position: "bottom-end",
        type: "success",
        title: "Success",
        html: `Buybox for ${ProductInfo['title']} is updated.`,
        showConfirmButton: !1,
        timer: 5000,
        confirmButtonClass: "btn btn-primary",
        buttonsStyling: !1,
      })
    } catch (error) {
      setMessageTitle("Error")
      setMessage(error.response.status + " " + error.response.statusText)
      setMessageVisibility("block")
      setMessageType("danger")
      // console.log('first', error)
    }

    setTimeout(() => {
      setMessageVisibility("none")
    }, 3000)
  }

  const InsertBuyBoxDetails = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `JWT ${localStorage.getItem('access')}`
      }
    }
    const Body = JSON.stringify(
      BuyBox
    )
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v2/buybox/list/`, Body, config)
      setMessageTitle("Inserted")
      setMessage("Buybox for " + ProductInfo['title'] + " is now active.")
      setMessageVisibility("block")
      setMessageType("primary")
      Swal.fire({
        position: "bottom-end",
        type: "success",
        title: "Success",
        html: `Buybox for ${ProductInfo['title']} is now active.`,
        showConfirmButton: !1,
        timer: 5000,
        confirmButtonClass: "btn btn-primary",
        buttonsStyling: !1,
      })
    } catch (error) {
      setMessageTitle("Error")
      setMessage(error.response.status + " " + error.response.statusText)
      setMessageVisibility("block")
      setMessageType("danger")
      // console.log('first', error)
    }

    setTimeout(() => {
      setMessageVisibility("none")
    }, 3000)
  }
  const onBuyBoxSubmit = async e => {
    e.preventDefault()
    if (BuyBox['insert']) {
      await InsertBuyBoxDetails()
    } else {
      await UpdateBuyBoxDetails()
    }
    onLoadProductList(1, StatusFilters, OrderBy, 1, user.id, SearchQuery, PageSize)

    // window.location.reload();
  }
  const [ProductCost, setProductCost] = useState({
    price_per_unit: 0,
    supplier: "",
    total_units_ordered: 0,
    cost_per_unit: 0,
    storage_cost_per_unit: 0,
    shipment_cost_per_unit: 0
  })
  const onProductCostChange = e => {
    setProductCost({ ...ProductCost, [e.target.name]: e.target.value })
  }
  const LoadProductCostDetails = (e, tsin_id, storeId) => {
    e.preventDefault()
    LoadProductDetails(tsin_id)
    let sku = `${tsin_id}-${storeId}`
  }
  const GetProductCostDetails = async (sku) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `JWT ${localStorage.getItem('access')}`
      }
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v2/product_cost/detail/${sku}/`, config)
      setProductCost(response.data['ProductCost_details'])
    } catch (error) {
      // console.log('first', error)
    }
  }
  const UpdateProductCostDetails = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `JWT ${localStorage.getItem('access')}`
      }
    }
    const Body = JSON.stringify(
      ProductCost
    )
    try {
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/v2/product_cost/detail/${ProductCost['tsin_id']}-${storeId}/`, Body, config)

      Swal.fire({
        position: "bottom-end",
        type: "success",
        title: "Success",
        html: `${ProductInfo['title']}'cost has been updated`,
        showConfirmButton: !1,
        timer: 5000,
        confirmButtonClass: "btn btn-primary",
        buttonsStyling: !1,
      })
    } catch (error) {
      console.log(error.response.data)
      setMessageTitle("Error")
      setMessage(error.response.status + " " + error.response.statusText)
      setMessageVisibility("block")
      setMessageType("danger")
      // console.log('first', error)
    }

    setTimeout(() => {
      setMessageVisibility("none")
    }, 3000)
  }

  const InsertProductCostDetails = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `JWT ${localStorage.getItem('access')}`
      }
    }
    const Body = JSON.stringify(
      ProductCost
    )
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v2/product_cost/list/`, Body, config)
      setMessageTitle("Inserted")
      setMessage(ProductInfo['title'] + " 's cost has been inserted")
      setMessageVisibility("block")
      setMessageType("primary")
      Swal.fire({
        position: "bottom-end",
        type: "success",
        title: "Success",
        html: `${ProductInfo['title']}'cost has been updated`,
        showConfirmButton: !1,
        timer: 5000,
        confirmButtonClass: "btn btn-primary",
        buttonsStyling: !1,
      })
    } catch (error) {
      Swal.fire({
        position: "bottom-end",
        type: "success",
        title: "Success",
        html: `${error.response.statusText}`,
        showConfirmButton: !1,
        timer: 5000,
        confirmButtonClass: "btn btn-primary",
        buttonsStyling: !1,
      })
      setMessageTitle("Error")
      setMessage(error.response.status + " " + error.response.statusText)
      setMessageVisibility("block")
      setMessageType("danger")
      // console.log('first', error)
    }

    setTimeout(() => {
      setMessageVisibility("none")
    }, 3000)
  }
  const onProductCostSubmit = async e => {
    e.preventDefault()
    if (ProductCost['insert']) {
      await InsertProductCostDetails()
    } else {
      await UpdateProductCostDetails()
    }
    onLoadProductList(1, StatusFilters, OrderBy, 1, user.id, SearchQuery, PageSize, Sortby, SortDirection)

    // window.location.reload();
  }


  const Date_Var = new Date()
  const [FilterType, setFilterType] = useState("2")
  const currentYear = Date_Var.getFullYear()
  const [Month, setMonth] = useState(("0" + (Date_Var.getMonth() + 1)).slice(-2))
  const [Year, setYear] = useState(currentYear)
  const [MonthYear, setMonthYear] = useState(currentYear)
  const [CurrentDate, setCurrentDate] = useState(Date_Var.getFullYear() + "-" + ("0" + (Date_Var.getMonth() + 1)).slice(-2) + "-" + ("0" + Date_Var.getDate()).slice(-2))
  const [FromDate, setFromDate] = useState(Date_Var.getFullYear() + "-" + ("0" + (Date_Var.getMonth() + 1)).slice(-2) + "-" + ("0" + Date_Var.getDate()).slice(-2))
  const [ToDate, setToDate] = useState(Date_Var.getFullYear() + "-" + ("0" + (Date_Var.getMonth() + 1)).slice(-2) + "-" + ("0" + Date_Var.getDate()).slice(-2))
  const year = 2021
  const years = Array.from(new Array(currentYear - year + 1), (val, index) => index + year)
  const [SalesVsQuantityTrendingData, setSalesVsQuantityTrendingData] = useState([])
  const [SalesStats, setSalesStats] = useState([])
  const [SalesTrendingData, setSalesTrendingData] = useState([])
  const OnMonthChange = async (e) => {
    e.preventDefault()
    setMonth(e.target.value)
    if (e.target.value === ('0' + Date_Var.getMonth() + 1).slice(-2)) {
      LoadCurrentMonthlyStats()
    } else {
      LoadMonthlyStats(MonthYear + "-" + e.target.value)
    }
  }
  const OnMonthYearChange = async (e) => {
    e.preventDefault()
    setMonthYear(e.target.value)
    if (Month === ('0' + Date_Var.getMonth() + 1).slice(-2) & e.target.value === Date_Var.getFullYear()) {
      LoadCurrentMonthlyStats()
    } else {
      LoadMonthlyStats(e.target.value + "-" + Month)
    }
    // LoadMonthlyStats(e.target.value+"-"+Month)
    // await delay(2000)
  }
  const LoadCurrentMonthlyStats = async (tsin_id) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `JWT ${localStorage.getItem('access')}`
      }
    }
    const Body = JSON.stringify({
      tsin_id: tsin_id
    })
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v2/product/sales/currentMonth/`, Body, config)
      setSalesStats(response.data['stats'])
      setSalesVsQuantityTrendingData((response.data['sales_vs_quantity_trending_data']))
      setSalesTrendingData(response.data['sales_trending_data'])
      //   console.log('Users', JSON.stringify(response.data.Data))
    } catch (error) {
      // console.log('first', error)
      //   setResponseError(error.response.statusText)
    }
  }
  const OnDayChange = async (e) => {
    e.preventDefault()
    // console.log(e.target.value)
    setCurrentDate(e.target.value)
    LoadDayStats(e.target.value)
    // await delay(2000)
  }
  const onFromDateChange = async (e) => {
    e.preventDefault()
    // console.log(e.target.value)
    setFromDate(e.target.value)
    LoadCustomStats(e.target.value, ToDate)
    // await delay(2000)
  }
  const onToDateChange = async (e) => {
    e.preventDefault()
    // console.log(e.target.value)
    setToDate(e.target.value)
    LoadCustomStats(FromDate, e.target.value)
    // await delay(2000)
  }
  const OnYearChange = async (e) => {
    e.preventDefault()
    loadYearStats(e.target.value)
    setYear(e.target.value)
    // await delay(2000)
  }

  const loadYearStats = async (year, tsin_id) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `JWT ${localStorage.getItem('access')}`
      }
    }
    const Body = JSON.stringify(
      {
        "year": year,
        "tsin_id": tsin_id
      }
    )
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v2/product/sales/annual/`, Body, config)
      setSalesStats(response.data['stats'])
      setSalesVsQuantityTrendingData((response.data['sales_vs_quantity_trending_data']))
      setSalesTrendingData(response.data['sales_trending_data'])
      //   console.log('Users', JSON.stringify(response.data.Data))
    } catch (error) {
      // console.log('first', error)
      //   setResponseError(error.response.statusText)
    }
  }
  const LoadMonthlyStats = async (date, tsin_id) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `JWT ${localStorage.getItem('access')}`
      }
    }
    const Body = JSON.stringify(
      {
        "date": date,
        "tsin_id": tsin_id
      }
    )
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v2/product/sales/month/`, Body, config)
      setSalesStats(response.data['stats'])
      setSalesVsQuantityTrendingData((response.data['sales_vs_quantity_trending_data']))
      setSalesTrendingData(response.data['sales_trending_data'])
      //   console.log('Users', JSON.stringify(response.data.Data))
    } catch (error) {
      // console.log('first', error)
      //   setResponseError(error.response.statusText)
    }
  }
  const LoadDayStats = async (date, tsin_id) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `JWT ${localStorage.getItem('access')}`
      }
    }
    const Body = JSON.stringify(
      {
        "date": date,
        "tsin_id": tsin_id
      }
    )
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v2/product/sales/day/`, Body, config)
      setSalesStats(response.data['stats'])
      setSalesVsQuantityTrendingData((response.data['sales_vs_quantity_trending_data']))
      setSalesTrendingData(response.data['sales_trending_data'])
      //   console.log('Users', JSON.stringify(response.data.Data))
    } catch (error) {
      // console.log('first', error)
      //   setResponseError(error.response.statusText)
    }
  }
  const LoadCustomStats = async (fromDate, toDate, tsin_id) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `JWT ${localStorage.getItem('access')}`
      }
    }
    const Body = JSON.stringify(
      {
        "fromDate": fromDate,
        "toDate": toDate,
        "tsin_id": tsin_id
      }
    )
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v2/product/sales/custom/`, Body, config)
      setSalesStats(response.data['stats'])
      setSalesVsQuantityTrendingData((response.data['sales_vs_quantity_trending_data']))
      setSalesTrendingData(response.data['sales_trending_data'])
      //   console.log('Users', JSON.stringify(response.data.Data))
    } catch (error) {
      // console.log('first', error)
      //   setResponseError(error.response.statusText)
    }
  }
  const ChangeFilter = (e) => {
    e.preventDefault()
    // addTodaysData()
    setFilterType(e.target.value)
    if (e.target.value === "1") {
      loadYearStats(Year)
    } else if (e.target.value === "2") {
      if ((Year + "-" + Month) === Date_Var.getFullYear() + "-" + ("0" + (Date_Var.getMonth() + 1)).slice(-2) + "-" + ("0" + Date_Var.getDate()).slice(-2)) {
        LoadCurrentMonthlyStats()
      } else {
        LoadMonthlyStats(Year + "-" + Month)
      }
    } else if (e.target.value === "3") {
      if (CurrentDate === Date_Var.getFullYear() + "-" + (('0' + Date_Var.getMonth() + 1).slice(-2)) + "-" + Date_Var.getDate()) {
        LoadDayStats(Date_Var.getFullYear() + "-" + (('0' + Date_Var.getMonth() + 1).slice(-2)) + "-" + Date_Var.getDate())
      } else {
        LoadDayStats(CurrentDate)
      }
    } else if (e.target.value === "4") {
      LoadCustomStats(FromDate, ToDate)
    }
  }


  const searchForm = (e) => {
    e.preventDefault()
    setTotalProducts(0)
    loadProductList(1, StatusFilters, OrderBy, storeId, user.id, SearchQuery, PageSize, Sortby, SortDirection)
  }

  useEffect(() => {
    LoadProductsData()
    if (localStorage.getItem('page-size')) {
      setPageSize(localStorage.getItem('page-size'))
      onLoadProductList(1, StatusFilters, OrderBy, 1, user.id, SearchQuery, localStorage.getItem('page-size'), Sortby, SortDirection)
    } else {
      onLoadProductList(1, StatusFilters, OrderBy, 1, user.id, SearchQuery, PageSize, Sortby, SortDirection)
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>{ CompanyProfile['companyName'] } Products</title>
        <meta
          name="description"
          content={ `${CompanyProfile['companyName']} Products` }
        />
        <link rel="stylesheet" href="../assets/css/style.css" />
        <script src="../assets/js/script.js" />
      </Helmet>
      <div style={ { display: LoadingVisibility } }>
        <div id="global-loader" >
          <div className="whirly-loader"> </div>
        </div>
      </div>
      <div className="content">
        <div style={ { display: dataVisibility } }>
          <ProductKPIs
            numberFormatter={ numberFormatter }
            currencyFormatter={ formatter }
            KPIsData={ KPIsData }
          />
          <SearchComponent
            placeholder={ "Product Name / SKU / Offer ID / TSIN ID" }
            SearchQuery={ SearchQuery }
            onSearchInputChange={ onSearchInputChange }
            searchForm={ searchForm }
          />
          <hr />
          <ProductsList
            PageSize={ PageSize }
            storeId={ storeId }
            onPageSizeChange={ onPageSizeChange }
            onStatusFilterChange={ onStatusFilterChange }
            ProductList={ ProductList }
            TotalProducts={ TotalProducts }
            LoadProductDetails={ LoadProductDetails }
            formatter={ formatter }
            numberFormatter={ numberFormatter }
            ProductInfo={ ProductInfo }
            WarehouseDetails={ WarehouseDetails }
            onCustomOrderByChange={ onCustomOrderByChange }
            TotalPages={ TotalPages }
            loadProductList={ loadProductList }
            OrderBy={ OrderBy }
            StatusFilters={ StatusFilters }
            SearchQuery={ SearchQuery }
            LoadBuyBoxDetails={ LoadBuyBoxDetails }
            LoadProductCostDetails={ LoadProductCostDetails }
            Sortby={ Sortby }
            SortDirection={ SortDirection }
            onSortChange={ onSortChange }
            onSortDirectionChange={ onSortDirectionChange }
          />
          <ProductDetails
            storeId={ storeId }
            formatter={ formatter }
            numberFormatter={ numberFormatter }
            LoadingSalesDetailsVisibility={ LoadingSalesDetailsVisibility }
            SalesDetailsVisibility={ SalesDetailsVisibility }
            ProductInfo={ ProductInfo }
            WarehouseDetails={ WarehouseDetails }
            onUpdatedProductDetailsChange={ onUpdatedProductDetailsChange }
            UpdatedProductDetails={ UpdatedProductDetails }
            onUpdatedProductDetailsSubmit={ onUpdatedProductDetailsSubmit }
            onProductCostSubmit={ onProductCostSubmit }
            onProductCostChange={ onProductCostChange }
            ProductCost={ ProductCost }
          />
          <BuyboxModal
            storeId={ storeId }
            formatter={ formatter }
            numberFormatter={ numberFormatter }
            LoadingSalesDetailsVisibility={ LoadingSalesDetailsVisibility }
            SalesDetailsVisibility={ SalesDetailsVisibility }
            ProductInfo={ ProductInfo }
            WarehouseDetails={ WarehouseDetails }
            onUpdatedProductDetailsChange={ onUpdatedProductDetailsChange }
            UpdatedProductDetails={ UpdatedProductDetails }
            OtherOffers={ OtherOffers }
            onBuyBoxSubmit={ onBuyBoxSubmit }
            BuyBox={ BuyBox }
            onBuyBoxChange={ onBuyBoxChange }
            HourDetails={ HourDetails }
            setBuyBox={ setBuyBox }
          />
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  isAuthenticated: state.Auth.isAuthenticated,
  user: state.Auth.user,
})

export default connect(mapStateToProps, {})(StoreProducts)