import React from 'react'
import { NavLink } from 'react-router-dom'

const SalesModal = (props) => {
    const storeId = props.storeId
    const onChange = props.onChange
    const formatter = props.formatter
    const FeeDetails = props.FeeDetails
    const TotalOrderProducts = props.TotalOrderProducts
    const OrderDetails = props.OrderDetails
    const CustomerDetails = props.CustomerDetails
    const CustomerAddressVisible = props.CustomerAddressVisible
    const setCustomerAddressVisible = props.setCustomerAddressVisible
    return (
        <>

            <div className="modal fade" id={ "modalCenter-" + storeId } tabindex="-1" aria-hidden="true">
                <div className="modal-dialog  modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id={ "modalCenterTitle-" + storeId } style={ { alignItems: "center" } }>Sales and Fee Details</h4>
                        </div>

                        <div className="modal-body">
                            <div style={ { display: 'block' } } className="row">
                                <div className="row mb-5">
                                    <div className="col-md">
                                        <div className="mb-1">
                                            <div className="row g-0">
                                                <div className="col-md-4 col-sm-12">
                                                    <img className="card-img card-img-left" src={ OrderDetails['image_url'] } alt="Card image" />
                                                </div>
                                                <div className="col-md-8 col-sm-12">
                                                    <div className="card-body">
                                                        <h5 className="card-title">{ OrderDetails['product_title'] }</h5>
                                                        <div className="row g-2">
                                                            <div className="col mb-0">
                                                                <label className="form-label">Order Time : <b>{ OrderDetails['order_time'] }</b></label>
                                                            </div>
                                                            <div className="col mb-0">
                                                                <label className="form-label">Order SKU : <b>{ OrderDetails['sku'] }</b></label>
                                                            </div>
                                                        </div>
                                                        <br />
                                                        <div className="row g-2">
                                                            <div className="col mb-0">
                                                                <label className="form-label">Order Date : <b>{ OrderDetails['order_date'] }</b></label>
                                                            </div>
                                                            <div className="col mb-0">
                                                                <label className="form-label">Order No : <b>{ OrderDetails['order_id'] }</b></label>
                                                            </div>
                                                        </div>
                                                        <div className="row g-2">
                                                            <div className="col mb-0">
                                                                <label className="form-label">Gross Sales : <b>{ formatter.format(OrderDetails['sales']) }</b></label>
                                                            </div>
                                                        </div>
                                                        <div className="row g-2">
                                                            <div className="col mb-0">
                                                                <label className="form-label">Fee : <b>{ OrderDetails['fee'] ? formatter.format(OrderDetails['fee']) : formatter.format(0) }</b></label>
                                                            </div>
                                                        </div>
                                                        <div className="row g-2">
                                                            <div className="col mb-0">
                                                                <label className="form-label">Net Sales : <b>{ OrderDetails['fee'] ? formatter.format(OrderDetails['net_sales']) : formatter.format(OrderDetails['sales']) }</b></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    OrderDetails['fee'] ?
                                        <>
                                            <table className="table table-borderless">
                                                <thead>
                                                    <tr>
                                                        <th>Takealot Fee</th>
                                                        <th>Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Fulfilment Fee</td>
                                                        <td>{ FeeDetails['fulfillment_fee'] ? formatter.format(FeeDetails['fulfillment_fee']) : formatter.format(0) }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Success Fee</td>
                                                        <td>{ FeeDetails['success_fee'] ? formatter.format(FeeDetails['success_fee']) : formatter.format(0) }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Courier Collection Fee</td>
                                                        <td>{ FeeDetails['courier_collection_fee'] ? formatter.format(FeeDetails['courier_collection_fee']) : formatter.format(0) }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Auto IBT Fee</td>
                                                        <td>{ FeeDetails['auto_ibt_fee'] ? formatter.format(FeeDetails['auto_ibt_fee']) : formatter.format(0) }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Fee</td>
                                                        <td>{ OrderDetails['fee'] ? formatter.format(OrderDetails['fee']) : formatter.format(0) }</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </> : <></>
                                }
                            </div>
                        </div>
                        <div className="modal-footer">
                            {
                                TotalOrderProducts === 1 ?
                                    <button type="button" data-bs-target={ "#invoiceModal-" + storeId } data-bs-toggle="modal" data-bs-dismiss="modal" className="btn btn-primary">Create Order Invoice</button>
                                    :
                                    <>
                                        <button type="button" data-bs-target={ "#invoiceModal-" + storeId } data-bs-toggle="modal" data-bs-dismiss="modal" className="btn btn-primary">Create Single Invoice</button>
                                        <button type="button" data-bs-target={ "#invoiceCombinedModal-" + storeId } data-bs-toggle="modal" data-bs-dismiss="modal" className="btn btn-primary">Create Combined Invoice</button>
                                    </>
                            }
                            <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="modal fade"
                id={ "invoiceModal-" + storeId }
                aria-hidden="true"
                aria-labelledby="modalToggleLabel2"
                tabindex="-1"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalToggleLabel2">Download Invoice</h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col mb-3">
                                    <label htmlFor="CustomerVAT" className="form-label">Customer VAT No</label>
                                    <input type="text" id="CustomerVAT" className="form-control" name="customerVAT" onChange={ (e) => onChange(e) } placeholder="Customer VAT Number" />
                                </div>
                                <div className="col mb-3">
                                    <label htmlFor="CustomerReg" className="form-label">Customer Reg. No</label>
                                    <input type="text" id="CustomerReg" className="form-control" name="customerReg" onChange={ (e) => onChange(e) } placeholder="Customer Registration Number" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col mb-3">
                                    <label htmlFor="CustomerName" className="form-label">Customer Name</label>
                                    <input type="text" id="CustomerName" className="form-control" name="customerName" onChange={ (e) => onChange(e) } value={ CustomerDetails['customerName'] } />
                                </div>
                                <div className="col mb-3">
                                    <label htmlFor="businessName" className="form-label">Business Name</label>
                                    <input type="text" id="businessName" className="form-control" placeholder='Customer Business Name' name="customerBusinessName" onChange={ (e) => onChange(e) } value={ CustomerDetails['customerBusinessName'] } />
                                </div>
                                <div className="form-check mt-3">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={ (e) => { CustomerAddressVisible ? setCustomerAddressVisible(false) : setCustomerAddressVisible(true) } } />
                                    <label className="form-check-label" htmlfor="defaultCheck1"> Include User Address </label>
                                </div>
                                {
                                    CustomerAddressVisible ?
                                        <>
                                            <div className="col mb-0">
                                                <label htmlFor="CustomerAddress" className="form-label">Customer Address</label>
                                                <input type="text" id="customerAddressStreet" className="form-control" name="customerAddressStreet" placeholder='Street' onChange={ (e) => onChange(e) } />
                                                <input type="text" id="customerAddressCity" className="form-control" name="customerAddressCity" placeholder='City' onChange={ (e) => onChange(e) } />
                                                <input type="text" id="customerAddressPostalCode" className="form-control" name="customerAddressPostalCode" placeholder='Post Address' onChange={ (e) => onChange(e) } />
                                                <input type="text" id="customerAddressCountry" className="form-control" name="customerAddressCountry" placeholder='Country' onChange={ (e) => onChange(e) } />
                                            </div>
                                        </> : <></>
                                }
                            </div>
                        </div>
                        <div className="modal-footer">
                            <NavLink
                                to={ { pathname: "/invoice" } }
                                state={ { "OrderDetails": OrderDetails, "CustomerDetails": CustomerDetails } }>
                                <button type="button" className="btn btn-primary" data-bs-dismiss="modal">
                                    Generate
                                </button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="modal fade"
                id={ "invoiceCombinedModal-" + storeId }
                aria-hidden="true"
                aria-labelledby="modalToggleLabel2"
                tabindex="-1"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalToggleLabel2">Download Invoice</h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col mb-3">
                                    <label htmlFor="CustomerVAT" className="form-label">Customer VAT No</label>
                                    <input type="text" id="CustomerVAT" className="form-control" name="customerVAT" onChange={ (e) => onChange(e) } placeholder="Customer VAT Number" />
                                </div>
                                <div className="col mb-3">
                                    <label htmlFor="CustomerReg" className="form-label">Customer Reg. No</label>
                                    <input type="text" id="CustomerReg" className="form-control" name="customerReg" onChange={ (e) => onChange(e) } placeholder="Customer Registration Number" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col mb-3">
                                    <label htmlFor="CustomerName" className="form-label">Customer Name</label>
                                    <input type="text" id="CustomerName" className="form-control" name="customerName" onChange={ (e) => onChange(e) } value={ CustomerDetails['customerName'] } />
                                </div>
                                <div className="col mb-3">
                                    <label htmlFor="businessName" className="form-label">Business Name</label>
                                    <input type="text" id="businessName" className="form-control" placeholder='Customer Business Name' name="customerBusinessName" onChange={ (e) => onChange(e) } value={ CustomerDetails['customerBusinessName'] } />
                                </div>
                                <div className="form-check mt-3">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={ (e) => { CustomerAddressVisible ? setCustomerAddressVisible(false) : setCustomerAddressVisible(true) } } />
                                    <label className="form-check-label" htmlfor="defaultCheck1"> Include User Address </label>
                                </div>
                                {
                                    CustomerAddressVisible ?
                                        <>
                                            <div className="col mb-0">
                                                <label htmlFor="CustomerAddress" className="form-label">Customer Address</label>
                                                <input type="text" id="customerAddressStreet" className="form-control" name="customerAddressStreet" placeholder='Street' onChange={ (e) => onChange(e) } />
                                                <input type="text" id="customerAddressCity" className="form-control" name="customerAddressCity" placeholder='City' onChange={ (e) => onChange(e) } />
                                                <input type="text" id="customerAddressPostalCode" className="form-control" name="customerAddressPostalCode" placeholder='Post Address' onChange={ (e) => onChange(e) } />
                                                <input type="text" id="customerAddressCountry" className="form-control" name="customerAddressCountry" placeholder='Country' onChange={ (e) => onChange(e) } />
                                            </div>
                                        </> : <></>
                                }
                            </div>
                        </div>
                        <div className="modal-footer">
                            <NavLink
                                to={ { pathname: "/combinedInvoice" } }
                                state={ { "OrderDetails": OrderDetails, "store": OrderDetails?.dashboard_id, "CustomerDetails": CustomerDetails } }>
                                <button type="button" className="btn btn-primary" data-bs-dismiss="modal">
                                    Generate Combined
                                </button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SalesModal