import React, { useState } from 'react'
import LoginImage from '../../assets/img/login.jpg'
import Logo from '../../assets/img/companyLogo.png'
import LogoWhite from '../../assets/img/logo-white.png'
import MailIcon from '../../assets/img/icons/mail.svg'
import { connect } from 'react-redux'
import {Navigate, NavLink, useSearchParams} from 'react-router-dom'
import axios from 'axios'
import { useEffect } from 'react'

const ActivateAccount = ({resetPasswordConfirm, isAuthenticated}) => {
    const [eye, seteye] = useState(true)
    const [searchParams, setSearchParams] = useSearchParams()
    const [FormData, setFormData] = useState({
        new_password: "",
        re_new_password: "" 
    })
    const [errorData, setErrorData] = useState({
        status: "",
        message: ""
    })
    const [SuccessData, setSuccessData] = useState({
        status: "",
        message: ""
    })
    const [RequestSent, setRequestSent] = useState(false)
    const [SuccessVisibilty, setSuccessVisibilty] = useState("none")
    const [SubmissionErrorVisibilty, setSubmissionErrorVisibilty] = useState("none")
    const {
        new_password, re_new_password
    } = FormData
    const onChange = e => setFormData({...FormData, [e.target.name]: e.target.value})
    const [errors, seterrors] = useState({
        new_password: "",
        re_new_password: ""
    })
    const onEyeClick = () =>{
        seteye(!eye)
    }
    const uid = searchParams.get("uid")
    const token = searchParams.get("token")
    const activateUser = async (uid, token) => {
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios.defaults.xsrfCookieName = "csrftoken"
    
        const config = {
          headers: {
              'Content-Type' : 'application/json',
              "X-CSRFToken": "csrfToken"
          }
        }
        const Body = JSON.stringify({
            "uid":uid, 
            "token":token
        })
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/users/activation/`, Body ,config)
            setSuccessData({
                status: "Success",
                message: "Your account has been activated now you can login into your account."
            })
            setSubmissionErrorVisibilty("none");
            setSuccessVisibilty("block")
            setTimeout(() => {
                setSuccessVisibilty("none")
                setRequestSent(true)
            }, 3000)
        }catch (error) {
            setErrorData({
                status: "Error",
                message: "Invalid activation link."
            })
            setSubmissionErrorVisibilty("block");
            setSuccessVisibilty("none")
            setTimeout(() => {
                setSubmissionErrorVisibilty("none")
                setRequestSent(true)
            }, 3000)
        }
    }
    const [LoadingVisibility, setLoadingVisibility] = useState("none")
    
    useEffect(() => {
        activateUser(uid, token)
    }, [])
    
    if (RequestSent) {
        return <Navigate to='/signin' />
    }
    if (isAuthenticated == true) {
        return <Navigate to="/" />;
    }
    
    return (
        <>
            <div style={{display: LoadingVisibility}}>
                <div id="global-loader" >
                    <div className="whirly-loader"> </div>
                </div>
            </div>
            <div className="main-wrapper">
                <div className="account-content">
                    <div className="login-wrapper">
                        <div className="login-content">
                            <div className="login-userset">
                                <div className="alert alert-success text-center" style={{display: SuccessVisibilty}} role="alert">
                                    <p><b>{SuccessData.status}: {SuccessData.message} </b></p>
                                </div>
                                <div className="alert alert-danger text-center" style={{display: SubmissionErrorVisibilty}} role="alert">
                                    <p><b>{errorData.status}: {errorData.message} </b></p>
                                </div>
                            </div>
                        </div>
                        <div className="login-img">
                            <img src={LoginImage} alt="img" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.Auth.isAuthenticated,
})
  
  
export default connect(mapStateToProps)(ActivateAccount)