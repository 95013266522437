import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

const CompanyProfileComponent = (props) => {
    const user = props.user
    const loadSettings= props.LoadCompanyProfile
    const [userName, setUserName] = useState("")
    const [MessageTitle, setMessageTitle] = useState("")
    const [Message, setMessage] = useState("")
    const [MessageVisibility, setMessageVisibility] = useState("none")
    const [MessageType, setMessageType] = useState("primary")
    const [CompanyProfileImage, setCompanyProfileImage] = useState({
        company_id : "1",
        companyLogo : null
    })
    // console.log(JSON.stringify(CompanyProfileImage))
    const [CompanyProfile, setCompanyProfile] = useState({
        id: "1",
        companyName : "",
        companyAddressStreet : "",
        companyAddressCity : "",
        companyAddressCountry : "",
        companyAddressPostalCode : "",
        companyVAT : ""
    })
    const [userData, setUserData] = useState([])
    const LoadCompanyProfile = async() => {
        
        const config = {
            headers: {
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
                'Authorization' : `JWT ${localStorage.getItem('access')}`
            }
        }
        try {

            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/companyProfile/`,config)
            // console.log(response.data['company']['companyLogo'])
            setCompanyProfile(response.data['company'])
        } catch (error) {
            // console.log('first', error)
            setCompanyProfile({
                companyName : "",
                companyLogo : null
            })
        }
        
    }
    const UpdateCompanyProfile = async() => {
        
        const config = {
            headers: {
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
                'Authorization' : `JWT ${localStorage.getItem('access')}` 
            }
        } 

        const Body = JSON.stringify(CompanyProfile)
        try { 
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/updateCompanyProfile/`, Body, config)
            setMessageTitle("Company Profile")
            setMessage("Your company profile details have been updated")
            setMessageVisibility("block")
            setMessageType("primary")
            props.loadSettings()
        } catch (error) {
        //   console.log('first', error.response.data['Errors'])
            
            setMessageTitle(error.response.status)
            setMessage(error.response.statusText)
            setMessageVisibility("block")
            setMessageType("danger")
        //   setCompanyProfile({
        //     companyName : "",
        //     companyLogo : ""
        // })
        }    
        setTimeout(() => {
            setMessageVisibility("none")
        }, 3000)
        LoadCompanyProfile()
    }
    const UpdateCompanyProfileImage = async() => {
        
        const config = {
          headers: {
            'Content-Type' : 'application/json',
            'Accept' : 'application/json',
              'Authorization' : `JWT ${localStorage.getItem('access')}` 
          }
        } 

        const Body = JSON.stringify(CompanyProfileImage)
        try { 
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/updateCompanyProfileImage/`, Body, config)
            setMessageTitle("Company Profile Image")
            setMessage("Your company profile image has been updated")
            setMessageVisibility("block")
            setMessageType("primary")
            props.loadSettings()
        } catch (error) {
        //   console.log('first', error.response.data['Errors'])
            setMessageTitle(error.response.status)
            setMessage(error.response.statusText)
            setMessageVisibility("block")
            setMessageType("danger")
        //   setCompanyProfile({
        //     companyName : "",
        //     companyLogo : ""
        // })
        }     
        setTimeout(() => {
            setMessageVisibility("none")
        }, 3000)
        LoadCompanyProfile()
    }
    const convertToBase64 = (e) => {
        const reader = new FileReader()
            reader.readAsDataURL(e.target.files[0])    
            reader.onload = () => {
            //   console.log('called: ', reader)
                setCompanyProfileImage({...CompanyProfileImage,[e.target.name]: reader.result})
            //   setCompanyProfile({...CompanyProfile,[e.target.name]: e.target.files[0]})
        }
    }
    const onUpdateCompanyProfile = (e) => {
        e.preventDefault()
        UpdateCompanyProfile()    
        props.loadSettings()
    }
    const onUpdateCompanyProfileImage = (e) => {
          e.preventDefault()
          UpdateCompanyProfileImage()        
          LoadCompanyProfile()
          props.loadSettings()
    }
    // console.log(JSON.stringify(CompanyProfileImage))
    useEffect(() => {
        LoadCompanyProfile()
        if (user){
          setUserName(user["name"])
          setUserData(user)
        }
    },[userName, userData, user])
    return (
        <>
            
            <div className="content">
                {/* <div style={{display: MessageVisibility}}>
                    <PushNotification messageTitle={MessageTitle} message={Message} type={MessageType}  />
                </div> */}

                <div className="container-xxl flex-grow-1 container-p-y">
                    
                        {/* <!--/ Header -->

                        <!-- Navbar pills --> */}
                        <div className="row">
                            
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Company Profile / </span>Company Logo</h4>
                            <div className="col-xl-12" id="navs-tabs-line-card-profile">
                                {/* <!-- About User --> */}
                                <div className="card mb-4">
                                    <div className="tab-content p-0">
                                        <div className="card-body">
                                            <form className="" onSubmit={(e)=>{onUpdateCompanyProfileImage(e)}}>
                                                <div className="col mb-3">
                                                    <label htmlFor="companyLogo" className="form-label">Company Logo</label><br/>
                                                    <img src={props.CompanyLogo} width="20%" />
                                                </div>
                                                <div className="col mb-3">
                                                    <label htmlFor="updateCompanyLogo" className="form-label">Update Company Logo</label>
                                                    <input type="file" id="updateCompanyLogo" accept="image/png, image/jpeg" className="form-control" name="companyLogo" multiple={false} onChange={(e)=>(convertToBase64(e))} placeholder="Company Logo" />
                                                </div>
                                                <button type='submit' className="btn btn-md btn-primary col-12">Update  </button>

                                            </form>
                                        </div>

                                    </div>

                                </div>
                                {/* <!--/ Profile Overview --> */}
                            </div>
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Company Profile / </span>Company profile</h4>
                            <div className="col-xl-12" id="navs-tabs-line-card-profile">
                                {/* <!-- About User --> */}
                                <div className="card mb-4">
                                    <div className="tab-content p-0">
                                        <div className="card-body">
                                            <form className="" onSubmit={(e)=>{onUpdateCompanyProfile(e)}}>
                                                <div className='row'>
                                                    <div className="col mb-3">
                                                        <label htmlFor="companyName" className="form-label">Company Name</label>
                                                        <input type="text" id="companyName" required className="form-control" name="companyName" onChange={(e)=>(setCompanyProfile({...CompanyProfile,companyName: e.target.value}))}placeholder="Company Name" value={CompanyProfile['companyName']} />
                                                    </div>
                                                    <div className="col-6 mb-3">
                                                        <label htmlFor="CompanyStreetAddress" className="form-label">Company Street Address</label>
                                                        <input type="text" id="CompanyStreetAddress" required className="form-control" name="companyAddressStreet" onChange={(e)=>(setCompanyProfile({...CompanyProfile,[e.target.name]: e.target.value}))} placeholder="Street" value={CompanyProfile['companyAddressStreet']} />
                                                    </div>
                                                    <div className="col-6 mb-3">
                                                        <label htmlFor="CompanyAddressCity" className="form-label">Company Address City</label>
                                                        <input type="text" id="CompanyAddressCity" required className="form-control" name="companyAddressCity" onChange={(e)=>(setCompanyProfile({...CompanyProfile,[e.target.name]: e.target.value}))} placeholder="City" value={CompanyProfile['companyAddressCity']} />
                                                    </div>
                                                    <div className="col-6 mb-3">
                                                        <label htmlFor="CompanyAddressPostalCode" className="form-label">Company Address Postal Code</label>
                                                        <input type="text" id="CompanyAddressPostalCode" required className="form-control" name="companyAddressPostalCode" onChange={(e)=>(setCompanyProfile({...CompanyProfile,[e.target.name]: e.target.value}))} placeholder="Postal Code" value={CompanyProfile['companyAddressPostalCode']} />
                                                    </div>
                                                    <div className="col-6 mb-3">
                                                        <label htmlFor="CompanyAddressCountry" className="form-label">Company Address Country</label>
                                                        <input type="text" id="CompanyAddressCountry" required className="form-control" name="companyAddressCountry" onChange={(e)=>(setCompanyProfile({...CompanyProfile,[e.target.name]: e.target.value}))} placeholder="Country" value={CompanyProfile['companyAddressCountry']} />
                                                    </div>
                                                    <div className="col-6 mb-3">
                                                        <label htmlFor="CompanyAddressVAT" className="form-label">Company Address VAT</label>
                                                        <input type="text" id="CompanyAddressVAT" required className="form-control" name="companyVAT" onChange={(e)=>(setCompanyProfile({...CompanyProfile,[e.target.name]: e.target.value}))} placeholder="Company VAT" value={CompanyProfile['companyVAT']} />
                                                    </div>
                                                </div>
                                                <button type='submit' className="btn btn-md btn-primary col-12">Update  </button>

                                            </form>
                                        </div>

                                    </div>

                                </div>
                                {/* <!--/ Profile Overview --> */}
                            </div>

                        </div>
                        {/* <!--/ Navbar pills -->

                        <!-- User Profile Content --> */}
                        <div className="row">
                        </div>
                </div>
            </div>            
        </>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.Auth.isAuthenticated,
    user: state.Auth.user,
})
export default connect(mapStateToProps)(CompanyProfileComponent)