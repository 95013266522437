import React, { useState, useEffect } from 'react'
import './Styles/Invoice.css'
import { Form, Navigate, NavLink, useLocation } from 'react-router-dom'
import logo from '../../assets/img/logo.png'
import axios from 'axios'
import Moment from 'moment'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
const InvoiceComponent = (props) => {
    // const CompanyProfile = props.CompanyProfile
    const [StoreProfile, setStoreProfile] = useState({
        store_name: ""
    })
    const [StoreLogo, setStoreLogo] = useState("")
    const CompanyLogo = props.CompanyLogo
    const location = useLocation()
    const { state } = location
    const CustomerDetails = state['CustomerDetails']
    const OrderDetails = state['OrderDetails']
    const [VAT, setVAT] = useState(0)
    const getVATValue = async () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify({
            "account": props.user.id,
            "order_id": OrderDetails["order_id"],
            "store": OrderDetails["store"]
        })
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v3/vat/`, Body, config)
            setVAT(response.data['vat'])
        } catch (error) {

        }
    }
    const getStoreDetails = async () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify({
            "order_id": OrderDetails["order_id"],
            "store": OrderDetails["store"],
        })
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v4/store/get/order/store/details/`, Body, config)
            setStoreProfile(response.data['data'])
        } catch (error) {

        }
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v4/store/get/order/store/logo/`, Body, config)
            setStoreLogo(response.data['data'])
        } catch (error) {

        }
    }
    const [Loader, setLoader] = useState(false)
    const DownloadPDF = async (e) => {
        e.preventDefault()
        setLoader(true)
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            },
            responseType: 'blob' // Important for file download
        }
        const Body = JSON.stringify(
            {
                "dashboard": 1,
                "CustomerDetails": {
                    "customerBusinessName": CustomerDetails['customerBusinessName'] ? CustomerDetails['customerBusinessName'] : "",
                    "customerName": CustomerDetails['customerName'] ? CustomerDetails['customerName'] : "",
                    "customerAddressStreet": CustomerDetails['customerAddressStreet'] ? CustomerDetails['customerAddressStreet'] : "",
                    "customerAddressCity": CustomerDetails['customerAddressCity'] ? CustomerDetails['customerAddressCity'] : "",
                    "customerAddressPostalCode": CustomerDetails['customerAddressPostalCode'] ? CustomerDetails['customerAddressPostalCode'] : "",
                    "customerAddressCountry": CustomerDetails['customerAddressCountry'] ? CustomerDetails['customerAddressCountry'] : "",
                    "customerVAT": CustomerDetails['customerVAT'] ? CustomerDetails['customerVAT'] : "",
                    "customerReg": CustomerDetails['customerReg'] ? CustomerDetails['customerReg'] : "",
                },
                "OrderDetails": {
                    "store": OrderDetails["store"],
                    "order_date": OrderDetails["order_date"],
                    "order_id": OrderDetails["order_id"],
                    "product_title": OrderDetails["product_title"],
                    "sales": OrderDetails['sales'],
                    "units": OrderDetails['units'],
                    "totals": OrderDetails['sales'],
                    "subtotals": Number(OrderDetails['sales'] / Number(`1.${VAT}`)),
                    "vat": Number(OrderDetails['sales']) - Number(OrderDetails['sales'] / Number(`1.${VAT}`)),
                }
            }
        )
        try {
            // return axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/products/sales/invoice/`,Body, config).then(function (response) {
            //     return response
            // })
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/products/sales/invoice/`, Body, config)
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Invoice for ${CustomerDetails['customerName']}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            // console.log('first', error)
        }
        setLoader(false)
    }
    const numberFormatter = new Intl.NumberFormat('en-ZA', {
        // These options are needed to round to whole numbers if that's what you want.
        minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    })
    const formatter = new Intl.NumberFormat('en-ZA', {
        style: 'currency',
        currency: 'ZAR',

        // These options are needed to round to whole numbers if that's what you want.
        minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    })
    useEffect(() => {
        getStoreDetails()
        getVATValue()
    }, [])

    return (
        <>
            <Helmet>
                <title>{ StoreProfile['store_name'] } Sales</title>
                <meta
                    name="description"
                    content={ `${StoreProfile['store_name']} Sales Dashboard` }
                />
                <link rel="stylesheet" href="../assets/css/style.css" />
                <script src="../assets/js/script.js" />
            </Helmet>
            <div className="content">
                <div className="card mb-0">
                    <div className="card-body">
                        <div className="card-header pb-0 d-flex justify-content-between align-items-center">
                            <h4 className="card-title">Sale Invoice</h4>
                            <div className="graph-sets">
                                <div className="px-1 w-100">
                                    <button type='button' onClick={ (e) => { DownloadPDF(e) } } className="btn btn-md btn-primary">Download Invoice</button><br />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            {
                                Loader ?
                                    <div className='skeleton-loader'></div>
                                    :
                                    <div className="col-xs-12">
                                        <br />
                                        <div className="invoice-title row">
                                            <div className="col-1"></div>
                                            <h2 className='col-5'>Invoice</h2>
                                            <h3 className="pull-right col-5" style={ { textAlign: "right" } }>Order # { OrderDetails['order_id'] }</h3>
                                            <div className="col-1"></div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-1"></div>
                                            <div className="col-5">
                                                <address>
                                                    <strong>Order date: { OrderDetails['order_date'] }</strong>
                                                </address>
                                                <address>
                                                    <strong>Invoice date: { Moment((new Date).getFullYear() + "-" + ("0" + ((new Date).getMonth() + 1)).slice(-2) + "-" + ("0" + (new Date).getDate())).format('DD MMM YYYY') }</strong>
                                                </address>
                                                <address>
                                                    <strong>Invoice Number: { OrderDetails['order_id'] }</strong>
                                                </address>
                                                <address>
                                                    <strong>Invoice for:</strong><br />
                                                    { CustomerDetails['customerBusinessName'] ? <><b>{ CustomerDetails['customerBusinessName'] }</b></> : <></> } <br />
                                                    { CustomerDetails['customerName'] } <br />
                                                    {
                                                        CustomerDetails['customerAddressStreet'] ?
                                                            <>
                                                                Street: { CustomerDetails['customerAddressStreet'] } <br />
                                                                City: { CustomerDetails['customerAddressCity'] } <br />
                                                                Postal Code: { CustomerDetails['customerAddressPostalCode'] } <br />
                                                                Country: { CustomerDetails['customerAddressCountry'] } <br />
                                                            </> : <></>
                                                    }
                                                </address>
                                                <address>
                                                    {
                                                        CustomerDetails['customerVAT'] ?
                                                            <>
                                                                <strong>Customer VAT: { CustomerDetails['customerVAT'] }</strong><br />
                                                            </> : <></>
                                                    }
                                                    {
                                                        CustomerDetails['customerReg'] ?
                                                            <>
                                                                <strong>Customer Reg No: { CustomerDetails['customerReg'] }</strong><br />
                                                            </> : <></>
                                                    }
                                                </address>
                                            </div>

                                            <div className="col-5" style={ { textAlign: "right" } }>
                                                <address>
                                                    <strong>Issued By:</strong><br />
                                                    {
                                                        StoreLogo ?
                                                            <img src={ `${process.env.REACT_APP_BACKEND_URL}/media/${StoreLogo}` } width="100" />
                                                            : <></>
                                                    }
                                                    <h3>{ StoreProfile['legal_name'] }</h3>
                                                    { StoreProfile['storeAddressStreet'] }<br />
                                                    { StoreProfile['storeAddressCity'] }<br />
                                                    { StoreProfile['storeAddressCountry'] }<br />
                                                    { StoreProfile['storeAddressPostalCode'] }<br />
                                                    {
                                                        StoreProfile?.storeVATNumber ?
                                                            <>
                                                                <strong>VAT: </strong>{ StoreProfile?.storeVATNumbe }
                                                            </>
                                                            : <></>

                                                    }
                                                </address>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-1"></div>
                                            <div className="col-10">
                                                <div className="panel-heading">
                                                    <h3 className="panel-title"><strong>Order summary</strong></h3>
                                                </div>
                                                <div className="panel-body">
                                                    <div className="table-responsive">
                                                        <table className="table table-condensed">
                                                            <thead>
                                                                <tr>
                                                                    <td><strong>Description</strong></td>
                                                                    <td className="text-center"><strong>Price</strong></td>
                                                                    <td className="text-center"><strong>Quantity</strong></td>
                                                                    <td className="text-right"><strong>Totals</strong></td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {/* <!-- foreach ($order->lineItems as $line) or some such thing here --> */ }
                                                                <tr>
                                                                    <td>{ OrderDetails['product_title'] }</td>
                                                                    <td className="text-center">{ formatter.format(OrderDetails['sales'] / OrderDetails['units']) }</td>
                                                                    <td className="text-center">{ numberFormatter.format(OrderDetails['units']) }</td>
                                                                    <td className="text-right">{ formatter.format(OrderDetails['sales']) }</td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td className="text-center"></td>
                                                                    <td className="text-center"></td>
                                                                    <td className="text-right"></td>
                                                                </tr>
                                                                {
                                                                    StoreProfile?.storeVAT !== 0 ?
                                                                        <>
                                                                            <tr>
                                                                                <td className="thick-line"></td>
                                                                                <td className="thick-line"></td>
                                                                                <td className="thick-line text-center"><strong>Subtotal (without VAT)</strong></td>
                                                                                <td className="thick-line text-right">{ formatter.format(Number(OrderDetails['sales'] / Number(`1.${VAT}`))) }</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="no-line"></td>
                                                                                <td className="no-line"></td>
                                                                                <td className="no-line text-center"><strong>VAT ({ VAT }%)</strong></td>
                                                                                <td className="no-line text-right">{ (formatter.format(Number(OrderDetails['sales']) - Number(OrderDetails['sales'] / Number(`1.${VAT}`)))) }</td>
                                                                            </tr>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <tr>
                                                                                <td className="thick-line"></td>
                                                                                <td className="thick-line"></td>
                                                                                <td className="thick-line"></td>
                                                                                <td className="thick-line"></td>
                                                                            </tr>
                                                                        </>
                                                                }
                                                                <tr>
                                                                    <td className="no-line text-center"><strong>Total</strong></td>
                                                                    <td className="no-line"></td>
                                                                    <td className="no-line"></td>
                                                                    <td className="no-line text-right">{ formatter.format(OrderDetails['sales']) }</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-1"></div>
                                        </div>

                                    </div>
                            }
                        </div>

                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-10">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = state => ({
    isAuthenticated: state.Auth.isAuthenticated,
    user: state.Auth.user,
})
export default connect(mapStateToProps)(InvoiceComponent)